import axios from 'axios';
import React, { useState, useEffect, useContext} from 'react'
import { MenuContext } from '../../../contexts/MenuContext'

import { AiOutlineSearch } from 'react-icons/ai';
import { BiChevronDown } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import {LoginDelyContext} from '../../../contexts/LoginDelyContext';

const SelectCategory = (props) => {

  const menus = useContext(MenuContext)
  const User = useContext(LoginDelyContext)
  const settings = User.page_info
  //const [menu, setMenu]= useState([])
  let menu = menus.menus
  let categories = menus.list_category
  const option = props.option
  const {t} = useTranslation()
  const [inputValue, setInputValue] = useState ("")
  /*const [selected, setSelected] =useState(()=>{
    const saved = localStorage.getItem("category");
    const initialValue = JSON.parse(saved);
    return initialValue || ""
  })*/
  let selected=props.selected
  const [open, setOpen] = useState(false)
  const restaurant_id = settings.user_id

useEffect(()=>{
  window.localStorage.setItem('category', JSON.stringify(selected));
},[selected]);


useEffect(()=>{
  if (menus.menus.length === 0) {
    console.log("useeffect Menu")
    menus.fetchMenu(restaurant_id);
  }else  if (menus.customDish.length === 0) {
    console.log("Useffect Custom Dish")
    menus.fetchAddIns(restaurant_id);
  }
},[])


  // Error and Loading states
	

  
  return (
    <div className='flex-row mx-auto w-[250px] items-center justify-center my-3'>

        <div
          onClick={()=>setOpen(!open)} 
          className={`bg-white w-full p-2 flex items-center justify-between rounded-xl ${selected} && "text-gray-700"`}>
            {selected.length!==0 
              ? selected?.length >25
                ? selected?.substring(0,25) + '...'
                : selected  
              :t("Select Category")}
            <BiChevronDown size={20} className={`${open && "rotate-180"}`}/>
        </div>
        <ul className={`bg-white my-2 overflow-y-auto rounded-xl ${open ? "max-h-60" : "max-h-0"}`}>
          <div className='flex items-center px-2 sticky top-0 bg-white'>
            <AiOutlineSearch size={20} className='text-gray-700'/>
            <input 
              type='text'
              value={inputValue}
              onChange={(e)=>setInputValue(e.target.value.toLowerCase())}
              placeholder='Enter Category Name' 
              className='placeholder:text-gray-700 p-2 outline-none' />
          </div>
          {categories?.find(ingredients=>ingredients.ingredient===option).category.map((category)=>(
           <li 
            key={category?.name} 
            className={`p-2 text-sm hover:bg-sky-600 hover:text-white 
            ${category?.name?.toLowerCase() === selected?.toLowerCase() && 'bg-sky-600 text-white'}
            ${category?.name?.toLowerCase().startsWith(inputValue) 
              ? 'block' 
              :'hidden'
            }`}
            onClick={()=>{
              if(category?.name?.toLowerCase() !== selected.toLowerCase()){
                //setSelected(category?.name);
                {props.handleCategorySelected(category?.name)};
                setOpen(false);
                setInputValue("");
              }

            }}
            >
              {category?.name}
            </li>
           ))}
 
        </ul>
      
    </div>
  );
};

export default SelectCategory
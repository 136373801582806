/*global google*/
import React , {useContext, useState, useRef,useEffect} from 'react'
import { LoginDelyContext } from '../../contexts/LoginDelyContext'
import {DeleteModalUser} from './'
import {AiFillDelete} from 'react-icons/ai'
import {AiOutlineUpload} from 'react-icons/ai'
import {useLoadScript, GoogleMap, Marker, Autocomplete, } from '@react-google-maps/api'
import { Loading } from '../../components'
import { useTranslation } from 'react-i18next';
import axios from 'axios'
import { FaCcStripe } from "react-icons/fa";

const User_Page = () => {
  const {t} = useTranslation()
  const User = useContext(LoginDelyContext)
  const user = User.restaurant_info
  const stripe_account = User.stripe_account
  const restaurant=true
  const [openSubmit, setOpenSubmit] = useState(false)
  const [message, setMessage]= useState("")
  const libraries = ['places', 'maps']
  let libRef = useRef(libraries)
  const {isLoaded} = useLoadScript({
    id:"google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: libRef.current,
  })
  const restaurantAddress = user.address

  /**@type <React.MutableRefObject<HTMLInputElement> */
  let addrMapRef = useRef("")

  localStorage.removeItem("option_selected");
  const [center, setCenter] = useState()

  useEffect(()=>{
    setMessage("")
      if(Object.entries(user?.address).length!==0){
        fromAddrtoGeoInit(user?.address)
      }else{
        fromAddrtoGeoInit("Napoli")
      }
  
  },[])

  const intialState = {
    first_name: user.first_name || "",
    last_name:user.last_name || "",
    email: user.email || "",
    address: user.address || "",
    gps_address: user.gps_address || "",
    city:user.city || "",
    company_name: user.company_name || "",
    vat_number:user.vat_number || "",
    country: user.country || "",
    phone:user.phone || "",
    postal_code: user.postal_code || "",
    paypal_account:user.paypal_account || "",
    registration_method: user.registration_method || "",
    iban:user.iban || "",
    bic:user.bic || "",
    credit_card:user.credit_card || "",
    vat_number:user.vat_number || "",
    lat: user.lat || "",
    lng: user.lng || "",
  }

  const initEmailState = {
    old_email : user.email,
    new_email : "",
  }
  const initPassState= {
    old_password : "",
    new_password_1: "",
    new_password_2: "",
  }
  const [newuser, setNewuser] = useState(user)
  const [modify, setModify] = useState(false)
  const [modifyEmail, setModifyEmail] = useState(false)
  const [modifyPass, setModifyPass] = useState(false)
 

  const [newEmail, setNewEmail] = useState(initEmailState)
  const [newPass, setNewPass] = useState(initPassState)
  const [open, setOpen] = useState(false)

 
  const fromAddrtoGeoInit = async (address) =>{
    if (address.length >0) {
      setMessage("")
      const geocoder = new google.maps.Geocoder()
      geocoder.geocode({ address: address }, (results, status) => {
        console.log(results, status)
        if (status === 'OK') { 
          setCenter(results[0].geometry.location)
          console.log(results)
        }
      })
    }
  }

  const fromAddrtoGeo = async (address) =>{
    if (address.length >0) {
      setMessage("")
      const geocoder = new google.maps.Geocoder()
      geocoder.geocode({ address: address }, (results, status) => {
        console.log(results, status)
        if (status === 'OK') { 
          setCenter(results[0].geometry.location)
          console.log(results)
          console.log(results[0].geometry.location)
          setNewAddress(address)

          let userClone = {}
          userClone = {...newuser }
          userClone["address"] = address
          userClone["lat"] = results[0].geometry.location.lat()
          userClone["lng"] = results[0].geometry.location.lng()
          console.log(userClone)
          try {
            if(results[0].address_components.some(type=>type.types[0]==="administrative_area_level_3")){
              userClone["city"] = results[0].address_components.find(type=>type.types[0]==="administrative_area_level_3").long_name
            }else if (results[0].address_components.some(type=>type.types[0]==="locality")){
              userClone["city"] = results[0].address_components.find(type=>type.types[0]==="locality").long_name
            }else if (results[0].address_components.some(type=>type.types[0]==="postal_town")){
              userClone["city"] = results[0].address_components.find(type=>type.types[0]==="postal_town").long_name
            }else{
              userClone["city"] = ""
            }
          } catch (error){
            console.log(error)
            userClone["city"] = ""
          }
          try {
            userClone["country"] = results[0].address_components.find(type=>type.types[0]==="country").long_name 
          } catch (error){
            console.log(error)
            userClone["country"] = ""
          }
          try {
            userClone["postal_code"] = results[0].address_components.find(type=>type.types[0]==="postal_code").long_name 
          } catch (error){
            console.log(error)
            userClone["postal_code"] = ""
          }
          setNewuser(userClone)
        } else {
          alert(t('Geocode was not successful for the following reason') + ': ' + status);
        }
      
      });
    }else{
      console.log("No address for geolocation")
    }
  };

  const [newAddress, setNewAddress] = useState(()=>{
    return user.address || ""
  })
  

  const handleChangeAddress = () => {
    setMessage("")
    setNewAddress(addrMapRef.current.value)
    let userClone = {}
    userClone = {...newuser }
    userClone["address"] = newAddress
    setNewuser(userClone)
  }

  console.log(restaurantAddress, newAddress)

  const handleChange = (e) => {
    
    let userClone = { };
    //if (id==="new"){
      userClone = {...newuser }
      userClone[e.target.name] = e.target.value;
      
      setNewuser(userClone);
    /*}else{
      console.log(dish)
      dishClone = {...dish}
      dishClone[e.target.name] = e.target.value;
      setNewdish(dishClone);
    }*/
  };

  const handleChangeEmail = (e) => {
    
    let emailClone = { };

    emailClone = {...newEmail }
    emailClone[e.target.name] = e.target.value;
      
    setNewEmail(emailClone);

  }

  const handleChangePass = (e) => {
    
    let passClone = { };

    passClone = {...newPass }
    passClone[e.target.name] = e.target.value;
      
    setNewPass(passClone);

  }

  const handleModify = () => {

    if (modify===true){
      handleDiscard()
    }else{
      setModify(!modify)
    }

  }

  const handleModifyEmail = (e) => {
    e.preventDefault()
    setModifyEmail(!modifyEmail)
    
  }

  const handleModifyPass = (e) => {
    e.preventDefault()
    setModifyPass(!modifyPass)

  }


  const handleDiscard = () => {

    setModify(!modify)
    setNewuser(intialState)

  }

  const handleDiscardEmail = (e) => {
    e.preventDefault()
    setModifyEmail(false)
    setNewEmail(initEmailState)

  }
  const handleDiscardPass = (e) => {
    e.preventDefault()
    setModifyPass(false)
    setNewEmail(initEmailState)

  }

  const handleSubmit= async (e) => {
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    e.preventDefault()
    setModify(false)
    Object.entries(newuser).map(([key, item])=>{
      if (newuser[key]?.length===0 ){
        newuser[key]=user[key]
      }
    })
    console.log(user)
    console.log(newuser)

    const updateuser = user

    Object.entries(user).map(([key, item]) => {
      updateuser[key] = newuser[key]
    })
    console.log(updateuser)
    User.staterestaurantDel(updateuser)
    
    User.update_user_info(updateuser,restaurant).then(async res=>{
      const response = res
      console.log(response)
      if (response.stat ===201){
        //setOpenSubmit(false)
        await axios.post(backend + "/api/stripe/update-account/",updateuser).then((res)=>{
          console.log(res)
          if(res.status===200){
            alert(t("Update successfully"))
            setOpenSubmit(false)     
          }
        }).catch((err) => {
          alert(err.message)
          console.log(err.message)});
      }else{
        //setOpenSubmit(false)
        alert(t("Error to Update, please try again or contact the support"))
      }
    })
  }

  const handleSubmitEmail= (e) => {
    
    e.preventDefault()
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    console.log(newEmail)
    newEmail.stripe_id = user.stripe_id
    newEmail.sub_stripe_id = user.sub_stripe_id
    const emailtest = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(newEmail.new_email)
    if (emailtest){
      User.update_email(newEmail,restaurant).then(async res=>{
        const response = res
        console.log(response)
        if (response.stat ===201){
          await axios.post(backend + "/api/stripe/update-account/",newEmail).then((res)=>{
            console.log(res)
            if(res.status===200){
              alert(t("Update successfully"))
              setModifyEmail(false)   
              window.location.reload()
            }
          }).catch((err) => {
            alert(err.message)
            console.log(err.message)});
        }else{
          alert(t("Error to update Email, please try again or contact the support"))
        }
      })
    }else{
      alert(t("Please Use a New Valid Email"))
    }
  }
  

  const handleSubmitPass= (e) => {
    e.preventDefault()
    console.log(newPass)
    if(newPass.new_password_1.length > 0 || newPass.new_password_2.length > 0){
      if (newPass.old_password.length > 0){
        if(newPass.new_password_1===newPass.new_password_2){
          User.update_pass(newPass,restaurant).then(async res=>{
            const response = res
            console.log(response)
            if (response.stat ===201){
              alert(t("Update successfully"))
              setModifyPass(false)    
              window.location.reload()
            }else{
              console.log(response.data)
              alert(t(response.data.message))
            }
          }).catch((err) => {
              alert(err.message)
              console.log(err.message)});
        }else{
          alert(t("The 2 New Passwords are different, they must match"))
        }
      }else {
        alert(t("Please Type the Old password"))
      }
    }else{
      alert(t("Please type The  New Password"))
    }
    
  }
  

  const handleDelAccount = async (e) => {
    e.preventDefault()
    const resp = await User.delUser().then(async res => {
      //const resp = await res
      //setResp(resp)
      return res
      
    })
    console.log(resp)
    if (resp?.stat !== 200){
      console.log(resp)

    }
  }

  let stylemodify = ""
  let color = ""
  let classNameInput=""

  if (modify){
    stylemodify = "" 
    color="bg-gray-100"
    classNameInput = "border-0 px-3 py-3 placeholder-gray-500 text-gray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
  }else{ 
    stylemodify = "readonly"
    color="bg-gray-200"
    classNameInput = "border-0 px-3 py-3 placeholder-gray-300 text-gray-600 bg-gray-100 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"}

  const handleStripeLink = () =>{
    const body = {stripe_id:stripe_account.connect.id , type : "account_update"}
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    console.log(body)
    axios.post(backend + "/api/stripe/link-account/",body).then((res)=>{
      console.log(res.data)
      if(res.data){
        console.log(res.data)
        window.open(res.data,"_blank")     
      }
    }).catch((err) => {
      alert(err.message)
      console.log(err.message)});
 
    }
  const[status,setStatus] = useState("")
  const[style_status,setStyle_status] = useState("")

  const pyament_status = () =>{
    let sts = ""
    console.log(stripe_account)
    if (Object.values(stripe_account.connect.capabilities).some(payment => payment==="inactive")){
      sts="Disabled"
      setStatus(sts)
      setStyle_status("text-red-500  bg-opacity-50")
    }else if (Object.values(stripe_account.connect.capabilities).some(payment => payment==="pending")){
      sts="Pending"
      setStatus(sts)
      setStyle_status("text-yellow-600  bg-opacity-50")
    }else {
      sts="Active"
      setStatus(sts)
      setStyle_status("text-green-600  bg-opacity-50")
    }
  }

  useEffect(()=>{
    pyament_status()
    console.log(status, style_status)
  },[stripe_account])

  console.log(newuser)
  console.log(user)

  if (!isLoaded) return  <Loading />
  return (
     <div className='flex flex-col px-4 mx-auto my-3 '>

      <div className={`flex flex-col w-full mb-6 shadow-lg rounded-lg ${color}`}>
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-gray-700 text-xl font-bold">
              My account
            </h6>
            <button 
            className="bg-pink-500 text-white hover:bg-pink-700 active:bg-green-700 text-xl font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" 
            onClick={handleModify}>
              {t("Modify")}
            </button>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">   
        {user.registration_method!=="email" ? 
          <div>
            <h6 className="text-gray-400 text-sm mt-3 mb-6 font-bold uppercase">
              {t("Contact Information")}
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-gray-600 text-xs font-bold mb-2"  >
                    {t("First Name")}
                  </label>
                  <input 
                    type="text" 
                    className="border-0 px-3 py-3 placeholder-gray-300 text-gray-600 bg-gray-100 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder={user.first_name === "" ? t("First Name") + "..." : user.first_name}
                    name="first_name"
                    value={newuser.first_name}
                    onChange={handleChange}
                    readOnly="readonly"/>
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-gray-600 text-xs font-bold mb-2"  >
                    {t("Last Name")}
                  </label>
                  <input 
                    type="text" 
                    className="border-0 px-3 py-3 placeholder-gray-300 text-gray-600 bg-gray-100 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder={user.last_name === "" ? t("Last Name") + "..." : user.last_name}
                    name="last_name"
                    value={newuser.last_name}
                    onChange={handleChange}
                    readOnly="readonly"/>
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-gray-600 text-xs font-bold mb-2"  >
                    {t("Company Name")}
                  </label>
                  <input 
                    type="text" 
                    className={classNameInput}
                    placeholder={user.company_name === "" ? t("Company Name") + "..." : user.company_name}
                    name="company_name"
                    value={newuser.company_name}
                    onChange={handleChange}
                    readOnly={stylemodify}/>
                </div>
              </div>
              {!modify ? 
              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-gray-600 text-xs font-bold mb-2"  >
                    {t("Address")}
                  </label>
                  <input 
                    type="text" 
                    className={classNameInput}
                    placeholder={user.address === "" ? t("Address") + "..." : user.address}
                    name="address"
                    value={newuser.address}
                    onChange={handleChange}
                    readOnly={stylemodify}/>
                </div>
              </div>
              :
              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-gray-600 text-xs font-bold mb-2"  >
                    {t("Address")}
                  </label>
                  <Autocomplete >
                    <input
                      placeholder={t("Address")}
                      name="address"
                      className=" block rounded-lg h-[30px] px-1 break-words border-1 border-blue-800 mx-auto w-[350px] md:w-[550px] my-1"
                      ref={addrMapRef}
                      value={newAddress}
                      onChange={handleChangeAddress}>
                      
                    </input>
                  </Autocomplete>
                  <button className='flex mx-auto border my-2 bg-blue-400 text-black items-center justify-center text-center cursor-pointer hover:bg-blue-800 hover:text-white'
                    onClick={()=>fromAddrtoGeo(addrMapRef.current.value)}>
                    {t("Confirm & Verify on Map")}
                  </button>
                
                
                  <div className='flex mx-auto items-center justify-center mt-3 mb-2 ' >
                    <GoogleMap center={center} zoom={14} mapContainerStyle={{width:'300px',height: '200px'}}
                    options={{
                      zoomControl: false,
                      streetViewControl: false,
                      mapTypeControl: false,
                      fullscreenControl:false,
                    }}>
                      <Marker position={center}/>
                    </GoogleMap>
                  </div>
                </div>
              </div>
              
              }
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-gray-600 text-xs font-bold mb-2"  >
                    {t("City")}
                  </label>
                  <input 
                    type="text" 
                    className={classNameInput}
                    placeholder={user.city === "" ? t("City") + "..." : user.city}
                    name="city"
                    value={newuser.city}
                    onChange={handleChange}
                    readOnly={stylemodify}/>
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-gray-600 text-xs font-bold mb-2"  >
                    {t("Country")}
                  </label>
                  <input 
                    type="text" 
                    className={classNameInput}
                    placeholder={user.country === "" ? t("Country") + "..." : user.country}
                    name="country"
                    value={newuser.country}
                    onChange={handleChange}
                    readOnly={stylemodify}/>
                </div>
              </div>
              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-gray-600 text-xs font-bold mb-2"  >
                    {t("Phone Number")}
                  </label>
                  <input 
                    type="text" 
                    className={classNameInput}
                    placeholder={user.phone === "" ? t("Phone Number") + "..." : user.phone}
                    name="phone"
                    value={newuser.phone}
                    onChange={handleChange}
                    readOnly={stylemodify}/>
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-gray-600 text-xs font-bold mb-2"  >
                    {t("Postal Code")}
                  </label>
                  <input 
                    type="text" 
                    className={classNameInput}
                    placeholder={user.postal_code === "" ? t("Postal Code") + "..." : user.postal_code}
                    name="postal_code"
                    value={newuser.postal_code}
                    onChange={handleChange}
                    readOnly={stylemodify}/>
                </div>
              </div>
            </div>
            <hr className="mt-6 border-b-1 border-gray-300"/>
            <div className='flex justify-between items-center'>
              <h6 className="text-gray-400 text-sm mt-3 mb-6 font-bold uppercase">
                {t("Bank Account Information - Payment") + " - "}
                <span className={`${style_status}`}>{" " + t(status)}</span>

              </h6>
              <div className='bg-teal-100 flex-row flex p-1.5 rounded-xl shadow-xl hover:bg-teal-200 hover:font-semibold justify-center items-center cursor-pointer'
              onClick={handleStripeLink}>
                 < FaCcStripe size={40} color="green" className='mr-2'/>
                {t("Update")}</div>
            </div>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-gray-600 text-xs font-bold mb-2"  >
                    Iban
                  </label>
                  <input 
                    type="text" 
                    className={classNameInput}
                    placeholder={user.iban === "" ? "Iban..." : user.iban}
                    name="iban"
                    value={newuser.iban}
                    onChange={handleChange}
                    readOnly={stylemodify}/>
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-gray-600 text-xs font-bold mb-2"  >
                    BIC
                  </label>
                  <input 
                    type="text" 
                    className={classNameInput}
                    placeholder={user.bic === "" ? "BIC..." : user.bic}
                    name="bic"
                    value={newuser.bic}
                    onChange={handleChange}
                    readOnly={stylemodify}/>
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-gray-600 text-xs font-bold mb-2"  >
                    {"PAYPAL ACCOUNT (EMAIL)"} 
                  </label>
                  <input 
                    type="email" 
                    className={classNameInput}
                    placeholder={user.paypal_account === "" ? "PAYPAL ACCOUNT (EMAIL)..." : user.paypal_account}
                    name="paypal_account"
                    value={newuser.paypal_account}
                    onChange={handleChange}
                    readOnly={stylemodify}/>
                </div>
              </div>
              {modify ?
              <div className="w-full items-center justify-center flex mt-3">
              <button 
                className='flex bg-green-400 rounded-xl mx-1 hover:bg-green-600  hover:text-gray-100 w-[110px] text-center'
                onClick={handleSubmit}
                >
                {t("Confirm Update")}</button>
              <button 
                className='flex bg-red-400 rounded-xl mx-1 hover:bg-red-300  hover:text-gray-300 w-[110px] text-center'
                onClick={handleDiscard}
              >{t("Discard Update")}</button>
              </div>
              : ""}
            </div>
          </div>
          :
          <div>
            <div className="text-center flex justify-between mt-2">
              <h6 className="text-gray-400 text-sm mt-3 mb-6 font-bold uppercase">
                {t("User Access Information")} 
              </h6>
            </div>
            <div className="flex flex-wrap">
            {modifyEmail ?
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">      
                  <label className="block uppercase text-gray-600 text-xs font-bold mb-2"  >
                    {t("Old Email address")}
                  </label>
                    <input 
                      type="text" 
                      className="border-0 px-3 py-3 placeholder-gray-500 text-gray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder={newEmail.old_email}
                      name="email"
                      value={newEmail.old_email}
                      readOnly="readonly"/>
                  <label className="block uppercase text-gray-600 text-xs font-bold my-2"  >
                    {t("New Email address")}
                  </label>
                    <input 
                      type="text" 
                      className="border-0 px-3 py-3 placeholder-gray-500 text-gray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder={t("New Email address") + "..."}
                      name="new_email"
                      value={newEmail.new_email}
                      onChange={handleChangeEmail}
                    />
                  <div className="w-full items-center justify-center flex mt-3">
                    <button 
                      className='flex bg-green-400 rounded-xl mx-1 hover:bg-green-600  hover:text-gray-100 w-[100px]'
                      onClick={handleSubmitEmail}
                      >
                      {t("Confirm Update")}</button>
                    <button 
                      className='flex bg-red-400 rounded-xl mx-1 hover:bg-red-300  hover:text-gray-300 w-[100px]'
                      onClick={handleDiscardEmail}
                    >{t("Discard Update")}</button>
                  </div>
                </div>
              </div>
              :
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3"> 
                    <label className="block uppercase text-gray-600 text-xs font-bold mb-2"  >
                      {t("Email address")}
                    </label>
                    <input 
                      type="text" 
                      className="border-0 px-3 py-3 placeholder-gray-300 text-gray-600 bg-gray-100 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder={newEmail.old_email}
                      name="email"
                      readOnly="readonly"/>
                    <button 
                      className="hidden sm:block sm:absolute right-0 top-7 h-[35px] bg-blue-500 text-white hover:bg-blue-700 active:bg-green-700  font-semibold uppercase px-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-3 ease-linear transition-all duration-150" 
                      onClick={handleModifyEmail}>
                      {t("Modify Email")}
                    </button>
                    <button 
                      className="absolute sm:hidden right-0 top-7 h-[35px] bg-blue-500 text-white hover:bg-blue-700 active:bg-green-700  font-semibold uppercase px-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 text-sm ease-linear transition-all duration-150" 
                      onClick={handleModifyEmail}>
                      {t("Modify")}
                    </button>
                </div>
              </div>}
              {modifyPass ? 
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label className="block uppercase text-gray-600 text-xs font-bold mb-2"  >
                      {t("Old Password")}
                    </label>
                    <input 
                      type="text" 
                      className="border-0 px-3 py-3 placeholder-gray-500 text-gray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder={t("Old Password") + "..."}
                      name="old_password"
                      value={newPass.old_password}
                      onChange={handleChangePass} />
                      
                    <label className="block uppercase text-gray-600 text-xs font-bold my-2"  >
                      {t("New Password")}
                    </label>
                    <input 
                      type="text" 
                      className="border-0 px-3 py-3 placeholder-gray-500 text-gray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder={t("New Password") + "..."}
                      name="new_password_1"
                      value={newPass.new_password_1}
                      onChange={handleChangePass} />
                     <label className="block uppercase text-gray-600 text-xs font-bold my-2"  >
                      {t("Confirm Password")}
                    </label>
                    <input 
                      type="text" 
                      className="border-0 px-3 py-3 placeholder-gray-500 text-gray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder={t("Confirm Password") + "..."}
                      name="new_password_2"
                      value={newPass.new_password_2}
                      onChange={handleChangePass} />
                    <div className="w-full items-center justify-center flex mt-3">
                      <button 
                        className='flex bg-green-400 rounded-xl mx-1 hover:bg-green-600  hover:text-gray-100 w-[100px]'
                        onClick={handleSubmitPass}
                        >
                        {t("Confirm Update")}</button>
                      <button 
                        className='flex bg-red-400 rounded-xl mx-1 hover:bg-red-300  hover:text-gray-300 w-[100px]'
                        onClick={handleDiscardPass}
                      >{t("Discard Update")}</button>
                    </div>
                  </div>
                </div>
                :
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label className="block uppercase text-gray-600 text-xs font-bold mb-2"  >
                      Password
                    </label>
                    <input 
                      type="text" 
                      className="border-0 px-3 py-3 placeholder-gray-300 text-gray-600 bg-gray-100 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Password..."
                      name="password"
                      readOnly="readonly"/>
                    <button 
                      className="hidden sm:block sm:absolute right-0 top-7 h-[35px] bg-blue-500 text-white hover:bg-blue-700 active:bg-green-700  font-semibold uppercase px-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-3 ease-linear transition-all duration-150" 
                      onClick={handleModifyPass}>
                      {t("Modify Password")}
                    </button>
                    <button 
                      className="absolute sm:hidden right-0 top-7 h-[35px] bg-blue-500 text-white hover:bg-blue-700 active:bg-green-700  font-semibold uppercase px-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 text-sm ease-linear transition-all duration-150" 
                      onClick={handleModifyPass}>
                      {t("Modify")}
                    </button>
                  </div>
                </div>}
            </div>
        
            <hr className="mt-6 border-b-1 border-gray-300"/>
    
            <h6 className="text-gray-400 text-sm mt-3 mb-6 font-bold uppercase">
              {t("Contact Information")}
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-gray-600 text-xs font-bold mb-2"  >
                    {t("First Name")}
                  </label>
                  <input 
                    type="text" 
                    className={classNameInput}
                    placeholder={user.first_name === "" ? t("First Name") + "..." : user.first_name}
                    name="first_name"
                    value={newuser.first_name}
                    onChange={handleChange}
                    readOnly={stylemodify}/>
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-gray-600 text-xs font-bold mb-2"  >
                    {t("Last Name")}
                  </label>
                  <input 
                    type="text" 
                    className={classNameInput}
                    placeholder={user.last_name === "" ? t("Last Name") + "..." : user.last_name}
                    name="last_name"
                    value={newuser.last_name}
                    onChange={handleChange}
                    readOnly={stylemodify}/>
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-gray-600 text-xs font-bold mb-2"  >
                    {t("Company Name")}
                  </label>
                  <input 
                    type="text" 
                    className={classNameInput}
                    placeholder={user.company_name === "" ? t("Company Name") + "..." : user.company_name}
                    name="company_name"
                    value={newuser.company_name}
                    onChange={handleChange}
                    readOnly={stylemodify}/>
                </div>
              </div>
              {!modify ? 
              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-gray-600 text-xs font-bold mb-2"  >
                    {t("Address")}
                  </label>
                  <input 
                    type="text" 
                    className={classNameInput}
                    placeholder={user.address === "" ? t("Address") + "..." : user.address}
                    name="address"
                    value={newuser.address}
                    onChange={handleChange}
                    readOnly={stylemodify}/>
                </div>
              </div>
              :
              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-gray-600 text-xs font-bold mb-2"  >
                  {t("Address")}
                  </label>
                  <Autocomplete >
                    <input
                      placeholder={t("Address") + "..."}
                      name="address"
                      className=" block rounded-lg h-[30px] px-1 break-words border-1 border-blue-800 mx-auto w-[350px] md:w-[550px] my-1"
                      ref={addrMapRef}
                      value={newAddress}
                      onChange={handleChangeAddress}>
                      
                    </input>
                  </Autocomplete>
                  <button className='flex mx-auto border my-2 bg-blue-400 text-black items-center justify-center text-center cursor-pointer hover:bg-blue-800 hover:text-white'
                    onClick={()=>fromAddrtoGeo(addrMapRef.current.value)}>
                    {t("Confirm & Verify on Map")}
                  </button>
                
                
                  <div className='flex mx-auto items-center justify-center mt-3 mb-2 ' >
                    <GoogleMap center={center} zoom={14} mapContainerStyle={{width:'300px',height: '200px'}}
                    options={{
                      zoomControl: false,
                      streetViewControl: false,
                      mapTypeControl: false,
                      fullscreenControl:false,
                    }}>
                      <Marker position={center}/>
                    </GoogleMap>
                  </div>
                </div>
              </div>
              
              }
               <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-gray-600 text-xs font-bold mb-2"  >
                    {t("City")}
                  </label>
                  <input 
                    type="text" 
                    className={classNameInput}
                    placeholder={user.city === "" ? t("City") + "..." : user.city}
                    name="city"
                    value={newuser.city}
                    onChange={handleChange}
                    readOnly={stylemodify}/>
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-gray-600 text-xs font-bold mb-2"  >
                    {t("Country")}
                  </label>
                  <input 
                    type="text" 
                    className={classNameInput}
                    placeholder={user.country === "" ? t("Country") + "..." : user.country}
                    name="country"
                    value={newuser.country}
                    onChange={handleChange}
                    readOnly={stylemodify}/>
                </div>
              </div>
              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-gray-600 text-xs font-bold mb-2"  >
                    {t("Phone Number")}
                  </label>
                  <input 
                    type="text" 
                    className={classNameInput}
                    placeholder={user.phone === "" ? t("Phone Number") + "..." : user.phone}
                    name="phone"
                    value={newuser.phone}
                    onChange={handleChange}
                    readOnly={stylemodify}/>
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-gray-600 text-xs font-bold mb-2"  >
                    {t("Postal Code")}
                  </label>
                  <input 
                    type="text" 
                    className={classNameInput}
                    placeholder={user.postal_code === "" ? t("Postal Code") + "..." : user.postal_code}
                    name="postal_code"
                    value={newuser.postal_code}
                    onChange={handleChange}
                    readOnly={stylemodify}/>
                </div>
              </div>
            </div>
            <hr className="mt-6 border-b-1 border-gray-300"/>
            <div className='flex justify-between items-center'>
              <h6 className="text-gray-400 text-sm mt-3 mb-6 font-bold uppercase">
                {t("Bank Account Information - Payment") + " - "}
                <span className={`${style_status}`}>{" " + t(status)}</span>

              </h6>
              {status==="Disabled" ? 
                <div className='bg-gray-200 flex-row flex p-1.5 rounded-xl shadow-xl justify-center items-center cursor-not-allowed'>
                  < FaCcStripe size={40} color="green" className='mr-2'/>
                  {t("Update")}</div>
              :
                <div className='bg-teal-100 flex-row flex p-1.5 rounded-xl shadow-xl hover:bg-teal-200 hover:font-semibold justify-center items-center cursor-pointer'
                  onClick={handleStripeLink}>
                    < FaCcStripe size={40} color="green" className='mr-2'/>
                    {t("Update")}</div>
                
              }
            </div>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-gray-600 text-xs font-bold mb-2"  >
                    Iban
                  </label>
                  <input 
                    type="text" 
                    className={classNameInput}
                    placeholder={user.iban === "" ? "Iban..." : user.iban}
                    name="iban"
                    value={newuser.iban}
                    onChange={handleChange}
                    readOnly="readonly"/>
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-gray-600 text-xs font-bold mb-2"  >
                    BIC
                  </label>
                  <input 
                    type="text" 
                    className={classNameInput}
                    placeholder={user.bic === "" ? "BIC..." : user.bic}
                    name="bic"
                    value={newuser.bic}
                    onChange={handleChange}
                    readOnly="readonly"/>
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-gray-600 text-xs font-bold mb-2"  >
                    {"PAYPAL ACCOUNT (EMAIL)"} 
                  </label>
                  <input 
                    type="email" 
                    className={classNameInput}
                    placeholder={user.paypal_account === "" ? "PAYPAL ACCOUNT (EMAIL)..." : user.paypal_account}
                    name="paypal_account"
                    value={newuser.paypal_account}
                    onChange={handleChange}
                    readOnly={stylemodify}/>
                </div>
              </div>
              {modify ?
              <div className="w-full items-center justify-center flex mt-3">
              <button 
                className='flex bg-green-400 rounded-xl mx-1 hover:bg-green-600  hover:text-gray-100 w-[110px] text-center'
                onClick={ () => setOpenSubmit(true)}
                >
                {t("Confirm Update")}</button>
              <button 
                className='flex bg-red-400 rounded-xl mx-1 hover:bg-red-300  hover:text-gray-300 w-[110px] text-center'
                onClick={handleDiscard}
              >{t("Discard Update")}</button>
              </div>
              : ""}
            </div>
            
            {/*<div className="w-full items-center justify-center flex mt-3">
              
              <button 
                className='flex bg-red-400 rounded-xl mx-1 hover:bg-red-600  hover:text-gray-100 w-[100px]'
                onClick={()=> setOpen(true)}
                >
                {t("Delete Account")}
              </button>
              <DeleteModalUser open={open} onclose={()=>{setOpen(false)}}>
                <div className='text-center w-56 items-center justify-center flex-col'>
                  <AiFillDelete className="mx-auto" size={56} color="red"/>
                  <div>
                    <h3 className='text-lg font-black text-gray-800'> {t("Confirm Delete Account")}</h3>
                    <p className='text-sm text-gray-600'>{t("Are you sure to delete your account permanentely")}?
                    </p>
                  </div>
                  <div className='w-full items-center justify-center flex mt-3'>
                    <button  
                      className='flex bg-red-400 rounded-xl mx-1 hover:bg-red-600  hover:text-gray-100 w-auto'
                      onClick={handleDelAccount}>{t("Delete")}</button>
                    <button 
                      className='flex bg-gray-200 rounded-xl mx-1 hover:bg-gray-400 hover:text-gray-100 w-auto text-center'
                      onClick={()=> setOpen(false)}>{t("Cancel")}</button>
                  </div>
                </div>
              </DeleteModalUser>
              
              </div>*/}
          
          </div>
          } 
        </div>
        <DeleteModalUser open={openSubmit} onclose={() => { setOpenSubmit(false) }}>
              <div className='text-center w-56 items-center justify-center flex-col'>
                <AiOutlineUpload className="mx-auto" size={56} color="green" />
                <div>
                  <h3 className='text-lg font-black text-gray-800'> {t("Confirm Update Business Account")}</h3>
                  <p className='text-sm text-gray-600'>{t("Are you sure you want to Update Business Account")}?
                  </p>
                </div>
                <div className='w-full items-center justify-center flex mt-3'>
                  <button
                    className='flex bg-green-400 rounded-xl mx-1 hover:bg-green-600  hover:text-gray-100 w-auto'
                    onClick={handleSubmit}>{t("Update")}</button>
                  <button
                    className='flex bg-gray-200 rounded-xl mx-1 hover:bg-gray-400 hover:text-gray-100 w-auto text-center'
                    onClick={() => setOpenSubmit(false)}>{t("Cancel")}</button>
                </div>
              </div>
            </DeleteModalUser>
      </div>
      
    </div>

  )
}

export default User_Page
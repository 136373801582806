import React, { useContext, useEffect, useState } from 'react'
import {Create_table_QR} from './TableWaiters'
import { Navigate , useNavigate } from 'react-router-dom';
import {LoginDelyContext} from '../../contexts/LoginDelyContext'
import {MenuContext} from '../../contexts/MenuContext'
import { useTranslation } from 'react-i18next';


const Table_Waiters = () => {
  const {t} = useTranslation()
  const User = useContext(LoginDelyContext)
  const menu = useContext(MenuContext)
  const settings = User.page_info
  const restaurant_id = settings.user_id
  const status = settings.sub_status
  const type = settings.sub_type.split("_")[0]
  
  useEffect(()=>{
    console.log(restaurant_id)
    menu.getAllQrTables(restaurant_id)

  },[])
  
  return (
    type!=="premium" ? <Navigate to="/dash/manager-dash" /> : 
    <div className='bg-gray-50 shadow-xl rounded-b-2xl rounded-t max-w-[1400px] h-auto w-full pb-10 flex flex-col md:w-[600px] lg:w-[800px] mx-auto'>
      <p className="justify-center items-center flex text-4xl font-bold">{t("Manage Table & Waiters")}</p>
      <div className='bg-gray-200 shadow-xl rounded-2xl my-[20px] mx-[20px] h-auto flex-cols items-center p-2'>
      <p className='justify-center items-center flex text-2xl font-bold'>{t("Create QR Code for Table")}</p>
      <Create_table_QR />
      </div>
      <div className='bg-gray-200 shadow-xl rounded-2xl my-[20px] mx-[20px] h-auto flex-cols items-center p-2'>
      <p className='justify-center items-center flex text-2xl font-bold'>{t("Manage Waiters per Table")}</p>
      </div>
    </div>

  )
}

export default Table_Waiters
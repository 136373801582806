import styles from "../../style";
import { logo } from "../../data";
import { footerLinks, socialMedia } from "../../data/consts";
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const {t} = useTranslation()
  const application = process.env.REACT_APP_APPLICATION_NAME


  return(
  <section className={`${styles.flexCenter} ${styles.paddingY} flex-col`}>
    <div className={`${styles.flexStart} md:flex-row flex-col mb-8 w-full`}>
      <div className="flex-[1] flex flex-col justify-start mr-10">
        <div className="flex items-center ">
        <img
          src={logo}
          alt={application}
          className="w-[80px] h-[80px]"
        />
        <h1 className="text-3xl text-green-600 font-bold ml-2">{application.charAt(0).toUpperCase() + application.slice(1)}</h1>
        </div>
        <p className={`${styles.paragraph} mt-4 max-w-[400px]`}>
          {t("A new way to order and make the payments easy, reliable and secure, on a table restaurant & delivery")}.
        </p>
        
      </div>

      <div className="flex-[1.5] w-full flex flex-row justify-between flex-wrap md:mt-0 mt-10">
        {footerLinks.map((footerlink) => (
          <div key={footerlink.title} className={`flex flex-col smmy-0 my-4 min-w-[150px]`}>
            <h4 className="font-poppins font-semibold text-[18px] leading-[27px] text-white">
              {t(footerlink.title)}
            </h4>
            <ul className="list-none mt-4">
              {footerlink.links.map((link, index) => (
                <li
                  key={link.name}
                  className={`font-poppins font-normal text-[16px] leading-[24px] text-dimWhite hover:text-secondary cursor-pointer ${
                    index !== footerlink.links.length - 1 ? "mb-4" : "mb-0"
                  }`}
                >
                  <a className="text-white underline" href={`${link.link}`}>{t(link.name)}</a>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>

    <div className="w-full flex justify-between items-center md:flex-row flex-col pt-6 border-t-[1px] border-t-[#3F3E45]">
      <p className="font-poppins font-normal text-center text-[18px] leading-[27px] text-white">
        Copyright Ⓒ 2023 {application.charAt(0).toUpperCase() + application.slice(1)}. {t("All Rights Reserved.")} <a className="text-teal-500 italic underline" href={'https://www.auxilium-services.com'}>{t("Powered by Auxilium SRL")}.</a>
      </p>

      <div className="flex flex-row md:mt-0 mt-6">
        {socialMedia.map((social, index) => (
          <img
            key={social.id}
            src={social.icon}
            alt={social.id}
            className={`w-[21px] h-[21px] object-contain cursor-pointer ${
              index !== socialMedia.length - 1 ? "mr-6" : "mr-0"
            }`}
            onClick={() => window.open(social.link)}
          />
        ))}
      </div>
    </div>
  </section>
  );
};

export default Footer;

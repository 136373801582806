import React , {useState} from 'react'
import { AiOutlineSearch } from 'react-icons/ai';
import { BiChevronDown } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';






const DropdownComp = ({ already_selected, menu, which_option , handleOptionSelected,variable}) => {
  const {t} = useTranslation()
  const [open, setOpen] = useState(false)
  const [inputValue, setInputValue] = useState ("")
  const [selected, setSelected] =useState(already_selected)

  return (
    <div className='flex-col items-center justify-center mt-4'>
    <div
          onClick={()=>setOpen(!open)} 
          className={`bg-white p-2 flex items-center justify-between rounded-lg ${selected} && "text-gray-700"`}>
            {selected 
              ? selected?.length >25
                ? selected?.substring(0,25) + '...'
                : t(selected)
              : t(selected) }
            <BiChevronDown size={20} className={`${open && "rotate-180"}`}/>
        </div>
        <ul className={`bg-white my-2 overflow-y-auto rounded-xl ${open ? "max-h-60" : "max-h-0"}`}>
          <div className='flex items-center px-2 sticky top-0 bg-white'>
            <AiOutlineSearch size={20} className='text-gray-700'/>
            <input 
              type='text'
              value={t(inputValue)}
              onChange={(e)=>setInputValue(e.target.value)}
              placeholder={t(which_option.replace(/_/g, ' '))} 
              className='placeholder:text-gray-700 p-2 outline-none' />
          </div>
          {menu?.map((option, idx)=>(
           <li 
            key={idx} 
            className={`p-2 text-sm hover:bg-sky-600 hover:text-white 
            ${option === selected && 'bg-sky-600 text-white'}
            ${option 
              ? 'block' 
              :'hidden'
            }`}
            onClick={()=>{
              if(option !== selected){
                setSelected(option);
                {handleOptionSelected({what: variable, choose: option})};
                setOpen(false);
                setInputValue("");
              }

            }}
            >
              {t(option)}
            </li>
           ))}
 
        </ul>
      </div>
  );
};

export default DropdownComp
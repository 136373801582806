import React ,{useState, useEffect, useContext} from 'react'
//import data_CL_mobileTest from '../../data/consts/data_CL_MobileTest'
import {ProductCard, DishDetails, CreateFood} from "./"
import { useTranslation } from 'react-i18next';
import { MenuContext } from '../../contexts/MenuContext'
import { LoginDelyContext } from '../../contexts/LoginDelyContext'

const ListOfFoodTest = () => {

  const menus = useContext(MenuContext)
  const [foods,setFoods] = useState([]);
  const [custom_Dish, setCustom_Dish] = useState([]);
  const [data_CL_mobileTest, setData_CL_mobileTest] = useState([]);
  const User = useContext(LoginDelyContext)
  const settings = User.hmi_CL_info
  const restaurant_id=settings.user_id.replace(/-/g,'') 
  const intialstate =  async () =>{
    console.log(settings)
    const res = await menus.clientfetchMenu(restaurant_id).then((response)=>{
      return response
    }).catch(error => console.log(`Error: ${error}`));
    const menu = await res
    setData_CL_mobileTest(menu)
    setFoods(menu)
    const resp = await menus.fetchAddIns(restaurant_id).then((response)=>{
      console.log(response)
      
      return response
    }).catch(error => console.log(`Error: ${error}`));
    const custom_dish= await resp.data
    
    setCustom_Dish(custom_dish)
    
  }
  const [categorySelected, setCategorySelected] = useState("All")
  useEffect(()=>{
    menus.clientfetchMenu(restaurant_id);
    menus.fetchAddIns(restaurant_id);
    intialstate() ;
  },[])

  useEffect(()=>{
    if (custom_Dish.length===0){
      menus.clientfetchMenu(restaurant_id);
      menus.fetchAddIns(restaurant_id);
      intialstate() ;
    }

  },[custom_Dish])
  




  const {t} = useTranslation()
  const [show, setShow] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [selectedCD,setSelectedCD] = useState([])
  const [detail, setDetail] = useState("");
  const [image, setImage] = useState("");
  const [name, setName] = useState("");
  const [price, setPrice] = useState(0);

  const handleClose = () => setShow(false);
  const handleCloseCreate = () => setShowCreate(false);
 

  const DetailsSelected = (details_selected)=>{
    setShow(details_selected.selected)
    setDetail(details_selected.selected_detail)
    setName(details_selected.selected_dish)
    setImage(details_selected.selected_image)
    setPrice(details_selected.selected_price)

  }



  //filter type
  const filterType = (category) => {
    setCategorySelected(category)
    setFoods(
      data_CL_mobileTest.filter((item)=>{
        return item.name === category
      })
      
    );
  };


  //filter by price
  const filterPrice = (price) => {
    
    let FoodPriceFilter = data_CL_mobileTest.filter(item=> (
          item.dish_list.some(dish=>(
            dish.prezzo<=price
          ))
    ))


    /*const data = data_CL_mobileTest;
    data.map(item=>(
      delete Object.assign(item, {dish_list2 : data.dish_list} ) ["dish_list"]
    ))
    console.log(data)
    
    let FoodPriceFilter = data.map(({dish_list2,...others})=>{
      const dish_list = dish_list2.filter(dish=>dish.prezzo<=price);
      return {
        dish_list,
        ...others
      };
    }).filter(foodprice =>foodprice.dish_list.length > 0)*/
    setFoods(FoodPriceFilter)
  };

  const fetch_Initnewingredients = async (ingred) =>{
    console.log(ingred)
    let customDish = []
    const resp = await menus.fetchIngredients(restaurant_id,ingred.name, ingred._id).then(async(res)=>{
      console.log(res)
      console.log(custom_Dish)
      const status = await res.stat
      if (status ===200){
        if (custom_Dish && custom_Dish.length!==0){
          if (Object.hasOwn(custom_Dish[0]?.dish_list.find(dish=>dish.name===ingred.name),"ingredients")){
            customDish = await custom_Dish[0]?.dish_list.find(dish=>dish.name===ingred.name)
          }
        }
      }
    })
    console.log(customDish)
    setShowCreate(true)
    setSelectedCD(customDish)
  }

  console.log(custom_Dish)

  return (
    <div className='max-w-[1640px] m-auto py-6'>
       <div className='flex flex-col justify-center mx-3 py-4 my-4 bg-gray-200 rounded-xl shadow-xl'>
        <h1 className='font-bold text-gray-700 px-4 text-center lg:text-2xl sm:text-xl mb-2'>{t("Create Your") + " " + t("Custom Dish")}</h1>
          {custom_Dish[0]?.dish_list?.map((ingred,idx)=>
            <div key={idx} className='mt-2 items-center flex justify-center text-center bg-teal-400 mx-auto text-2xl hover:bg-teal-800 hover:text-white w-[150px] cursor-pointer p-1 rounded-xl'

            onClick={()=>fetch_Initnewingredients(ingred)}>
              <span className='font-bold'>{ingred.name}</span>
            </div>
          )}
      </div>
      <h1 className='text-teal-600 font-bold lg:text-6xl text-3xl text-center pb-6'>{t("The Best Menu")}</h1>
     
      {/* filter row */}
      <div className='flex flex-col justify-center w-full'>
        {/* filter type */}
        <div  >
          <h1 className='font-bold text-gray-700 px-4 text-center lg:text-2xl sm:text-xl mb-2'>{t("Filter Category")}</h1>
          
          <ul className='mx-3 flex flex-row gap-2 overflow-y-auto text-sm font-semibold h-auto'>
            <li onClick={()=>{setFoods(data_CL_mobileTest)
                              setCategorySelected("All")}} 
           className={`whitespace-nowrap flex p-2 items-center rounded-xl text-center grow border-[1px] m-1 border-teal-600 text-teal-600 hover:bg-teal-500 hover:text-white justify-center cursor-pointer ${categorySelected==="All" ? "bg-teal-600 text-white font-bold" : ""}`}>{t("All")}</li>
            {data_CL_mobileTest?.map((category, idx)=>
              <li key={idx}
                onClick={()=>filterType(category.name)} 
                className={`whitespace-nowrap flex p-2 items-center rounded-xl text-center grow border-[1px] m-1 border-teal-600 text-teal-600 hover:bg-teal-500 hover:text-white justify-center cursor-pointer ${categorySelected===category.name ? "bg-teal-600 text-white font-bold" : ""}`}>{category.name}</li>
            )}
          </ul>
          
        </div>
        {/* filter price 
        <div className='pt-6 sm:pt-6 md:pt-6 lg:pt-0'>
            <p className='font-bold text-gray-700 text-center lg:text-2xl sm:text-xl px-4'>Filter Price</p>
            <div className='flex justify-between flex-wrap px-4'> 
              <button onClick={()=>filterPrice(5)} className='border-[1px] m-1 border-teal-600 text-teal-600 hover:bg-teal-600 hover:text-white'>€5,00</button>
              <button onClick={()=>filterPrice(10)} className='border-[1px] m-1 border-teal-600 text-teal-600 hover:bg-teal-600 hover:text-white'>€10,00</button>
              <button onClick={()=>filterPrice(15)} className='border-[1px] m-1 border-teal-600 text-teal-600 hover:bg-teal-600 hover:text-white'>€15,00</button>
              <button onClick={()=>filterPrice(20)} className='border-[1px] m-1 border-teal-600 text-teal-600 hover:bg-teal-600 hover:text-white'>€20,00</button>
              <button onClick={()=>filterPrice(30)} className='border-[1px] m-1 border-teal-600 text-teal-600 hover:bg-teal-600 hover:text-white'>€30,00</button>
            </div>
        </div>*/}
      </div>
      
      {/*display food*/}
      {foods.map((item,index1) => (
        <div key={index1} className=''>
          <p className='font-bold text-gray-700 text-center lg:text-3xl sm:text-2xl my-3 '>{item.name}</p>
          <div className='grid grid-cols-2 lg:grid-cols-4 gap-6 p-4 '>
            {item.dish_list.map((dish,index2) => (
             <ProductCard handleDetails={DetailsSelected} key={index2} product={dish} category={item.name} selected={show}/>
             
            ))}
          </div>
        </div>
      ))}
      {show ? <DishDetails onClose={handleClose} visible={setShow} detail={detail} name={name} image={image} price={price}/> : ""}
      {showCreate ? <CreateFood onClose={handleCloseCreate} visible={setShowCreate} custom_Dish={selectedCD}/> : ""}
    </div>
  );
};

export default ListOfFoodTest
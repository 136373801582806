import { useEffect, useState, useContext } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { MenuContext } from '../../../contexts/MenuContext';
import { useTranslation } from 'react-i18next';
import {LoginDelyContext} from '../../../contexts/LoginDelyContext';

const Modify_update_QR = () => {
  const { id } = useParams();
  const {t} = useTranslation()
  const User = useContext(LoginDelyContext)
  const settings = User.page_info
  const restaurant_id = settings.user_id
  const intialTable = {
    _id: "",
    picture_name: "",
  }
  const [newTable, setNewTable] = useState(intialTable)
  const menus = useContext(MenuContext)
  let tables = [] =  menus.qrtables;

  const navigate = useNavigate();
 
  const extract = () =>{
    //menus.fetchMenu();
    if (id !== "new") {
        setNewTable({
          _id: id,
          picture_name: tables.filter(table => table._id === id).picture_name,
      })
      }
     
      return newTable
    } 
  
    const [table, setTable] = useState(extract)


  const handleChange = (e) => {
    let tableClone = { };
      tableClone = {...newTable }
      tableClone[e.target.name] = e.target.value;  
       
      setNewTable(tableClone);
  };

  console.log(newTable) 

  //console.log(newTable)

  const handleSubmit = async (e) => {
    e.preventDefault();
    //menus.fetchMenu()
    if (id === "new") {
      const res = menus.postNewQrTable(newTable._id, restaurant_id).then(response => {
        return response
      })

    } else {
      
      Object.entries(newTable).map(([key, item])=>{
        if (newTable._id===''){
          newTable.id=table._id
        }else if (newTable[key]===''){
          newTable[key]=table[key]
        }else{
          return
        }

      })
     
      const res = menus.postUpdtQrTable(newTable._id,restaurant_id).then(response => {
        return response
      })
    }

  };

  return (
    <div className='max-w-[900px] w-full md:w-[600px] h-auto mx-auto items-center justify-center my-3'>
      <div className="bg-gray-200 rounded-[30px] w-auto mx-[20px] shadow-xl py-[30px] px-[20px]">
        <p className="text-center font-bold text-3xl">
          {id === "new" ? t("Create New Table QR") : t("Update")}
        </p>
        <form className="w-full flex items-center mb-4 justify-center flex-row gap-[20px] mt-[30px]">
          <p>{t("Table Name or Number")}</p>
          <input
            className="w-full rounded-lg h-[30px] p-1 "
            type="text"
            placeholder={id === "new" ? "Table Name..." : table._id}
            name="_id"
            value={newTable._id}
            onChange={handleChange}
          />
        </form>
        <div className="flex justify-center items-center gap-4">

          {newTable._id.length===0 ? 
          
          
          <button onClick={handleSubmit} className="rounded-xl py-[10px] px-[20px]flex  cursor-not-allowed bg-gray-100 text-black"
          disabled={true}>
          {id === "new" ? t("Create New QR") : t("Update")}
          </button>    
          : 
       
          <button onClick={handleSubmit} className="rounded-xl py-[10px] px-[20px]flex bg-green-400 hover:bg-green-600  hover:text-gray-100">
          {id === "new" ? t("Create New QR") : t("Update")}
          </button>
          
          
          }
          

          <button 
            onClick={ () => {
              setNewTable(intialTable)
              navigate('/dash/manager-dash/table-waiters')
            }}
            className="rounded-xl py-[10px] px-[20px]flex bg-red-400 hover:bg-red-600  hover:text-gray-100">
            {t("Discard")}
          </button>


        </div>

      </div>
    </div>
  )
}

export default Modify_update_QR
import React from 'react'
import { useTranslation } from "react-i18next";
import bg2 from "../data/AppLogo_ReApp.png";
import { Helmet } from 'react-helmet-async';

const CookiePolicy = () => {
  const {t} = useTranslation()
  return (
    <div className='flex flex-col mx-3 items-center justify-center space-y-10'>
      <Helmet>
        <title>Cookie Policy - Speaty</title>
          <meta name="description" content='The Cookie Policy are very important ethically for our platform. All the information and data are important to protect of personal data for the EU Regulation.'/>
          <link rel='canonical' href="https://speaty.com/help-center/cookie-policy"/>
          <meta name="keywords" content='food, delivery restaurant, bar, ordering, app, business, cibo, ristorante, ordinazioni, orders, ordini, ordinazione, takeaway, consegna, domicilio'/>
      </Helmet>
      <h2 className='text-center mx-auto font-bold text-4xl text-white'>{t("Cookie Policy")}</h2>
      <div className='flex flex-col relative mx-auto items-start justify-start gap-5'>
      <img className='absolute -bottom-[70px] lg:-bottom-[120px] -right-7 z-10 w-[400px] h-[400px] opacity-20' src={bg2}></img>
      <div className='z-20 flex flex-col  mx-auto items-center justify-center space-y-4'>
        <h2 className='text-center mx-auto font-bold text-white text-xl'>{t("Extended cookie information")}</h2>
        <p className='text-white text-justify cursor-auto'>{t("In line with Article 13 of Legislative Decree 196/2003 of the Code regarding the protection of personal data and EU Regulation 679/2016, the user must be informed about the use of cookies present on the website, both technical and security profiling, and consent to the use of profiling ones.This website uses technical cookies to improve the user experience on the site, it may use third-party cookies and also profiling cookies to send targeted advertising messages, in line with the user's preferences. For profiling cookies, which include third-party cookies, the visitor's consent is required. Please check the cookies used via the banner visible on any page of the site before granting consent to processing")}.</p>
      </div>
      <div className='flex flex-col mx-auto items-start justify-start space-y-4'>
        <h2 className='text-center mx-auto font-bold text-white text-xl'>{t("Cookie definition")}</h2>
        <p className='text-white text-justify cursor-auto'>{t("In general, cookies are small text files containing information that the server of the site visited by the user sends to the user's device. The same server will re-read them on the next visit to obtain various types of data. While browsing the site, cookies may also be received from different websites or servers, the so-called third-party cookies, i.e. cookies set by a site other than the one you are browsing. This is because there may be some elements on the site that reside on different servers. Cookies, usually present in users' browsers in very large numbers and sometimes even with characteristics of long temporal persistence, are used for different purposes: execution of computer authentication, monitoring of sessions, storage of information on specific configurations regarding users who access the server, choice of consultation language, storage of the shopping cart, etc. Pursuant to provision no. 229/2014 of the Privacy Guarantor, two macro-categories are therefore identified")}:</p>
        <ul className='list-inside list-none text-white font-bold space-y-3'>
          <li>{t("Tecnichal cookie")}</li>
          <p className='text-white text-justify cursor-auto font-normal'>{t("Technical cookies guarantee the correct functioning of the site and a better browsing experience for the user. They are cookies that are always sent and ensure that each section is clearly displayed. They are all always used, regardless of the user's preferences as prior consent is not required, and include")}:</p>
          <ul className='list-inside list-disc'>
            <li>{t("Session and Navigation cookies")}<span className='text-white text-justify cursor-auto font-normal'>{t(", useful for the normal use of the website, also used by our Site, contain the information that is used in your current browser session; these cookies are automatically deleted when you close your browser. Nothing is stored on your computer beyond the time you use the site")};</span></li>
            
            <li>{t("functionality cookies")}<span className='text-white text-justify cursor-auto font-normal'>{t(", which allow the user to navigate based on a series of criteria, such as those for language")};</span></li>
            <li>{t("analytical cookies")}<span className='text-white text-justify cursor-auto font-normal'>{t(", used directly by the site manager to collect information, in aggregate and anonymous form, on the number of users and how they visit the site itself")};</span></li>
            <li>{t("persistent cookies")}<span className='text-white text-justify cursor-auto font-normal'>{t(", stored in the visitor's device and which help him to reconnect to the site at the start of a new session. They are used to maintain the information that is used in the period between one access to the website and another, or used for technical purposes and to improve navigation on the site. This data allows sites to recognize that you are a known user or visitor and adapt accordingly. “Persistent” cookies have a duration that is set by the website and which can vary from a few minutes to two years")};</span></li>
          </ul>
          <li>{t("Profiling Cookies")}</li>
          <p className='text-white text-justify cursor-auto font-normal'>{t("Profiling cookies are used to send targeted advertising messages tailored to the preferences that the user has expressed while browsing the site. They create a personalized profile based on the pages you visit. Due to the particular invasiveness that these devices can have in the private sphere of users, European and Italian legislation requires that the user must be adequately informed about their use and thus express their valid consent. The art refers to them. 122 of the Privacy Code where it provides that 'the storage of information in the terminal device of a contractor or user or access to information already archived is permitted only on condition that the contractor or user has expressed his/her consent after have been informed using the simplified methods referred to in Article 13 of the Privacy Code, paragraph 3'.This site uses profiling cookies, mentioned in the next paragraphs")}:</p>
          <ul className='list-inside list-disc'>
          <li>{t("First party cookies")}<span className='text-white text-justify cursor-auto font-normal'>{t(", these are cookies installed by the same manager of the site that the user is visiting (so-called 'first part'). First-party cookies are created and readable by the site that created them. This site uses first-party cookies of a technical nature, for which, as mentioned, consent is not required, but only this information")};</span></li>
          <li>{t("Third party cookies")}<span className='text-white text-justify cursor-auto font-normal'>{t(", these are Cookies installed by a site other than that of the manager that the user is visiting, which installs through the former (so-called 'third parties'). Third-party cookies are created and readable by domains external to the site you are visiting, and whose data is stored by the third party and for which the third party's cookie and data processing policy applies")};</span></li>
          </ul>
        </ul>
      </div>
      <div className='flex flex-col mx-auto items-center justify-center space-y-4'>
        <h2 className='text-center mx-auto font-bold text-white text-xl'>{t("Legal references")}</h2>
        <p className='text-white text-justify cursor-auto'>{t("This information is drawn up in fulfillment of the obligations established by the Art. 10 of Directive no. 95/46/EC, as well as the provisions of Directive 2002/58/EC, as updated by Directive 2009/136/EC, regarding Cookies.This information concerns exclusively this site, and can be reached from every page of the site itself via a link in the footer")}.</p>
      </div>
      </div>

    </div>
  )
}

export default CookiePolicy
import React, { createContext, useState, useEffect, useRef,} from 'react'
import { useNavigate, useLocation} from "react-router-dom"
import { useTranslation } from 'react-i18next';
import axios from 'axios'

export const LoginDelyContext = createContext({

  restaurant_info : {},
  client_info:{},
  order_info: {},
  page_info:{},
  hmi_CL_info:{},
  restaurant_orders:[],
  client_orders: [],
  stripe_account:{},
  subscription:{},
  loading:Boolean,
  stateOrder: () => {},
  staterestaurantDel: () => {},
  stateclient: () => {},
  statepage: () => {},
  stateHmiCLpage: () => {},
  stateSubscription: () => {},
  isVerified: Boolean,
  stateIsVerified: () => {},
  stateLoading : () => {},
  getUserRestaurant: () => {},
  getUserClient: () => {},
  registerUser: () => {},
  loadData: () => {},
  isAuth: () =>{},
  logout: () => {},
  login: () => {},
  update_user_info: () => {},
  googleLogin: () =>{},
  googleLoginTable: () =>{},
  googleCompLogin: () =>{},
  getRestaurantPage: () =>{},
  update_restaurant_settings: () =>{},
  get_restaurant_orders: () =>{},
  get_client_orders: () =>{},
  get_order: () =>{},
  del_order: () =>{},
  del_account: () =>{},
  update_sts_order: () => {},
  modify_order: () => {},
  get_stripe_account: () => {},
  check_on_map: () =>{},
  update_email: ()=>{},
  update_pass: ()=>{},
  update_pass_link: ()=>{},
  email_change_pass: ()=>{},
  check_link_pass: () =>{},
})

export function LoginDelyProvider({children}){

  
  const location = useLocation()
  const navigate = useNavigate()
  const {t} = useTranslation()
  const [isVerified, setIsVerified] = useState(false)
  const initial_stateDel = {
    first_name: "",
    last_name: "",
    email: "",
    address: "",
    gps_address:"",
    city: "",
    company_name: "",
    vat_number: "",
    country: "",
    phone: "",
    postal_code: "",
    iban:"",
    bic:"",
    paypal_account: "",
    registration_method: "",
    delivery_days: 0,
    start_dely_time_hours: 0,
    start_dely_time_minutes: 0,
    end_dely_time_hours: 0,
    end_dely_time_minutes: 0,
    minutes_dely: 0,
    menu_bg_color: "",
    vat_number: "",
    credit_card:"",
    stripe_id:"",
    sub_stripe_id:"",
    sub_duration:"",
    sub_session_id:"",
    sub_status:"",
    stripe_sub_id:"",
    sub_type:"",
    user_id:"",
    user:"",
    lat:0,
    lng:0,
  }


  const initial_stateClient = {
    first_name: "",
    last_name: "",
    email: "",
    address: "",
    city: "",
    company_name: "",
    vat_number: "",
    country: "",
    phone: "",
    postal_code: "",
    credit_card: "",
    paypal_account: "",
    registration_method: "",
    user_id:"",
    customer_stripe_id:"",
  }



  const initial_stateOrder = {
    dishes: {},
    date: {},
    type: "",
    time: {
      begin: {hours: Number, minutes: ""},
      end: {hours: Number, minutes: ""}
    },
    notes: "",
    delivery_address: "",
    city:"",
    country:"",
    postal_code:"",
    phone:"",
    total_cost: "",

  }

  const initial_page = {
    company_title: "",
    delivery_days: 0,
    start_dely_time_hours: 0,
    start_dely_time_minutes: 0,
    end_dely_time_hours: 0,
    end_dely_time_minutes: 0,
    minutes_dely: 0,
    delivery_cost: 1,
    delivery_km: 4,
    menu_bg_color: "",
    user_id:"",
    user:"",
    address:"",
    gps_address:"",
    city:"",
    phone: "",
    postal_code: "",
    country:"",
    client_link:"",
    restaurant_description:"",
    stripe_id:"",
    sub_stripe_id:"",
    sub_duration:"",
    sub_session_id:"",
    sub_status:"",
    stripe_sub_id:"",
    sub_type:"",
    order_without_pay:false,
    lat:0,
    lng:0,
  }

  const initial_stripe_account = {
    connect: {},
    client:{},
    sub_client: {}
  }

  const initial_subscription = {
    stripe_description_dev: "",
    stripe_description_prod: "",
    cost : 0,
    duration_month : 0,
    email : "",
    name: ""
  }

  const [restaurantDel, setRestaurantDel] = useState(() => {

    const saved = localStorage.getItem("restaurant_info");
    const initialValue = JSON.parse(saved);


    return initialValue || initial_stateDel

  })

  const [stripe_account, setStripe_account] = useState(() => {
    const saved = localStorage.getItem("stripe_account");
    const initialValue = JSON.parse(saved);
    return initialValue || initial_stripe_account
  })

  const [clientDel, setClientDel] = useState(() => {
    const saved = localStorage.getItem("client_info");
    const initialValue = JSON.parse(saved);


    return initialValue || initial_stateClient


  })


  const [order, setOrder] = useState(() => {
    const saved = localStorage.getItem("order");
    const initialValue = JSON.parse(saved);


    return initialValue || initial_stateOrder

  })

  const [page,setPage] = useState(() => {
    const saved = localStorage.getItem("page");
    const initialValue = JSON.parse(saved);


    return initialValue || initial_page

  })

  const [hmi_CL_page, setHmi_CL_page]= useState(() => {
    const saved = sessionStorage.getItem("hmi_CL_page");
    const initialValue = JSON.parse(saved);


    return initialValue || initial_page

  })
  const [loading, setLoading] = useState(false)
  const [subscription, setSubscription] = useState(initial_subscription)

  const [restaurant_orders, setRestaurant_orders] = useState([])
  const [client_orders, setClient_orders] = useState([])

  useEffect(() => {
    window.localStorage.setItem('order', JSON.stringify(order));
    window.localStorage.setItem('restaurant_info', JSON.stringify(restaurantDel))
    window.localStorage.setItem('client_info', JSON.stringify(clientDel))
    window.localStorage.setItem('page', JSON.stringify(page))
    window.localStorage.setItem('stripe_account', JSON.stringify(stripe_account))
    sessionStorage.setItem('hmi_CL_page', JSON.stringify(hmi_CL_page))


  }, [order, restaurantDel, clientDel, page, hmi_CL_page, stripe_account]);

  const isAuth = async () => {
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    const res = await axios.get(backend + '/api/login/token/auth/',
    {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        //'X-CSRFTOKEN': csrfCookie,
      },
    }).catch(err=>{return err.response})
    //const data = await res.json();
    const data = await res.data;
    if (res.status === 200) {
      console.log(data);
      setIsVerified(true)
      const response = {
        stat: res.status,
        data: data
      }
      return response;
    } else {
      if (restaurantDel.registration_method === "email") {
        setIsVerified(false)
        setRestaurantDel(initial_stateDel)
      } const response = {
        stat: res.status,
        data: data
      }
      return response;
    }
  }

  const registerUser = async (SignUp, restaurant) => {
    const body = JSON.stringify(SignUp);
    console.log(body)
    let res = {}
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    //const body=LogIn
    if (restaurant === true) {
      res = await axios.post(backend + '/api/login/register-restaurant/',body,  {
        withCredentials: true,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          //'X-CSRFTOKEN': csrfCookie,
        },
      }).catch(err=>{
        console.log(err)
        return err.response})

    } else {
      res = await axios.post(backend + '/api/login/register-client/', body,  {
        withCredentials: true,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          //'X-CSRFTOKEN': csrfCookie,
        },
      }).catch(err=>{
        console.log(err)
        return err.response})

    }

    //const data = await res.json();
    const data = await res.data;
    console.log(data, res)
    if (res.status === 200 || res.status === 302) {
      console.log(data);
      const response = {
        stat: res.status,
        data: data
      }
      return response;
    } else {
      console.log(data);
      const response = {
        stat: res.status,
        data: data
      }
      return response;

    }

  }

  const getUserRestaurant = async () => {
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    const res = await axios.get(backend + '/api/login/me-restaurant/', 
    {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        //'X-CSRFTOKEN': csrfCookie,
      },
      
    }).catch(err =>{console.log(err) 
      return err.response})
    console.log(res)
    //const data = await res.json();
    //console.log(data)
    const data = await res.data
    console.log(data)
    if (res.status === 200) {
      
      console.log(data);

      const response = {
        stat: res.status,
        data: data
      }
      console.log(restaurantDel)
      let client = restaurantDel

      Object.entries(client).map(([key, item]) => {
        client[key] = data[key]
      })
      console.log(client)
      staterestaurantDel(client)

      let settings = page
      console.log(settings)
      Object.entries(settings).map(([key, item]) => {
        settings[key] = data[key] || ""
        if (key==="user_id"){
          console.log(settings[key])
          settings[key]=settings[key].replace(/-/g,"")
          console.log(settings[key])
        }
      })
      console.log(settings)
      statepage(settings)

      return response;

    } else {
      console.log(data);
      const response = {
        stat: res.status,
        data: data
      }

      return response;

    }
  }

  const getUserClient = async () => {

    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    const res = await axios.get(backend + '/api/login/me-client/',
    {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
        //'X-CSRFTOKEN': csrfCookie,
      },
      
    }).catch(err =>{console.log(err) 
      return err.response})
    const data = await res.data

    //const data = await res.json();
    console.log(data)

    if (res.status === 200) {

      console.log(data);

      const response = {
        stat: res.status,
        data: data
      }
      const client = clientDel

      Object.entries(client).map(([key, item]) => {
        client[key] = data[key]
        if (key==="user_id"){

          client[key]= data[key].replace(/-/g,"")
        }

      })

      console.log(client)
      stateclient(client)
      
      return response;
      
    } else {
      console.log(data);
      const response = {
        stat: res.status,
        data: data
      }

      return response;

    }
  }


  const googleLogin = async (code) => {
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    const res = await axios.get(backend + `/api/login/google-register${code}`, {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        //'X-CSRFTOKEN': csrfCookie,
      },

    }).catch(err =>{console.log(err) 
      return err.response});

    //const resp = await res.json();
    //const data = await resp.user
    const resp = await res.data
    if (res.status === 200) {
      const data = await resp.user
      const respons = await getUserClient().then(res => {
        console.log(res)
        return res
      })
      
      console.log(data);
      console.log(data["id"])
      const response = {
        stat: res.status,
        data: resp
      }
      
      return response;

    } else {

      const response = {
        stat: res.status,
        data: resp
      }

      return response;

    }
  }

  const googleLoginTable = async (code) => {
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    const res = await axios.get(backend + `/api/login/google-register-table${code}`, {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        //'X-CSRFTOKEN': csrfCookie,
      },

    }).catch(err =>{console.log(err) 
      return err.response});

    //const resp = await res.json();
    //const data = await resp.user
    const resp = await res.data
    if (res.status === 200) {
      const data = await resp.user
      const respons = await getUserClient().then(res => {
        console.log(res)
        return res
      })
      
      console.log(data);
      console.log(data["id"])
      const response = {
        stat: res.status,
        data: resp
      }
      
      return response;

    } else {

      const response = {
        stat: res.status,
        data: resp
      }

      return response;

    }
  }

  const googleCompLogin = async (code) => {
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    const res = await axios.get(backend + `/api/login/google-register-comp${code}`, {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        //'X-CSRFTOKEN': csrfCookie,
      },
    }).catch(err =>{console.log(err) 
      return err.response});

    //const data = await res.json();
    const data = await res.data;
    console.log(data)
    if (res.status === 200) {

      console.log(data);

      const response = {
        stat: res.status,
        data: data
      }
      const client = restaurantDel
      Object.entries(client).map(([key, item]) => {
        client[key] = data.user[key]
      })
      staterestaurantDel(client)

      const settings = page
      Object.entries(settings).map(([key, item]) => {
        settings[key] = data.user[key] || ""
        if (key==="user_id"){
          console.log(settings[key])
          settings[key]=settings[key].replace(/-/g,"")
          console.log(settings[key])
        }
      })
      console.log(settings)
      statepage(settings)

      return response;

    } else {
      console.log(data);
      const response = {
        stat: res.status,
        data: data
      }

      return response;

    }
  }




  const login = async (logIn, restaurant) => {
    const body = JSON.stringify(logIn);
    const email = JSON.stringify(restaurantDel.email)
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    if (restaurant === true) {
      const res = await axios.post(backend + '/api/login/normal_token_restaurant/', body,
      {
        withCredentials: true,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          //'X-CSRFTOKEN': csrfCookie,
        },
        
      }).catch(err =>{console.log(err) 
        return err.response});
      const data = await res.data
      //const data = await res.json();
      console.log(data)
      if (res.status === 200) {

        const resp = await getUserRestaurant().then(res => {
          console.log(res)
          return res
        })
        const result = { status: res.status, message: "Email or Password are correct", data: data }
        console.log(result.message)

        return result;
      } else {
        console.log(data)
        const result = { status: res.status, message: data.detail, data: data }
        console.log(result.message)
        return result;
      }

    } else {



      const res = await axios.post(backend + '/api/login/normal_token_client/', body,{
        withCredentials: true,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          //'X-CSRFTOKEN': csrfCookie,
        },
        
      }).catch(err =>{console.log(err) 
        return err.response});

      //const data = await res.json();
      const data = await res.data;
      if (res.status === 200) {

        const resp = await getUserClient().then(res => {
          console.log(res)
          return res
        })


        const result = { status: res.status, message: "Email or Password are correct", data: data }
        console.log(result.message)

        return result;
      } else {
        console.log(data)
        const result = { status: res.status, message: data.detail, data: data }
        console.log(result.message)
        return result;
      }

    }
  }
  const logout = async (restaurant) => {
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    console.log(restaurant)
    if (restaurant===false){
      setClientDel(initial_stateClient)
    }
    const res = await axios.get(backend + '/api/login/token/logout/', 
    {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        //'X-CSRFTOKEN': csrfCookie,
      },
    }).catch(err=>{return err.response})
     
    if (res.status === 200) {
      console.log(res)
      staterestaurantDel(initial_stateDel)
      statepage(initial_page)
      setIsVerified(false)
      localStorage.clear()
      if (restaurant===true){
        navigate("/")
      }
      return res;
    } else {
      return console.log(res, "error to LogOut User");
    }

  }

  const update_user_info = async (userinfo,restaurant) => {
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    const body = JSON.stringify(userinfo)
    let res = {}
    if (restaurant===true){
      res = await axios.patch(backend + '/api/login/update-restaurant_account/',body, 
      {
        withCredentials: true,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          //'X-CSRFTOKEN': csrfCookie,
          
        },
        
      }).catch(err =>{console.log(err) 
        return err.response});
    }else{
      res = await axios.patch(backend + '/api/login/update-client_info/', body, 
      {
        withCredentials: true,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          //'X-CSRFTOKEN': csrfCookie,
          
        },
        
      }).catch(err =>{console.log(err) 
        return err.response});

    }
    //const data = await res.json();
    const data = await res.data
    if (res.status === 201) {
      if (restaurant===true){
      getUserRestaurant()
      }else{
        getUserClient()
      }
      const response = {
        stat: res.status,
        data: data
      }
      return response;
    } else {
      const response = {
        stat: res.status,
        data: data
      }
      return response;
    }


  }

  const update_email = async (email,restaurant) => {
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    const body = JSON.stringify(email)
    let res = {}
  
    res = await axios.patch(backend + '/api/login/change-email', body, 
    {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        //'X-CSRFTOKEN': csrfCookie,
        
      },
    }).catch(err =>{console.log(err) 
      return err.response});

    //const data = await res.json();
    const data = await res.data
    if (res.status === 201) {
      if (restaurant===true){
      getUserRestaurant()
      }else{
        getUserClient()
      }
      const response = {
        stat: res.status,
        data: data
      }
      return response;
    } else {
      const response = {
        stat: res.status,
        data: data
      }
      return response;
    }
  }


  const update_pass = async (pass,restaurant) => {
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    const body = JSON.stringify(pass)
    let res = {}
  
    res = await axios.patch(backend + '/api/login/change-pass', body, 
    {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        //'X-CSRFTOKEN': csrfCookie,
        
      },
    }).catch(err =>{console.log(err) 
      return err.response});

    //const data = await res.json();
    const data = await res.data
    if (res.status === 201) {
      if (restaurant===true){
      getUserRestaurant()
      }else{
        getUserClient()
      }
      const response = {
        stat: res.status,
        data: data
      }
      return response;
    } else {
      const response = {
        stat: res.status,
        data: data
      }
      return response;
    }
  }



  const update_pass_link = async (pass,restaurant) => {
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    const body = JSON.stringify(pass)
    let res = {}
  
    res = await axios.patch(backend + '/api/login/change-pass-link', body, 
    {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        //'X-CSRFTOKEN': csrfCookie,
        
      },
    }).catch(err =>{console.log(err) 
      return err.response});

    //const data = await res.json();
    const data = await res.data
    if (res.status === 201) {
      if (restaurant===true){
      getUserRestaurant()
      }else{
        getUserClient()
      }
      const response = {
        stat: res.status,
        data: data
      }
      return response;
    } else {
      const response = {
        stat: res.status,
        data: data
      }
      return response;
    }
  }



  const update_restaurant_settings = async (userinfo) => {
    const body = JSON.stringify(userinfo)
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    const res = await axios.patch(backend + '/api/login/update-restaurant_settings/', body, 
    {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        //'X-CSRFTOKEN': csrfCookie,
        
      },
      
    }).catch(err =>{console.log(err) 
      return err.response});



    //const data = await res.json();
    const data = await res.data;

    if (res.status === 201) {
      getUserRestaurant()
      const response = {
        stat: res.status,
        data: data
      }
      return response;
    } else {
      const response = {
        stat: res.status,
        data: data
      }
      return response;
    }

  }



  const getRestaurantPage = async (restaurant_id) => {
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    const res = await fetch(backend + `/api/login/page-profile?restaurant_id=${restaurant_id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },

    });

    const data = await res.json();
    console.log(data)
    if (res.status === 200) {

      console.log(data);

      const response = {
        stat: res.status,
        data: data
      }
      const page_info = hmi_CL_page
      Object.entries(page_info).map(([key, item]) => {
        page_info[key] = data[key] || ""
        if (key==="user_id"){

          page_info[key]= page_info[key].replace(/-/g,"")

        }
      })
      stateHmiCLpage(page_info)

      return response;

    } else {
      console.log(data);
      stateHmiCLpage(initial_page)
      const response = {
        stat: res.status,
        data: data
      }

      return response;

    }
  }



  const get_restaurant_orders = async (restaurant_id) => {
    const body = JSON.stringify({restaurant_id: restaurant_id})
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    const res = await axios.post(backend + '/api/order-pay/delivery/get-restaurant-order', body, 
    {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        //'X-CSRFTOKEN': csrfCookie,
        
      },
      
    }).catch(err =>{console.log(err) 
      return err.response});

    
    //const data = await res.json();
    const data = await res.data;
    console.log(data)
    if (res.status === 200) {

      console.log(data);

      const response = {
        stat: res.status,
        data: data
      }

      stateRestaurantOrders(data)

      return response;

    } else {
      console.log(data);
      
      const response = {
        stat: res.status,
        data: data
      }
      stateRestaurantOrders([])
      return response;

    }
  }

  const get_client_orders = async (restaurant_id, client_id) => {
    const body = JSON.stringify({restaurant_id: restaurant_id, client_id: client_id})
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    const res = await axios.post(backend + '/api/order-pay/delivery/get-client-order', body, 
    {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        //'X-CSRFTOKEN': csrfCookie,
        
      },
      
    }).catch(err =>{console.log(err) 
      return err.response});

    //const data = await res.json();
    const data = await res.data;
    console.log(data)
    if (res.status === 200) {

      console.log(data);

      const response = {
        stat: res.status,
        data: data
      }

      stateClientOrders(data)

      return response;

    } else {
      console.log(data);
      
      const response = {
        stat: res.status,
        data: data
      }
      stateClientOrders([])
      return response;

    }
  }

  const del_order = async(order_id, restaurant_id) => {
    const body = JSON.stringify({order_id: order_id, restaurant_id: restaurant_id});
    
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    const res = await axios.post(backend + '/api/order-pay/delivery/del-order', body, 
    {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        //'X-CSRFTOKEN': csrfCookie,
        
      },
      
    }).catch(err =>{console.log(err) 
      return err.response});

   
    //const data = await res.json();
    const data = await res.data;
    if (res.status === 200) {

      const response = {
        stat: res.status,
        data: data
      } 
      get_restaurant_orders(restaurant_id)
      return response;
    } else {

      const response = {
        stat: res.status,
        data: data
      } 
      return response;
    }

  }

  const get_order = async(order_id) => {
    const body = JSON.stringify({order_id: order_id});
    
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    const res = await axios.post(backend + '/api/order-pay/delivery/get-order', body, 
    {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        //'X-CSRFTOKEN': csrfCookie,
        
      },
      
    }).catch(err =>{console.log(err) 
      return err.response});

   
    //const data = await res.json();
    const data = await res.data;
    if (res.status === 200) {

      const response = {
        stat: res.status,
        data: data
      } 
      return response;
    } else {

      const response = {
        stat: res.status,
        data: data
      } 
      return response;
    }

  }

  const del_account = async(email) => {
    const body = JSON.stringify({email: email});
    
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    const res = await axios.post(backend + '/api/login/delete-user/', body, 
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        //'X-CSRFTOKEN': csrfCookie,
        
      },
      
    }).catch(err =>{console.log(err) 
      return err.response});
   
    const data = await res.data;
    if (res.status === 200) {

      const response = {
        stat: res.status,
        data: data
      } 
      return response;
    } else {

      const response = {
        stat: res.status,
        data: data
      } 
      return response;
    }

  }


  const update_sts_order = async(order_id, sts, restaurant_id) => {
    const body = JSON.stringify({order_id: order_id, sts: sts, restaurant_id: restaurant_id});
    
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    const res = await axios.post(backend + '/api/order-pay/delivery/modify-sts-order', body, 
    {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        //'X-CSRFTOKEN': csrfCookie,
        
      },
      
    }).catch(err =>{console.log(err) 
      return err.response});

    //const data = await res.json();
    const data = await res.data;

    if (res.status === 200) {

      const response = {
        stat: res.status,
        data: data
      } 
      get_restaurant_orders(restaurant_id)
      return response;
    } else {

      const response = {
        stat: res.status,
        data: data
      } 
      return response;
    }

  }

  const modify_order = async(order, restaurant_id) => {
    const body = JSON.stringify({order: order, restaurant_id: restaurant_id});
    
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    const res = await axios.post(backend + '/api/order-pay/delivery/modify-order', body, 
    {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        //'X-CSRFTOKEN': csrfCookie,
        
      },
      
    }).catch(err =>{console.log(err) 
      return err.response});
   
    //const data = await res.json();
    const data = await res.data

    if (res.status === 200) {

      const response = {
        stat: res.status,
        data: data
      } 
      get_restaurant_orders(restaurant_id)
      return response;
    } else {

      const response = {
        stat: res.status,
        data: data
      } 
      return response;
    }

  }

  const check_on_map = async(gps) => {
    const body = JSON.stringify(gps);
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    const res = await axios.post(backend + '/api/login/check-map', body, 
    {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        //'X-CSRFTOKEN': csrfCookie,
        
      },
      
    }).catch(err =>{console.log(err) 
      return err.response});
   
    //const data = await res.json();
    const data = await res.data

    if (res.status === 200) {

      const response = {
        stat: res.status,
        data: data
      } 
      return response;
    } else {
      const response = {
        stat: res.status,
        data: data
      } 
      return response;
    }

  }


  const email_change_pass = async(email) =>{
    const body = JSON.stringify({email: email})
    console.log(body)
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    const res = await axios.post(backend + '/api/login/email-change-pass', body, 
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          //'X-CSRFTOKEN': csrfCookie,
          
        },
      }).catch(err =>{console.log(err) 
        return err.response});
      const data = await res.data;
      console.log(data)
      if (res.status === 200) {
        console.log(data);
        const response = {
          stat: res.status,
          data: data
        }
        return response;
      }else{
        console.log(data);
        const response = {
          stat: res.status,
          data: data
        }
        return response;
      } 
  }

  const check_link_pass = async (code) => {
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    const res = await fetch(backend + `/api/login/link-change-pass?code=${code}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },

    });

    const data = await res.json();
    console.log(data)
    if (res.status === 200) {
      console.log(data);
      const response = {
        stat: res.status,
        data: data
      }
      return response;
    } else {
      console.log(data);
      const response = {
        stat: res.status,
        data: data
      }
      return response;
    }
  }


  const get_stripe_account = async (stripe_id, sub_stripe_id) => {
    const body = JSON.stringify({stripe_id: stripe_id, sub_stripe_id:sub_stripe_id})
    console.log(body)
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    const res = await fetch(backend + '/api/stripe/retrieve-account/', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body
    });
    console.log(res)
    const data = await res.json();
    console.log(data)
    if (res.status === 200) {

      console.log(data);

      const response = {
        stat: res.status,
        data: data
      }

      stateStripeAccount(data)

      return response;

    } else {
      console.log(data);
      
      const response = {
        stat: res.status,
        data: data
      }
      stateStripeAccount(initial_stripe_account)
      navigate("/")
      return response;

    }
  }


  




  const stateOrder = (orders) => {
    console.log(orders)
    setOrder({ ...orders })
  }

  const staterestaurantDel = (info_restaurant) => {
    console.log(info_restaurant)

    setRestaurantDel({ ...info_restaurant })
  }

  const stateIsVerified = (test) => {

    setIsVerified(test)
  }

  const stateclient = (info_client) => {

    setClientDel({...info_client})
  }

  const statepage = (page) => {

    setPage({...page})
  }

  const stateHmiCLpage = (page) => {

    setHmi_CL_page({...page})
  }

  const stateRestaurantOrders = (orders) => {

    setRestaurant_orders([...orders])
  }

  const stateClientOrders = (orders) => {

    setClient_orders([...orders])
  }

  const stateStripeAccount = (account) => {
    setStripe_account({...account})
  }

  const stateSubscription = (sub) => {
    setSubscription({...sub})
  }

  const stateLoading = (load) => {
    console.log(load)
    setLoading(load)
  }


  

  const contextValue = {

    restaurant_info: restaurantDel,
    order_info: order,
    client_info: clientDel,
    page_info:page,
    hmi_CL_info:hmi_CL_page,
    restaurant_orders: restaurant_orders,
    client_orders:client_orders,
    stripe_account: stripe_account,
    subscription: subscription,
    loading:loading,
    staterestaurantDel,
    stateOrder,
    stateclient,
    statepage,
    stateIsVerified,
    stateHmiCLpage,
    stateRestaurantOrders,
    stateClientOrders,
    stateSubscription,
    stateLoading,
    isVerified,
    getUserClient,
    getUserRestaurant,
    registerUser,
    isAuth,
    login,
    logout,
    update_user_info,
    googleLogin,
    googleLoginTable,
    googleCompLogin,
    getRestaurantPage,
    update_restaurant_settings,
    get_restaurant_orders,
    get_client_orders,
    get_order, 
    del_order,
    del_account,
    update_sts_order,
    modify_order,
    get_stripe_account,
    check_on_map,
    update_email,
    update_pass,
    update_pass_link,
    email_change_pass,
    check_link_pass,
  }

  console.log(contextValue.subscription)
  return(
    <LoginDelyContext.Provider value={contextValue}>
      {children}
    </LoginDelyContext.Provider>
  )


}

export default LoginDelyProvider

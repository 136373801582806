import React , {useContext, useState, useEffect, useRef}from 'react'
import { LoginDelyContext } from '../../../contexts/LoginDelyContext'
import { useTranslation } from 'react-i18next';
import axios from 'axios';


const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

const Register_CL = (props) => {

  const User = useContext(LoginDelyContext)
  const {t} = useTranslation()

  const[email, setEmail] = useState('');
  const[password,setPassword] = useState('');
  const[password2,setPassword2] = useState('');
  const[name,setName] = useState('')
  const [lastname, setLastname]  = useState('')
  const [noMatch, setNoMatch] = useState(false)
  const [style, setStyle] = useState("rounded-xl px-4 w-full py-1 bg-gray-200  border border-gray-400 mb-4 text-green-900 placeholder-gray-700 focus:outline-none")
  const [message, setMessage] = useState("")
  const [phone, setPhone] = useState("")
  const [company_name, setCompany_name]= useState('')
  const [isChecked, setIsChecked] = useState(false);

  let SignUp = {
    company_name: company_name,
    name: name,
    first_name:name,
    last_name: lastname,
    email: email,
    password: password,
    phone: phone,
  }
  console.log(SignUp);

  const handleOnChange = () => {
    setIsChecked(!isChecked);
    setMessage("")
  };

  const restaurant=false

  const handleSubmit = async (e) => {
    e.preventDefault()
    if(isChecked===false){
      setMessage(t("Please, Confirm the Terms and Conditions"))
    }else{
      if(email.length===0 || password.length===0 || name.length===0 || lastname.length===0){
        setMessage(t("All fields should be filled"))
        console.log(SignUp)
        
      }else{

        if (password === password2){
          const resp = await User.registerUser(SignUp,restaurant).then(async res => {
            User.stateLoading(true)
            //const resp = await res
            //setResp(resp)
            return res
          })
          if (resp?.stat === 200){
            User.stateLoading(false)
            console.log(resp)
            alert(t("The Account has been successfully") + resp.data)
            setEmail('')
            setLastname('')
            setName('')
            setPassword('')
            setPassword2('')
            setCompany_name('')
            setPhone('')
            console.log(resp)
          }else{
            User.stateLoading(false)
            console.log(resp)
            setMessage(resp.data.email + " Please log in with the email " + email)
          }

        }else{
          User.stateLoading(false)
          setNoMatch(true)
          setStyle("rounded-xl px-4 w-full py-1 bg-gray-400  border border-red-400 mb-4 text-green-900 placeholder-red-500 focus:outline-none")
          setMessage(t("Passwords don't match"))
          };
        }
      }
}



  return (
    <div className=" bg-white shadow-xl rounded-lg p-4">
      <div >
        <div className="flex items-center justify-center">
          <h2 className="text-2xl font-bold tracking-wide mb-6">{t("Register & Subscribe")}</h2>
        </div>
        <label className="text-lg font-semibold" htmlFor="first_name">{t("Name")}</label>
        <input value={name} onChange={(e) => setName(e.target.value)} className="rounded-xl px-4 w-full py-1 bg-gray-200  border border-gray-400 mb-4 text-green-900 placeholder-gray-700 focus:outline-none" name="name" id="name" placeholder={t("Name")} />
        <label className="text-lg font-semibold" htmlFor="last_name">{t("Last Name")}</label>
        <input value={lastname} onChange={(e) => setLastname(e.target.value)} className="rounded-xl px-4 w-full py-1 bg-gray-200  border border-gray-400 mb-4 text-green-900 placeholder-gray-700 focus:outline-none" name="last_name" id="last_name" placeholder={t("Last Name")} />
        <label className="text-lg font-semibold" htmlFor="phone">{t("Phone Number")}</label>
        <input value={phone} onChange={(e) => setPhone(e.target.value)} type="phone" className="rounded-xl px-4 w-full py-1 bg-gray-200  border border-gray-400 mb-4 text-green-900 placeholder-gray-700 focus:outline-none" placeholder={t("Phone Number")} id="phone" name="phone" />
        <label className="text-lg font-semibold" htmlFor="email">Email</label>
        <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" className="rounded-xl px-4 w-full py-1 bg-gray-200  border border-gray-400 mb-4 text-green-900 placeholder-gray-700 focus:outline-none" placeholder="Email" id="email" name="email" />
       
        <label className="text-lg font-semibold" htmlFor="password">Password</label>
        <input value={password} onChange={(e) => setPassword(e.target.value)} type="password" className="rounded-xl px-4 w-full py-1 bg-gray-200  border border-gray-400 mb-4 text-green-900 placeholder-gray-700 focus:outline-none" placeholder="Password" id="password1" name="password1" />
        <label className="text-lg font-semibold" htmlFor="password">{t("Confirm Password")}</label>
        <input value={password2} onChange={(e) => setPassword2(e.target.value)} type="password" className={style} placeholder="Password" id="password2" name="password2" />
        <div className="mr-2 p-0 sm:text-sm underline justify-start flex">
        <input
          className='w-7'
          type="checkbox"
          id="terms"
          name="terms"
          value="terms"
          checked={isChecked}
          onChange={handleOnChange}
        />
        <a className="ml-2 text-black " href={'/terms-and-services'}>{t("Confirm the Terms & Conditions")}</a>
      </div>
      <p className='text-red-500 mt-4'>{t(message)}</p>
      <div className="flex items-center justify-between">
        <button className="mx-2 p-0 text-xs sm:text-sm underline" onClick={()=>props.onFormSwitch('login')}>{t("Already have an account? Login here")}</button>
          <button
            type="submit"
            className="bg-green-900 text-gray-200 px-2 py-1 rounded-lg object-right text-lg hover:bg-gray-800 md:text-xl"
            onClick={handleSubmit}
          >Sign In</button>
        </div>
      </div>
    </div>
  )
}

export default Register_CL
import React ,{useState, useEffect, useContext, useRef} from 'react'
import {TableCard, TableDetails} from "."
import { useTranslation } from 'react-i18next';
import { HMICLContext } from '../../contexts/HMICLContext'
import { LoginDelyContext } from '../../contexts/LoginDelyContext'
import { HMIWTContext } from '../../contexts/HMIWTContext'
import { HMICLProvider} from '../../contexts/HMICLContext'

const ListOfTables = ({manager}) => {
  console.log(manager)
  const {t} = useTranslation()
  //console.log(data_CL_Mobile)
  const [show, setShow] = useState(false);
  const [tableID, setTableID] = useState("");
  const params = useContext(HMICLContext)
  const User = useContext(LoginDelyContext)
  const settings = User.page_info
  const [orders, setOrders] = useState([])
  const restaurant_id = settings.user_id

  const handleClose = () => {
    setShow(false);
  }

  const ref_table = useRef(null)

  const tables = [
    {"tableID":"1"},
    {"tableID":"2"},
    {"tableID":"3"},
    {"tableID":"5"}]


  const DetailsSelected = (details_selected)=>{
    setShow(details_selected.selected)
    setTableID(details_selected.selected_table)
  }

  const getListTableDishes = () => {
    if (manager ===false) {
    tables.map(table => { 
      params.getUserTableDishes(table.tableID, restaurant_id).then((response)=>{
      const orders= response
      if (orders.length===0){
        setOrders([])
      }else{
        setOrders(orders => [...orders, {"tableID":table.tableID, "orders":orders}])
      }
    
    }).catch(error => console.log(`Error: ${error}`))    
    })

    }else{
      params.getAllTablesSorted(restaurant_id).then((response)=>{
        const table_dishes = response.data;
        console.log(table_dishes)
        setOrders(table_dishes)
      })
    }
    
  }
  
  orders?.sort((a,b) =>
  a.tableID - b.tableID
  )


  useEffect(()=>{
    ref_table.current = setInterval(() => {getListTableDishes()}, 1*20*1000)
    return () => {
      if(ref_table.current){
        clearInterval(ref_table.current);
      }
    }
  },[])

  useEffect(()=>{
    getListTableDishes()
  },[])


  console.log(orders)

  return (
    <HMICLProvider>
    {manager ?
     <div className='bg-gray-50 shadow-xl rounded-2xl flex flex-col w-full px-4 mx-auto'>
     <h3 className='justify-center items-center flex text-4xl font-bold'>{t("List Tables")}</h3>
     {/*display food*/}
     {orders?.length===0 ?
         <h5 className='text-2xl font-bold my-[100px] text-center'>
           {t("No Orders Detected from the Tables")}
         </h5>
       :
       <div className='flex flex-col sm:grid sm:grid-cols-2  lg:grid-cols-4 gap-6 p-4 '>
         {orders?.map((table,index1) => (
           <TableCard handleDetails={DetailsSelected} key={index1} orders={table.orders} selected={show} table={table.tableID} manager={manager} getListTableDishes={getListTableDishes}/>
           
         ))}
       
       </div>
     }
     
     {show ? <TableDetails onClose={handleClose} visible={setShow} tableID={tableID} orders={orders}/> : ""}
   </div>
    :
    
    <div className='max-w-[1640px] m-auto py-6'>
      <h1 className='text-green-600 font-bold lg:text-6xl text-4xl text-center pb-6'>{t("List Tables")}</h1>
      {/*display food*/}
      {orders.length===0 ?
          <h5 className='text-2xl font-bold mt-[100px] text-center'>
            {t("No orders detected")}
          </h5>
        :
        <div className='flex flex-col sm:grid sm:grid-cols-2  lg:grid-cols-4 gap-6 p-4 '>
          {orders?.map((table,index1) => (
            <TableCard handleDetails={DetailsSelected} key={index1} orders={table.orders} selected={show} table={table.tableID} manager={manager}/>
            
          ))}
        
        </div>
      }
      
      {show ? <TableDetails onClose={handleClose} visible={setShow} tableID={tableID} orders={orders}/> : ""}
    </div>
    }
   </HMICLProvider>
  );
};

export default ListOfTables
import React , {useContext, useState, useEffect, useRef} from 'react'
import { CartContext } from '../../contexts/CartContext'
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { MenuContext } from '../../contexts/MenuContext'
import { LoginDelyContext } from '../../contexts/LoginDelyContext'
import {DeliveryAddress} from './'
import {AiFillDelete, AiFillCreditCard} from 'react-icons/ai'
import {useLoadScript, } from '@react-google-maps/api'
import Loading from '../Loading';
import { DeleteModalUser } from '../../page/Dashboard';
import {IoFastFoodOutline} from 'react-icons/io5'


const OrderButtonModalDely = ({onClose, visible, visibleUser}) => {
  const cart = useContext(CartContext)
  const menus = useContext(MenuContext)
  const [foods,setFoods] = useState([]);
  const [error,setError] = useState("")
  const User = useContext(LoginDelyContext);
  const restaurant = false
  const {t} = useTranslation();
  const user = User.client_info
  const delyinfo = User.hmi_CL_info
  const order = User.order_info
  const [notes, setNotes] = useState(()=>{
    return User.order_info.notes || ""
  })
  const [loading,setLoading] = useState(false)
  const [addressVerified, setAddressVerified] = useState(false)
  const dely_info = {
    delivery_cost : delyinfo.delivery_cost || 1,
    delivery_days: delyinfo.delivery_days || 0,
    start_hours: delyinfo.start_dely_time_hours || 19,
    start_minutes: delyinfo.start_dely_time_minutes|| 30,
    end_hours: delyinfo.end_dely_time_hours || 22,
    end_minutes: delyinfo.end_dely_time_minutes|| 30,
    minutes_dely: delyinfo.minutes_dely || 30,
    order_without_pay: delyinfo.order_without_pay || false,
    
  }
  const handleChange = (e) =>{
    setError("")
    const new_client = user
    new_client.phone = e.target.value
    User.stateclient(new_client)
  }

  const libraries = ['places', 'maps']
  let libRef = useRef(libraries)
  const {isLoaded} = useLoadScript({
    id:"google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: libRef.current,
  })

  const handleVerify = (verify) =>{
    setError("")
    setAddressVerified(verify)
  }
  const [openConfirm, setOpenConfirm] = useState(false)
  let totalCart = cart.items
  let total_cost = cart.getTotalCost().toFixed(2)
  
  if (order.type==="Delivery") {

    total_cost = (cart.getTotalCost() + dely_info.delivery_cost).toFixed(2)
    
  }

  
  

  const now = new Date();
  const today = {
    month: now.getMonth() + 1,
    monthstr: now.toLocaleString('default', { month: 'short' }),
    year: now.getFullYear(),
    day: now.getDate(),
  }


  const getNextDays = (currentDate, daysToAdd) => {
    //const nextDate = new Date(currentDate)
    
    let daysSet = [today]
    for (let i=1; i<= daysToAdd; i++){
      let nextDate = new Date(currentDate.getTime() + (i * 86400000))
      let nextday= {
        month: nextDate.getMonth() + 1,
        monthstr: nextDate.toLocaleString('default', { month: 'short' }),
        year: nextDate.getFullYear(),
        day: nextDate.getDate(),
      }

      daysSet.push(nextday)
    }

    return daysSet
  }

  const days = getNextDays(now,dely_info.delivery_days)
  
  
  const getTime = () =>{
    const starts = {
      hours: dely_info.start_hours,
      minutes: dely_info.start_minutes
    }
    const ends = {
      hours: dely_info.end_hours,
      minutes: dely_info.end_minutes
    }
    if (dely_info.start_minutes==="00") {
      starts.minutes=0
    }
    if (ends.minutes==="00") {
      ends.minutes=0
    }
    const startTime = new Date().setHours(starts.hours, starts.minutes, 0)
    const endTime = new Date().setHours(ends.hours,ends.minutes,0)
    let nextTime = new Date(startTime)
    let hours = nextTime.getHours()
    let time = []

    for (let minute = dely_info.minutes_dely ; nextTime<endTime;){
      
      const begin = {
        hours:nextTime.getHours(), 
        minutes:nextTime.getMinutes(),
      }


      nextTime.setMinutes(nextTime.getMinutes() + minute)
      const end = {
        hours:nextTime.getHours(), 
        minutes:nextTime.getMinutes(),
      }
      if (end.minutes===0){
        end.minutes="00"
      }
      if (begin.minutes===0){
        begin.minutes="00"
      }
      const newtime= {
        begin, end
      }
      hours=nextTime.getHours()
      if (hours===0){
        hours=24
      }
      time.push(newtime)
    }
    return time
  }

  const time = getTime()

  const intialstate =  async () =>{
    console.log(order)
    const dateTimeCheck =  deliveryTime_Style(User.order_info.date,User.order_info.time)
    console.log(dateTimeCheck)
    if (dateTimeCheck===true){
      handleDeleteDate()
      handleDeleteTime()
    }
    const res = menus.fetchMenu(delyinfo.user_id).then((response)=>{
      return response
    }).catch(error => console.log(`Error: ${error}`));
    const menu = await res
    setFoods(menu)
  }


  const deliveryTime_Style = (delivery_date, delivery_time) =>{
    console.log(delivery_time)
    if (Object.values(delivery_time).length===0){
      delivery_time = {
        begin: {hours: Number, minutes: ""},
        end: {hours: Number, minutes: ""}
      }
    }
    let style = false

      if (delivery_time.begin.minutes==="00"){
        delivery_time.begin.minutes=0
      }
      
      const date = new Date(delivery_date.year, delivery_date.month-1, delivery_date.day, delivery_time.begin.hours,delivery_time.begin.minutes)

      const remaining_time = ((date - now)/1000/60)

      if (delivery_time.begin.minutes===0){
        delivery_time.begin.minutes="00"
      }
      if (remaining_time<=80){
        style = true
      }else {
        style = false
      }
    
    return style
  }


  const handleDeleteDate = () => {
    let order = User.order_info
    order.date = {}
    User.stateOrder(order)

  }

  const handleDeleteTime = () => {
    setError("")
    let order = User.order_info
    order.time = {
      begin: {hours: Number, minutes: ""},
      end: {hours: Number, minutes: ""},
    }
    User.stateOrder(order)

  }

  const handleDate = (day) =>{
    setError("")
    let order = User.order_info
    order.date = day
    order.total_cost = (cart.getTotalCost() + dely_info.delivery_cost).toFixed(2)
    User.stateOrder(order)
  }

  const handleTime = (time) => {
    setError("")
    console.log("AAAAAAAAAAAAAAAAAAAAAAAA")
    console.log(time)
    let order = User.order_info
    order.time = time
    User.stateOrder(order)
    
  }


  const handleNotes = (e) => {
    setError("")
    let order = User.order_info
    let note = e.target.value
    setNotes(note)
    order.notes = note
    User.stateOrder(order)
    
  }
  

  useEffect(()=>{
    intialstate() ;
  },[])



 
  const handleCheckout = async (order_id) => {
    setError("")
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    let body = {}
      if (order.type==="Delivery") {
        body = {
          items: cart.items,
          email: user.email,
          client_id: user.user_id,
          delivery_address: order.delivery_address,
          city:order.city,
          country: order.country,
          postal_code: order.postal_code,
          delivery_date:order.date,
          delivery_time:order.time,
          notes: order.notes,
          delivery_cost: dely_info.delivery_cost,
          total_cost: total_cost,
          restaurant_id: delyinfo.user_id,
          restaurant_name: delyinfo.company_title,
          type: order.type,
          order_id: order_id,
          stripe_id: delyinfo.stripe_id,
          phone:user.phone,
          url_receipt:"",
          customer_stripe_id: user.customer_stripe_id
        }
      }else{
        body = {
          items: cart.items,
          email: user.email,
          client_id: user.user_id,
          delivery_date:order.date,
          delivery_time:order.time,
          notes: order.notes,
          total_cost: total_cost,
          restaurant_id: delyinfo.user_id,
          restaurant_name: delyinfo.company_title,
          type: order.type,
          order_id: order_id,
          stripe_id: delyinfo.stripe_id,
          phone:user.phone,
          url_receipt:"",
          customer_stripe_id: user.customer_stripe_id
        }
      }
      console.log(body)
      axios.post(backend + "/api/stripe/create-checkout-session-dely/",body,{
        withCredentials: true
      }).then((res)=>{
          console.log(res.data)

          if(res.data.url){
            setLoading(false)
            onClose()
            window.location.href = res.data.url      
          }
          
  
        }).catch((err) => {
          setLoading(false)
          User.del_order(order_id)
          alert(err.message)
          console.log(err.message)});
     
      }
      
    
    
  
    const handleOrder = async () => {
      let body = {}
      let data = ""
      let backend = ""
      if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
        backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
      }
      
      if (cart.items.length===0){
        setError(t("No food Selected, please Select Food"))
      }else if (Object.values(User.order_info.time).length===0 || Object.values(User.order_info.date).length===0 ){
        setError(t("No Time or Date Selected, please Select Date and Time"))
      }else{
        User.update_user_info(user,restaurant)
        setLoading(true)
        if (order.type==="Delivery") {
          body = {
            items: cart.items,
            email: user.email,
            client_id: user.user_id,
            delivery_address: order.delivery_address,
            city:order.city,
            country: order.country,
            postal_code: order.postal_code,
            delivery_date:order.date,
            delivery_time:order.time,
            notes: order.notes,
            delivery_cost: dely_info.delivery_cost,
            total_cost: total_cost,
            restaurant_id: delyinfo.user_id,
            restaurant_name: delyinfo.company_title,
            type: order.type,
            stripe_id: delyinfo.stripe_id,
            phone:user.phone,
            url_receipt:"",
            customer_stripe_id: user.customer_stripe_id
          }
        }else{
          body = {
            items: cart.items,
            email: user.email,
            client_id: user.user_id,
            delivery_date:order.date,
            delivery_time:order.time,
            notes: order.notes,
            total_cost: total_cost,
            restaurant_id: delyinfo.user_id,
            restaurant_name: delyinfo.company_title,
            type: order.type,
            stripe_id: delyinfo.stripe_id,
            phone:user.phone,
            url_receipt:"",
            customer_stripe_id: user.customer_stripe_id
          }
        }
        data = await axios.post(backend + "/api/order-pay/delivery/order",body,{
          withCredentials: true
        }).then(response => {
          console.log(response)
          const statusCode = response.status
          const success = response.statusText;
          if (order.type==="Delivery"){
            let client = user
            client.city = order.city
            client.country =order.country
            client.postal_code = order.postal_code
            client.address=order.delivery_address
            User.update_user_info(client,restaurant)
          }
          if(response.status===200){
            console.log(response.data)
            User.get_client_orders(user.user_id)
            return response
          }else{
            setLoading(false)
            console.log(response.data.message)
            alert(t("There are some problems, please try again & contact the support"))
          }

        }).catch((err) => {
          setLoading(false)
          alert(err)
          console.log(err.message)
        }); 
      }
    console.log(data)
    
    return data
    }

    
  const handlPayment = async () => {

    setError("")
    if (order.type==="Delivery"){
      if(Object.keys(order?.delivery_address).length<10 ){
        setError(t("Please specify the Delivery Address"))
        return
      }else if(Object.keys(user?.phone).length<10){
        setError(t("Please specify a correct Phone Number"))
        return
      }else if (addressVerified === true){
        setError("")
        if (Object.keys(user.email).length>0){
          const res = await handleOrder ()
          console.log(res)
          if (res.status===200){
            await handleCheckout(res.data.order_id)
            window.localStorage.removeItem('cart')
          }
        }else{
          visibleUser(true)
        }
      }else{
        setError(t("The Address is to Far for Delivery"))
      }
    }else {
      if (Object.keys(user.email).length>0){
        const res = await handleOrder ()
        console.log(res)
        if (res){
          await handleCheckout(res.data.order_id)
          window.localStorage.removeItem('cart')
        }

      }else{
        visibleUser(true)
      }
    }

  }

  console.log(cart.items)

  if(!visible) {
    setNotes("")
    let order = User.order_info
    order.notes = ""
    User.stateOrder(order)

    return null}
    else if(!isLoaded || loading) return  <Loading />
  return (

    <div className='fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center z-50' >

      <div className='bg-gray-100 px-1 rounded max-h-[700px] relative overflow-y-auto md:max-w-[700px] md:max-h-[750px] w-full'>
        
       
        <header className='top-0 w-full sticky border-gray-400 border-b-1 py-3 bg-green-200 z-20'>
          <span className='flex items-center justify-center font-bold text-xl'>{t("Hi")}<span className='text-orange-400 mx-2'>{user.first_name}</span>{t("Your" + " " + User.order_info.type)}</span>
          <button type="button" 
          className="absolute top-1 right-1 rounded-md bg-green-300 p-2 inline-flex items-center justify-center text-black hover:text-bold hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:bg-indigo-500 z-20"
          onClick={onClose}>
            <span className="sr-only">Close menu</span>
            {/*Heroicon name: outline/x*/}
            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
         </button>
        </header>
        <div className='flex-cols items-center justify-center my-1 mx-2 py-1'>
          <h3 className='text-center font-semibold text-xl'>{t("List of Foods Selected")}</h3>
          {cart.items.map((dish,idx) => 
          <div key={idx} className='grid grid-cols-2 my-3 mx-1 gap-3 text-sm bg-white rounded-xl shadow-xl p-2'>
            {dish.custom ?
            <div  className='flex-row items-center font-semibold'>{dish.category}
              {dish.ingredienti.map((ingrediente,idx)=>
                <p className='text-start font-thin text-[10px] my-1'>{ingrediente.name} €{Number(ingrediente.price).toFixed(2)} x {ingrediente.quantity} = €{Number(ingrediente.price*ingrediente.quantity).toFixed(2)}</p>
                
              )}
            </div>
            :
            <div  className='flex-row items-center font-semibold'>{dish.name}
              <p className='text-start font-thin text-[10px] my-1'>{dish.ingredienti}</p>
            </div>
            
            }
            <div className='flex items-center justify-center cursor-pointer'
              onClick={() => cart.deleteFromCart(dish.name)}>
              <AiFillDelete color="red" size={30} />

            </div>
            <div className='flex items-center md:justify-center text-red-500 text-xl font-semibold'>€ {(dish.quantity * Number(dish.prezzo)).toFixed(2)}</div>
            <div className='flex items-center justify-center'>
              <div className='flex justify-center items-center'>
                <button className='mx-2 bg-red-500 rounded w-[20px] items-center justify-center font-bold text-xl flex'
                onClick={() => cart.removeOneFromCart(dish.name, dish.category, dish.prezzo)}
                ><span>-</span></button>
                <h4 className='text-center flex items-center justify-center'>{dish.quantity} qty</h4>
                <button className='mx-2 bg-green-500 rounded w-[20px] items-center justify-center font-bold text-xl flex'
                onClick={() => cart.addOneToCart(dish.name,dish.category,dish.prezzo,dish.custom)}
                ><span>+</span></button>
              </div>
            </div>
          </div>
          )}
        </div>
        <div div className='flex-cols items-center justify-center my-1 mx-2 py-1 border-t-1 border-gray-400'>
          <h3 className='text-center font-semibold text-xl'>{t("Date & Time Details")}</h3>
          <div className='grid grid-cols-2 my-3 gap-3 py-2 border-b-1 border-gray-400'>
          {Object.values(User.order_info.date).length>0 ?
          <>
            <p className='text-center'>{t("Selected Day")}: <span className='font-bold'>{User.order_info.date.day} {t(User.order_info.date.monthstr)} {User.order_info.date.year}</span></p>
            <div className='flex items-center justify-center cursor-pointer'
              onClick={handleDeleteDate}>
              <AiFillDelete color="red" size={30} />
            </div>
          </>
          :
            <div className='col-span-2 flex flex-row gap-2 overflow-y-auto text-sm font-semibold h-auto'>
            {days?.map((day, idx) =>
                <div key={idx}
                  onClick={() => handleDate(day)}
                  className=' whitespace-nowrap p-2 items-center rounded-xl text-center grow border-[1px]  my-3 bg-green-500 border-white text-white hover:bg-green-800 hover:text-white cursor-pointer'>
                    <h3>{day.day}</h3>
                    <h3>{t(day.monthstr)} {day.year}</h3>
                </div>
              )}
            </div>
            }
            {Object.values(User?.order_info?.date).length>0 ?
            <>
            {Object.values(String(User.order_info.time?.begin?.minutes)).length>0 ? 
            <>
              <p className='text-center my-1'>{t("Time Selected")}: <span className='font-bold'>
                {User.order_info.time.begin.hours}:{User.order_info.time.begin.minutes} - {User.order_info.time.end.hours}:{User.order_info.time.end.minutes}</span></p>
              <div className='flex items-center justify-center cursor-pointer'
              onClick={handleDeleteTime}>
              <AiFillDelete color="red" size={30} />
              </div>
            </>
            
            :
            <div className='col-span-2 flex flex-row gap-2 overflow-y-auto text-sm font-semibold h-auto'>
            {time?.map((time, idx) =>
                <button key={idx}
                  onClick={() => handleTime(time)}
                  className={`whitespace-nowrap p-2 items-center rounded-2xl text-center grow border-[1px]  my-3 ${deliveryTime_Style(User.order_info.date,time) ? "cursor-not-allowed bg-gray-300" : "bg-green-500 border-white text-white hover:bg-green-800 hover:text-white cursor-pointer"}`}
                  disabled={deliveryTime_Style(User.order_info.date,time)}>
                    <h3>{time.begin.hours}:{time.begin.minutes} - {time.end.hours}:{time.end.minutes}</h3>
                </button>
              )}
              
            </div>
            }
            </>
            :
            ""
          }
          </div>
        </div>

        <DeliveryAddress verified={handleVerify}/>
        
        <div className='mx-2 text-center py-4 my-3 bg-white rounded-xl shadow-xl'>
          <label className="text-black mr-2" >
            {t("Modify Phone")}:
          </label>
          <input
          type="number"
          className="border-1 rounded-md p-1 w-[150px]"
          placeholder={user?.phone.length === 0 ? t("Phone") : user.phone}
          name="phone"
          value={user?.phone}
          onChange={handleChange} 
          />
        </div>

        <div div className='flex-cols items-center justify-center my-1 mx-2 py-1'>
          <h3 className='text-center font-semibold text-xl'>{t("Notes / Comments")}</h3>
          <textarea
            placeholder='Additional Info...'
            name="notes"
            className=" block rounded-lg h-[100px] px-1 break-words border-1 border-blue-800 mx-auto w-[350px] md:w-[550px] my-1"
            onChange={handleNotes}
            value={notes}>
            
          </textarea>
        </div>

        {order.type==="Delivery" ?
          <div div className='flex-cols items-center justify-center my-1 mx-2 py-1'>
            <h3 className='text-center font-semibold text-xl'>{t("Summary Cost")}</h3>

            <div className='grid grid-cols-2 my-3 gap-3 py-2 border-b-1 border-gray-300'>
              <div className='flex items-center justify-start'>{t("Subtotal")}</div>
              <div className='flex items-center justify-end'>€{(cart.getTotalCost()).toFixed(2)}</div>
            </div>
            
            <div className='grid grid-cols-2 my-3 gap-3 py-2 border-b-1 border-gray-300'>
              <div className='flex items-center justify-start'>{t("Delivery Cost")}</div>
              <div className='flex items-center justify-end'>€{dely_info.delivery_cost.toFixed(2)}</div>
            </div>

          </div>
        
        : ""}
        <div className='bottom-0 border-t-1 bg-blue-100 border-gray-400 sticky flex-row items-center justify-center text-center  py-3 font-bold'>     
          <h2 className='mb-3 text-2xl'>{t("Total")}: €{total_cost}</h2>
          <div className='flex items-center justify-center mx-auto text-white'>
            <button className='mx-2 bg-green-500 rounded-xl text-xl items-center justify-center font-bold hover:bg-green-700 flex'
            onClick={()=>{
              setError("")
              setOpenConfirm(true)}}>
              <AiFillCreditCard size={25} className='mr-2'/>{t("Order & Pay")}
            </button>
            <button className='mx-2 bg-red-500 rounded-xl text-xl items-center justify-center font-bold hover:bg-red-700 flex'
            onClick={onClose}>
              {t("Discard")}
            </button>
          </div>
          <h2 className='my-2 text-lg text-red text-center text-red-400'>{error}</h2>
        </div>
        
      </div>
      <DeleteModalUser open={openConfirm} onclose={()=>{setOpenConfirm(false)}}>
        <div className='text-center md:w-70 w-full overflow-y-auto items-center justify-center flex-col'>
          <IoFastFoodOutline className="mx-auto" size={56} color="green"/>
          <div>
            <h3 className='text-lg font-black text-gray-800 my-2'> {t("Confirm Payment") + " € " + total_cost + "?"}</h3>
            <div className='text-center items-center justify-center flex-col bg-white shadow-xl rounded-xl py-2 mx-10'>
              <h3 className='text-center my-1 font-semibold'>{t("List of Foods Selected")}</h3>
              {cart.items.map((dish,idx) => 
                <>
                {!dish.custom ? 
                <h3 key={idx} className='text-center'>{dish.name + " € " + Number(dish.prezzo).toFixed(2) + " x " + dish.quantity + " = € " + (dish.quantity * Number(dish.prezzo)).toFixed(2)}</h3>
                :
                <h3 key={idx} className='text-center'>{dish.category + " € " + dish.prezzo + " x " + dish.quantity + " = € " + (dish.quantity * Number(dish.prezzo)).toFixed(2)}</h3>
                }
                </>
                )}
              {User.order_info.type==="Delivery" ? 
              <h3 className='text-center'>{t("Delivery Cost") + " = € " + dely_info.delivery_cost.toFixed(2)}</h3>
              : ""} 
            </div>
            <p className='text-center mt-2'>{t("Selected Day")}: <span className='font-bold'>{User.order_info.date.day} {t(User.order_info.date.monthstr)} {User.order_info.date.year}</span></p>
            <p className='text-center my-1'>{t("Time Selected")}: <span className='font-bold'>
                {User.order_info.time.begin?.hours}:{User.order_info.time.begin?.minutes} - {User.order_info.time.end?.hours}:{User.order_info.time.end?.minutes}</span></p>
            {User.order_info.type==="Takeaway" ? 
              <>
                <h3 className='text-center'>{t("Company Address")}: <span className="font-bold ">{delyinfo.address}</span></h3>
                <h3 className="text-center ">{t("City")}: {delyinfo.city}</h3>
                <h3 className="text-center "> {t("Country")}: {delyinfo.country}</h3> 
                <h3 className="text-center "> {t("Phone")}: {delyinfo.phone}</h3>
              </>
            : 
              <>
                <h3 className='text-center'>{t("Address Selected")}: <span className="font-bold ">{User.order_info.delivery_address}</span></h3>
                <h3 className="text-center ">{t("City")}: {user.city}</h3>
                <h3 className="text-center "> {t("Country")}: {user.country}</h3> 
                <h3 className="text-center "> {t("Phone")}: {user.phone}</h3>
              </>
            }
            <p className='text-sm underline my-2'>{t("Confirming the Order, it will be no possible to change the foods selected neither the Address and the Date")}
            </p>
          </div>
          <div className='w-full items-center justify-center flex mt-3'>
            <button  
              className='flex bg-green-400 rounded-xl mx-1 hover:bg-green-600  hover:text-gray-100 w-auto font-semibold'
              onClick={handlPayment}>
                <AiFillCreditCard size={25} className='mr-2'/>
                {t("Confirm") + " € " + total_cost}</button>
            <button 
              className='flex bg-gray-200 rounded-xl mx-1 hover:bg-gray-400 hover:text-gray-100 w-auto text-center'
              onClick={()=>{setOpenConfirm(false)}}>{t("Cancel")}</button>
          </div>
          <h2 className='my-2 text-lg text-red text-center text-red-400'>{error}</h2>
        </div>
      </DeleteModalUser>
    </div>
    
  )
}

export default OrderButtonModalDely
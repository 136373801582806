import React from 'react'
import {useCookies} from 'react-cookie'
import { useTranslation } from "react-i18next";

const CookieConsent = () => {
  const {t} = useTranslation()
  const [cookies, setCookies] = useCookies(["__CookieConsentSpeaty"])
  const giveCookieConsent = () => {
    console.log(cookies)
    setCookies("__CookieConsentSpeaty", true, {path:"/"})
  }
  return (
    <div className='bg-white flex flex-col sm:flex-row p-4 items-center justify-center sm:justify-between sm:space-x-10 space-y-5'>
      <p className='text-black cursor-default text-justify text-sm sm:text-lg font-semibold'>
        {t("We use cookies to enhance your user experience. By using our website, you agree to our use of cookies")}. <a href='/help-center/cookie-policy'>{t("Learn More")}.</a>
      </p>
      <div className='p-2 px-5 mx-auto flex cursor-pointer shadow-2xl font-bold rounded-xl text-xl lg:text-2xl bg-teal-400 hover:bg-teal-600 text-white'
        onClick={giveCookieConsent}>
        {t("Accept")}
      </div>
    </div>
  )
}

export default CookieConsent
import React, {useEffect, useState, useContext} from 'react'
import { HMIWTContext } from '../../contexts/HMIWTContext'
import { useTranslation } from 'react-i18next';
import {AiFillDelete} from 'react-icons/ai'

const Notification = ({onClose, visible, reservationCount}) => {
  const waiterParam = useContext(HMIWTContext)
  let call = waiterParam.call
  const {t} = useTranslation()
  console.log(call)

  if(!visible) return null 
  return (
    <div className='fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center z-50' >

    <div className='bg-white px-1 rounded w-[800px] max-h-[600px] relative overflow-y-auto sm:w-[600px]'>
      <header className='top-0 w-full sticky border-gray-400 border-b-1 py-3 bg-blue-200'>
        <span className='flex items-center justify-center font-bold text-3xl'>{t("WT Notification")}</span>
        <button type="button"
          className="absolute top-2 right-1 rounded-md bg-blue-300 p-2 inline-flex items-center justify-center text-black hover:text-bold hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:bg-indigo-500 z-20"
          onClick={onClose}>
          <span className="sr-only">Close menu</span>
          {/*Heroicon name: outline/x*/}
          <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </header>
        {call.length !== 0 
          ?
              <>
                <div className='w-full mt-1 mb-5 items-center justify-center text-center '>
                  <div className='mx-auto flex-row '>
                    <table className='mx-auto w-full '>
                      <thead className=''>
                        <tr className='' >
                          <th className='text-center font-bold text-2xl'>{t("Table")}</th>
                          <th className='text-center font-bold text-2xl'>{t("Client")}</th>
                          <th className='text-center font-bold text-2xl'>{t("Number of Calls WT")}</th>
                          <th className='text-center font-bold text-2xl'></th>
                        </tr>
                      </thead>

                      {call.map((call, idx) =>

                        <tbody key={idx} className='text-center'>
                          <tr>
                            <td className='text-center text-xl'>{call.tableId}</td>
                            <td className='text-center text-xl'>{call.clientId}</td>
                            <td className='text-center text-xl'>{call.countCall}</td>
                            <td>
                            <button 
                              className='flex items-center p-1'
                              onClick={()=>waiterParam.deleteNotif(call.tableId)}
                              >
                              <AiFillDelete color="red" size={40}/>
                            </button>
                            </td>
                          </tr>
                        </tbody>

                      )}
                    </table>
                  </div>
                </div>
              </>
          :
            <div className='w-full h-full my-20 items-center justify-center text-center text-xl'>{t("No Calls from Clients")}</div>
        }
    </div>
    </div>
  )
}

export default Notification
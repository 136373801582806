import React , {useState, useContext}from 'react'
import {loadConnectAndInitialize} from '@stripe/connect-js';
import {
  ConnectPayments,
  ConnectPaymentDetails,
  ConnectPayouts,
  ConnectComponentsProvider,
} from "@stripe/react-connect-js";
import { LoginDelyContext } from '../../contexts/LoginDelyContext'


const Transaction = () => {
  const User = useContext(LoginDelyContext)
  const user = User.restaurant_info
  let stripe_publishableKey = process.env.REACT_APP_STRIPE_DEV_PUBLISHABLE_KEY
  let backend = ""
  if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
    stripe_publishableKey = process.env.REACT_APP_STRIPE_DEV_PUBLISHABLE_LIVE
    backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
  }
  localStorage.removeItem("option_selected");
  const [stripeConnectInstance] = useState(() =>{
    const fetchClientSecret = async () => {
      // Fetch the AccountSession client secret
      const body=JSON.stringify({stripe_id:user.stripe_id})
      const response = await fetch(backend + '/api/stripe/create-account-session/', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          //'X-CSRFTOKEN': csrfCookie,
          withCredentials: true
        },
        body,
      });
      if (!response.ok) {
        // Handle errors on the client side here
        const {error} = await response.json();
        console.log('An error occurred: ', error);
        return undefined;
      } else {
        const clientSecret = await response.json();
        return clientSecret;
      }
    }
    return loadConnectAndInitialize({
      // This is your test publishable API key.
      publishableKey: stripe_publishableKey,
      fetchClientSecret: fetchClientSecret,
      appearance: {
        overlays: 'dialog',
        variables: {
          colorPrimary: '#625afa',
        },
      },
    })
  }
  );
  
  console.log(stripeConnectInstance)
  return (
    <div className="container">
    <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
      <ConnectPayments />
      {/*<ConnectPaymentDetails />*/}
      <ConnectPayouts />
    </ConnectComponentsProvider>
  </div>
  )
}

export default Transaction
/*global google*/
import React , {useEffect, useContext} from "react"
import { useTranslation } from 'react-i18next';
import AOS from "aos"
import 'aos/dist/aos.css'
import { FaLocationArrow, FaTimes } from 'react-icons/fa'
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  Autocomplete,
  InfoWindow,
  useLoadScript, LoadScript, geo
} from '@react-google-maps/api'
import { useRef, useState } from 'react'
import {Loading} from "../";
import { logo } from "../../data";
import { LoginDelyContext } from '../../contexts/LoginDelyContext'
import { AdvancedMarker }  from "@vis.gl/react-google-maps";
import { IoSearchOutline } from "react-icons/io5";


const CheckMap = () => {
  const User = useContext(LoginDelyContext)
  const {t} = useTranslation()
  const libraries = ['places', 'maps',]
  let libRef = useRef(libraries)
  const visibleInit = {
    name:"",
    user_id:"",
  }
  const [image,setImage] = useState()
  const [link, setLink]  = useState(process.env.REACT_APP_BACKEND_DEV_LINK)
  const [visible, setVisible] = useState(visibleInit)
  const [markers, setMarkers] = useState([])
  const [address,setAddress] = useState({
    address:"",
    city:"",
    postal_colde:"",
    country:"",
  })
  /**@type <React.MutableRefObject<HTMLInputElement> */
  let addrMapRef = useRef("")
  const [center, setCenter] = useState()
  const [message,setMessage] = useState("")
  const {isLoaded} = useLoadScript({
    id:"google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: libRef.current,
  })

  const fromAddrtoGeo = async (addressselected) =>{
    if (addressselected.length >0) {
      setMessage("")
      let geocoder = new google.maps.Geocoder()
      geocoder.geocode({ address: addressselected }, (results, status) => {
        console.log(results, status)
        if (status === 'OK') { 
          setCenter(results[0].geometry.location)
          console.log({lat:results[0].geometry.viewport.ci.hi,lng:results[0].geometry.viewport.Fh.hi})
          const gps = results[0].geometry.location
          let newAddress = address
          console.log(newAddress)
          newAddress.address = addressselected
          try {
            if(results[0].address_components.some(type=>type.types[0]==="administrative_area_level_3")){
              newAddress["city"] = results[0].address_components.find(type=>type.types[0]==="administrative_area_level_3").long_name
            }else if (results[0].address_components.some(type=>type.types[0]==="locality")){
              newAddress["city"] = results[0].address_components.find(type=>type.types[0]==="locality").long_name
            }else if (results[0].address_components.some(type=>type.types[0]==="postal_town")){
              newAddress["city"] = results[0].address_components.find(type=>type.types[0]==="postal_town").long_name
            }else{
              newAddress["city"] = ""
            }
          } catch (error){
            console.log(error)
            newAddress["city"] = ""
          }
          try {
            newAddress["country"] = results[0].address_components.find(type=>type.types[0]==="country").long_name 
          } catch (error){
            console.log(error)
            newAddress["country"] = ""
          }
          try {
            newAddress["postal_code"] = results[0].address_components.find(type=>type.types[0]==="postal_code").long_name 
          } catch (error){
            console.log(error)
            newAddress["postal_code"] = ""
          }
          setAddress(newAddress)
          User.check_on_map(gps).then(async res=>{
            const response = res
            console.log(response)
            if(response.stat===200){
              setMarkers(response.data)
            }else{
              alert(t("Error to Update, please try again or contact the support"))
            }
          }).catch((err) => {
            alert(err.message)
            console.log(err.message)});
          
        } else {
          alert(t('Geocode was not successful for the following reason') + ': ' + status);
        }
      
      });
    }else{
      console.log("No address for geolocation")
    }
  };


  const getImage = async (restaurant_id)  => {
    let body = JSON.stringify({title: "main_picture", restaurant_id: restaurant_id});
    let backend = ""
    let url = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
      url = "https://speaty.com"
    }
    setLink(url)
    const res = await fetch(backend + '/api/category/get-dish-picture', {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'content-type': 'application/json',
        responseType: 'blob',
      },
      body,
    }).catch(err=>console.log(err))
    if (res.status===302) {
      const imageBlob = await res.blob();
      const imageObjectURL = URL.createObjectURL(imageBlob);

      const file = await blobToBase64(imageObjectURL)
      setImage(file)
      URL.revokeObjectURL(imageBlob)

    }else{
      setImage("")
    }
    
  }

  const blobToBase64 = (url) => {
    return new Promise(async (resolve, _) => {
      // do a request to the blob uri
      const response = await fetch(url);
  
      // response has a method called .blob() to get the blob file
      const blob = await response.blob();
  
      // instantiate a file reader
      const fileReader = new FileReader();
  
      // read the file
      fileReader.readAsDataURL(blob);
  
      fileReader.onloadend = function(){
        resolve(fileReader.result); // Here is the base64 string
      }
    });
  };


  useEffect(()=>{
    
    setMessage("")
        intialAddrtoGeo("Italy")
  },[])
  const intialAddrtoGeo = async (address) =>{
    if (address.length >0) {
      setMessage("")
      let geocoder = new google.maps.Geocoder()
      geocoder.geocode({ address: address }, (results, status) => {
        console.log(results, status)
        if (status === 'OK') { 
          setCenter(results[0].geometry.location)
        } else {
          alert(t('Geocode was not successful for the following reason') + ': ' + status);
        }
      
      });
    }else{
      console.log("No address for geolocation")
    }
  };

  const handleChange = () => {
    setMessage("")
    let newAddress = address
    newAddress.address = addrMapRef.current.value
    console.log(newAddress)
    setAddress({...newAddress})
  }

  const handleVisible = async (marker) => {
    setVisible({...visibleInit})
    console.log(center.lat)
    console.log(marker)
    const rest = visible
    rest.name = marker.name
    rest.user_id = marker.user_id
    getImage(marker.user_id)
    setVisible({...rest})
  }

  useEffect(()=>{
    setVisible(visibleInit)
  },[])

  console.log(markers)
  console.log(visible)

  if (!isLoaded) return  <Loading />
  return (
    <div id="check_map" className="flex mx-2 flex-col h-[650px] md:h-[750px]  space-y-5 sm:space-y-8  w-full">
      <h3 className='text-center font-bold sm:text-5xl text-3xl text-white  w-full'>{t("Search the Best Food around You")}</h3>
      <section  className= "h-full flex ">
      
        <div className="justify-center relative items-center mx-auto w-full">
          <div className='absolute w-full h-full left-0 -top-1 items-center  justify-center ' >
              <GoogleMap center={center} zoom={14} mapContainerStyle={{width:'100%',height: '100%', borderRadius: "20px", boxShadow:"8px 8px 8px #79ffe96c" }}
              options={{
                zoomControl: true,
                streetViewControl: false,
                mapTypeControl: false,
                fullscreenControl:false,
              }}>
                {markers.length!==0 && 
                <>
                {markers.map(marker => (
                  <>
                  <Marker
                    position={{ lat: marker.lat, lng: marker.lng }}
                    key={marker.user_id}
                    icon={{
                      url: logo,
                      scaledSize:  new window.google.maps.Size(40,40)
                    }}
                    onClick={()=>handleVisible(marker)}
                    >
                    
                  </Marker>
                  {marker.user_id===visible.user_id &&
                  <InfoWindow
                  
                    key={"infoWinfow" + marker.user_id}
                    onCloseClick={()=>setVisible(visibleInit)}
                    position={{ lat: marker.lat, lng: marker.lng}}
                    options={{ pixelOffset: new window.google.maps.Size(0, -40) }}>
                  <div className="flex flex-row p-1 space-x-1 max-w-[350px] max-h-[200px]">
                    <div className="flex flex-col space-y-2">
                      <a target="_blank" className="text-blue-600 text-sm sm:text-lg font-[400] hover:underline" href={link + "/client-take-delivery?restaurant_id=" + marker.user_id}>{marker.company_title}</a>
                      
                      <h4 className="text-[12px] sm:text-[15px] font-bold">{marker.restaurant_description} </h4>
                      <h4 className="text-[10px] sm:text-[14px]">{marker.address} </h4>
                      </div>
                        {!image || image.length===0 ? 
                          <div className='bg-gray-400 items-center w-full h-full '>
                          </div>
                          : 
                          <img className='items-center rounded-lg w-[100px] sm:w-[150px] object-fill' 
                            src={image} />
                        }
                    </div>
                </InfoWindow>
                }
                </>
                ))}
                </>}
                  <Marker
                      position={center}
                      key={"address"}
                    />
              </GoogleMap>
            </div>
            <div className="flex flex-row md:flex-col relative items-center justify-center m-2 ">
           
            <Autocomplete className="flex w-full">
              <input
                placeholder={t('Your Address')}
                name="address"
                className=" block rounded-lg h-[40px] px-1 break-words border-1 border-blue-800 md:mx-10 mx-3 my-1 w-full"
                ref={addrMapRef}
                value={address.address}
                onChange={handleChange}>
                
              </input>
            </Autocomplete>
            <button className='hidden md:flex mx-auto border my-2 bg-blue-400 text-black items-center justify-center text-center cursor-pointer hover:bg-blue-800 hover:text-white'
              onClick={()=>fromAddrtoGeo(addrMapRef.current.value)}>
              {t("Confirm & Verify on Map")}
            </button>
            <button className='flex md:hidden mx-3 my-2 bg-blue-400 text-black items-center justify-center text-center cursor-pointer hover:bg-blue-800 hover:text-white'
              onClick={()=>fromAddrtoGeo(addrMapRef.current.value)}>
              <IoSearchOutline color="white" size={30}/>
            </button>
            </div>
          
            

            {/*<div className='grid grid-cols-1 md:grid-cols-2 gap-3 py-2 mt-7 bg-white rounded-xl shadow-xl'>
              <h3 className='text-center'>{t("Address Selected")}: <span className="font-bold ">{address.address}</span></h3>
              <h3 className="text-center ">{t("City")}: {address.city}</h3>
              <h3 className="text-center "> {t("Country")}: {address.country}</h3>
              <h3 className="text-center "> {t("Postal Code")}: {address.postal_code}</h3>
                
                </div>*/}
            
        </div>
        <h3 className='text-center text-red-600 text-bold text-lg my-1'>{message}</h3>
      </section>
    </div>
  )
}

export default CheckMap
import React, {useState, useEffect} from 'react'
import { card } from "../../data";
import styles, { layout } from "../../style";
import Button from "./Button";
import { useTranslation } from 'react-i18next';
import AOS from "aos"
import 'aos/dist/aos.css'

const CardDeal = () => {
  useEffect(()=>{
    AOS.init({duration:3000})
  },[])
  const {t} = useTranslation()
  return (
  <section className="flex xl:flex-row flex-col sm:py-16 py-6" >
    <div className={layout.sectionInfo}>
      <h2 className={styles.heading2}>
       {t("Delivery or Orders, Pay")} {t("In few easy steps")}.
      </h2>
      <p className={`${styles.paragraph} max-w-[600px] text-justify  mt-5`}>
        {t("User Friendly Interface for your Client, to order delivery food. Speaty Premium offer the digital ordering and payment of the food, just scanning the QR Code of the Table -> Order and Pay easily. No more queue for payment into the restaurant. For the Client no more losing time to figured out what he ordered and what he have to pay")}.
      </p>

      <Button styles={`mt-10`} />
    </div>

    <div className={`${layout.sectionImg } animation`} data-aos="fade-up">
      <img src={card} alt="Speaty Payment" className="sm:w-[200%] h-[100%]" />
    </div>
  </section>
);
};

export default CardDeal;

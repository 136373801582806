import React, { createContext, useState, useEffect, useRef} from 'react'
import axios from 'axios';

export const HMIWTContext = createContext({

  HMI_WT_Params: {},
  getAssignedTable: () => {},
  getListTableDishes: () => {},
  retrieveData: () => {},
  deleteNotif: () => {},
  setCalls: () => {},
  getAllTablesSorted: () => {},


})

export function HMIWTProvider({children}){

  const [Orders, setOrders] = useState([]);
  const socketRef = useRef();
  const callRef= useRef();
  
  

  const initCall = () =>{
    const saved = localStorage.getItem("calls");
    let initialValue = JSON.parse(saved);
    console.log(initialValue)
    return initialValue || []
    //return call
  }

  const [calls, setCalls] = useState(initCall);
  console.log(calls)

  callRef.current = calls


  let last_call = {tableId:0, clientId:"", ts:0};

  const retrieveData = (data) => {
    console.log(calls)
    console.log(callRef.current)
    const call = callRef.current

    if (call.some(count =>count.tableId === data.tableId)){
      call.map(
        count => {
          if (count.tableId === data.tableId){
            count.countCall = count.countCall + 1
          }
        }
      )
      console.log(call)
      setCalls([...call])
    }else{
      console.log(call)

      call.push(data)
      setCalls([...call])
    }
  }

  const sleep = (ms) =>{
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const deleteNotif = (tableID) => {
    const call = calls.filter(call => call.tableId!==tableID)
    setCalls(call)
    /*setCalls(
      call=>
      call.filter(call =>{
        return call.tableId!==tableID; 
      })
    )*/
  }
  

  useEffect(()=>{


    socketRef.current = new WebSocket('ws://127.0.0.1:3001/ws/notification')

    
    socketRef.current.onopen = e => {
      console.log('open', e)
    }
    socketRef.current.onmessage = (e) => {
      const data = JSON.parse(e.data);
      const last_date = Date.now();
      if (((last_date- last_call.ts) > 500) || (last_call.clientId !== data.clientId) || (last_call.tableId !== data.tableId))
      {
        last_call.ts = last_date;
        last_call.clientId = data.clientId;
        last_call.tableId = data.tableId;
        retrieveData(data);
      }
    }
    socketRef.current.onerror = e => {
      console.log('error',e)
    }

    return () => {
      socketRef.current.close()
    }

  },[])

  const getAssignedTable = () =>{

  }
  const tables = [
    {"tableID":"1"},
    {"tableID":"2"},
    {"tableID":"3"},
    {"tableID":"5"}]

  const getUserTableDishes = async (tableId) => {
    const body = JSON.stringify({tableId: tableId})
    const res = await fetch('/api/table/getUserTableDishes/', {
      method: "POST",
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },body
    });
    const response = await res.json()
    if (res.status === 200) {

        return response.data
      }else{
        return `Error message ${res.status}`
      }
    }

    const getAllTablesSorted = async(restaurant_id) =>{
      const body = JSON.stringify({restaurant_id:restaurant_id})
      let backend = ""
      if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
        backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
      }
      const res = await  axios.post(backend + '/api/table/getAllTables/', body, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          //'X-CSRFTOKEN': csrfCookie,
          
        }
      }).catch(err =>{console.log(err) 
        return err.response});
    
      console.log(res)
    
      const data = await res.data;
      if (res.status === 200) {
        console.log(data)
        return data;
      } else {
        console.log(data)
        return data;
      }
    }




  const contextValue = {
 
    call : calls,
    dishList: Orders,
    setCalls,
    getUserTableDishes,
    retrieveData,
    deleteNotif,
    getAllTablesSorted,
  }
 

  useEffect(() => {
    window.localStorage.setItem('calls', JSON.stringify(calls));

  }, [calls]);


return(
  <HMIWTContext.Provider value={contextValue}>
    {children}
  </HMIWTContext.Provider>
)

}



export default HMIWTProvider
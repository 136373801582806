import React, { useEffect, useState, useContext} from 'react'
import {BiPlusCircle} from 'react-icons/bi'
import axios from 'axios';
import {LoginDelyContext} from '../../../contexts/LoginDelyContext';
import { useTranslation } from 'react-i18next';
import {Loading} from '../../'


const FileUpload = (props) => {
  const {t} = useTranslation()
  const chars = {
    " ": "_",
    "'":"",
    ".":"",
    ",":"",
    "-":"_",
  }
  const old_filename=props?.filename.old_filename
  const filename = props?.filename.filename
  const User = useContext(LoginDelyContext)
  const settings = User.page_info
  const imageName = props?.imageName
  console.log(imageName)
  console.log(filename)
  const [image, setImage] = useState()
  const [file, setFile] = useState();
  const [imageConfirm, setImageConfirm] = useState(false)
  const [oldimage, setOldImage] = useState()
  const [loading, setLoading] = useState(false)
  const restaurant_id = settings.user_id.replace(/-/g,'')
  console.log(settings)
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        if (fileReader.readAsDataURL || typeof(fileReader.readAsDataURL )===Blob) {
          fileReader.readAsDataURL(file)
          fileReader.onload = () => {
              resolve(fileReader.result);
          }
          fileReader.onerror = (error) => {
              reject(error);
          }
        }
        else return
    })
}


  const handleConfirm = async ( ) =>{

    await props.ImageData(
      {
        imageName: (filename.replace(/[ '.,-]/g, m=>chars[m]) + "." + image.type.substr(6)),
        
      }
    )

    setImageConfirm(true)


  }

  const handleSubmit = async () => {
    setLoading(true)
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    let formData = new FormData()
    if(!file && !oldimage){
      return;
    } else if(!file && oldimage){
      formData.append('title', filename)
      formData.append('old_title', old_filename)
      formData.append('image_name', imageName)
      formData.append('restaurant', restaurant_id)
    }else{
      console.log(filename +"."+ image.type.substr(6))
      formData.append('image', image, (filename.replace(/[ '.,]/g, m=>chars[m]) + "." + image.type.substr(6)))
      formData.append('title', filename)
      formData.append('old_title', old_filename)
      formData.append('image_name', imageName)
      formData.append('restaurant', restaurant_id)
    }
    const config = {
      withCredentials: true,
      headers:{
        'content-type': 'multipart/form-data',
      }
    }


    await axios.post(backend + "/api/category/add-dish-picture",formData, config)
    .then(async(res) => {
      if (res.status===202){
        alert(t("Update successfully"))
        await getImage(filename)
        
      }
      })
    .catch(err =>console.log(err));
    
    console.log("Image Submitted")

    setImage()
    setFile()
    setLoading(false)
  };

  
  const handleFileChange = async (e) =>{
    if(e.target.files){

      const data = e.target.files[0]
      /*const data = new FileReader()

      data.addEventListener("load", ()=>{
        console.log(data)
        setFile(data.result)
      })
      data.readAsDataURL(e.target.files[0])*/
      const base64 = await convertBase64(data)
      setFile(base64)
      setImage(e.target.files[0])
      
    }
    
  }


  const getImage = async (picture_name) => {
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    let body = JSON.stringify({picture_name: picture_name, restaurant_id: restaurant_id});;
     
    const res = await fetch(backend + '/api/category/get-dish-picture', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'content-type': 'application/json',
        responseType: 'blob',
      },
      body,
    }).catch(err=>console.log(err))

    const imageBlob = await res.blob();
    const imageObjectURL = URL.createObjectURL(imageBlob);


    const file = await blobToBase64(imageObjectURL)
    setOldImage(file)
    URL.revokeObjectURL(imageBlob)
  }

  const blobToBase64 = (url) => {
    return new Promise(async (resolve, _) => {
      // do a request to the blob uri
      const response = await fetch(url);
  
      // response has a method called .blob() to get the blob file
      const blob = await response.blob();
  
      // instantiate a file reader
      const fileReader = new FileReader();
  
      // read the file
      fileReader.readAsDataURL(blob);
  
      fileReader.onloadend = function(){
        resolve(fileReader.result); // Here is the base64 string
      }
    });
  };

  useEffect(()=>{
    getImage(filename)
  },[])
    


  useEffect(()=>{
    if (props.trigger){
      handleSubmit()
    }else{
      setImageConfirm(false)
    }
  },[props.trigger])

  return (
      
      <div className='bg-sky-200 border-dashed border-sky-500 border-[2px] my-2 flex h-auto justify-center items-center flex-col rounded-2xl py-[30px]'>
        {!oldimage && <Loading /> }
        {loading && <Loading /> }
        {imageConfirm ?
          <div className='relative mb-[20px]'>
             <input 
              type="" 
              accept="image/jpeg,image/png,image/jpg"
              className='relative max-w-[260px] h-[50px] z-10  opacity-0'/>
            <div 
              className=' absolute top-0 left-0 w-full h-full  flex justify-center items-center text-white bg-gray-400 rounded-xl border-none outline-none shadow-xl'>
              <i className='bg-white text-gray-300 flex justify-center items-center mr-2 rounded-full ' >
                <BiPlusCircle size={25}/>
              </i>
              {t("Select Dish Image")}
            </div>
          
          </div>    
          :
          <div className='relative mb-[20px]'>
            <input 
              type="file" 
              onChange={handleFileChange}
              accept="image/jpeg,image/png,image/jpg"
              className='px-4 relative max-w-[260px] h-[50px] z-10 cursor-pointer opacity-0'/>
            <button 
              className=' absolute top-0 left-0 w-full h-full mx-auto flex justify-center items-center text-white bg-red-400 cursor-pointer rounded-xl border-none outline-none shadow-xl hover:bg-red-700'>
              <i className='bg-white text-red-300 flex justify-center items-center mr-2 rounded-full ' >
                <BiPlusCircle size={25}/>
              </i>
              {t("Select Dish Image")}
            </button>
          
          </div>
        }
        <>
        {image && `${image.name} - ${image.type}`  ?
          <div className='justify-center items-center flex mx-auto flex-col'>
            <span > {t("File Selected")}: {image.name} - {image.type}</span>
            <div className='h-max-[150px] w-[150px] my-2'>
              <img src={file} className="items-center object-cover " />
            </div>
        
            <div className='justify-center items-center flex mx-auto flex-col'>
              <button 
                onClick={handleConfirm}
                className='w-auto h-full flex justify-center items-center text-white bg-green-400 cursor-pointer rounded-xl border-none outline-none shadow-xl hover:bg-green-700 mx-auto'>
                {t("Confirm Image")}
              </button>
            </div>
          </div>
        :
        <>
          { imageName ?
            <div className='justify-center items-center flex mx-auto flex-col'>
              <span >{t("File Selected")}: {imageName}</span>
              <div className='h-max-[150px] w-[150px] my-2'>
                <img src={oldimage} className="items-center object-cover " />
              </div>
            </div>
          : 
            ""
          }
        </>
        }
        </>
        <p className=''>{t("Supported files")}</p>
        <p className=''>JPG, PNG, JPEG. {t("Less than")} 3.5 MB</p>

      </div>
  )
}

export default FileUpload
import React , {useState , useContext} from 'react'
import {LoginDelyContext} from '../../contexts/LoginDelyContext'
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation} from "react-router-dom"
import axios from 'axios';



const Register = (props) => {
  const {t} = useTranslation()
  const navigate = useNavigate ();
  const [isChecked, setIsChecked] = useState(false);
  const[email, setEmail] = useState('');
  const[password,setPassword] = useState('');
  const[password2,setPassword2] = useState('');
  const[name,setName] = useState('')
  const [lastname, setLastname]  = useState('')
  const [company_name, setCompany_name]= useState('')
  const [noMatch, setNoMatch] = useState(false)
  const [style, setStyle] = useState("rounded-xl px-4 w-full py-1 bg-gray-200  border border-gray-400 mb-4 text-green-900 placeholder-gray-700 focus:outline-none")
  const [message, setMessage] = useState("")
  const [phone, setPhone] = useState("")
  const restaurant = true

  const handleOnChange = () => {
    setIsChecked(!isChecked);
    setMessage("")
  };

  const User = useContext(LoginDelyContext)
  const sub = User.subscription
  let SignUp = {
    company_name: company_name,
    username: name,
    first_name:name,
    last_name: lastname,
    email: email,
    password: password,
    phone: phone,
    stripe_description_prod: sub.stripe_description_prod,
    stripe_description_dev: sub.stripe_description_dev,
    sub_type: "",
    sub_duration:sub.duration_month,
    sub_cost:sub.cost,
  }
  console.log(SignUp);

  const handleSubmit = async (e) => {
    setMessage("")
    let billing_link = process.env.REACT_APP_BILLING_STRIPE_SUBSCR_DEV
    let backend = ""
    SignUp.sub_type=sub.stripe_description_dev
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      SignUp.sub_type=sub.stripe_description_prod
      billing_link = process.env.REACT_APP_BILLING_STRIPE_SUBSCR_PROD
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    e.preventDefault()
    if(isChecked===false){
      setMessage(t("Please, Confirm the Terms and Conditions"))
    }else{
      if(email.length===0 || password.length===0 || name.length===0 || lastname.length===0 || company_name.length===0 || sub.name.length===0 || phone.length===0) {
        setMessage(t("All fields should be filled"))
        console.log(SignUp)
      }else{
        const phonetest= /^[0-9]*$/.test(phone)
        const emailtest = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)
        if (!phonetest || !emailtest || phone.length<10) {
          setMessage(t("Email or Phone are incorrect"))
        }else{
        if (password === password2){
          const resp = await User.registerUser(SignUp,restaurant).then(res => {
            User.stateLoading(true)
            return res
          })
          if (resp?.stat === 200){
            let body = {lookup_keys:"", email: SignUp.email, customer_id:resp.data.customer_id}
            console.log(body)
            if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
              body.lookup_keys = sub.stripe_description_prod
            }else{
              body.lookup_keys = sub.stripe_description_dev
            }
            
            axios.post(backend + "/api/stripe/create-checkout-session-sub/",body).then((res)=>{
              
              console.log(res.data)
              if(res.data.url){
                User.stateLoading(false)
                console.log(res)
                setEmail('')
                setLastname('')
                setName('')
                setPassword('')
                setPassword2('')
                setCompany_name('')
                setPhone('')
                props.onFormSwitch('login')
                window.location.href = res.data.url      
              }
            }).catch((err) => {
              User.stateLoading(false)
              alert(err.message)
              console.log(err.message)
              User.del_account(SignUp.email)}
              );
            //navigate(billing_link)
          }else if(resp?.stat === 302){
            User.stateLoading(false)
            console.log(resp)
            setMessage(t("Please log in with the email") + email)
          }else{
            User.stateLoading(false)
            setMessage(t("Error contact the techincal support"))
            User.del_account(SignUp.email)
            }
          
        }else{
          User.stateLoading(false)
          setNoMatch(true)
          setStyle("rounded-xl px-4 w-full py-1 bg-gray-400  border border-red-400 mb-4 text-green-900 placeholder-red-500 focus:outline-none")
          setMessage(t("Passwords don't match"))
          };
        }
      }
      }
    }

  return (
    <div className="bg-teal-300 shadow-xl rounded-lg p-4 mx-4 sm:mr-4 mb-4 md:mb-0 max-w-[450px]">
    <div >
      <div className="flex items-center justify-center">
        <h2 className="text-3xl font-bold tracking-wide mb-6">{t("Register & Subscribe")}</h2>
      </div>
      <label className="text-lg font-semibold" htmlFor="first_name">{t("Plan Selected")}</label>
      <input value={Object(sub.stripe_description_prod).length===0 ? t("Select a Plan") : sub.name + " - " + sub.duration_month + " month - €" + sub.cost.toFixed(2) + " + " + t("VAT")} readOnly="readonly" className="font-bold rounded-xl px-4 w-full py-1 bg-gray-200  border border-gray-400 mb-4 text-green-900 placeholder-gray-700 focus:outline-none" name="plan_selected" id="plan_selected" placeholder={t("Plan Selected")}/>
      <label className="text-lg font-semibold" htmlFor="first_name">{t("Company Name")}</label>
      <input value={company_name} onChange={(e) => setCompany_name(e.target.value)} className="rounded-xl px-4 w-full py-1 bg-gray-200  border border-gray-400 mb-4 text-green-900 placeholder-gray-700 focus:outline-none" name="company_name" id="company_name" placeholder={t("Company Name")}/>
      <label className="text-lg font-semibold" htmlFor="first_name">{t("Name")}</label>
      <input value={name} onChange={(e) => setName(e.target.value)} className="rounded-xl px-4 w-full py-1 bg-gray-200  border border-gray-400 mb-4 text-green-900 placeholder-gray-700 focus:outline-none" name="name" id="name" placeholder={t("Name")}/>
      <label className="text-lg font-semibold" htmlFor="last_name">{t("Last Name")}</label>
      <input value={lastname} onChange={(e) => setLastname(e.target.value)} className="rounded-xl px-4 w-full py-1 bg-gray-200  border border-gray-400 mb-4 text-green-900 placeholder-gray-700 focus:outline-none" name="last_name" id="last_name" placeholder={t("Last Name")}/>
      <label className="text-lg font-semibold" htmlFor="last_name">{t("Phone")}</label>
      <input type="tel" value={phone} onChange={(e) => setPhone(e.target.value)} className="rounded-xl px-4 w-full py-1 bg-gray-200  border border-gray-400 mb-4 text-green-900 placeholder-gray-700 focus:outline-none" name="phone" id="phone" placeholder={t("Phone")}/>
      <label className="text-lg font-semibold" htmlFor="email">{t("Email")}</label>
      <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" className="rounded-xl px-4 w-full py-1 bg-gray-200  border border-gray-400 mb-4 text-green-900 placeholder-gray-700 focus:outline-none" placeholder={t("Email")} id="email" name="email"/>
      <label className="text-lg font-semibold" htmlFor="password">{t("Password")}</label>
      <input value={password} onChange={(e) => setPassword(e.target.value)} type="password" className="rounded-xl px-4 w-full py-1 bg-gray-200  border border-gray-400 mb-4 text-green-900 placeholder-gray-700 focus:outline-none" placeholder={t("Password")} id="password1" name="password1" />
      <label className="text-lg font-semibold" htmlFor="password">{t("Confirm Password")}</label>
      <input value={password2} onChange={(e) => setPassword2(e.target.value)} type="password" className={style} placeholder={t("Confirm Password")} id="password2" name="password2" />
      
      <div className="mr-2 p-0 sm:text-sm underline justify-start flex">
        <input
          className='w-7'
          type="checkbox"
          id="terms"
          name="terms"
          value="terms"
          checked={isChecked}
          onChange={handleOnChange}
        />
        <a className="ml-2 text-black " href={'/terms-and-services'}>{t("Confirm the Terms & Conditions")}</a>
      </div>
      <p className='text-red-500 mt-4'>{t(message)}</p>
      <div className="flex items-center justify-between">
        <button className="mx-2 p-0 text-xs sm:text-sm underline" onClick={()=>props.onFormSwitch('login')}>{t("Already have an account? Login here")}</button>
        <button 
          type="submit" 
          className="bg-teal-600 text-gray-200 py-2 px-1 rounded-xl object-right sm:text-xl hover:bg-teal-800 text-sm"
          onClick={handleSubmit}
          >{Object(sub.stripe_description_prod).length===0 ? t("Subscribe") : t("Subscribe") + " - €" + sub.cost.toFixed(2)}</button>       
      </div>
    </div>
  </div>
  )
}

export default Register
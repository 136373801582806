import React,{ useEffect, useState, useContext } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import {FileUpload} from '.'
import { MenuContext } from '../../../contexts/MenuContext'
import { useTranslation } from 'react-i18next';
import {LoginDelyContext} from '../../../contexts/LoginDelyContext';




const UpdateCreateDish = () => {
  const {t} = useTranslation()
  const { id, category, option } = useParams();
  const User = useContext(LoginDelyContext)
  const settings = User.page_info
  const intialNewDish = {
    name:"",
    prezzo: 0,
    descrizione:"",
    ingredienti: "",
    picture_name:"",
    category:category,
  }
  const intialNewIngredient = {
    name:"",
    price: 0,
    category:category,
    details:"",
    cd_list:[],
  }
  
  const [newdish, setNewdish] = useState(intialNewDish)
  const [newingredient, setNewingredient] = useState(intialNewIngredient)
  const [trigger, setTrigger] = useState(false)
  const menus = useContext(MenuContext)
  const menu = menus.menus;
  const customDish = menus.customDish;
  const ingredients = menus.ingredients;
  const restaurant_id = settings.user_id
  let categories = menus.list_category

 


  const navigate = useNavigate();
 
  //const [files, setFiles] = useState([{ name: 'myfile.jpg' }])
 
  const extract = () =>{
    let dish = {}
    if (option==="menu"){
     // menus.fetchMenu(restaurant_id);
        if (id !== "new") {
          menu.find(categ=>categ.name===category).dish_list.map(dishes =>{
            if (dishes.name ===id){
            dish = dishes
            setNewdish({
              name: dish.name,
              prezzo: Number(dish.prezzo),
              descrizione:dish.descrizione,
              ingredienti: dish.ingredienti,
              picture_name: dish.picture_name,
              category:category,
            })
          }
          })
        }
      }else if (option==="ingredients"){
        //menus.fetchAddIns(restaurant_id);
          if (id !== "new") {
            ingredients.find(categ=>categ.name===category).ingredient_list.map(food =>{
              if (food.name ===id){
              dish = food
              setNewingredient({
                name: food.name,
                price: Number(food.price),
                category:category,
                details: food.details,
              })
            }
            })
          }
      }
      console.log(dish)
     
      return dish
    } 
  
    const [dish, setDish] = useState(extract)
    const [ingredient, setIngredient] = useState(extract)
    console.log(dish,ingredient)

  const handleChange = (e) => {
    if (option==="menu") {
      let dishClone = { };
      dishClone = {...newdish }
      dishClone[e.target.name] = e.target.value;  
       
      setNewdish(dishClone);


    }else{
      let customClone = { };
      customClone = {...newingredient }
      customClone[e.target.name] = e.target.value;  
       
      setNewingredient(customClone);

    }
   
  };

  console.log(newdish) 
  console.log(newingredient) 

  //console.log(newdish)

  const handleSubmit = async (e) => {
    if (option === "menu"){
      Object.entries(newdish).map(([key, item])=>{
        if (newdish.name===''){
          newdish.name=dish.name
        }else if (newdish[key]===''){
          newdish[key]=dish[key]
        }else{
          return
        }

      })
      e.preventDefault();

        if (id === "new") {
          if(menu?.find(categ=>categ.name===category).dish_list?.find(dish=>dish.name===newdish.name)){
            alert(t("Dish already exists"))
          }else{
            const res = await menus.CreateNewDish(newdish).then(async res=>{
    
              const resp = await res
              console.log(resp)
              if (resp.stat === 200) {
                if(resp.data["message"]==="Dish already exists"){
                  alert(t(resp.data["message"]))}
                else{
                  setTrigger(true)
                  menus.fetchMenu(restaurant_id)
                }
              }else{
                alert(t("Problem to updload the New Dish"))
                setTrigger(false)
              }
    
            })
          }
          
      
        }else{
          if(dish.name===newdish.name){
            const res = await menus.UpdateDish(dish.name,newdish).then(async res=>{
              console.log(dish.name)
              const resp = await res
              console.log(resp)
              if (res.stat === 200) {
                if(resp.data["message"]==="Dish already exists"){
                  alert(t(resp.data["message"]))}
                else{
                  setTrigger(true)
                  menus.fetchMenu(restaurant_id)
                }
              }else{
                alert(t("Problem to updload the New Dish"))
                setTrigger(false)
              }
    
            })
          }else if(menu?.find(categ=>categ.name===category).dish_list?.find(dish=>dish.name===newdish.name)){
            alert(t("Dish already exists"))
          }else{
            const res = await menus.UpdateDish(dish.name,newdish).then(async res=>{
              console.log(dish.name)
              const resp = await res
              console.log(resp)
              if (res.stat === 200) {
                if(resp.data["message"]==="Dish already exists"){
                  alert(t(resp.data["message"]))}
                else{
                  setTrigger(true)
                  menus.fetchMenu(restaurant_id)
                }
              }else{
                alert(t("Problem to updload the New Dish"))
                setTrigger(false)
              }
    
            })
          }
        }

      
    }else{
      Object.entries(newingredient).map(([key, item])=>{
        if (newingredient.name===''){
          newingredient.name=ingredient.name
        }else if (newingredient[key]===''){
          newingredient[key]=ingredient[key]
        }else{
          return
        }

      })
      e.preventDefault();
      
        if (id === "new") {
          if(ingredients?.find(categ=>categ.name===category).ingredient_list?.find(food=>food.name===newingredient.name)){
            alert(t("Ingredient already exists"))
          }else{
            const res = await menus.CreateDish_CD(newingredient, option).then(async res=>{
              const resp = await res
              console.log(resp)
              if (resp.stat === 200) {
                if(resp.data["message"]==="Ingredient already exists"){
                  alert(t(resp.data["message"]))}
                else{
                  menus.fetchAddIns(restaurant_id)
                  menus.fetchIngredients(restaurant_id)
                  navigate("/dash/manager-dash/modify-menu")
                }
              }else{
                alert(t("Problem to updload the New Custom Ingredient"))
              }
            })
          }
        }else{
          if(dish.name===newingredient.name){
            const res = await menus.UpdateDish_CD(dish.name,newingredient, option).then(async res=>{
              const resp = await res
              console.log(resp)
              if (res.stat === 200) {
                if(resp.data["message"]==="Ingredient already exists"){
                  alert(t(resp.data["message"]))}
                else{
                  menus.fetchAddIns(restaurant_id)
                  menus.fetchIngredients(restaurant_id)
                  navigate("/dash/manager-dash/modify-menu")
                }
              }else{
                alert(t("Problem to updload the New Custom Ingredient"))
              }

            })
          }else if(ingredients?.find(categ=>categ.name===category).ingredient_list?.find(food=>food.name===newingredient.name)){
            alert(t("Ingredient already exists"))
          }else{
            const res = await menus.UpdateDish_CD(dish.name,newingredient, option).then(async res=>{
              const resp = await res
              console.log(resp)
              if (res.stat === 200) {
                if(resp.data["message"]==="Ingredient already exists"){
                  alert(t(resp.data["message"]))}
                else{
                  menus.fetchAddIns(restaurant_id)
                  menus.fetchIngredients(restaurant_id)
                  navigate("/dash/manager-dash/modify-menu")
                }
              }else{
                alert(t("Problem to updload the New Custom Ingredient"))
              }

            })
          }
        }
      }

    };

  console.log(ingredients)

  const ImageData = async (file) => {
    console.log(file)
    setNewdish({...newdish, picture_name: file.imageName})

  }


  const get_name = () => {
    let filename = ""
    let old_filename=""
    if (id==="new"){
      filename = newdish.name
      const name = {filename: filename,old_filename:old_filename}
      return name
    }else if(newdish.name===dish.name){
      filename = id
      const name = {filename: filename,old_filename:old_filename}
      return name
    }else{
      filename=newdish.name
      old_filename=dish.name
      const name = {filename: filename,old_filename:old_filename}
      return name
    }

  }


  return (
    <div className='max-w-[900px] w-full md:w-[600px] h-auto mx-auto items-center justify-center my-3'>
      <div className="bg-gray-200 rounded-[30px] w-auto mx-[20px] shadow-xl py-[30px] px-[20px]">
        {option === "menu" ?
          <>
            <p className="text-center font-bold text-3xl">
              {id === "new" ? t("Create New Dish") : t("Update")}
            </p>
            <form className="w-full flex md:items-center justify-start flex-col md:flex-row  md:gap-[20px] mt-[30px] ">
              <p >{t("Name")}</p>
              <input
                className="w-full rounded-lg h-[30px] p-1 mb-7 md:mb-0"
                type="text"
                placeholder={option === "menu" ? (id === "new" ? t("Name") + "..." : dish.name) : (id === "new" ? t("Name") + "..." : ingredient.name)}
                name="name"
                value={option === "menu" ? newdish.name : newingredient.name}
                onChange={handleChange}
              />
              <p className="whitespace-nowrap">{t("Price")} €</p>
              <input
                className="w-full rounded-lg h-[30px] p-1 mb-2 md:mb-0"
                type="number"
                placeholder={option === "menu" ? (id === "new" ? t("Price") + " €..." : dish.prezzo) : (id === "new" ? t("Price") + "..." : ingredient.price)}
                name="prezzo"
                value={option === "menu" ? newdish.prezzo : newingredient.price}
                onChange={handleChange}
              />
              </form>
              <form className="w-full flex items-center justify-center flex-col gap-[20px] mt-[20px] ">
              <div className="w-full flex-row justify-between max-h-[150px] break-words" >
                <p>{t("Description")}</p>
                <textarea
                  className="w-full rounded-lg h-full px-1 break-words max-h-[100px] p-1"
                  placeholder={id === "new" ? t("Description") + "..." : dish.descrizione}
                  name="descrizione"
                  value={newdish.descrizione}
                  onChange={handleChange}
                />
              </div>
              <div className="w-full flex-row justify-between max-h-[150px] break-words"
              >
                <p>{t("Ingredients")}</p>
                <textarea
                  className="w-full rounded-lg  h-full max-h-[100px] p-1 break-words"
                  type="text"
                  placeholder={id === "new" ? t("Ingredients") + "..." : dish.ingredienti}
                  name="ingredienti"
                  value={newdish.ingredienti}
                  onChange={handleChange}
                />
              </div>
            </form>
            <div className='p-5'>
              <p className='justify-center items-center flex text-1xl font-bold'>{t("Upload the Dish Image")}</p>
              <FileUpload filename={get_name()} ImageData={ImageData} trigger={trigger} imageName={newdish.picture_name} />
            </div>
            <div className="flex justify-center">

              {trigger ?
                <button
                  onClick={() => {
                    setNewdish(intialNewDish)
                    setTrigger(false)
                    navigate(`/dash/manager-dash/modify-menu/menu/${category}/new`)
                  }}
                  className="flex bg-sky-600 first-line:rounded-xl py-[10px] px-[20px] mb-4 j"
                >
                  {t("Add Another New Dish")}
                </button>
                :
                <div>
                  {Object.values(newdish).some(x => x === '') || newdish.price<=0 ?
                   <button onClick={handleSubmit} className="rounded-xl py-[10px] px-[20px]flex  bg-gray-300 text-gray-400  cursor-not-allowed"
                   disabled={true}>
                   {id === "new" ? t("Create New Dish") : t("Update")}
                  </button>

                  :

                  <button onClick={handleSubmit} className="rounded-xl py-[10px] px-[20px]flex bg-green-400 hover:bg-green-600  hover:text-gray-100">
                    {id === "new" ? t("Create New Dish") : t("Update")}
                  </button>
                }

                  
                </div>
              }
            </div>
          </>
          :
          <>
            <p className="text-center font-bold text-3xl">
              {id === "new" ? t("Create New Ingredient") : t("Update")}
            </p>


            <form className="w-full flex items-start justify-center flex-col space-y-3 mt-[30px]">
              <div className="flex items-start sm:items-center  justify-center sm:flex-row flex-col w-full sm:space-x-4 sm:space-y-0 space-y-4 " >
                <p className="whitespace-nowrap">{t("Name")}</p>
                <input
                  className="w-full rounded-lg h-[30px] p-1 "
                  type="text"
                  placeholder={option === "menu" ? (id === "new" ? t("Name") + "..." : dish.name) : (id === "new" ? t("Name") + "..." : ingredient.name)}
                  name="name"
                  value={option === "menu" ? newdish.name : newingredient.name}
                  onChange={handleChange}
                />
                <p className="whitespace-nowrap pt-3 sm:pt-0">{t("Price")} €</p>
                <input
                  className="w-full rounded-lg h-[30px] p-1 "
                  type="number"
                  placeholder={option === "menu" ? (id === "new" ? t("Price") + " €..." : dish.prezzo) : (id === "new" ? t("Price") + "..." : ingredient.price)}
                  name="price"
                  value={option === "menu" ? newdish.prezzo : newingredient.price}
                  onChange={handleChange}
                />
              </div>
              <p className="pt-3">{t("Details")}</p>
              <textarea
                className="w-full rounded-lg  h-full max-h-[80px] p-1 break-words"
                type="text"
                placeholder={id === "new" ? t("Details") + "..." : ingredient.details}
                name="details"
                value={newingredient.details}
                onChange={handleChange}
              />
            </form>
            <div className="flex justify-center mt-5">
                {Object.values(newingredient).some(x => x === '')?
                   <button onClick={handleSubmit} className="rounded-xl py-[10px] px-[20px]flex  bg-gray-300 text-gray-400  cursor-not-allowed"
                   disabled={true}>
                   {id === "new" ? t("Create New Ingredient") : t("Update")}
                  </button>

                  :

                  <button onClick={handleSubmit} className="rounded-xl py-[10px] px-[20px]flex bg-green-400 hover:bg-green-600  hover:text-gray-100">
                    {id === "new" ? t("Create New Ingredient") : t("Update")}
                  </button>
                }
              </div>
            </>
            }
          </div>
      </div>
  )
}

export default UpdateCreateDish
import React , {useContext, useState, useEffect}from 'react'
import { HMICLContext } from '../../contexts/HMICLContext'
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { LoginDelyContext } from '../../contexts/LoginDelyContext'
import {AiFillCreditCard} from 'react-icons/ai'

const PayDishesBooked = ({dishes_orded, totalCost}) => {
  const params = useContext(HMICLContext)
  const CLParams = params.HMI_CL_Params
  const userID = CLParams.userID
  const tableID = CLParams.tableID
  const {t} = useTranslation()
  const [foods,setFoods] = useState([]);
  const [error,setError] = useState("")
  const User = useContext(LoginDelyContext);
  const user = User.client_info
  const delyinfo = User.hmi_CL_info
  const restaurant_id =  delyinfo.user_id

  const handleCheckout = async (order_id) => {

    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }

    const cart_db = {
        
    }
    cart_db[`${userID}`] = []
    dishes_orded.map((dish,idx)=>{
      
        
        cart_db[`${userID}`].push(dish)
      })

    console.log(cart_db)
    
    const body = {
      items: dishes_orded,
      cart: cart_db,
      email: user.email,
      userId: userID,
      tableId: tableID,
      client_id: user.user_id,
      restaurant_id: delyinfo.user_id,
      restaurant_name: delyinfo.company_title,
      stripe_id: delyinfo.stripe_id,
      order_id: order_id,
      type: "table_order",
      url_receipt:"",
      customer_stripe_id: user.customer_stripe_id,
      total_cost: totalCost,
    }
    axios.post(backend + "/api/stripe/create-checkout-session/", body,{
      withCredentials: true
    }).then((res)=>{
      console.log(res.data)

        if(res.data.url){
          window.location.href = res.data.url      
        }
        

      }).catch((err) => {
        User.del_order(order_id)
        alert(err.message)
        console.log(err.message)});
    }

  const handleOrder = async () => {
    let body = {}
    let data = ""
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    
    const cart_db = {
        
    }
    cart_db[`${userID}`] = []
    dishes_orded.map((dish,idx)=>{
      
        
        cart_db[`${userID}`].push(dish)
      })

    console.log(cart_db)


    body = {
      items: dishes_orded,
      cart: cart_db,
      email: user.email,
      userId: userID,
      tableId: tableID,
      client_id: user.user_id,
      restaurant_id: delyinfo.user_id,
      restaurant_name: delyinfo.company_title,
      stripe_id: delyinfo.stripe_id,
      type: "table_order",
      url_receipt:"",
      customer_stripe_id: user.customer_stripe_id,
      total_cost: totalCost,
    }




    data = await axios.post(backend + "/api/order-pay/delivery/order",body,{
      withCredentials: true
    }).then(response => {
      console.log(response.data.order_id)
      const statusCode = response.status
      const success = response.statusText;
      if(!success){
        //handle errors here
        console.log(response.data.message)
        alert(t("There are some problems"))  
      }
      console.log(response.data)
      User.get_client_orders(user.user_id)
      params.getUserDish(userID,tableID,user.user_id,delyinfo.user_id)
      params.getUserTableDishes(tableID,delyinfo.user_id)
      alert(t("successfully odered") + "!!!");
      console.log(response.data)
      console.log(statusCode)
      return response
      }).catch((err) => {
        alert(err)
        console.log(err.message)
      }); 
      console.log(data)

      return data
    }

  const handlePayment = async () => {
    const res = await handleOrder ()
    console.log(res)
    if (res){
      await handleCheckout(res.data.order_id)
    }
  }




  return (
    <div>
      {dishes_orded?.length !== 0 ?
        <div className='flex-cols items-center justify-center pb-2 mx-2 '>
          {dishes_orded.map((dish, idx) =>
            <div key={idx} className='grid grid-cols-2 my-3 mx-1 gap-3 text-sm bg-white rounded-xl shadow-xl p-2'>
              {dish.custom ?
                <div className='flex-row items-center text-lg font-semibold'>{dish.category}
                  {dish.ingredienti.map((ingrediente, idx) =>
                    <p className='text-start text-xs font-normal my-1'>{ingrediente.name} €{(ingrediente.price).toFixed(2)} x {ingrediente.quantity} = €{(ingrediente.price * ingrediente.quantity).toFixed(2)}</p>

                  )}
                </div>
                :
                <div className='flex-row items-center text-lg font-semibold'>{dish.name}
                  <p className='text-start text-xs font-normal my-1'>{dish.ingredienti}</p>
                </div>

              }

              <div className='flex items-center md:justify-center text-red-500 text-xl font-semibold'>€ {(dish.quantity * Number(dish.prezzo)).toFixed(2)}</div>

            </div>
          )}
        </div>
        :
        <div className='flex-row items-center justify-center text-center '>
          <div className='my-[100px] font-bold text-xl'>
            {t("No Items Ordered yet")}
          </div>

        </div>}
        <h1 className='mb-3 text-center font-bold text-2xl'>{t("Total")}: €{totalCost.toFixed(2)}</h1>
        <button  
          className='flex mx-auto bg-green-400 rounded-xl hover:bg-green-600  hover:text-gray-100 w-auto font-semibold shadow-xl text-xl'
          onClick={handlePayment}>
            <AiFillCreditCard size={25} className='mr-2'/>
            {t("Pay") + " €" + totalCost.toFixed(2)}</button>
        <button />

    </div>
  )
}

export default PayDishesBooked
import React from 'react'
import styles from "../style";
import { useTranslation } from 'react-i18next';
import bg2 from "../data/AppLogo_ReApp.png";

const PrivacyComp_EN = () => {
  const {t} = useTranslation()
  return (
    <div className='flex flex-col mx-3 items-center justify-center space-y-10'>

    <h2 className='text-center mx-auto font-bold text-4xl text-white'>{t("Privacy Policy")}</h2>
    <div className='flex flex-col relative lg:grid lg:grid-cols-2 mx-auto items-start justify-start gap-5'>
      <img className='absolute -bottom-[70px] lg:-bottom-[120px] -right-7 z-10 w-[400px] h-[400px] opacity-20' src={bg2}></img>
      <div className='z-20 flex flex-col lg:col-span-2 mx-auto items-center justify-center space-y-4'>
      <p className='text-white text-justify cursor-auto'>Auxilium S.R.L. Via Libero Temolo, 4, 20126 - Milano - Italy - (given below “<span className="font-bold"> Speaty</span>” or “<span className="font-bold">Owner</span>”) is constantly committed to protecting the online privacy of its users. This document was drawn up pursuant to articles. 13 and 14 of EU Regulation 2016/679 (hereinafter:"<span className="font-bold">Regulation</span>") and Legislative Decree 196/2003 and subsequent amendments (“<span className="font-bold">Privacy Code</span>”) in order to let you know our privacy policy on how your personal information is handled when you use our sites - www.speaty.com (“<span className="font-bold">WebSite</span>”) and, where appropriate, to give express and informed consent to the processing of your personal data. The information and data provided by you or otherwise acquired as part of the use of Speaty services, such as for example registration in the reserved area of the Site or the purchase of products ("<span className="font-bold">Services</span>"), will be processed in compliance with the provisions of the Regulation and the confidentiality obligations that inspire the activity of Speaty.</p>
      <p className='text-white text-justify cursor-auto'>What does “process personal data” mean? It means carrying out any type of operation on information that allows you to be identified, such as, in this case, your name and surname or your e-mail address. The operations can be of various types and consist, for example, of: collection, recording, storage, modification, consultation, use, cancellation or destruction. By reading this information you will know which personal data of yours will be processed by Speaty and why. These are personal data (“<span className="font-bold">Data</span>”) all information relating to you, through which you can be identified or identifiable. The category of processed data includes, for example:</p>
      <ul className='list-inside list-disc text-white space-y-3'>
        <li className='text-white text-justify cursor-auto'>name, date of birth, e-mail address, postal address, landline and/or mobile telephone number;</li>
        <li className='text-white text-justify cursor-auto'>information relating to PCs, tablets or smartphones (such as the IP address, which is a numerical label that uniquely identifies a device - host - connected to an information network that uses the Internet Protocol (IP) as a network protocol, or the IMEI code, which is the numerical code that uniquely identifies your smartphone).</li>
        </ul>
        <p className='text-white text-justify cursor-auto'>The Data Controller can carry out processing activities directly or through subjects external to his organization, defined as Data Processors. These Managers process your Data according to the Owner's instructions, and exclusively for the purposes established by the latter. Data controllers may be, for example, the subjects who provide the technological infrastructure of the Site.</p>
        <p className='text-white text-justify cursor-auto font-bold'>This Privacy Policy, in particular:</p>
        <ul className='list-inside list-disc text-white  space-y-3'>
          <li className='text-white text-justify cursor-auto'>informs you about your rights regarding the processing of your personal data;</li>
          <li className='text-white text-justify cursor-auto'>allows you to know in a transparent way how we collect and process your personal data and how we guarantee their protection;</li>
          <li className='text-white text-justify cursor-auto'>shows you the ways in which you can exercise your rights and the ways in which we will respond to your requests.</li>
        </ul>
        <p className='text-white text-justify cursor-auto'>The processing of your Data is inspired by compliance with the principles of correctness, lawfulness, transparency, purpose limitation and conservation, minimization and accuracy, integrity and confidentiality, as well as the principle of accountability referred to in art. 5 of the Regulation. Your Data is processed in accordance with the legislative provisions of the Regulation and the Privacy Code and the confidentiality obligations provided therein.</p>
      </div>
    </div>
  </div>
  )
}

export default PrivacyComp_EN
import React, {useState, useContext, useEffect} from 'react'
import { useNavigate, useSearchParams, useParams, useLocation} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Outlet} from 'react-router-dom';
import {loadStripe} from "@stripe/stripe-js"
import {TbTruckDelivery} from "react-icons/tb"
import {MdOutlineNoFood} from "react-icons/md"
import {BsFillArrowRightCircleFill} from "react-icons/bs"
import {LoginDelyContext,LoginDelyProvider} from '../contexts/LoginDelyContext';
import {AiOutlineUser} from 'react-icons/ai';
import { Loading } from '../components';
import queryString from "query-string";
import { Helmet } from 'react-helmet-async';
import styles from "../style";
import { Footer, Navbar} from "../components/HomePage";


import i18next from 'i18next'

const HMI_Change_pass = () => {
  const User = useContext(LoginDelyContext)
  const {t} = useTranslation()
  const [color, setColor] = useState(false);
  const [searchParams] = useSearchParams();
  const [linkValid, setLinkValid] = useState(false)
  const [message, setMessage] = useState("")
  const url = Object.fromEntries([...searchParams]);
  console.log(url)
  const code = url.code
  const navigate = useNavigate();
  const location = useLocation();
  const changeColor = () =>{
    if (window.scrollY >=60){
      setColor(true)
    }else {
      setColor(false)
    }
  }
  const [company_name,setcCompany_name] = useState("")

  const initPassState= {
    code: code,
    new_password_1: "",
    new_password_2: "",
  }
  const [newPass, setNewPass] = useState(initPassState)

  const handleChangePass = (e) => {
    
    let passClone = { };

    passClone = {...newPass }
    passClone[e.target.name] = e.target.value;
      
    setNewPass(passClone);

  }

  const restaurant = true

  const handleSubmitPass= (e) => {
    e.preventDefault()
    console.log(newPass)
    if(newPass.new_password_1.length > 0 || newPass.new_password_2.length > 0){
      if(newPass.new_password_1===newPass.new_password_2){
        User.update_pass_link(newPass,restaurant).then(async res=>{
          const response = res
          console.log(response)
          if (response.stat ===201){
            alert(t("Update successfully"))  
          }else{
            console.log(response.data)
            alert(t(response.data.message))
          }
        }).catch((err) => {
            alert(err.message)
            console.log(err.message)});
      }else{
        alert(t("The 2 New Passwords are different, they must match"))
      }
    }else{
      alert(t("Please type The  New Password"))
    }
  }

  const checkLinkPass= async () =>{
    const res = await User.check_link_pass(code).then(res=>{
      let resp = ""
      if (res.stat===200){
        setLinkValid(true)
        setcCompany_name(res.data["company_name"])
        console.log(res)
      }else{
        setLinkValid(false)
        if(res.data.message.includes("expire") ){
          setMessage("The link for changing Password is expired. Please retry with a new request")
        }else{
          setMessage(res.data.message)
        }
        
      }
      return resp
    }).catch(error => {console.log(error)})
  }

  console.log(newPass)
  useEffect(()=>{
    checkLinkPass()
  },[])

  window.addEventListener('scroll',changeColor)
  return (
    <div className='bg-[url("data/background.png")] bg-no-repeat bg-cover bg-center bg-fixed' >
      <Helmet>
        <title>{t("Speaty - Revolution of Order & Delivery Food")}</title>
          <meta name="description" content={t('The Best Ordering System for Restaurants e Bars. Orders and Pay directly with Speaty Platform both delivery and order from the table of your restaurant')}/>
          <meta name="keywords" content='food, delivery restaurant, bar, ordering, app, business, cibo, ristorante, ordinazioni, orders, ordini, ordinazione, takeaway, consegna, domicilio'/>
        <link rel='canonical' href="https://speaty.com/chage-password"/>
      </Helmet>
      <div className={`${styles.flexCenter} ${color ? "bg-gradient-to-r from-cyan-200 to-green-400" : ""} sticky -top-1 z-40` }>
        <div className={`${styles.boxWidth} px-8`}>
          <Navbar use={"help"}/>
        </div>
      </div>
      <div className="mx-3 flex items-center justify-center my-3 flex-col min-h-[150px] lg:min-h-[350px]">
        <h2 className='text-white text-4xl font-bold'>{t("Chage Password Request")}</h2>
        {linkValid && 
          <>
            <h3 className='text-white text-xl mt-4 font-semibold'>{t("Hi ") + company_name} </h3>
            <div className='my-10 mx-auto items-center justify-center min-w-[400px]'>
              <label className="block uppercase text-gray-600 text-xs font-bold my-2"  >
                {t("New Password")}
              </label>
              <input 
                type="text" 
                className="border-0 px-3 py-3 placeholder-gray-500 text-gray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder={t("New Password") + "..."}
                name="new_password_1"
                value={newPass.new_password_1}
                onChange={handleChangePass} />
                <label className="block uppercase text-gray-600 text-xs font-bold my-2"  >
                {t("Confirm Password")}
              </label>
              <input 
                type="text" 
                className="border-0 px-3 py-3 placeholder-gray-500 text-gray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder={t("Confirm Password") + "..."}
                name="new_password_2"
                value={newPass.new_password_2}
                onChange={handleChangePass} />
            </div>
            <button 
              className='flex bg-green-400 text-center mx-auto rounded-xl hover:bg-green-600  hover:text-gray-100'
              onClick={handleSubmitPass}
              >
              {t("Confirm Update")}</button>
            <button  />
          </>
        }
        {message.length > 0 && 
          <p className='text-xl my-10 font-semibold text-center text-red-700'>{t(message)}</p>
        }
      </div>
      <div className={` ${styles.paddingX} ${styles.flexCenter}`}>
        <div className={`${styles.boxWidth}`}>
          <Footer />
        </div>
    </div>
    </div> 
  )
}

export default HMI_Change_pass
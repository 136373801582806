import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {LoginDelyContext} from '../contexts/LoginDelyContext'


const HMI_MN_Dash = () => {
  const {t} = useTranslation()
  const User = useContext(LoginDelyContext)
  const user = User.restaurant_info
  const status = user.sub_status
  const type = user.sub_type.split("_")[0]
  window.localStorage.removeItem('category')
  localStorage.removeItem("option_selected");

  return (
    <div className='grid grid-cols-2 items-center gap-[30px] mx-auto justify-center'>
      <div className='relative border rounded-lg shadow-lg hover:scale-105 duration-300 cursor-pointer w-[160px] sm:w-[250px] mx-auto'>
        <Link to="modify-menu">
          <button  className='absolute z-10 w-[140px] sm:w-[220px] text-sm sm:text-lg h-auto top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-green-600 text-white '>{t("Modify Menu and Add-ins")}</button>
          
          <img src={require('../data/Menu_Main.jpeg')} alt='Menu'
            className='h-full w-full object-cover rounded-lg'
          />
        </Link>
        
      </div>
      <div className='relative border rounded-lg shadow-lg hover:scale-105 duration-300 cursor-pointer w-[160px] sm:w-[250px] mx-auto'>
      <Link to="/dash/account">
        <button className='absolute z-10 w-[140px] sm:w-[220px] text-sm sm:text-lg h-auto top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-green-600 text-white '>{t("Modify Business Information")}</button>
        <img src={require('../data/Menu_Main.jpeg')} alt='Menu'
          className='h-full  object-fit rounded-lg' />
      </Link>
      </div>
      <div className={`relative border rounded-lg shadow-lg ${type==="premium" ? "hover:scale-105 duration-300 cursor-pointer " : "cursor-not-allowed "} w-[160px] sm:w-[250px] mx-auto`}>
      <Link to={type==="premium" ? "table-waiters" : ""}>
        <button className={`absolute z-10 w-[140px] sm:w-[220px] text-sm sm:text-lg h-auto top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ${type==="premium" ? " bg-green-600 text-white" : " opacity-40 cursor-not-allowed bg-gray-400 text-black"}`}>{t("Manage Table and Waiters")}</button>
        {type!=="premium" ?  
          <h2 className='absolute z-10 left-1/2 -translate-x-1/2 w-[140px] sm:w-[200px] text-sm sm:text-lg  font-bold text-orange-400 top-4 text-center bg-white rounded-xl shadow-xl '>{t("Premium Feature")}</h2>
        :
          ""
        }
        <img src={require('../data/Menu_Main.jpeg')} alt='Menu'
          className={`h-full w-full object-cover rounded-lg ${type==="premium" ? "" : "opacity-30"} `}  />
      </Link>
      </div>
      <div className='relative border rounded-lg shadow-lg hover:scale-105 duration-300 cursor-pointer w-[160px] sm:w-[250px] mx-auto'>
      <Link to="/dash/settings">
        <button className='absolute z-10 w-[140px] sm:w-[220px] text-sm sm:text-lg h-auto top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-green-600 text-white '>{t("Settings")}</button>
        <img src={require('../data/Menu_Main.jpeg')} alt='Menu'
          className='h-full object-cover rounded-lg' />
      </Link>
        
      </div>
      

    </div>
  )
}

export default HMI_MN_Dash




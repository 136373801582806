import styles from "../../style";
import Button from "./Button";
import { useTranslation } from 'react-i18next';

const CTA = () => {
  const {t} = useTranslation()
  const application = process.env.REACT_APP_APPLICATION_NAME
  return(
  <section className={`${styles.flexCenter} ${styles.marginY} ${styles.padding} sm:flex-row flex-col bg-black-gradient-2 rounded-[20px] box-shadow`}>
    <div className="flex-1 flex flex-col">
      <h2 className={styles.heading2}>{t("Let’s try now")} {application.charAt(0).toUpperCase() + application.slice(1)}. {t("Our Amazing Platform")}!</h2>
      <p className={`${styles.paragraph} w-full text-justify mt-5`}>
        {t("Everything you need for growing Your resturant & Bar Business")}.
      </p>
    </div>

    <div className={`${styles.flexCenter} sm:ml-10 ml-0 sm:mt-0 mt-10`}>
      <Button />
    </div>
  </section>
  );
};

export default CTA;

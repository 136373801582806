import React , {useContext, useState, useEffect}from 'react'
import { CartContext } from '../../contexts/CartContext'
import CartProduct from './CartProduct'
import { HMICLContext } from '../../contexts/HMICLContext'
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { LoginDelyContext } from '../../contexts/LoginDelyContext'
import {AiFillDelete, AiFillCreditCard} from 'react-icons/ai'
import Loading from '../Loading';
import { DeleteModalUser } from '../../page/Dashboard';
import {IoFastFoodOutline} from 'react-icons/io5'

const OrderButtonModal = ({onClose, visible, productsCount, visibleUser}) => {
  const cart = useContext(CartContext)
  const params = useContext(HMICLContext)
  const CLParams = params.HMI_CL_Params
  const userID = CLParams.userID
  const tableID = CLParams.tableID
  const {t} = useTranslation()
  const [foods,setFoods] = useState([]);
  const [error,setError] = useState("")
  const User = useContext(LoginDelyContext);
  const [payOthers, setPayOthers] = useState(false)
  const [selectedUser, setSelectedUser] = useState(false)
  const [selectedDish, setSelectedDish] = useState(false)
  const [checked, setChecked] = useState([]);
  const [checkedUser, setCheckedUser] = useState([]);
  const OtherDishData = params.userDishList
  const restaurant = false
  const user = User.client_info
  const delyinfo = User.hmi_CL_info
  const order = User.order_info
  const restaurant_id =  delyinfo.user_id
  const [notes, setNotes] = useState(()=>{
    return User.order_info.notes || ""
  })
  const dely_info = {
    delivery_cost : delyinfo.delivery_cost || 1,
    delivery_days: delyinfo.delivery_days || 0,
    start_hours: delyinfo.start_dely_time_hours || 19,
    start_minutes: delyinfo.start_dely_time_minutes|| 30,
    end_hours: delyinfo.end_dely_time_hours || 22,
    end_minutes: delyinfo.end_dely_time_minutes|| 30,
    minutes_dely: delyinfo.minutes_dely || 30,
    order_without_pay:  delyinfo.order_without_pay || false
  }

  console.log(CLParams)

  const handleNotes = (e) => {
    setError("")
    let order = User.order_info
    let note = e.target.value
    setNotes(note)
    order.notes = note
    User.stateOrder(order)
    
  }



  const createInitialOtherDish = () =>{
    params.getUserTableDishes(tableID,restaurant_id)
    const otherDishInit = OtherDishData



    otherDishInit?.map(user => (

      user.dishes?.map(order=>{

        order.checked = false
        order.user=user.user_id
      })
    ))

    otherDishInit?.map((user,idx1) => (

      user.dishes?.map((order,idx2)=>{
        if (order.sts ==="paid")
        otherDishInit[idx1].dishes.splice(idx2,1)
      })
    ))




    return otherDishInit
  };

  const [otherDish, setOtherDish] = useState(createInitialOtherDish)

  console.log(otherDish)
  

  const handleSubmit = () => {
    handleCheckout()
    //handleOrder()

  }

  const handlePayOthers = () => {
    setPayOthers(!payOthers)
    setChecked([])
    setCheckedUser([])
    setSelectedUser(false)
    setOtherDish(createInitialOtherDish)
  }

  
  const handleCheck = (e) => {
    setSelectedDish(!selectedDish)
    let updatedList = [...checked];
    let updatedUser = [...checkedUser];
    let user = JSON.parse(e.target.value)
    let User = user.user.user_id
    let UserDish = user.dish
    let index = user.idx
    let userOnly = {"user_id" : User, "completed" : false}
    console.log(e.target.checked,index)
    if ( otherDish.find( user=>  user.user_id === User).dishes[index].checked===false) {
      const nextotherDish = otherDish
      nextotherDish.find( user=>  user.user_id === User).dishes[index].checked=true
      setOtherDish(nextotherDish)
     
      updatedList = [...checked, UserDish ];
    } else {
      const nextotherDish = otherDish
      updatedList.splice(updatedList.findIndex((dish=>dish.name===UserDish.name)), 1);


      nextotherDish.find( user=>  user.user_id === User).dishes[index].checked=false
          
      setOtherDish(nextotherDish)
    
    }
    if ( otherDish.find( user=>  user.user_id === User).dishes.every(dish=> dish.checked===true)&& updatedUser.every(user=>user.user_id!==userOnly.user_id)){
        
      userOnly.completed = true
      updatedUser = [...checkedUser, userOnly]
    }else if ( otherDish.find( user=>  user.user_id === User).dishes.every(dish=> dish.checked===true)&& updatedUser.some(user=>user.user_id===userOnly.user_id)){
      return
    }else if( otherDish.find( user=>  user.user_id === User).dishes.some(dish=> dish.checked===false)){
        
      //updatedUser.splice(updatedUser.findIndex((user=>user.UserId===userOnly.UserId)), 1);
      updatedUser=updatedUser.filter((user=>user.user_id!==userOnly.user_id));
    }
      
    setChecked(updatedList);
    setCheckedUser(updatedUser);
    
  };

  const handleUserCheck = (e) =>{
    setSelectedUser(!selectedUser)
    let updatedList = [...checked];
    let updatedUser = [...checkedUser];
    let User = JSON.parse(e.target.value)
    let userOnly = {"user_id" : User?.user_id, "completed" : false}
    let element = otherDish.find( user=>  user.user_id === User.user_id)

    if (e.target.checked) {
      
      
      element.dishes.map(order=>{
        if (order.checked===false){
        order.checked=true
        updatedList.push(order)
        }else{
          return
        }

      })
      
      
      setOtherDish(otherDish)  
    
      userOnly.completed = true
      updatedUser = [...checkedUser, userOnly]
    }else{

      element.dishes.map(order=>{
        order.checked=false
        updatedList.push(order)

      })

      
      
      updatedUser.splice(updatedUser.findIndex(user=>user.user_id===userOnly.user_id), 1);
      //updatedUser=updatedUser.filter((user=>user.UserId===userOnly.UserId));
      
      //updatedList = updatedUser.filter(dish => !User.Orders(dish))

      element.dishes.map(order =>{
 
        //let index = updatedList.findIndex(dish=>dish.name===order.name)
        //let deleted = updatedList.splice(index)
        updatedList=updatedList.filter(dish => dish.user!==order.user)
       
       
      })

    }
      setChecked(updatedList);
      setCheckedUser(updatedUser)
    }
  
  const ischecked = (userName,dish,idx) =>{

    if (otherDish.find(user=>user.user_id===userName).dishes[idx].checked){
      return true
    }else{
      return false
    }
  }

  const ischeckedUser = (userName) =>{
    if (checkedUser.find(x=>x.user_id===userName && x.completed===true)){
      return true
    }else{
      return false
    }
  }

  // Generate Total of checked dishes
  let totalCost = 0;
  checked.map((cartItem)=>{
    totalCost += (Number(cartItem.prezzo) * cartItem.quantity);
  });

  console.log(checked)
  console.log(checkedUser)


  const handleCheckout = async (order_id) => {

    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }

    let body = {
      items: [],
      cart: [],
      email: user.email,
      userId: userID,
      tableId: tableID,
      client_id: user.user_id,
      notes: order.notes,
      restaurant_id: delyinfo.user_id,
      restaurant_name: delyinfo.company_title,
      stripe_id: delyinfo.stripe_id,
      type: "table_order",
      url_receipt: "",
      customer_stripe_id: user.customer_stripe_id,
      total_cost: totalCost,
      order_id:order_id
    }


    if (payOthers === false) {

      const cart_db = {

      }
      cart_db[`${userID}`] = []
      cart.items.map((dish, idx) => {


        cart_db[`${userID}`].push(dish)
      })

      body.cart = cart_db
      body.items = cart.items
      body.total_cost = cart.getTotalCost().toFixed(2)

    } else {
      const data = {

      }

      checked.map((dish, idx) => {
        const user = dish.user
        if (data[`${user}`] === undefined || data[`${user}`].length === 0) {

          delete dish.user
          data[`${user}`] = []
          data[`${user}`].push(dish)
        } else {

          delete dish.user
          data[`${user}`].push(dish)
        }
      })

      const checked_clean = checked
      delete checked_clean.user
      body.cart = data
      body.items = checked_clean
    }
    //create cart object for payment of others
    /*Example: {'clients':{'user_id_1':[{'_id':'pasta e patate'},{'_id':'spaghetti e vongole'}],'user_id_1':[{'_id':'panino'},{'_id':'carne'}]}*/

    axios.post(backend + "/api/stripe/create-checkout-session/", body,{
      withCredentials: true
    }).then((res) => {
      console.log(res.data)

      if (res.data.url) {
        User.stateLoading(false)
        onClose()
        window.location.href = res.data.url
      }
    }).catch((err) => {
      User.stateLoading(false)
      if (payOthers === false) { User.del_order(order_id) }
      alert(err.message)
      console.log(err.message)
    });
  }

  const handleOnlyOrder = async () => {
    if (Object.keys(user.email).length > 0) {

      let body = {}
      let data = ""
      let backend = ""
      if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
        backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
      }

      if (cart?.items?.length === 0) {
        setError(t("No food Selected, please Select Food"))
      }else{
        User.stateLoading(true)
        body = {
          items: cart.items,
          email: user.email,
          userId: userID,
          tableId: tableID,
          client_id: user.user_id,
          notes: order.notes,
          restaurant_id: delyinfo.user_id,
          restaurant_name: delyinfo.company_title,
          stripe_id: delyinfo.stripe_id,
          type: "table_order",
          url_receipt: "",
          customer_stripe_id: user.customer_stripe_id,
          total_cost: totalCost,
        }
        data = await axios.post(backend + "/api/table/only-order/", body, {
          withCredentials: true
        }).then(response => {
          console.log(response.data.order_id)
          const statusCode = response.status
          const success = response.statusText;
          if (response.status === 200) {
            User.stateLoading(false)
            onClose()
            cart.deleteAll()
            console.log(response.data)
            User.get_client_orders(user.user_id,)
            params.getUserDish(userID, tableID, user.user_id, delyinfo.user_id)
            params.getUserTableDishes(tableID, delyinfo.user_id)
            console.log(response.data)
            console.log(statusCode)
            return response
          } else {
            console.log(response.data.message)
            alert(t("There are some problems, please try again & contact the support"))
          }
        }).catch((err) => {
          User.stateLoading(false)
          alert(err)
          console.log(err.message)
        });
        console.log(data)
      }

    } else {
      visibleUser(true)
    }
  }
    
    const handleOrder = async () => {
      let data = ""
      let backend = ""
      if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
        backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
      }
  
      let body = {
        items: [],
        cart: [],
        email: user.email,
        userId: userID,
        tableId: tableID,
        client_id: user.user_id,
        notes: order.notes,
        restaurant_id: delyinfo.user_id,
        restaurant_name: delyinfo.company_title,
        stripe_id: delyinfo.stripe_id,
        type: "table_order",
        url_receipt: "",
        customer_stripe_id: user.customer_stripe_id,
        total_cost: totalCost,
      }
  
  
      if (payOthers === false) {
  
        const cart_db = {
  
        }
        cart_db[`${userID}`] = []
        cart.items.map((dish, idx) => {
  
  
          cart_db[`${userID}`].push(dish)
        })
  
        body.cart = cart_db
        body.items = cart.items
        body.total_cost = cart.getTotalCost().toFixed(2)
  
      } else {
        const data = {
  
        }
  
        checked.map((dish, idx) => {
          const user = dish.user
          if (data[`${user}`] === undefined || data[`${user}`].length === 0) {
  
            delete dish.user
            data[`${user}`] = []
            data[`${user}`].push(dish)
          } else {
  
            delete dish.user
            data[`${user}`].push(dish)
          }
        })
  
        const checked_clean = checked
        delete checked_clean.user
        body.cart = data
        body.items = checked_clean
      }

      data = await axios.post(backend + "/api/table/order/",body,{
        withCredentials: true
      }).then(response => {
        console.log(response.data.order_id)
        const statusCode = response.status
        const success = response.statusText;
        if(response.status===200){
          onClose()
          cart.deleteAll()
          console.log(response.data)
          User.get_client_orders(user.user_id,)
          params.getUserDish(userID,tableID,user.user_id,delyinfo.user_id)
          params.getUserTableDishes(tableID,delyinfo.user_id)
          alert(t("successfully odered") + "!!!");
          console.log(response.data)
          console.log(statusCode)
          return response
        }else{
          //handle errors here
          console.log(response.data.message)
          alert(t("There are some problems, please contact the support"))  
        }
        
        }).catch((err) => {
          alert(err)
          console.log(err.message)
        }); 
        console.log(data)
  
        return data
      }

  const handlePayment = async () => {
      if (Object.keys(user.email).length>0){
        if (cart?.items?.length === 0) {
          setError(t("No food Selected, please Select Food"))
        }else{
        User.stateLoading(true)
        const res = await handleOrder ()
        console.log(res)
        if (res){
          await handleCheckout(res.data.order_id)
          window.localStorage.removeItem('cart')
          
        }
      }
      }else{visibleUser(true)
    }
  }


  console.log(totalCost)

  if(!visible) return null && setChecked([]) && setCheckedUser([]) && setOtherDish(createInitialOtherDish);

  return (
    <div className='fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center z-50' >

      <div className='bg-gray-100 px-1 rounded md:w-[700px] max-h-[650px] relative overflow-y-auto w-[500px]'>


        <header className='top-0 w-full sticky border-gray-400 border-b-1 py-3 bg-green-200'>
          <span className='flex items-center justify-center font-bold text-xl'>{t("Hi")}<span className='text-orange-400 mx-2'>{userID}</span>{t("Your Order")}</span>
          <button type="button"
            className="absolute top-1 right-1 rounded-md bg-green-300 p-2 inline-flex items-center justify-center text-black hover:text-bold hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:bg-indigo-500 z-20"
            onClick={onClose}>
            <span className="sr-only">Close menu</span>
            {/*Heroicon name: outline/x*/}
            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </header>

        <div>

          <div>
            {payOthers ?
              otherDish?.map((user, idx1) =>
                <div key={idx1} className='text-xl grid-flow-row border-b-1 py-3'>
                  <input
                    className='my-3'
                    type="checkbox"
                    name="allSelected"
                    //value={JSON.stringify(user.Orders)}
                    value={JSON.stringify(user)}
                    onChange={handleUserCheck}
                    checked={ischeckedUser(user.user_id)}>
                  </input>
                  <span className='ml-3 font-bold my-3 '>{user.user_id}</span>
                  {user.dishes?.map((dish, idx2) =>
                    <div key={idx2} className='grid-flow-row text-sm '>
                      <input
                        className='ml-3 my-2'
                        value={JSON.stringify({ user: user, dish: dish, idx: idx2 })}
                        type="checkbox"
                        onChange={handleCheck}
                        name={dish.name}
                        checked={ischecked(user.user_id, dish, idx2)}
                      >
                      </input>
                      {dish.custom ?
                        <span className={`${checked.some(x => x.name === dish.name) ? "font-semibold text-blue-500" : ""} ml-3 text-gray-600 my-2`}>{dish.category} ( €{Number(dish.prezzo)} ) x{dish.quantity} = €{Number(dish.prezzo) * dish.quantity}</span>
                        :
                        <span className={`${checked.some(x => x.name === dish.name) ? "font-semibold text-blue-500" : ""} ml-3 text-gray-600 my-2`}>{dish.name} ( €{Number(dish.prezzo)} ) x{dish.quantity} = €{Number(dish.prezzo) * dish.quantity}</span>
                      }
                    </div>)
                  }
                </div>
              )
              :
              <div>
                {productsCount > 0 ?
                  <div className='flex-cols items-center justify-center my-1 mx-2 py-1'>
                    <h3 className='text-center font-semibold text-xl'>{t("List of Foods Selected")}</h3>
                    {cart.items.map((dish, idx) =>
                      <div key={idx} className='grid grid-cols-2 my-3 mx-1 gap-3 text-sm bg-white rounded-xl shadow-xl p-2'>
                        {dish.custom ?
                          <div className='flex-row items-center text-lg font-semibold'>{dish.category}
                            {dish.ingredienti.map((ingrediente, idx) =>
                              <p className='text-start font-thin text-[10px] my-1'>{ingrediente.name} €{Number(ingrediente.price).toFixed(2)} x {ingrediente.quantity} = €{Number(ingrediente.price * ingrediente.quantity).toFixed(2)}</p>

                            )}
                          </div>
                          :
                          <div className='flex-row items-center text-lg font-semibold'>{dish.name}
                            <p className='text-start font-thin text-[10px] my-1'>{dish.ingredienti}</p>
                          </div>

                        }
                        <div className='flex items-center justify-center cursor-pointer'
                          onClick={() => cart.deleteFromCart(dish.name)}>
                          <AiFillDelete color="red" size={30} />

                        </div>
                        <div className='flex items-center md:justify-center text-red-500 text-xl font-semibold'>€ {(dish.quantity * Number(dish.prezzo)).toFixed(2)}</div>
                        <div className='flex items-center justify-center'>
                          <div className='flex justify-center items-center'>
                            <button className='mx-2 bg-red-500 rounded w-[20px] items-center justify-center font-bold text-xl flex'
                              onClick={() => cart.removeOneFromCart(dish.name, dish.category, Number(dish.prezzo))}
                            ><span>-</span></button>
                            <h4 className='text-center flex items-center justify-center'>{dish.quantity} qty</h4>
                            <button className='mx-2 bg-green-500 rounded w-[20px] items-center justify-center font-bold text-xl flex'
                              onClick={() => cart.addOneToCart(dish.name, dish.category, Number(dish.prezzo), dish.custom)}
                            ><span>+</span></button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  :
                  <div className='container flex-row items-center justify-center text-center my-[120px] font-bold text-2xl'>
                    <h1>{t("No Items Ordered yet")}</h1>
                  </div>
                }
              </div>
            }
          </div>

          {payOthers
            ?
            <div className='bottom-0 border-t-1 bg-blue-100 border-gray-400 sticky flex-row items-center justify-center text-center  py-3 font-bold'>

              <h1 className='mb-3 text-2xl'>{t("Total")}: €{totalCost.toFixed(2)}</h1>

              <div className='items-center justify-center text-center text-xl flex '>

                <button className='mx-2 px-2 rounded-lg w-auto items-center justify-center text-white animate-bg bg-gradient-to-r from-green-500 via-green-800 to-orange-400 '
                  onClick={handlePayOthers}
                ><span>{t("Purchase only for you")}</span></button>

                <button className='mx-2 px-2 rounded-lg items-center justify-center text-white animate-bg bg-gradient-to-r from-fuchsia-400 via-fuchsia-800 to-blue-400 '
                  onClick={handleSubmit}
                ><span>{t("Confirm pay for Others")}</span></button>
              </div>
            </div>

            :
            <div className='bottom-0 border-t-1 bg-blue-100 border-gray-400 sticky flex-row items-center justify-center text-center  py-3 font-bold'>


              <h1 className='mb-3 text-2xl'>{t("Total")}: €{cart.getTotalCost().toFixed(2)}</h1>
              {productsCount > 0 ?
                <div className='items-center justify-center text-center text-xl flex '>
                  {dely_info.order_without_pay ?
                    <>
                      <button className='mx-2 px-2 bg-blue-500 rounded-lg w-auto items-center justify-center  text-white'
                        onClick={handleOnlyOrder}
                      ><span>{t("Book Order")}</span></button>

                      <button className='mx-2 px-2 rounded-lg w-auto items-center justify-center text-white animate-bg bg-gradient-to-r from-green-500 via-green-800 to-orange-400 '
                        onClick={handlePayment}
                      ><span>{t("Purchase Food")}</span></button>

                      <button className='mx-2 px-2 rounded-lg items-center justify-center text-white animate-bg bg-gradient-to-r from-fuchsia-400 via-fuchsia-800 to-blue-400 '
                        onClick={handlePayOthers}
                      ><span>{t("Purchase for Others")}</span></button>
                    </>
                    :
                    <>
                      <button className='mx-2 px-2 rounded-lg w-auto items-center justify-center text-white animate-bg bg-gradient-to-r from-green-500 via-green-800 to-orange-400 '
                        onClick={handlePayment}
                      ><span>{t("Order & Pay")}</span></button>

                      <button className='mx-2 px-2 rounded-lg items-center justify-center text-white animate-bg bg-gradient-to-r from-fuchsia-400 via-fuchsia-800 to-blue-400 '
                        onClick={handlePayOthers}
                      ><span>{t("Purchase for Others")}</span></button>
                    </>
                  }
                </div>
                :
                <div className='items-center justify-center text-center text-xl flex '>
                  {OtherDishData?.length !== 0 ?
                    <button className='mx-2 px-2 rounded-lg items-center justify-center text-white animate-bg bg-gradient-to-r from-fuchsia-400 via-fuchsia-800 to-blue-400 '
                      onClick={handlePayOthers}
                    ><span>{t("Purchase for Others")}</span></button>
                    :
                    <button className='mx-2 px-2 rounded-lg items-center justify-center text-white bg-gray-400'
                    ><span>{t("Purchase for Others")}</span></button>
                  }
                </div>
              }
            </div>
          }
        </div>

      </div>
    </div>
  );
};

export default OrderButtonModal
import React, {useState, useEffect, useContext,}from 'react'
import {features, subscription} from '../../data/consts'
import { GiCheckMark } from "react-icons/gi";
import { useTranslation } from 'react-i18next';
import {Speaty_Standard, Speaty_Premium} from '../../data'
import {LoginDelyContext} from '../../contexts/LoginDelyContext'
import {useNavigate } from 'react-router-dom';
import AOS from "aos"
import 'aos/dist/aos.css'


let stripe_publishableKey = process.env.REACT_APP_STRIPE_DEV_PUBLISHABLE_KEY
  if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
    stripe_publishableKey = process.env.REACT_APP_STRIPE_DEV_PUBLISHABLE_LIVE
  }

const ProductList = (dimension) => {
  
  const User =  useContext(LoginDelyContext)
  const user = User.restaurant_info
  const sub = User.subscription
  const {t} = useTranslation()
  const navigate = useNavigate();

  const [duration, setDuration] = useState(1)
  console.log(dimension)

  const handleselect = (price) =>{
    const newsub = sub
    Object.entries(newsub).map(([key, item]) => 
      newsub[key] = price[key] || ""
    )
    User.stateSubscription(newsub)
  }
  useEffect(()=>{
    AOS.init({duration:3000})
  },[])
  return (
    <div className='flex flex-col items-center py-4 text-white h-full animation' data-aos="zoom-in">

      <div className='flex flex-row rounded-full bg-white mb-3'>
        <button 
          className={` text-xl w-[120px] ${duration===1 ? "bg-teal-700 text-white font-bold" : "text-teal-700"}`}
          onClick={()=>{setDuration(1)}}>{t("Monthly")}</button>
        <button className={` text-xl w-[120px] ${duration===12 ? "bg-teal-700 text-white font-bold" : "text-teal-700"}`}
          onClick={()=>{setDuration(12)}}>{t("Yearly")}</button>
      </div>
      {dimension.dimension==="normal" ? 
      <div className='flex flex-col md:flex-row px-4 gap-3'>
        {subscription.map((price, idx) =>
        <>
        {price.duration_month===duration ? 
          <div key={idx} className={`relative flex flex-col gap-6 p-3 border-1 rounded-3xl max-w-[340px] ${subscription.name===price.name ? "bg-teal-300 opacity-25": ""}`}>
            <div className={`${price.best===false ? "hidden" : "absolute p-1 rounded-xl top-3 right-3 bg-teal-500 text-yellow-500 font-bold text-xl"}`}>{t("Best Deal")}</div>
            <div className=' flex min-h-[150px] w-[320px] flex-col rounded-3xl px-8 pt-5 pb-3'>
              <img className="w-[120px] mb-5" src={price.name === "Speaty Standard" ? Speaty_Standard : Speaty_Premium} alt={price.name === "Speaty Standard" ? "Speaty Standard" : "Speaty Premium"} />
              <h2 className='mb-5 text-2xl font-medium'>{price.name}</h2>
              {price.duration_month===1 ?
                  <div className='text-2xl font-medium'>€{price.cost.toFixed(2) + "/" + t("month")}
                    <span className='ml-0.5 font-thin text-sm'>{t("Excluding VAT")}</span>
                  </div>
                  :
                  <div className='text-2xl font-medium'>€{price.cost.toFixed(2) + "/" + t("year")}
                   <span className='ml-0.5 font-thin text-sm'>{t("Excluding VAT")}</span>
                  </div>
                }
            </div>
            <button className='rounded-full bg-teal-500 py-3 px-6 text-lg font-medium text-white hover:bg-teal-700 hover:text-xl'
            onClick={()=>{
              navigate("/login-register")
            }}>{t("Subscribe")}</button>
            <p className='mb-5 text-center h-[40px]'>{t(price.description)}</p>
            <ul className='mb-10 flex flex-col gap-y-2'>
              {price.features.map((feature, idx2) =>
                <li key={idx2} className='flex flex-row justify-start items-center'>
                  <GiCheckMark color='white' className='w-8' />
                  <h3 className=' text-sm'>{t(feature)};</h3>
                </li>

              )}
            </ul>
            
          </div>
          :""}
          </>
        )}
      </div>
      :
      <div className='flex flex-col lg:flex-row px-4 gap-3'>
        {subscription.map((price, idx) =>
        <>
        {price.duration_month===duration ? 
          <div key={idx} className={`relative flex flex-col gap-2 sm:gap-6 p-3 border-1 rounded-3xl w-[300px] md:max-w-[420px] ${sub.stripe_description_prod===price.stripe_description_prod? "bg-teal-500 bg-opacity-50": ""}`}>
            <div className={`${price.best===false ? "hidden" : "absolute p-1  rounded-xl top-2 right-2 bg-teal-500 text-yellow-500 font-bold text-md sm:text-xl"}`}>{t("Best Deal")}</div>
            <div className=' flex h-[90px] flex-row rounded-3xl p-2 gap-3 items-center '>
              <img className="w-[80px] h-[80px]" src={price.name === "Speaty Standard" ? Speaty_Standard : Speaty_Premium}  alt={price.name === "Speaty Standard" ? "Speaty Standard" : "Speaty Premium"} />
              <h2 className='justify-center text-2xl font-medium'>{price.name}</h2>
            </div>
              <div className=' flex text-2xl  font-black justify-between items-center'>
                {price.duration_month===1 ?
                  <div className='flex flex-col '>€{price.cost.toFixed(2) + "/" + t("month")}
                    <span className='ml-0.5 font-thin text-xs'>{t("Excluding VAT")}</span>
                  </div>
                  :
                  <div className='flex flex-col '>€{price.cost.toFixed(2) + "/" + t("year")}
                   <span className='ml-0.5 font-thin text-xs'>{t("Excluding VAT")}</span>
                  </div>
                }
                  <button className='sm:hidden rounded-full bg-teal-500 py-3 px-3 text-lg font-medium text-white hover:bg-teal-700 hover:text-xl'
                    onClick={() =>handleselect(price)}>
                  {t("select")}</button>
              </div>
            
            <button className='hidden sm:flex rounded-full bg-teal-500 py-3 px-3 text-lg font-medium text-white hover:bg-teal-700 hover:text-xl'
              onClick={()=>handleselect(price)}>
            {t("select")}</button>
            <p className='mb-5 text-center h-[40px]'>{t(price.description)}</p>
            </div>
          :""}
          </>
        )}
      </div>
      }
    </div>
)
}

export default ProductList
import React from "react";
import {Navigate , useNavigate} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Button = ({ styles }) => {
  const {t} = useTranslation()
  const navigate = useNavigate();
  return(
  <button type="button" className={`py-4 px-6 font-poppins  text-[18px] text-primary bg-gradient-to-r from-green-400 to-fuchsia-400 rounded-[10px] outline-none font-semibold ${styles}`}
  onClick={()=>{navigate("/login-register")}}>
    {t("Get Started")}
  </button>
);
};

export default Button;

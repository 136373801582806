import React, {createContext, useState, useEffect, useContext}from 'react'
import { MenuContext } from './MenuContext'

export const CartContext = createContext ({

  items: [],
  fodd_add_in : [],
  totalAddinCost: 0,
  getProductQuantity: () => {},
  getAddInsQuantity: () => {},
  addOneToCart: () => {},
  addFoodAddOneToCart: () => {},
  removeOneFromCart: () => {},
  removeFoodAddOneFromCart: () => {},
  deleteFromCart: () => {},
  deleteFoodAdd: () => {},
  getTotalCost: () => {},
  getTotalFoodAddCost: () => {},
  deleteAll: () =>{},
  deleteAllFoodAdd: () =>{},
  state_addtotalcost: () =>{},

  }
)

export function CartProvider({children}){
  const [cartProducts, setCartProducts] = useState(()=>{
    const saved = localStorage.getItem("cart");
    const initialValue = JSON.parse(saved);

    
    return initialValue || []
 
  })

  const [totalAddinCost, setTotalAddinCost] = useState(0)

  const [food_add, setFood_add] = useState(()=>{
    const saved = localStorage.getItem("customDish");
    const initialValue = JSON.parse(saved);

    
    return initialValue || []
 
  })

  const menus = useContext(MenuContext)

  function getProductQuantity(name) {
    const quantity = cartProducts.find(product => product.name === name)?.quantity
    if (quantity === undefined) {
      return 0;
    }
    return quantity;
  }

  function getAddInsQuantity(name) {
    const quantity = food_add.find(addIn => addIn.name === name)?.quantity
    if (quantity === undefined) {
      return 0;
    }
    return quantity;
  }




  function addOneToCart(name, category, prezzo, ingredienti, custom) {
    console.log("AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA")
    const quantity = getProductQuantity(name);
    if (!custom){
      custom=false
    }
    console.log(quantity)
    if(quantity ===0){ //product is not in the cart
      setCartProducts([
        ...cartProducts,
        {
          name: name,
          quantity: 1,
          category: category,
          prezzo: prezzo,
          ingredienti: ingredienti,
          custom: custom 
        }
      ])
    }else{ //product is in the cart
      setCartProducts(
        cartProducts.map(
          product => product.name === name                                   //if condition
          ? {...product, quantity: product.quantity + 1, } //if statement is true
          : product                                                          //if statement is true
        ),
       
      )
    }
  }

  function addFoodAddOneToCart(name, category, price,custom_dish) {
    const quantity = getAddInsQuantity(name);

    if(quantity ===0){ //product is not in the cart
      setFood_add([
        ...food_add,
        {
          name: name,
          quantity: 1,
          category: category,
          price: price,
          
        }
      ])
    }else{ //product is in the cart
      setFood_add(
        food_add.map(
          product => product.name === name                                   //if condition
          ? {...product, quantity: product.quantity + 1, category: category, price: price, } //if statement is true
          : product                                                          //if statement is true
        ),
       
      )
    }
  }




  function removeOneFromCart (name, category, price,custom_dish) {
    const quantity = getProductQuantity(name);
    if(quantity ===1){
      deleteFromCart(name);
    }else{
      setCartProducts(
        cartProducts.map(
          product => product.name === name                                   //if condition
          ? {...product, quantity: product.quantity - 1, category: category, price: price,custom_dish:custom_dish} //if statement is true
          : product                                                          //if statement is true
        ),
      )
    }
  }

  function removeFoodAddOneFromCart (name, category, price,custom_dish) {
    const quantity = getAddInsQuantity(name);
    if(quantity ===1){
      deleteFoodAdd(name);
    }else{
      setFood_add(
        food_add.map(
          product => product.name === name                                   //if condition
          ? {...product, quantity: product.quantity - 1, category: category, price: price,} //if statement is true
          : product                                                          //if statement is true
        ),
      )
    }
  }


  function deleteFromCart (name) {
    setCartProducts(
      cartProducts=>
      cartProducts.filter(currentProduct =>{
        return currentProduct.name !== name; 
      })
    )
  }

  function deleteFoodAdd (name) {
    setFood_add(
      addins=>
      addins.filter(addins =>{
        return addins.name !== name; 
      })
    )
  }

  function deleteAll () {
    setCartProducts([])
  }

  function deleteAllFoodAdd () {
    setFood_add([])
  }


  function getTotalCost() {
    let totalCost = 0;
    cartProducts.map((cartItem)=>{
      totalCost += (cartItem.prezzo * cartItem.quantity);
    });
    return totalCost
  }

  const getTotalFoodAddCost = (custom_Dish) => {
    let totalCost = 0;
    food_add.map(async (addins)=>{
      console.log(addins)
      const AddInsData = await menus.getAddInsData(addins,custom_Dish);
      console.log(AddInsData)
      totalCost += (AddInsData.price * addins.quantity);
      console.log(totalCost)
      setTotalAddinCost(totalCost)
    });
    return totalAddinCost
  }

  const state_addtotalcost = (addtotcost)=>{
    setTotalAddinCost(addtotcost)
  }


  useEffect(() => {
    window.localStorage.setItem('cart', JSON.stringify(cartProducts));
    window.localStorage.setItem('foodAdd', JSON.stringify(food_add));


  }, [cartProducts],[food_add]);



  const contextValue = {
    items: cartProducts,
    fodd_add_in: food_add,
    totalAddinCost: totalAddinCost,
    getProductQuantity,
    getAddInsQuantity,
    addOneToCart,
    addFoodAddOneToCart,
    removeOneFromCart,
    removeFoodAddOneFromCart,
    deleteFromCart,
    deleteFoodAdd,
    getTotalCost,
    getTotalFoodAddCost,
    deleteAll,
    deleteAllFoodAdd,
    state_addtotalcost,
  

  }

  return(
    <CartContext.Provider value={contextValue}>
      {children}
    </CartContext.Provider>
  )
}

export default CartProvider
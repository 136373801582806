import React , {useContext, useEffect} from 'react'
import { MenuContext } from '../../../contexts/MenuContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {AiOutlineDownload} from 'react-icons/ai'
import {LoginDelyContext} from '../../../contexts/LoginDelyContext'
import { useTranslation } from 'react-i18next';

const Create_table_QR = ({ rows, deleteRow, editRow }) => {
  const menus = useContext(MenuContext)
  let tables = menus.qrtables;
  console.log(tables)
  const navigate = useNavigate();
  const {t} = useTranslation()
  const User = useContext(LoginDelyContext)
  const settings = User.page_info
  const restaurant_id = settings.user_id
  const status = settings.sub_status
  const type = settings.sub_type.split("_")[0]

  const handleDelete = async (e,table_Id) => {
    e.preventDefault();
    const res = menus.delQrTable(table_Id,restaurant_id).then((response)=>{
      return response
    })
    menus.getAllQrTables()
  
  };

  const handleDownload = async (e,table_Id) => {
    /*let linkImage = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      linkImage = process.env.REACT_APP_BACKEND_SERVER_DOMAIN + process.env.REACT_APP_QRCODE_MAIN_QR_LINK
    }else{
      linkImage = "http://localhost:3001" + process.env.REACT_APP_QRCODE_MAIN_QR_LINK
    }
    e.preventDefault();
    downloadImage(linkImage  + "/" + restaurant_id + process.env.REACT_APP_QRCODE_QR_LINK + table_Id + ".png", "table_" + table_Id + ".png")
  }*/
  let backend = ""
  if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
    backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
  }
  const body = JSON.stringify({table_Id: table_Id, restaurant_id: restaurant_id});;
   
  const res = await fetch(backend + '/api/talbeQR-info', {
    withCredentials: true,
    method:"PUT",
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      //'X-CSRFTOKEN': csrfCookie,
      responseType: 'blob',
      
    },
    body,
  }).catch(err=>console.log(err))
    console.log(res)
    const blob = await res.blob()
    console.log(blob)
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    // the filename you want
    a.download = "table_" + table_Id;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);


  /*function downloadImage(url, name){
    console.log(url)
    fetch(url)
      .then(resp => resp.blob())
      .then(blob => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          // the filename you want
          a.download = name;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
      })
      .catch((err) => alert(err));*/
  }


  

  console.log(tables)

  return (
    <div className='w-full h-full justify-center items-center'>
      <div className=" flex-row bg-white rounded-xl shadow-xl my-3 py-[30px] px-[20px] justify-center items-center mx-4 ">
        <div>
        <button
          onClick={() => navigate(`/dash/manager-dash/table-waiters/table/new?`)}
          className="flex bg-sky-600 first-line:rounded-xl py-[10px] px-[20px] mb-4 j"
        >
          {t("Create New Table QR")}
        </button>
        
        <table className="table-auto w-full">
          <thead>
            <tr className='items-center justify-between content-center'>
              <th className='text-center '>{t("Table Number")}</th>
              {/*<th className='text-center '>Update</th>*/}
              <th className='text-center '>{t("Delete")}</th>
              <th className='text-center '>Download</th>
            </tr>
          </thead>
          <tbody>
            {
            tables.map((table)=>( 
              <tr className='content-center' key={table._id}>
                <td className='text-center '> {table._id} </td>
                {/*<td className='text-center '>
                  <button
                    onClick={() => navigate(`/dash/manager-dash/table-waiters/table/${table._id}`)}
                    className="rounded-xl py-[10px] px-[20px]flex bg-green-400 hover:bg-green-600  hover:text-gray-100"
                  >
                    Update
                  </button>
                  </td>*/}
                <td className='text-center ' >
                  <button
                    onClick={(e)=>handleDelete(e,table._id)}
                    className="rounded-xl py-[10px] px-[20px]flex bg-red-400 hover:bg-red-600  hover:text-gray-100"
                  >
                    {t("Delete")}
                  </button>
                </td>
                <td className='text-center ' >
                  <button
                    onClick={(e)=>handleDownload(e,table._id)}
                    className='bg-blue-400 hover:bg-blue-700'
                  >
                    <AiOutlineDownload size={34} color='white'/>
                  </button>
                </td>
              </tr>
              ))}
          </tbody>
        </table>
        </div>
      </div>

    </div>




  );
};

export default Create_table_QR
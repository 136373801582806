import React , {useContext, useState, useEffect, useRef}from 'react'
import { LoginDelyContext } from '../../contexts/LoginDelyContext'
import { useTranslation } from 'react-i18next';
import {IoIosArrowBack} from 'react-icons/io'
import {AiFillDelete, AiFillEye,AiOutlineUpload} from 'react-icons/ai'
import DeleteModalUser from './DeleteModalUser';
import DropdownComp from './DropdownComp';

const Orders = () => {
  const User = useContext(LoginDelyContext)
  const {t} = useTranslation()
  const settings=User.page_info
  const user = User.restaurant_info
  const type = user.sub_type.split("_")[0]
  const restaurant_id = settings.user_id
  const restaurant = true
  const get_orders = User.restaurant_orders
  const dely_take_orders = get_orders.filter(order => order?.type!=="table_order")
  const table_orders = get_orders.filter(order => order?.type==="table_order")
  const [openSubmit,setOpenSubmit] = useState(false)
  const [openSubmitDel,setOpenSubmitDel] = useState(false)
  const [new_order, setNew_order] = useState({})
  const [orderID_del, setOrderID_del] = useState("")
  const [selected, setSelected] = useState(()=>{
    const saved = sessionStorage.getItem("restaurant_option_selected");
    const initialValue = JSON.parse(saved);
    return  initialValue || "today"
  } )


  const [optionSelelected, setOptionSelelected] = useState("delivery_take")
  localStorage.removeItem("option_selected");
  useEffect(()=>{
    window.sessionStorage.setItem('restaurant_option_selected', JSON.stringify(selected));
 },[selected]);

  const handleSubmit = (key) =>{
    let cloneOrder = details
    if (key==="sts"){
        const sts = new_order_sts.sts
        //setDetails({...cloneOrder})
        cloneOrder.sts = sts
        User.update_sts_order(details._id, sts, restaurant_id).then(async res=>{
        const response = await res
        console.log(response)
        if (response.stat ===200){
            setOpenSubmit(false)
            alert(t("Update successfully"))
        }else{
            setOpenSubmit(false)
            alert(t("Error to Update, please try again or contact the support"))
        }
      })

    }else{
        Object.entries(new_order).map(([key,value])=>{
          cloneOrder[key]=new_order[key]

        }) 

        User.modify_order(cloneOrder, restaurant_id).then(async res=>{
        const response = await res
        console.log(response)
        if (response.stat ===200){
            setOpenSubmit(false)
            alert(t("Update successfully"))
        }else{
            setOpenSubmit(false)
            alert(t("Error to Update, please try again or contact the support"))
        }
      })
    }
  }

  const [new_order_sts, setNew_order_sts] = useState({})

  const OptionSelected = (selected)=>{

    //{what: which_option, choose: option}
    const sts = {} //details
    sts[selected.what] = selected.choose
    setNew_order_sts({...sts})
  }


  const order_status = ["paid","confirmed","under preparation", "in delivery", "delivered", "completed","canceled","in reimbursement","refunded"] 

  //const order_status = ["new","payement failed", "modified","paid","confirmed","under preparation", "in delivery", "delivered","deleted", "completed"] 


  const [details, setDetails] = useState({})
  const [orders, setOrders] = useState(dely_take_orders)


  useEffect(()=>{
    
    setOrders(dely_take_orders)
    const orders_today = filter("delivery_date", today, true)
    const orders_tomorrow = filter("delivery_date", tomorrow, true)

    setToday_orders(...[orders_today])
    setTomorrow_orders(...[orders_tomorrow])

  },[get_orders])

  const now = new Date();
  const now_plus_one = new Date()
  now_plus_one.setDate(now.getDate() +1)
  const today = {
    month: now.getMonth() + 1,
    monthstr: now.toLocaleString('default', { month: 'short' }),
    year: now.getFullYear(),
    day: now.getDate(),
  }
  const tomorrow = {
    
    month: now_plus_one.getMonth() + 1,
    monthstr: now_plus_one.toLocaleString('default', { month: 'short' }),
    year: now_plus_one.getFullYear(),
    day: now_plus_one.getDate(),
  }


  const filter = (key1, value, initial)=> {
    let filter_orders = []
    
    if (typeof value==="object") {
      if (Object.keys(value).every(key2=>typeof value[key2]==="object")){
        dely_take_orders.map(order=> {
          

          //Object.keys(value).

          if (Object.keys(value).every(key2=>order[key1][key2]===value[key2])){
  
            filter_orders.push(order)
          }
  
        })


      }
      dely_take_orders.map(order=> {
        
        if (Object.keys(value).every(key2=>order[key1][key2]===value[key2])){

          filter_orders.push(order)
        }
      
      })
    }else{

      filter_orders= dely_take_orders.filter(orders=> orders[key1]===value)
      
    }
    
    
    if (!initial){
      setOrders(...[filter_orders])
    }else{
      return filter_orders
    }
   
  }

  const [today_orders, setToday_orders] = useState(()=>{
    
    const initial = filter("delivery_date", today, true)
    return initial || []
  })

  const [tomorrow_orders, setTomorrow_orders] = useState(()=>{
    
    const initial = filter("delivery_date", tomorrow, true)
    return initial || []
  })

  today_orders.sort(((a, b) => a.delivery_time.begin.minutes - b.delivery_time.begin.minutes))
  today_orders.sort(((a, b) => a.delivery_time.begin.hours - b.delivery_time.begin.hours))


  const get_date = (date) => {
    const new_date = date.split("-")
    const new_time = new_date[2].split("_")
    const utcDate = new Date(Date.UTC(new_date[0], new_date[1]-1, new_time[0],new_time[1],new_time[2],new_time[3]))

    const local_date = utcDate.toLocaleString()

    return local_date
  }

  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }
  
  function formatDate(date) {
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join('/');
  }

  const get_deliveryDate = (date) =>{
    let local_date = ""
    if (date){
      local_date = formatDate(new Date(date.year, date.month-1, date.day))
    }
    return local_date || " - "
  }

  const status_style = (sts) =>{
    let style = ""
    /*"new","modified","payed","confirmed","under preparation", "in delivery", "delivered","in reimbursement","refunded"*/
    if (sts==="new"){
      style = "text-yellow-800 bg-orange-300 bg-opacity-50"
    }else  if (sts==="payed" || sts==="paid"){
      style = "text-green-700 bg-yellow-300 bg-opacity-50"
    }else  if (sts==="confirmed"){
      style = "text-blue-700 bg-gray-300 bg-opacity-50"
    }else  if (sts==="under preparation"){
      style = "text-yellow-800 bg-yellow-300 bg-opacity-50"
    }else  if (sts==="in delivery"){
      style = "text-yellow-800 bg-yellow-300 bg-opacity-50"
    }else  if (sts==="delivered"){
      style = "text-green-800 bg-green-300 bg-opacity-50"
    }else  if (sts==="deleted"){
      style = "text-yellow-800 bg-orange-300 bg-opacity-50"
    }else  if (sts==="modified"){
      style = "text-red-800 bg-red-300 bg-opacity-50 underline"
    }else  if (sts==="completed"){
      style = "text-blue-800 bg-blue-300 bg-opacity-50 "
    }else  if (sts==="in reimbursement"){
      style = "text-red-800 bg-yellow-300 bg-opacity-50 underline"
    }else  if (sts==="refunded"){
      style = "text-blue-800 bg-yellow-300 bg-opacity-50 underline"
    }
    return style
  }

  const delete_order = async () =>{
    const res = await User.del_order(orderID_del, restaurant_id).then((response)=>{
      return response
    }).catch(error => console.log(`Error: ${error}`));
    console.log(res)
    if (res.stat===200){

      setOpenSubmitDel(false)
      alert(t("The Order has been deleted successfully"))
    }
  }

  const deliveryTime_Style = (delivery_date, delivery_time, sts) =>{
    /*"new","modified","payed","confirmed","under preparation", "in delivery", "delivered","deleted", "completed"*/
    
    let style = false
    if (sts=== "in delivery" || sts=== "delivered" || sts=== "deleted" || sts=== "completed"|| sts=== "refunded"|| sts=== "in reimbursement"|| sts=== "canceled"){
      return
    }else {
      if (delivery_time.begin.minutes==="00"){
        delivery_time.begin.minutes=0
      }
      
      const date = new Date(delivery_date.year, delivery_date.month-1, delivery_date.day, delivery_time.begin.hours,delivery_time.begin.minutes)

      const remaining_time = ((date - now)/1000/60)
      if (delivery_time.begin.minutes===0){
        delivery_time.begin.minutes="00"
      }
      if (remaining_time<=60){
        style = true
      }else {
        style = false
      }
    }
    return style
  }

  function compareNumbers(a, b) {
    return a - b;
  }

  return (
    <div className=' flex flex-col max-w-[1200px] xl:max-w-[1600px] h-full mx-auto items-center'>
      {Object.entries(details).length===0 ?
      <div className='border-1 border-teal-100 absolute bottom-[40px] xxl:top-[300px] xxl:left-[400px] z-20 rounded-xl flex flex-col bg-teal-100 h-[80px]'>
          <button
          onClick={() => {
            setOptionSelelected("delivery_take")
          }}
            className={`w-full text-center mx-auto py-2 px-4 ${optionSelelected === "delivery_take" ? "border-teal-500   bg-teal-500 hover:bg-teal-700 text-white" : "hover:border-teal-200 text-black hover:bg-teal-300"}`}
          >
            <span className="ml-1 text-center">{t("Delivery")}</span>
          </button>
          <button
            onClick={() => {
              setOptionSelelected("table_order")
            }}
            className={`w-full text-center mx-auto py-2 px-4 ${optionSelelected === "table_order" ? "border-teal-500   bg-teal-500 hover:bg-teal-700 text-white" : "hover:border-teal-200 text-black hover:bg-teal-300"}`}
          >
            <span className="ml-1 text-center flex flex-row text">{t("Tables Orders")}</span>
          </button>
        </div>
        :""}
      {optionSelelected==="delivery_take" ? 
      <>
       <ul className="sticky w-full -top-5 flex items-center justify-center  bg-gray-200 pb-1 z-10 rounded-b-xl">
          <li className="flex-1 shadow-xl text-center cursor-pointer"
            onClick={() => {
              setDetails({})
              setSelected("today")
            }}>
            <button
              className={`w-full ext-center mx-auto block rounded-t py-2 px-4 ${selected === "today" ? "border-blue-500   bg-blue-500 hover:bg-blue-700 text-white" : "hover:border-gray-200 text-black hover:bg-gray-300"}`}
            >
              <span className="ml-1 text-center">{t("Today")}</span>
            </button>
          </li>
          <li className="flex-1 shadow-xl text-center cursor-pointer"
            onClick={() => {
              setDetails({})
              setSelected("tomorrow")
            }}>
            <button
              className={`w-full ext-center mx-auto block  rounded-t py-2 px-4 ${selected === "tomorrow" ? "border-blue-500   bg-blue-500 hover:bg-blue-700 text-white" : "hover:border-gray-200 text-black hover:bg-gray-300"}`}
            >
              <span className="ml-1 text-center">{t("Tomorrow")}</span>
            </button>
          </li>
          <li className="flex-1 shadow-xl text-center cursor-pointer"
            onClick={() => {
              setDetails({})
              setSelected("all")
            }}>
            <button
              className={`w-full ext-center mx-auto block  rounded-t py-2 px-4 ${selected === "all" ? "border-blue-500   bg-blue-500 hover:bg-blue-700 text-white" : "hover:border-gray-200 text-black hover:bg-gray-300"}`}
            >
              <span className="ml-1 text-center">{t("All")}</span>
            </button>
          </li>
        </ul>
      {Object.entries(details).length === 0 ?
        <div className='flex mx-auto mb-6 p-3 shadow-lg rounded-lg items-center bg-gray-100 m-2 min-w-[350px] max-w-[750px] max-h-[580px] sm:max-h-[550px]'>
          {selected === "today" ? 
            <div className='mb-12 mx-auto'>
              <h3 className='text-center font-semibold text-xl my-5'>{t("Your Orders of Today") + ": " + get_deliveryDate(today)}</h3>
              <div className='mx-1 rounded-lg shadow my-4 bg-transparent sm:bg-white'>
              {today_orders.length === 0 ?
                <div className='text-center my-10 bg-white py-10'>{t("No Order for Today")}</div>
                :
                <>
                <table className="table-auto border-collapse border-gray-400 2xl:whitespace-nowrap w-full overflow-auto max-h-[450px] hidden sm:block">
                  <thead>
                    <tr className='items-center justify-between content-center'>
                      <th className='text-center tracking-wide  border-1'>{t("Actions")}</th>
                      <th className='text-center  tracking-wide  border-1'>{t("Type Order")}</th>
                      <th className='text-center  tracking-wide  border-1'>{t("Delivery Time")} </th>
                      <th className='text-center  tracking-wide  border-1'>{t("Status")}</th>
                      <th className='text-center  tracking-wide  border-1'>{t("Total")}</th>

                    </tr>
                  </thead>
                                       
                    <tbody className=''>

                      {today_orders?.map((order, idx) => (
                        <tr className={`content-center h-[30px] ${deliveryTime_Style(order?.delivery_date,order?.delivery_time, order?.sts) ? "bg-red-200 font-semibold" : ""}`} key={idx}>
                          <td className='items-start justify-center mx-auto'>
                            <div className='flex flex-row item-center justify-center '>
                              <AiFillDelete color="red" size={30}
                                onClick={() => {
                                  setOrderID_del(order?._id)
                                  setOpenSubmitDel(true)}}
                                className='cursor-pointer'
                              />
                              <AiFillEye
                                onClick={() => setDetails(order)}
                                color='blue' size={30}
                                className='cursor-pointer'
                              />
                            </div>
                          </td>
                          <td className='text-center border-1'> {t(order?.type)} </td>
                          <td className='text-center  border-1'> {order?.delivery_time.begin?.hours}:{order?.delivery_time.begin?.minutes} - {order?.delivery_time.end?.hours}:{order?.delivery_time.end?.minutes} </td>
                          <td className='text-center  border-1'><span className={`p-1.5 text-xs font-bold uppercase tracking-wider rounded-lg ${status_style(order?.sts)}`}>{t(order?.sts)} </span></td>
                          <td className='text-center  border-1'> €{order?.total_cost} </td>


                        </tr>
                      )
                      )
                      
                      }
                  </tbody>
                  
                </table>
                
                <div className='grid grid-cols-1 gap-4 max-h-[450px] overflow-auto sm:hidden bg-gray-100'>
                {today_orders?.map((order, idx) => (
                <div className={`${deliveryTime_Style(order?.delivery_date,order?.delivery_time, order?.sts) ? "space-y-3 p-4 rounded-lg shadow  bg-red-200  font-semibold" : "space-y-3 p-4 rounded-lg max-h-[450px] shadow bg-white"}`} key={idx} >
                
                  <div className='flex items-center justify-between text-sm '>
                    <div className='flex flex-row'>
                      <AiFillDelete color="red" size={30}
                        onClick={() => {
                          setOrderID_del(order?._id)
                          setOpenSubmitDel(true)}}
                        className='cursor-pointer'
                      />
                      <AiFillEye
                        onClick={() => setDetails(order)}
                        color='blue' size={30}
                        className='cursor-pointer'
                      />
                    </div>
                    <div className='flex flex-col text-md space-y-2 mx-2'>
                      <div className='text-gray-500 font-medium text-center'>{get_deliveryDate(order?.delivery_date)}</div>
                      <div className='text-gray-500 font-medium text-center'> {order?.delivery_time.begin?.hours}:{order?.delivery_time.begin?.minutes} - {order?.delivery_time.end?.hours}:{order?.delivery_time.end?.minutes}</div>
                      <div className='text-gray-500 font-medium text-center'>{t(order?.type)}</div>
                    </div>
                    <div><span className={`p-1.5 text-xs font-bold  uppercase tracking-wider rounded-lg ${status_style(order?.sts)}`}>{t(order?.sts)} </span></div>
                  </div>
                  <div className='text-md font-bold text-black'>€{order?.total_cost}</div>
                </div>
                ))}
              </div>
              </>
              }
              </div>
              
            </div>
          : "" }
          {selected==="tomorrow" ?
            <div className='xl:mr-10 mb-12 '>
            <h3 className='text-center font-semibold text-xl my-5'>{t("Your Orders of Tomorrow") + ": " + get_deliveryDate(tomorrow)}</h3>
            <div className='mx-1 rounded-lg shadow my-4 bg-transparent sm:bg-white'>
            {tomorrow_orders.length === 0 ?
              <div className='text-center py-10 bg-white my-10'>{t("No Order for Tomorrow")}</div>
              :
              <>
              <table className="table-auto border-collapse border-gray-400 2xl:whitespace-nowrap w-full overflow-auto max-h-[450px] hidden sm:block">
                <thead>
                  <tr className='items-center justify-between content-center'>
                    <th className='text-center tracking-wide  border-1'>{t("Actions")}</th>
                    <th className='text-center  tracking-wide  border-1'>{t("Type Order")}</th>
                    <th className='text-center  tracking-wide  border-1'>{t("Delivery Time")} </th>
                    <th className='text-center  tracking-wide  border-1'>{t("Status")}</th>
                    <th className='text-center  tracking-wide  border-1'>{t("Total")}</th>

                  </tr>
                </thead>
                                     
                  <tbody className=''>

                    {tomorrow_orders?.map((order, idx) => (
                      <tr className={`content-center h-[30px] ${deliveryTime_Style(order?.delivery_date,order?.delivery_time, order?.sts) ? "bg-red-200  font-semibold" : ""}`} key={idx}>
                        <td className='items-start justify-center mx-auto'>
                          <div className='flex flex-row item-center justify-center '>
                          <AiFillDelete color="red" size={30}
                              onClick={() => {
                                setOrderID_del(order?._id)
                                setOpenSubmitDel(true)}}
                              className='cursor-pointer'
                            />
                            
                            <AiFillEye
                              onClick={() => setDetails(order)}
                              color='blue' size={30}
                              className='cursor-pointer'
                            />
                          </div>
                        </td>
                        <td className='text-center border-1'> {t(order?.type)} </td>
                        <td className='text-center  border-1'> {order?.delivery_time.begin?.hours}:{order?.delivery_time.begin?.minutes} - {order?.delivery_time.end?.hours}:{order?.delivery_time.end?.minutes} </td>
                        <td className='text-center  border-1'><span className={`p-1.5 text-xs font-bold uppercase tracking-wider rounded-lg ${status_style(order?.sts)}`}>{t(order?.sts)} </span></td>
                        <td className='text-center  border-1'> €{order?.total_cost} </td>


                      </tr>
                    )
                    )
                    
                    }
                </tbody>
                
              </table>
              <div className='grid grid-cols-1 gap-4 max-h-[450px] overflow-auto sm:hidden'>
              {tomorrow_orders?.map((order, idx) => (
              <div className='bg-white space-y-3 p-4 rounded-lg shadow'>
              
                <div className='flex items-center justify-between text-sm '>
                  <div className='flex flex-row'>
                    <AiFillDelete color="red" size={30}
                      onClick={() => {
                        setOrderID_del(order?._id)
                        setOpenSubmitDel(true)}}
                      className='cursor-pointer'
                    />
                    <AiFillEye
                      onClick={() => setDetails(order)}
                      color='blue' size={30}
                      className='cursor-pointer'
                    />
                  </div>
                  <div className='flex flex-col text-md space-y-2 mx-2'>
                    <div className='text-gray-500 font-medium text-center'>{get_deliveryDate(order?.delivery_date)}</div>
                    <div className='text-gray-500 font-medium text-center'> {order?.delivery_time.begin?.hours}:{order?.delivery_time.begin?.minutes} - {order?.delivery_time.end?.hours}:{order?.delivery_time.end?.minutes}</div>
                    <div className='text-gray-500 font-medium text-center'>{t(order?.type)}</div>
                  </div>
                  <div><span className={`p-1.5 text-xs font-bold  uppercase tracking-wider rounded-lg ${status_style(order?.sts)}`}>{t(order?.sts)} </span></div>
                </div>
                <div className='text-md font-bold text-black'>€{order?.total_cost}</div>
              </div>
              ))}
            </div>
            </>
            }
            </div>
            
          </div> : ""}
          {selected==="all" ?  
          <div className='xl:mr-10 mb-12 mx-auto w-full'>
            <h3 className='text-center font-semibold text-xl my-5'>{t("Your Orders")}</h3>
            <div className='mx-1  shadow my-4 bg-transparent sm:bg-white' >
            {orders.length === 0 ?
              <div className='text-center bg-white rounded-xl py-10 my-10'>{t("No Orders")}</div>
              :
              <>
              <table className="table-auto border-collapse border-gray-400 2xl:whitespace-nowrap w-full overflow-auto max-h-[450px] hidden sm:block">
                <thead>
                  <tr className='items-center justify-between content-center'>
                    <th className='text-center tracking-wide  border-1'>{t("Actions")}</th>
                    <th className='text-center  tracking-wide border-1'>{t("Date")} </th>
                    <th className='text-center  tracking-wide  border-1'>{t("Type Order")}</th>
                    <th className='text-center  tracking-wide  border-1'>{t("Delivery Date")} </th>
                    <th className='text-center  tracking-wide  border-1'>{t("Delivery Time")} </th>
                    <th className='text-center  tracking-wide  border-1'>{t("Status")}</th>
                    <th className='text-center  tracking-wide  border-1'>{t("Total")}</th>

                  </tr>
                </thead>
                <tbody className=''>
                    {orders?.map((order, idx) => (
                      <tr className='content-center h-[30px]' key={idx}>
                        <td className='items-start justify-center mx-auto'>
                          <div className='flex flex-row item-center justify-center '>
                            <AiFillDelete color="red" size={30}
                              onClick={() => {
                                setOrderID_del(order?._id)
                                setOpenSubmitDel(true)}}
                              className='cursor-pointer'
                            />
                            <AiFillEye
                              onClick={() => setDetails(order)}
                              color='blue' size={30}
                              className='cursor-pointer'
                            />
                          </div>
                        </td>
                        <td className='text-center text-xs border-1'> {get_date(order?.ts)} </td>
                        <td className='text-center border-1 whitespace-nowrap'> {t(order?.type.replace("_"," "))} </td>
                        <td className='text-center  border-1'> {get_deliveryDate(order?.delivery_date)} </td>
                        <td className='text-center  border-1'> {order?.delivery_time.begin?.hours}:{order?.delivery_time.begin?.minutes} - {order?.delivery_time.end?.hours}:{order?.delivery_time.end?.minutes} </td>
                        <td className='text-center  border-1'><span className={`p-1.5 text-xs font-bold  uppercase tracking-wider rounded-lg ${status_style(order?.sts)}`}>{t(order?.sts)} </span></td>
                        <td className='text-center  border-1'> €{order?.total_cost} </td>


                      </tr>
                    ))
                  }
                </tbody>
              </table>
              <div className='grid grid-cols-1 gap-4 max-h-[450px] overflow-auto sm:hidden'>
                {orders?.map((order, idx) => (

                <div className='bg-white space-y-3 p-4 rounded-lg shadow'>
                
                  <div className='flex items-center justify-between  text-sm '>
                    <div className='flex flex-row'>
                      <AiFillDelete color="red" size={30}
                        onClick={() => {
                          setOrderID_del(order?._id)
                          setOpenSubmitDel(true)}}
                        className='cursor-pointer'
                      />
                      <AiFillEye
                        onClick={() => setDetails(order)}
                        color='blue' size={30}
                        className='cursor-pointer'
                      />
                    </div>
                    <div className='flex flex-col text-md space-y-2 mx-2'>
                      <div className='text-gray-500 font-medium text-center'>{get_deliveryDate(order?.delivery_date)}</div>
                      <div className='text-gray-500 font-medium text-center'> {order?.delivery_time.begin?.hours}:{order?.delivery_time.begin?.minutes} - {order?.delivery_time.end?.hours}:{order?.delivery_time.end?.minutes}</div>
                      <div className='text-gray-500 font-medium text-center'>{t(order?.type)}</div>
                    </div>
                    <div><span className={`p-1.5 text-xs font-bold  uppercase tracking-wider rounded-lg ${status_style(order?.sts)}`}>{t(order?.sts)} </span></div>
                  </div>
                  <div className='text-md font-bold text-black'>€{order?.total_cost}</div>
                </div>
                ))}
              </div>
              </>
              }
            </div>
          </div>
          : ""}
        </div>
        :
        <div className='flex flex-col mx-auto mb-6 p-3 shadow-lg rounded-lg bg-gray-100 m-2 w-full'>
           <h3 className='md:text-xl text-lg font-bold my-4 pt-3  text-center'>{ "# " + details._id }</h3>
          <div className='relative'>
            <IoIosArrowBack size={25} className='absolute md:top-[-43px] top-[-73px] left-2 cursor-pointer'
              onClick={() => { 
                setNew_order_sts({...{}});
                setDetails({...{}}) }} />
            <div className='absolute md:top-[-43px] top-[-73px] right-2  text-start '><span className={`p-1.5 text-xs font-bold  uppercase tracking-wider rounded-lg ${status_style(details.sts)}`}>{t(details.sts)} </span></div>
            {Object.entries(new_order_sts).length===0 || new_order_sts.sts===details.sts ?

            <div className='border-b-1 items-center justify-center border-gray-400 mx-auto md:grid md:grid-cols-5 flex flex-col mt-1 md:mt-0'>
              <h3 className='text-md text-center my-4 col-span-5 font-bold  whitespace-nowrap'><span className='bg-teal-300 rounded-xl p-2'>{t(details.type)}</span></h3>
              <h3 className='text-md col-span-3 font-semibold whitespace-nowrap'>{t("Date") + ": " +  get_date(details.ts)}</h3>
              <h3 className='text-md mt-4 col-span-3 font-semibold whitespace-nowrap'>{"Email" + ": " +  details.email}</h3>
              <h3 className='text-md col-span-3 font-semibold whitespace-nowrap'>{t("Phone") + ": " +  (details?.phone || details?.phone?.lenght===0 ? details.phone : "")}</h3>
              <h3 className='text-md font-semibold whitespace-nowrap mt-4 md:mt-0'>{t("Change Status")}</h3>
              <DropdownComp
                  handleOptionSelected={OptionSelected}
                  menu={order_status}
                  variable={"sts"}
                  which_option={"status"}
                  already_selected={t(details.sts)}
              />             
            </div>
            :
            <div className='border-b-1 items-center justify-center border-gray-400 mx-auto md:grid md:grid-cols-5 flex flex-col '>
            <h3 className='text-md col-span-3 font-semibold whitespace-nowrap '>{t("Date") + ": " +  get_date(details.ts)}</h3>
            <DropdownComp
                handleOptionSelected={OptionSelected}
                menu={order_status}
                variable={"sts"}
                which_option={"status"}
                already_selected={t(new_order_sts.sts)}
            />
              <button className='mx-2 bg-green-500 rounded-xl text-center font-bold hover:bg-green-700 flex justify-center hover:text-white mb-3 md:mb-0'
              onClick={()=>{setOpenSubmit(true)}}>{t("Confirm")}</button>
             
          </div>
             }
            <h3 className='text-center font-semibold text-xl my-2'>{t("List of Food")}</h3>
            <div className='md:grid md:grid-cols-2 gap-3 border-b-1 pb-1 my-2 border-gray-400'>
              {details.items.map((dish, idx) =>
                <div key={idx} className='flex-col my-3 mx-1 gap-3 text-sm bg-white rounded-xl shadow-xl p-2'>
                  {dish.custom ?
                    <>
                      <div className='flex-col items-center text-lg font-bold'>{dish.category + " = € " + Number(dish.prezzo).toFixed(2)}</div>
                      <div>
                        {dish.ingredienti.map((ingrediente, idx) =>

                          <p className='flex-col text-start font-thin text-[10px] '>{ingrediente.name} €{Number(ingrediente.price).toFixed(2)} x {ingrediente.quantity} = €{(Number(ingrediente.price) * ingrediente.quantity).toFixed(2)}</p>

                        )}
                      </div>
                    </>
                    :
                    <>
                      <div className='flex-row items-center text-lg font-bold'>{dish.name + " = € " + Number(dish.prezzo).toFixed(2)}</div>
                      <p className='text-start font-thin text-[10px] my-1'>{dish.ingredienti}</p>

                    </>
                  }
                  <h3 className='mx-auto text-center justify-center items-center md:justify-center text-red-500 text-2xl font-bold'>{"€ " + Number(dish.prezzo).toFixed(2) + " x " + dish.quantity + " = € " + (dish.quantity * Number(dish.prezzo)).toFixed(2)}</h3>

                </div>

              )}
              {/*details.type==="Delivery" ? 
              <div className='flex-row items-center text-lg mx-1 font-bold'>{t("Delivery Cost") + " = € " + Number(details.delivery_cost).toFixed(2)}</div>
              :
              ""
                */}
            </div>

            <div className='mx-1 items-center py-3 justify-center flex-col md:flex-row gap-3 md:gap-7 flex my-3  bg-white rounded-xl shadow-xl'>
              <p className='text-center'>{t("Delivery Date")}: <span className='font-bold'>{details.delivery_date.day} {details.delivery_date.monthstr} {details.delivery_date.year}</span></p>
              <p className='text-center'>{t("Time Selected")}: <span className='font-bold'>
                {details.delivery_time.begin.hours}:{details.delivery_time.begin.minutes} - {details.delivery_time.end.hours}:{details.delivery_time.end.minutes}</span></p>
            </div>
            {Object.entries(details.notes).length===0}
            <div className='mx-1 items-center py-3 justify-center flex-col  gap-2  flex my-5  bg-white rounded-xl shadow-xl'>
              <p className='text-center font-bold'>{t("Notes")}:</p>
              <p className='text-center'>{details.notes}</p>
            </div>
          </div>
          {details.type==="Delivery" ?
            <div className='grid grid-cols-1 mx-1 md:grid-cols-2 gap-3 py-2 mt-2 bg-white rounded-xl shadow-xl'>
              <h3 className='text-center'>{t("Address Selected")}: <span className="font-bold ">{details.delivery_address}</span></h3>
              <h3 className="text-center ">{t("City")}: {details.city}</h3>
              <h3 className="text-center "> {t("Country")}: {details.country}</h3>
              <h3 className="text-center "> {t("Postal Code")}: {details.postal_code}</h3>

            </div>
            :
            ""
          }
          <div className='flex md:w-[300px] items-center justify-center mx-auto mb-3 py-1 text-2xl px-1 text-center bg-white rounded-xl font-bold shadow-xl mt-3'>{t("Total")}: €{details.total_cost}</div>

        </div >

      }
      </>
      :
      <>
      {Object.entries(details).length === 0 ?
        <div className='flex mx-auto mb-6 p-3 shadow-lg rounded-lg items-center bg-gray-100 m-2 min-w-[350px] max-w-[750px] max-h-[580px] sm:max-h-[550px] overflow-y-auto'>
          <div className='mb-12 mx-auto'>
            <h3 className='text-center font-semibold text-xl my-5'>{t("Your Table Orders") + ": " + get_deliveryDate(today)}</h3>
            <div className='mx-1 rounded-lg shadow my-4 bg-transparent sm:bg-white'>
              {table_orders.length === 0 ?
                <div className='text-center my-10 py-9'>{t("No Orders Payed")}</div>
                :
                <>
                  <table className="table-auto border-collapse border-gray-400 2xl:whitespace-nowrap w-full overflow-auto max-h-[450px] hidden sm:block">
                    <thead>
                      <tr className='items-center justify-between content-center'>
                        <th className='text-center tracking-wide  border-1'>{t("Actions")}</th>
                        <th className='text-center  tracking-wide  border-1'>{t("Date")}</th>
                        <th className='text-center  tracking-wide  border-1'>{t("Table")}</th>
                        <th className='text-center  tracking-wide  border-1'>{t("Status")}</th>
                        <th className='text-center  tracking-wide  border-1'>{t("Total")}</th>

                      </tr>
                    </thead>

                    <tbody className=''>

                      {table_orders?.map((order, idx) => (
                        <tr className={`content-center h-[30px] "animate-bg font-semibold" `} key={idx}>
                          <td className='items-start justify-center mx-auto'>
                            <div className='flex flex-row item-center justify-center '>
                            <AiFillDelete color="red" size={30}
                              onClick={() => {
                                setOrderID_del(order?._id)
                                setOpenSubmitDel(true)}}
                              className='cursor-pointer'
                            />
                              <AiFillEye
                                onClick={() => setDetails(order)}
                                color='blue' size={30}
                                className='cursor-pointer'
                              />
                            </div>
                          </td>
                          <td className='text-center border-1'> {get_date(order?.ts)} </td>
                          <td className='text-center  border-1'> {order?.tableId} </td>
                          <td className='text-center  border-1'><span className={`p-1.5 text-xs font-bold uppercase tracking-wider rounded-lg ${status_style(order?.sts)}`}>{t(order?.sts)} </span></td>
                          <td className='text-center  border-1'> €{order?.total_cost} </td>


                        </tr>
                      )
                      )

                      }
                    </tbody>

                  </table>

                  <div className='grid grid-cols-1 gap-4 max-h-[450px] overflow-auto sm:hidden bg-gray-100 border-none'>
                    {table_orders?.map((order, idx) => (
                      <div className="space-y-3 p-4 rounded-lg shadow bg-white" key={idx} >

                        <div className='flex items-center justify-between text-sm '>
                          <div className='flex flex-row'>
                            <AiFillDelete color="red" size={30}
                              onClick={() => {
                                setOrderID_del(order?._id)
                                setOpenSubmitDel(true)}}
                              className='cursor-pointer'
                            />
                            <AiFillEye
                              onClick={() => setDetails(order)}
                              color='blue' size={30}
                              className='cursor-pointer'
                            />
                          </div>
                          <div><span className={`p-1.5 text-xs font-bold  uppercase tracking-wider rounded-lg ${status_style(order?.sts)}`}>{t(order?.sts)} </span></div>
                        </div>
                        <div className='flex items-center justify-between text-sm '>
                          <div className='text-md font-bold text-black'>€{order?.total_cost}</div>
                          <div className='text-md font-bold text-black'>{get_date(order?.ts)}</div>
                          <div className='text-md font-bold text-black'>{t("Table") + ": " + order?.tableId}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              }
            </div>

          </div>
        </div>
        : 
        <div className='flex flex-col mx-auto mb-6 p-3 shadow-lg rounded-lg bg-gray-100 m-2 w-full'>
           <h3 className='md:text-xl text-lg font-bold my-4 pt-3  text-center'>{ "# " + details._id }</h3>
          <div className='relative'>
            <IoIosArrowBack size={25} className='absolute md:top-[-43px] top-[-73px] left-2 cursor-pointer'
              onClick={() => { 
                setNew_order_sts({...{}});
                setDetails({...{}}) 
                setOptionSelelected("table_order")}} />
            <div className='absolute md:top-[-43px] top-[-73px] right-2  text-start '><span className={`p-1.5 text-xs font-bold  uppercase tracking-wider rounded-lg ${status_style(details.sts)}`}>{t(details.sts)} </span></div>
            {Object.entries(new_order_sts).length===0 || new_order_sts.sts===details.sts ?

            <div className='border-b-1 items-center justify-center border-gray-400 mx-auto md:grid md:grid-cols-5 flex flex-col mt-1 md:mt-0'>
             <h3 className='text-md text-center my-4 col-span-5 font-bold  whitespace-nowrap'><span className='bg-teal-300 rounded-xl p-2'>{t(details.type)}</span></h3>
              <h3 className='text-md col-span-3 font-semibold whitespace-nowrap'>{t("Date") + ": " +  get_date(details.ts)}</h3>
              <h3 className='text-md mt-4 col-span-3 font-semibold whitespace-nowrap'>{"Email" + ": " +  details.email}</h3>
              <h3 className='text-md col-span-3 font-semibold whitespace-nowrap'>{t("Phone") + ": " +  (details?.phone || details?.phone?.lenght===0 ? details.phone : "")}</h3>
              <h3 className='text-md font-semibold whitespace-nowrap mt-4 md:mt-0'>{t("Change Status")}</h3>
              <DropdownComp
                  handleOptionSelected={OptionSelected}
                  menu={order_status}
                  variable={"sts"}
                  which_option={"status"}
                  already_selected={t(details.sts)}
              />             
            </div>
            :
            <div className='border-b-1 items-center justify-center border-gray-400 mx-auto md:grid md:grid-cols-5 flex flex-col '>
            <h3 className='text-md col-span-3 font-semibold whitespace-nowrap '>{t("Date") + ": " +  get_date(details.ts)}</h3>
            <DropdownComp
                handleOptionSelected={OptionSelected}
                menu={order_status}
                variable={"sts"}
                which_option={"status"}
                already_selected={t(new_order_sts.sts)}
            />
              <button className='mx-2 bg-green-500 rounded-xl text-center font-bold hover:bg-green-700 flex justify-center hover:text-white mb-3 md:mb-0'
              onClick={()=>{setOpenSubmit(true)}}>{t("Confirm")}</button>
             
          </div>
             }
            <h3 className='text-center font-semibold text-xl my-2'>{t("List of Food")}</h3>
            <div className='md:grid md:grid-cols-2 gap-3 border-b-1 pb-1 my-2 border-gray-400'>
              {details.items.map((dish, idx) =>
                <div key={idx} className='flex-col my-3 mx-1 gap-3 text-sm bg-white rounded-xl shadow-xl p-2'>
                  {dish.custom ?
                    <>
                      <div className='flex-col items-center text-lg font-bold'>{dish.category + " = € " + Number(dish.prezzo).toFixed(2)}</div>
                      <div>
                        {dish.ingredienti.map((ingrediente, idx) =>

                          <p className='flex-col text-start text-sm text-[10px] '>{ingrediente.name} €{Number(ingrediente.price).toFixed(2)} x {ingrediente.quantity} = €{(Number(ingrediente.price) * ingrediente.quantity).toFixed(2)}</p>

                        )}
                      </div>
                    </>
                    :
                    <>
                      <div className='flex-row items-center text-lg font-bold'>{dish.name + " = € " + Number(dish.prezzo).toFixed(2)}</div>
                      <p className='text-start text-sm my-1'>{dish.ingredienti}</p>

                    </>
                  }
                  <h3 className='mx-auto text-center justify-center items-center md:justify-center text-red-500 text-2xl font-bold'>{"€ " + Number(dish.prezzo).toFixed(2) + " x " + dish.quantity + " = € " + (dish.quantity * Number(dish.prezzo)).toFixed(2)}</h3>

                </div>

              )}
            </div>
            <div className='mx-1 items-center py-3 justify-center flex-col  gap-2  flex my-5  bg-white rounded-xl shadow-xl'>
              <p className='text-center font-bold'>{t("Table Details")}</p>
              <p className='text-center font-semibold'>{t("Table") +": " + details.tableId}</p>
              <p className='text-center font-semibold'>{t("User") +": " + details.userId}</p>
            </div>
            <div className='mx-1 items-center py-3 justify-center flex-col  gap-2  flex my-5  bg-white rounded-xl shadow-xl'>
              <p className='text-center font-bold'>{t("Notes")}:</p>
              <p className='text-center'>{details.notes}</p>
            </div>
            <div className='flex w-[300px] items-center justify-center mx-auto mb-3 py-1 text-2xl px-1 text-center bg-white rounded-xl font-bold shadow-xl mt-3'>{t("Total")}: €{details.total_cost}</div>
          </div>
          </div>
        }
        </>
      }
      <DeleteModalUser open={openSubmit} onclose={() => { setOpenSubmit(false) }}>
        <div className='text-center w-56 items-center justify-center flex-col'>
          <AiOutlineUpload className="mx-auto" size={56} color="green" />
          <div>
            <h3 className='text-lg font-black text-gray-800'> {t("Confirm Update The Order Status")}</h3>
            <p className='text-sm text-gray-600'>{t("Are you sure you want to update The Order Status")}?
            </p>
          </div>
          <div className='w-full items-center justify-center flex mt-3'>
            <button
              className='flex bg-green-400 rounded-xl mx-1 hover:bg-green-600  hover:text-gray-100 w-auto'
              onClick={()=>{handleSubmit("sts")}}>{t("Update")}</button>
            <button
              className='flex bg-gray-200 rounded-xl mx-1 hover:bg-gray-400 hover:text-gray-100 w-auto text-center'
              onClick={() => setOpenSubmit(false)}>{t("Cancel")}</button>
          </div>
        </div>
      </DeleteModalUser>
      <DeleteModalUser open={openSubmitDel} onclose={() => { setOpenSubmitDel(false) }}>
        <div className='text-center w-56 items-center justify-center flex-col'>
          <AiFillDelete className="mx-auto" size={56} color="red" />
          <div>
            <h3 className='text-lg font-black text-gray-800'> {t("Confirm Delete Order")}</h3>
            <p className='text-sm text-gray-600'>{t("Are you sure you want to delete The Order: ") + orderID_del}?
            </p>
          </div>
          <div className='w-full items-center justify-center flex mt-3'>
            <button
              className='flex bg-red-400 rounded-xl mx-1 hover:bg-red-600  hover:text-gray-100 w-auto'
              onClick={()=>{delete_order()}}>{t("Delete")}</button>
            <button
              className='flex bg-gray-200 rounded-xl mx-1 hover:bg-gray-400 hover:text-gray-100 w-auto text-center'
              onClick={() => setOpenSubmitDel(false)}>{t("Cancel")}</button>
          </div>
        </div>
      </DeleteModalUser>
    </div >
  )
}

export default Orders
import React, { createContext, useState, useEffect, useContext} from 'react'
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import {LoginDelyContext} from './LoginDelyContext';
import { useTranslation } from 'react-i18next';


export const MenuContext = createContext({
    qrtables: [],
    menus: [],
    customDish : [],
    list_category: [],
    ingredients: [],
    fetchMenu: () => {},
    fetchIngredients: () => {},
    deleteCategory: () => {},
    DeleteCategory_ingredients: () => {},
    UpdateDish: () => {},
    deleteDish: () => {},
    replaceDish: () => {},
    clientfetchMenu: () => {},
    getProductData: () => {},
    stateMenu: () => {},
    stateCategory: () => {},

    stateCastomDish: () => {},
    stateIngredients: () => {},
    fetchAddIns: () => {},
    createNewCustomDish: () => {},
    DeleteCustomDish: () => {},
    DeleteCategory_CD: () => {},
    CreateNewDish: () => {},
    CreateDish_CD: () => {},
    UpdateDish_CD: () => {},
    CD_to_Ingredients: () => {},
    DeleteDish_CD: () => {},

    getAllQrTables : () => {},
    delQrTable: () => {},
    postNewQrTable: () => {},
    postUpdtQrTable: () => {},
    
})

export function MenuProvider({children}){
  const {t} = useTranslation();
  const User = useContext(LoginDelyContext)
  const settings = User.page_info
  const hmi_CL_info = User.hmi_CL_info
  console.log(settings)
  const restaurant_id = settings.user_id 
  const [category, setCategory] = useState(()=>{
    const saved = localStorage.getItem("list_category");
    const initialValue = JSON.parse(saved);
    return initialValue || []
  })
  const [menu, setMenu] = useState([])
  const [qrtables, setQrtables] = useState([])
  const [customDish, setCustomDish] = useState([])
  const [ingredients, setIngredients] = useState([])
  
  const navigate = useNavigate();
  
  useEffect(()=>{
    window.localStorage.setItem("list_category", JSON.stringify(category))
  },[category])
  

  const fetchMenu = async (rest_id) =>{
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    console.log(rest_id)
    let body={}
    let data = []
    if (rest_id.length>0){
      body = JSON.stringify({restaurant_id: rest_id})
    }else{
      body = JSON.stringify({restaurant_id: restaurant_id})
    }
    const res = await fetch(backend + '/api/', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body
    });

    data = await res.json();
    console.log(data)
    if (res.status === 200) {
      console.log(data);
      const response = {
        stat: res.status,
        data: data
      }
      setMenu(data)
      const new_categories = category

      if (new_categories.length===0 || !new_categories.some(ingre=>ingre.ingredient==="menu")){
        new_categories.push({"ingredient": "menu", "category":[]})
      }

      data.map(async categ=>{
        
        if (new_categories.find(ingre=>ingre.ingredient==="menu").category.some(category=>category.name===categ.name)){
          return
        }else{
          await new_categories.find(ingre=>ingre.ingredient==="menu").category.push({name:categ.name})
        }
      })
      stateCategory(new_categories)
      
      return data;
    } else {
      console.log(data);
      setMenu([])
      const response = {
        stat: res.status,
        data: data
      }
      return data;

    }
  }


  const fetchIngredients = async (rest_id,option=null, cd_id) =>{
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    console.log(rest_id)
    let body={}
    let data = []
    if (rest_id.length>0){
      body = JSON.stringify({restaurant_id: rest_id, cd_name: option, cd_id:cd_id})
    }else{
      body = JSON.stringify({restaurant_id: restaurant_id, cd_name: option, cd_id:cd_id})
    }
    const res = await fetch(backend + '/api/ingredients/get', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body
    });

    data = await res.json();
    console.log(data)
    if (option===null || option==="ingredients"){
      if (res.status === 200) {
        console.log(data);
        const response = {
          stat: res.status,
          data: data
        }
        
        setIngredients(data)
        const new_categories = category

        if (new_categories.length===0 || !new_categories.some(ingre=>ingre.ingredient==="menu")){
          new_categories.push({"ingredient": "ingredients", "category":[]})
        }

        data.map(async categ=>{
          
          if (new_categories.find(ingre=>ingre.ingredient==="ingredients").category.some(category=>category.name===categ.name)){
            return
          }else{
            await new_categories.find(ingre=>ingre.ingredient==="ingredients").category.push({name:categ.name})
          }
        })
        stateCategory(new_categories)
        
        return data;
      } else {
        console.log(data);
        setIngredients([])
        const response = {
          stat: res.status,
          data: data
        }
        return response;

      }
    }else{
      if (res.status === 200) {
        console.log(data);
        const response = {
          stat: res.status,
          data: data
        }
        const newcustomDish = customDish.slice()
        console.log(cd_id)
        if (cd_id.length>0){
          newcustomDish[0].dish_list.find(dish=>dish._id===cd_id)["ingredients"] = data
        }
        stateCastomDish(newcustomDish)
        return response;
      } else {
        console.log(data);
        setCustomDish([])
        const response = {
          stat: res.status,
          data: data
        }
        return response;

      }
    }
  }

  const CreateNewDish = async(newdish) => {
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    const body = JSON.stringify({newdish: newdish, restaurant_id: restaurant_id});
    
    const res = await axios.post(backend + '/api/category/new-dish', body, 
    {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        //'X-CSRFTOKEN': csrfCookie,
        
      },
      
    }).catch(err =>{console.log(err) 
      return err.response});
   
    //const data = await res.json();
    const data = await res.data
    if (res.status === 200) {

      const response = {
        stat: res.status,
        data: data
      }
      fetchMenu(restaurant_id)
      return response;
    } else {

      const response = {
        stat: res.status,
        data: data
      } 
      fetchMenu(restaurant_id)
      return response;
    }

  }

  const UpdateDish = async(dish_name, newdish) => {
    const body = JSON.stringify({old_dish_name: dish_name, newdish: newdish, restaurant_id: restaurant_id});
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    const res = await axios.post(backend + '/api/category/update-dish', body, 
    {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        //'X-CSRFTOKEN': csrfCookie,
        
      },
      
    }).catch(err =>{console.log(err) 
      return err.response});
   
    //const data = await res.json();
    const data = await res.data
    if (res.status === 200) {

      const response = {
        stat: res.status,
        data: data
      }
      fetchMenu(restaurant_id)
      return response;
    } else {

      const response = {
        stat: res.status,
        data: data
      }
      fetchMenu(restaurant_id)
      return response;
    }

  }

  const replaceDish = async(newdish) => {
    const body = JSON.stringify({newdish: newdish, restaurant_id: restaurant_id});
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    const res = await axios.post(backend + '/api/category/replace-dish', body, 
    {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        //'X-CSRFTOKEN': csrfCookie,
        
      },
      
    }).catch(err =>{console.log(err) 
      return err.response});
   
    //const data = await res.json();
    const data = await res.data

    if (res.status === 200) {

      const response = {
        stat: res.status,
        data: data
      }
      fetchMenu(restaurant_id)
      return response;
    } else {

      const response = {
        stat: res.status,
        data: data
      }
      fetchMenu(restaurant_id)
      return response;
    }

  }


  const deleteDish = async(deletedish) => {
    const body = JSON.stringify({deletedish: deletedish, restaurant_id: restaurant_id});
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    const res = await axios.post(backend + '/api/category/del-dish', body, 
      {
        withCredentials: true,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          //'X-CSRFTOKEN': csrfCookie,
          
        },
        
      }).catch(err =>{console.log(err) 
        return err.response});
     
      //const data = await res.json();
      const data = await res.data
    if (res.status === 200) {

      const response = {
        stat: res.status,
        data: data
      }
      fetchMenu(restaurant_id)
      return response;
    } else {

      const response = {
        stat: res.status,
        data: data
      } 
      fetchMenu(restaurant_id)
      return response;
    }

  }

  


  
  const clientfetchMenu = async (restaurant_id) =>{
    const body = JSON.stringify({restaurant_id: restaurant_id})
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    const res = await fetch(backend + '/api/', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body
    });

    const data = await res.json();
    console.log(data)
    if (res.status === 200) {
      console.log(data);
      const response = {
        stat: res.status,
        data: data
      }
      setMenu(data)
      return data;
    } else {
      console.log(data);
      setMenu([])
      const response = {
        stat: res.status,
        data: data
      }
      return data;

    }
  }


  useEffect(()=>{
    if (restaurant_id.length>0){
      console.log(restaurant_id)
      fetchAddIns(restaurant_id);
      fetchMenu(restaurant_id);
    }
  },[restaurant_id])
 
  const fetchAddIns = async (rest_id) =>{
    if (!rest_id || rest_id.length===0) {
      stateCastomDish([])
    }else{
      let backend = ""
      if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
        backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
      }
      console.log(rest_id)
      let body={}
      if (rest_id.length>0){
        body = JSON.stringify({restaurant_id: rest_id})
      }else{
        body = JSON.stringify({restaurant_id: restaurant_id})
      }
      const res = await fetch(backend + '/api/all_custom_dish', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body
      });

      const data = await res.json();
      console.log(data)
      if (res.status === 200) {
        const response = {
          stat: res.status,
          data: data
        }
        stateCastomDish(data)
        return response;

      } else {
        console.log(data);
        const response = {
          stat: res.status,
          data: data
        }
        return response;

      }
    }
  }


  const createNewCustomDish = async(cd_name) => {
    const body = JSON.stringify({cd_name: cd_name, restaurant_id: restaurant_id});
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    const res = await axios.post(backend + '/api/custom_dish/create_new_cd', body, 
    {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        //'X-CSRFTOKEN': csrfCookie,
        
      },
      
    }).catch(err =>{console.log(err) 
      return err.response});
   
    //const data = await res.json();
    const data = await res.data
    console.log(data)
    if (res.status === 200) {
      console.log(restaurant_id)
      fetchAddIns(restaurant_id)

      const response = {
        stat: res.status,
        data: cd_name + " " + t("Upload Successfully"),
      } 
      return response;
    } else{
      let mess = "";

      if(data[0]===0) {mess = "Already Exist"}
      else            {mess = "error"}

      const response = {
        stat: res.status,
        data: cd_name + " " + t(mess),
      } 
      fetchAddIns(restaurant_id)
      return response;
    }

  }

  const DeleteCustomDish = async(dish_tb_deleted) => {
    const body = JSON.stringify({cd_details: dish_tb_deleted, restaurant_id: restaurant_id});
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    const res = await axios.post(backend + '/api/custom_dish/del', body, 
    {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        //'X-CSRFTOKEN': csrfCookie,
        
      },
      
    }).catch(err =>{console.log(err) 
      return err.response});
   
    //const data = await res.json();
    const data = await res.data

    if (res.status === 200) {

      const response = {
        stat: res.status,
        data: data
      } 
      fetchAddIns(restaurant_id)
      return response;
    } else {

      const response = {
        stat: res.status,
        data: data
      } 
      fetchAddIns(restaurant_id)
      return response;
    }

  }

  const DeleteCategory_CD = async(cd_name,category) => {
    const body = JSON.stringify({cd_name: cd_name, category:category, restaurant_id: restaurant_id});
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    const res = await axios.post(backend + '/api/custom_dish/del', body, 
    {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        //'X-CSRFTOKEN': csrfCookie,
        
      },
      
    }).catch(err =>{console.log(err) 
      return err.response});
   
    //const data = await res.json();
    const data = await res.data

    if (res.status === 200) {

      const response = {
        stat: res.status,
        data: data
      } 
      fetchAddIns(restaurant_id)
      return response;
    } else {

      const response = {
        stat: res.status,
        data: data
      } 
      fetchAddIns(restaurant_id)
      return response;
    }

  }

  const DeleteCategory_ingredients = async(category) => {
    const body = JSON.stringify({category:category, restaurant_id: restaurant_id});
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    const res = await axios.post(backend + '/api/ingredients/del', body, 
    {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        //'X-CSRFTOKEN': csrfCookie,
        
      },
      
    }).catch(err =>{console.log(err) 
      return err.response});
   
    //const data = await res.json();
    const data = await res.data

    if (res.status === 200) {

      const response = {
        stat: res.status,
        data: data
      } 
      fetchIngredients(restaurant_id)
      return response;
    } else {

      const response = {
        stat: res.status,
        data: data
      } 
      fetchIngredients(restaurant_id)
      return response;
    }

  }


  const CreateDish_CD = async(new_cd,cd_name) => {
    const body = JSON.stringify({new_cd: new_cd, cd_name: cd_name, restaurant_id: restaurant_id});
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    console.log(body)
    const res = await axios.post(backend + '/api/custom_dish/new-ingr-cd', body, 
    {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        //'X-CSRFTOKEN': csrfCookie,
        
      },
      
    }).catch(err =>{console.log(err) 
      return err.response});
   
    //const data = await res.json();
    const data = await res.data

    if (res.status === 200) {

      const response = {
        stat: res.status,
        data: data
      }
      fetchAddIns(restaurant_id)
      return response;
    } else {

      const response = {
        stat: res.status,
        data: data
      } 
      return response;
    }

  }


  const UpdateDish_CD = async(old_cd_name, new_cd, cd_name) => {
    const body = JSON.stringify({old_cd_name: old_cd_name, new_cd: new_cd, cd_name: cd_name, restaurant_id: restaurant_id});
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    const res = await axios.post(backend + '/api/custom_dish/update-ingr-cd', body, 
    {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        //'X-CSRFTOKEN': csrfCookie,
        
      },
      
    }).catch(err =>{console.log(err) 
      return err.response});
   
    //const data = await res.json();
    const data = await res.data

    if (res.status === 200) {

      const response = {
        stat: res.status,
        data: data
      }
      fetchAddIns(restaurant_id)
      return response;
    } else {

      const response = {
        stat: res.status,
        data: data
      } 
      return response;
    }

  }

  const CD_to_Ingredients = async(restaurant_id,ingr_list_id, cd_id,) => {
    const body = JSON.stringify({ingr_list_id: ingr_list_id, cd_id: cd_id, restaurant_id: restaurant_id});
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    const res = await axios.post(backend + '/api/custom_dish/cd_to_ingr', body, 
    {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        //'X-CSRFTOKEN': csrfCookie,
        
      },
      
    }).catch(err =>{console.log(err) 
      return err.response});
   
    //const data = await res.json();
    const data = await res.data

    if (res.status === 200) {

      const response = {
        stat: res.status,
        data: data
      }
      fetchAddIns(restaurant_id)
      fetchIngredients(restaurant_id)
      return response;
    } else {

      const response = {
        stat: res.status,
        data: data
      } 
      return response;
    }

  }

  

  const DeleteDish_CD = async(delete_cd, cd_name) => {
    const body = JSON.stringify({delete_cd: delete_cd, cd_name: cd_name, restaurant_id: restaurant_id});
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    const res = await axios.post(backend + '/api/custom_dish/del-ingr-cd', body, 
    {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        //'X-CSRFTOKEN': csrfCookie,
        
      },
      
    }).catch(err =>{console.log(err) 
      return err.response});
   
    //const data = await res.json();
    const data = await res.data

    if (res.status === 200) {

      const response = {
        stat: res.status,
        data: data
      }
      fetchAddIns(restaurant_id)
      return response;
    } else {

      const response = {
        stat: res.status,
        data: data
      } 
      return response;
    }

  }
 

  const deleteCategory = async(category) => {
    const body = JSON.stringify({category: category, restaurant_id: restaurant_id});
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    const res = await axios.post(backend + '/api/category/del', body, 
    {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        //'X-CSRFTOKEN': csrfCookie,
        
      },
      
    }).catch(err =>{console.log(err) 
      return err.response});
   
    //const data = await res.json();
    const data = await res.data

    if (res.status === 200) {

      const response = {
        stat: res.status,
        data: data
      } 
      return response;
    } else {

      const response = {
        stat: res.status,
        data: data
      } 
      return response;
    }

  }

  
  const getAllQrTables = async(restaurant_id) =>{
    console.log(restaurant_id)
    const body = JSON.stringify({restaurant_id:restaurant_id});
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    const res = await axios.patch(backend + '/api/talbeQR-info', body,
    {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        //'X-CSRFTOKEN': csrfCookie,
        
      },
      
    }).catch(err =>{console.log(err) 
      return err.response});
   
    //const data = await res.json();
    const data = await res.data
    console.log(data)
    if (res.status === 201) {
      setQrtables(data)

      const response = {
        stat: res.status,
        data: data
      } 

      return response;
    } else {

      const response = {
        stat: res.status,
        data: data
      } 
      return response;
    }


  }

  const delQrTable = async (table_Id,restaurant_id) => {
    const body =  JSON.stringify({table_Id: table_Id, restaurant_id:restaurant_id});
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    const res = await fetch(backend + '/api/talbeQR-info',
    {
      withCredentials: true,
      method:"DELETE",
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        //'X-CSRFTOKEN': csrfCookie,
        
      },
      body, 
    }).catch(err =>{console.log(err) 
      return err.response});
   
    const data = await res.json();

    if (res.status === 202) {

      getAllQrTables(restaurant_id);
      const response = {
        stat: res.status,
        data: data
      } 
      return response;
    } else {

      const response = {
        stat: res.status,
        data: data
      } 
      return response;
    }
  }

  const postNewQrTable = async(table_Id,restaurant_id) => {
    const body =  JSON.stringify({table_Id: table_Id, restaurant_id:restaurant_id});
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    const res = await axios.post(backend + '/api/talbeQR-info', body, 
    {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        //'X-CSRFTOKEN': csrfCookie,
        
      },
      
    }).catch(err =>{console.log(err) 
      return err.response});
   
    //const data = await res.json();
    const data = await res.data

    if (res.status === 201) {

      getAllQrTables(restaurant_id);
      const response = {
        stat: res.status,
        data: data
      } 
      navigate("/dash/manager-dash/table-waiters")

      return response;
    } else {

      const response = {
        stat: res.status,
        data: data
      } 
      return response;
    }

  }
  
  const postUpdtQrTable = async(table_Id,restaurant_id) => {
    const body =  JSON.stringify({table_Id: table_Id, restaurant_id:restaurant_id});
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    const res = await axios.put(backend + '/api/talbeQR-info', body, 
    {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        //'X-CSRFTOKEN': csrfCookie,
        
      },
      
    }).catch(err =>{console.log(err) 
      return err.response});
   
    //const data = await res.json();
    const data = await res.data

    if (res.status === 201) {

      const response = {
        stat: res.status,
        data: data
      } 
      navigate("/dash/manager-dash/table-waiters")
      return response;
    } else {

      const response = {
        stat: res.status,
        data: data
      } 
      return response;
    }
  }


  const getProductData = (item)=>{

    let key = menu.map(x=> x.name).indexOf(item.category)

    let productData = menu[key].dish_list.find(product => product.name === item.name)
  
  
    if(productData===undefined){
      console.log("product data does not exist for name: "+ item.name);
      return undefined;
    }
    
    return productData
  
  }


  const getAddInsData = async (item,customdish)=>{
    console.log(item)
    console.log(hmi_CL_info.user_id)
    console.log(customDish)
    console.log(customdish)
    
    const addInsData = await customdish.ingredients?.find(categ=>categ.name===item.category).ingredient_list?.find(product=>product.name===item.name)

    if(addInsData===undefined){
      console.log("product data does not exist for name: "+ item.name);
      return undefined;
    }

    console.log(addInsData)
    
    return addInsData
  
  }

  const stateMenu = (menu) =>{
    setMenu([...menu])
  }

  const stateCastomDish = (addins) =>{
    setCustomDish([...addins])
  }

  const stateCategory = (categories) =>{
    setCategory([...categories])
  }


  const stateIngredients = (addins) =>{
    setIngredients([...addins])
  }
  




  const contextValue = {
    qrtables: qrtables,
    menus: menu,
    customDish: customDish,
    ingredients: ingredients,
    list_category:category,
    clientfetchMenu,
    fetchMenu,
    fetchIngredients,
    deleteCategory,
    DeleteCategory_ingredients,
    CreateNewDish,
    UpdateDish,
    deleteDish,
    replaceDish,

    getProductData,
    getAddInsData,
    getAllQrTables,
    delQrTable,
    postNewQrTable,
    postUpdtQrTable,

    stateMenu,
    stateCategory,
    stateCastomDish,
    stateIngredients,

    fetchAddIns,
    createNewCustomDish,
    DeleteCustomDish,
    DeleteCategory_CD,
   
    CreateDish_CD,
    UpdateDish_CD,
    CD_to_Ingredients,
    DeleteDish_CD,
    
  }

  console.log(contextValue.customDish)
 

  return(
    <MenuContext.Provider value={contextValue}>
      {children}
    </MenuContext.Provider>
  )
}

export default MenuProvider



import React, {useContext, useEffect}from 'react'
import MenuContext from '../contexts/MenuContext'

const HMI_CS = () => {

  useEffect(()=>{
 
  },[]);
  
  const test = useContext(MenuContext)
  const testName = test.map(a => a.name);

 

  console.log(testName)
  console.log(testName[0])
  return (
    <div className=''>HMI_CS {testName[0]}</div>
  )
}

export default HMI_CS
import React, { useContext, useState } from 'react'
import User_Card from './User_Card'
import { useTranslation } from 'react-i18next';
import i18next from 'i18next'
import {LoginDelyContext} from '../../contexts/LoginDelyContext'


const Header_dash = () => {
  const User = useContext(LoginDelyContext)
  const user = User.restaurant_info
  const {t} = useTranslation()
  const status = user.sub_status
  const type = user.sub_type.split("_")[0]


  return (

    <div className={`${type==="premium" ? "bg-orange-50" : "bg-teal-50"} h-20 px-4 flex items-center border-b border-gray-200 justify-between`}>
      
          <div className='ml-[50px] items-center justify-center'>
            <button
              className={`text-gray-900  bg-teal-500 border-1 border-gray-400 focus:outline-none hover:bg-teal-700 hover:text-white focus:ring-4 focus:ring-gray-600  focus:text-white font-semibold rounded-r px-3 ${i18next.language==="en"?"ring-gray-600 text-white bg-teal-700":""}` }
              onClick={() => i18next.changeLanguage("en")}>English</button>
            <button
              className={`text-gray-900  bg-teal-500 border-1 border-gray-400 focus:outline-none hover:bg-teal-700 hover:text-white focus:ring-4 focus:ring-gray-600  focus:text-white font-semibold rounded-l px-3 ${i18next.language==="it"?"ring-gray-600 text-white bg-teal-700":""}` }
              onClick={() => i18next.changeLanguage("it")}>Italiano</button>
          </div>
        {/*Search bar*/}
        {/*<div className='bg-gray-200 mx-auto rounded-full flex items-center px-2 w-[170px] md:w-[300px] lg:w-[400px]'>
          <AiOutlineSearch size={25} />
          <input className='bg-transparent p-2 focus:outline-none' type="text" placeholder='Search Topics' />
        </div>*/}
        

        <User_Card />

    </div>


  )
}

export default Header_dash
import styles from "../../style";
import { arrowUp } from "../../data";
import {Navigate , useNavigate} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const GetStarted = () => {
  const navigate = useNavigate();
  const {t} = useTranslation()
  return(
  <div className={`${styles.flexCenter} w-[140px] h-[140px] rounded-full bg-gradient-to-r from-green-400 to-fuchsia-500 p-[2px] cursor-pointer `}
  onClick={()=>{navigate("/login-register")}}>
    <div className={`${styles.flexCenter} flex-col bg-teal-700 w-[98%] h-[98%] rounded-full`}>
      <div className={`${styles.flexStart} flex-row`}>
        <p className="font-poppins font-medium text-[18px] leading-[23.4px]">
          <span className="text-transparent font-semibold bg-clip-text bg-gradient-to-r from-green-400 to-fuchsia-500">{t("Get")}</span>
        </p>
        <img src={arrowUp} alt="arrow-up" className=" w-[23px] h-[23px] object-contain" />
      </div>
      
      <p className="font-poppins font-medium text-[18px] leading-[23.4px]">
        <span className="text-transparent font-semibold bg-clip-text bg-gradient-to-r from-green-400 to-fuchsia-500">{t("Started")}</span>
      </p>
    </div>
  </div>
);
}
export default GetStarted;

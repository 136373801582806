import React , {useContext, useState} from 'react'
import { HMICLContext } from '../../contexts/HMICLContext'
import { useTranslation } from 'react-i18next';
import TablePic from '../../data/table.png';
import {AiFillDelete} from 'react-icons/ai';
import { LoginDelyContext } from '../../contexts/LoginDelyContext'
import { DeleteModalUser } from '../../page/Dashboard';




const TableCard = (props) => {
  const HMICLParams = useContext(HMICLContext)
  const orders = props.orders
  const table = props.table
  const manager = props.manager
  const User = useContext(LoginDelyContext)
  const {t} = useTranslation()
  const settings=User.page_info
  const user = User.restaurant_info
  const type = user.sub_type.split("_")
  const [show, setShow] = useState(false)
  const params = useContext(HMICLContext)
  const CLParams = params.HMI_CL_Params
  const restaurant_id = settings.user_id
  const [openSubmit, setOpenSubmit] = useState(false)
  let selectedStyle = ""
  
  const handleClick = (e) => {
    e.preventDefault();
    setShow(true)
    props.handleDetails({selected: show, selected_table: table})
    
  }

  if (orders?.length !==0){
    const dishList = []
    orders?.map( 
      users=>{
        dishList.push(users.dishes)
      })
      console.log(dishList)
      console.log( dishList.some(dish =>dish.sts !=="paid"))
     if( dishList.some(dish =>dish.sts !=="paid")){
          selectedStyle="bg-orange-200"
        }else{
          selectedStyle="bg-green-200"
        }
      }else{
    selectedStyle="bg-gray-200"
  }

  const handledelete = (e) =>{

    e.preventDefault();
    const res = params.deleteTable(table,restaurant_id).then((response)=>{
      if (response.status === 202){
        HMICLParams.getAllTablesSorted(restaurant_id).then(async(res)=>{

          const resp = await  res.data
          //HMICLParams.stateall_tables(resp)
          props.getListTableDishes()
          setOpenSubmit(false)
      })
    }})
    console.log(res)
  }


  return (
    <div>

      <div key={table} className={`relative max-h-[250px] h-[200px] border rounded-lg shadow-lg hover:scale-105 duration-300 cursor-pointer ${selectedStyle}`} >
        <button className='absolute flex top-0 right-[-3px] z-30'
          onClick={()=>{setOpenSubmit(true)}}>
          <AiFillDelete className='w-8 h-8' color='red' />
        </button>
        <div
          onClick={handleClick}>
          <img src={TablePic} className='mx-auto  h-[200px] rounded-t-lg opacity-40 z-0' />
          <div className='absolute inset-0 '>
            <p className='w-full text-center font-bold text-2xl mt-1'>{t("Table")} {table}</p>

            <div className='grid grid-cols-2 items-center justify-center my-4 gap-1'>
              {orders?.map((user_id, idx) =>

                <div key={idx} className='mx-auto font-semibold text-lg'>{user_id.user_id}</div>

              )}
            </div>
          </div>
        </div>
      </div>
      <DeleteModalUser open={openSubmit} onclose={() => { setOpenSubmit(false) }}>
        <div className='text-center w-56 items-center justify-center flex-col'>
          <AiFillDelete className="mx-auto" size={56} color="red" />
          <div>
            <h3 className='text-lg font-black text-gray-800'> {t("Confirm deletion of the Table")}</h3>
            <p className='text-sm text-gray-600'>{t("Are you sure you want to delete the Table information? You cannot retrieve the information anymore")}
            </p>
          </div>
          <div className='w-full items-center justify-center flex mt-3'>
            <button
              className='flex bg-red-400 rounded-xl mx-1 hover:bg-red-600  hover:text-gray-100 w-auto'
              onClick={handledelete}>{t("Delete")}</button>
            <button
              className='flex bg-gray-200 rounded-xl mx-1 hover:bg-gray-400 hover:text-gray-100 w-auto text-center'
              onClick={() => setOpenSubmit(false)}>{t("Cancel")}</button>
          </div>
        </div>
      </DeleteModalUser>

    </div>      
  )
}
export default TableCard
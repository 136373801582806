import React , {useEffect} from "react"
import { features } from "../../data/consts";
import styles, { layout } from "../../style";
import Button from "./Button";
import { useTranslation } from 'react-i18next';
import AOS from "aos"
import 'aos/dist/aos.css'


const application = process.env.REACT_APP_APPLICATION_NAME

const FeatureCard = ({ icon, title, content, index }) => {
  
  const {t} = useTranslation()
  useEffect(()=>{
    AOS.init({duration:3000})
  },[])
  return(
  <div className={`flex flex-row p-6 rounded-[20px]  ${index !== features.length - 1 ? "mb-6" : "mb-0"} feature-card `} >
    <div className={`w-[64px] h-[64px] rounded-full ${styles.flexCenter} bg-primary`}>
      <img src={icon} alt="star" className="w-[50%] h-[50%] object-contain" />
    </div>
    <div className="flex-1 flex flex-col ml-3">
      <h4 className="font-poppins font-semibold text-white text-[18px] leading-[23.4px] mb-1">
        {t(title)}.
      </h4>
      <p className="font-poppins font-normal text-dimWhite text-[16px] leading-[24px]">
        {t(content)}.
      </p>
    </div>
  </div>
);
}

const Business = () =>  {
  const {t} = useTranslation()
  return (
  <div >
  <section id="features" className={`${layout.section}`}>
    <div className={layout.sectionInfo}>
      <h2 className={styles.heading2}>
        {t("You improve your business")}. <br className="md:block hidden" /> {t("Your Clients will be more satisfied")}.<br className="md:block hidden" /> 
      </h2>
      <p className={`${styles.paragraph} max-w-[470px] mt-5`}>
        {t("With")} {application.charAt(0).toUpperCase() + application.slice(1)}, {t("you can improve your financial life by building your digital menu, earning more and increase your business. Payment directly on your bank account")}.
      </p>

      <Button styles={`mt-10`} />
    </div>

    <div className={`${layout.sectionImg} flex-col`}>
      {features.map((feature, index) => (
        <FeatureCard key={feature.id} {...feature} index={index} />
      ))}
    </div>
  </section>
   <section id="product" className={`${layout.section} `} >
   <div className={layout.sectionInfo}>
     <h2 className={styles.heading2}>
       {t("How It Works")}? <br className="md:block hidden" /> {t("Speaty Subscription is very Easy. Few steps and your Restaurant is already online")}.<br className="md:block hidden" /> 
     </h2>
     <p className={`${styles.paragraph} w-full mt-5 text-justify`}>
       {t("Subscribe to")} {application.charAt(0).toUpperCase() + application.slice(1)}. {t("Please find below the Subscription Prices. According to your choice you will have different services. Our Partnership with")} <a className="text-cyan-400 italic underline" href={'https://stripe.com/'}>Stripe</a> {t("gives us the most safe and quick payments application. If you have any questions or doubts don't hesitate to")}  <a className="text-cyan-400 italic underline" href={'/help-center'}>{t("contact Us")}.</a>
     </p>
   </div>
   </section>
   </div>
  );
}

export default Business;

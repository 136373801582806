import React, { useEffect, useState, useContext} from 'react'
import {BiPlusCircle} from 'react-icons/bi'
import axios from 'axios';
import {LoginDelyContext} from '../../contexts/LoginDelyContext';
import { useTranslation } from 'react-i18next';

const MainPictureUpload = (props) => {
  const {t} = useTranslation()
  const chars = {
    " ": "_",
    "'":"",
    ".":"",
    ",":"",
    "-":"_",
  }
  const filename = props?.imageName
  const User = useContext(LoginDelyContext)
  const settings = User.page_info
  const imageName = props?.imageName
  const [image, setImage] = useState()
  const [file, setFile] = useState();
  const [imageConfirm, setImageConfirm] = useState(false)
  const [oldimage, setOldImage] = useState()
  console.log(settings)
  const restaurant_id = settings.user_id
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        if (fileReader.readAsDataURL || typeof(fileReader.readAsDataURL )===Blob) {
          fileReader.readAsDataURL(file)
          fileReader.onload = () => {
              resolve(fileReader.result);
          }
          fileReader.onerror = (error) => {
              reject(error);
          }
        }
        else return
    })
}


  const handleConfirm = async ( ) =>{

    /*await props.ImageData(
      {
        imageName: (filename.replace(/[ '.,-]/g, m=>chars[m]) + "." + image.type.substr(6)),
        
      }
    )*/

    setImageConfirm(true)


  }

  const handleSubmit = async () => {
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    let formData = new FormData()
    console.log(file, oldimage)
    if(!file && !oldimage){
      return;
    } else if(!file && oldimage){
      console.log(restaurant_id)
      formData.append('title', filename)
      formData.append('image_name', imageName)
      formData.append('restaurant', restaurant_id)
    }else{
      console.log(filename +"."+ image.type.substr(6), restaurant_id)
      formData.append('image', image, (filename.replace(/[ '.,]/g, m=>chars[m]) + "." + image.type.substr(6)))
      formData.append('title', filename)
      formData.append('image_name', (imageName + "." + image.type.substr(6)))
      formData.append('restaurant', restaurant_id)
    }
    const config = {
      withCredentials: true,
      headers:{
        'content-type': 'multipart/form-data',
      }
    }

    await axios.put(backend + "/api/category/add-dish-picture",formData, config)
    .then((res) => console.log(res))
    .catch(err =>console.log(err));
    
    console.log("Image Submitted")
    setOldImage(file)
    setImage()
    
    setFile()
    
  };

  
  const handleFileChange = async (e) =>{
    if(e.target.files){

      const data = e.target.files[0]
      /*const data = new FileReader()

      data.addEventListener("load", ()=>{
        console.log(data)
        setFile(data.result)
      })
      data.readAsDataURL(e.target.files[0])*/
      const base64 = await convertBase64(data)
      setFile(base64)
      setImage(e.target.files[0])
      
    }
    
  }


  const getImage = async (picture_name) => {
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    let body = JSON.stringify({title: picture_name, restaurant_id: restaurant_id});;
     
    const res = await fetch(backend + '/api/category/get-dish-picture', {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'content-type': 'application/json',
        responseType: 'blob',
      },
      body,
    }).catch(err=>console.log(err))

    const imageBlob = await res.blob();
    const imageObjectURL = URL.createObjectURL(imageBlob);


    const file = await blobToBase64(imageObjectURL)
    setOldImage(file)
    URL.revokeObjectURL(imageBlob)
  }

  const blobToBase64 = (url) => {
    return new Promise(async (resolve, _) => {
      // do a request to the blob uri
      const response = await fetch(url);
  
      // response has a method called .blob() to get the blob file
      const blob = await response.blob();
  
      // instantiate a file reader
      const fileReader = new FileReader();
  
      // read the file
      fileReader.readAsDataURL(blob);
  
      fileReader.onloadend = function(){
        resolve(fileReader.result); // Here is the base64 string
      }
    });
  };

  useEffect(()=>{
    getImage(filename)
  },[])
    


  useEffect(()=>{
    if (props.trigger){
      console.log(restaurant_id)
      handleSubmit()
      getImage(filename)
    }else{
      setImageConfirm(false)
    }
  },[props.trigger])

  console.log(restaurant_id)

  return (

      <div className='bg-sky-200 border-dashed border-sky-500 border-[2px] px-1 m-5 flex h-auto justify-center items-center flex-col rounded-2xl py-[30px]'>
        {imageConfirm || !props.modify?
          <div className='mx-auto relative mb-[20px]'>
            <div 
              className='mx-auto h-[50px] w-full max-w-500 px-2 flex flex-row justify-center items-center text-white bg-gray-400 rounded-xl border-none outline-none shadow-xl '>
              <i className='bg-white text-gray-300 mr-2 rounded-full ' 
              disabled={true}>
                <BiPlusCircle size={25}/>
              </i>
              <p className='text-center'>{t("Select Main Picture")}</p>
            </div>
          
          </div>    
          :
          <div className='relative mb-[20px]'>
            <input 
              type="file" 
              onChange={handleFileChange}
              accept="image/jpeg,image/png,image/jpg"
              className='relative max-w-[400px] w-full h-[50px] z-10 cursor-pointer opacity-0'/>
            <button 
              className=' absolute top-0 left-0 w-full h-full mx-auto flex justify-center items-center text-white bg-red-400 cursor-pointer rounded-xl border-none outline-none shadow-xl hover:bg-red-700'>
              <i className='bg-white text-red-300 flex justify-center items-center mr-2 rounded-full ' >
                <BiPlusCircle size={25}/>
              </i>
              {t("Select Main Picture")}
            </button>
          
          </div>
        }
        <>
        {image && `${image.name} - ${image.type}`  ?
          <div className='justify-center items-center flex mx-auto flex-col'>
            <div className='h-max-[200px] w-[200px] my-2'>
              <img src={file} className="items-center object-cover " />
            </div>
        
            <div className='justify-center items-center flex mx-auto flex-col'>
              <button 
                onClick={handleConfirm}
                className={` ${imageConfirm ? "w-auto h-full flex justify-center items-center text-white bg-gray-400 cursor-not-allowed rounded-xl border-none outline-none shadow-xl mx-auto " : "w-auto h-full flex justify-center items-center text-white bg-green-400 cursor-pointer rounded-xl border-none outline-none shadow-xl hover:bg-green-700 mx-auto"}`}
                disabled={imageConfirm}>
                {t("Confirm Image")}
              </button>
            </div>
          </div>
        :
        <>
          { oldimage ?
            <div className='justify-center items-center flex mx-auto flex-col'>
              <div className='h-max-[200px] w-[200px] my-2'>
                <img src={oldimage} className="items-center object-cover " />
              </div>
            </div>
          : 
            ""
      }
        </>
        }
        </>
        <p className=''>{t("Supported files")}: JPG, PNG, JPEG</p>
        <p className='text-sm underline'>{t("Suggested Picture Dimension")}: 2048 x 1080 pixel</p>

      </div>
  )
}

export default MainPictureUpload
import React, {useState, useContext, useEffect, useRef} from 'react'
import {AiFillTag, AiOutlineClose, AiOutlineMenu, AiOutlineSearch} from 'react-icons/ai'
import {BsFillSaveFill} from 'react-icons/bs'
import {TbTruckDelivery} from 'react-icons/tb'
import {RiNotification3Fill} from 'react-icons/ri'
import {FaWallet} from 'react-icons/fa'
import {MdHelp} from 'react-icons/md'
import {RiListUnordered} from 'react-icons/ri'
import waiter from '../../data/waiter.png'
import { HMIWTContext } from '../../contexts/HMIWTContext'
import { useTranslation } from 'react-i18next';
import i18next from 'i18next'
import {Notification} from '.'



const Navbar_WT = () => {
  const {t} = useTranslation()
  const waitersID = "Massimo"
  //const [call, setCall] = useState(params.HMI_CL_Params.callWaiter)
  const [nav,setNav] = useState(false);
  const [show, setShow] = useState(false);
  const [showNotification, setShowNotification]  = useState(false);
  const handleClose = () => setShow(false);
  const handleCloseNotification = () => setShowNotification(false);
  const waiterParam = useContext(HMIWTContext)
  let call = waiterParam.call
  const [notification, setNotification] =  useState(0);

  const getNotification = () =>{
    let count = 0
    call?.map(call=>{
      count += call.countCall
    })
    setNotification(count)
  }
 
  useEffect(()=>{

    getNotification()
  },[call])


  return (
    <>
    <div className='max-w-[1640px] mx-auto px-4 pt-3 pb-5 sticky w-full inset-0 top-[-1px] justify-center items-center z-50 overflow-y-auto animate-bg bg-gradient-to-r from-yellow-200 via-orange-300 to-red-200 '>
        {/*left side */}
      <div className='flex items-center'>
        <div onClick={()=> setNav(!nav)}
        className='cursor-pointer'>
          <AiOutlineMenu size={30}/>
        </div>
        <h1 className='text-green-600 text-2xl sm:text-3xl lg:text-4xl px-2 '>
          Re<span className='font-bold'>APP</span>
        </h1>
      </div>
      {/*Change Languages */}
      <div className='items-center justify-center mt-4'>
        <button
          className='text-gray-900 bg-gray-300 border-1 border-gray-400 focus:outline-none hover:bg-gray-500 focus:ring-4 focus:ring-gray-600 focus:text-white font-medium rounded-r text-2xl px-3'
          onClick={() => i18next.changeLanguage("en")}>English</button>
        <button
          className='text-gray-900 bg-gray-300 border-1 border-gray-400 focus:outline-none hover:bg-gray-500 focus:ring-4 focus:ring-gray-600  focus:text-white font-medium rounded-l text-2xl px-3'
          onClick={() => i18next.changeLanguage("it")}>Italiano</button>
      </div>
      {/* Witer User Button */}
      <button 
        className='fixed w-auto top-[12px] right-4 bg-green-700 rounded-full text-xl text-white flex items-center py-2 hover:scale-110 z-40'
        onClick={()=>setShow(true)}>
        <img src={waiter} className='object-cover w-8 mr-2'/>
        <span className='font-bold'> {waitersID}</span>
      </button>

      {/*Notification*/}
      <div 
        className='fixed w-auto top-[70px] right-4  rounded-full text-lg text-white flex items-center py-1'
        onClick={()=>setShowNotification(true)}
        >
        <div className='absolute top-[-4px] right-[-4px] rounded-full bg-red-600 flex justify-center align-center px-1 w-[40px] text-2xl font-bold'>{notification}</div>
        <RiNotification3Fill color="green" size={40}/>
        <span className='flex justify-center align-center pl-1 text-lg font-bold'></span>
        <span className='flex justify-center align-center pr-1 text-lg font-bold mx-2 text-orange-400'></span>
      </div>
      {/* Waiters Button 
      <button 
        className='fixed w-auto z-40 bottom-3 text-lg left-4 bg-yellow-500 rounded-full text-white flex items-center py-2'>
        <img src={waiter} className='object-cover w-8 mr-2'/> <span className='font-bold'>{t("Call Waiter")}</span>
      </button>*/}

      {/*Mobile Menu */}
      {/*Overlay */}
      {nav ? <div className='bg-black/80 fixed w-full h-[100vh] z-50 top-0 left-0'></div> : ''}
      {/*Side drawer menu */}
      <div className={nav ? 'fixed top-0 left-0 w-[300px] h-[100vh]  bg-green-100 z-50 duration-300' : 'fixed top-0 left-[-100%] w-[300px] h-[100vh]  bg-green-100 z-50 duration-300'}>
        <AiOutlineClose 
          onClick={()=> setNav(!nav)}
          size={30} 
          className='absolute right-4 top-4 cursor-pointer text-green-600'
          />
        <h2 className='text-green-600 text-4xl p-4 '>
          Re<span className='font-bold'>APP</span>
        </h2>
        <nav>
          <ul className='flex flex-col p-4 text-green-600 '>
            <li 
              className='text-xl py-4 flex'
              onClick={()=>setShow(true)}>
              <RiListUnordered size={25}  className='mr-4'/> {t("Orders")} </li>
                <li 
                  className='text-xl py-4 flex'
                 >
                  <FaWallet size={25} className='mr-4'/> {t("Last Bill")} </li>

            <li className='text-xl py-4 flex'>
              <AiFillTag size={25} className='mr-4'/> Promotion </li>
            <li className='text-xl py-4 flex'>
              <BsFillSaveFill size={25} className='mr-4'/> Best One </li>
            <li className='text-xl py-4 flex'>
              <TbTruckDelivery size={25} className='mr-4'/> Delivery</li>
            <li className='text-xl py-4 flex'>
              <MdHelp size={25} className='mr-4'/> Help </li>
          </ul>
        </nav>
      </div>
    </div>
    {/*show ? <OrderButtonModal onClose={handleClose} visible={setShow} productsCount={productsCount}/> : ""*/}
    {showNotification? <Notification onClose={handleCloseNotification} visible={setShowNotification} notification={notification}/> : ""}
    </>
  )
}

export default Navbar_WT
import React, {useState, useEffect, Suspense,}from 'react'
import { Footer, Navbar, HelpCenterComp} from "../components/HomePage";
import {CookiePolicy, Privacy} from './';
import styles from "../style";
import { Helmet } from 'react-helmet-async';
import { useNavigate, useLocation,  Routes, Route} from 'react-router-dom';
import { useTranslation } from "react-i18next";

const HelpCenter = () => {

  const { i18n } = useTranslation();
  const {t} = useTranslation()
  const [lan,setLan] = useState(i18n.language)
  const [color, setColor] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const changeColor = () =>{
    if (window.scrollY >=60){
      setColor(true)
    }else {
      setColor(false)
    }
  }

  window.addEventListener('scroll',changeColor)


  return (
    <div className='bg-[url("data/background.png")] bg-no-repeat bg-cover bg-center bg-fixed' >
   
    <div className={`${styles.flexCenter} ${color ? "bg-gradient-to-r from-cyan-200 to-green-400" : ""} sticky -top-1 z-40` }>
      <div className={`${styles.boxWidth} px-8`}>
      <Navbar use={"help"}/>
      </div>
    </div>
    <div className={` ${styles.flexStart}`}>
      <div className={`${styles.boxWidth}`}>
        <Routes>
          <Route index element={<HelpCenterComp />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />
          <Route path="/privacy-policy" element={<Privacy />} />
        </Routes>
      </div>
    </div>
    <div className={` ${styles.paddingX} ${styles.flexCenter}`}>
      <div className={`${styles.boxWidth}`}>
      <Footer />
      </div>
    </div>
  </div>
  )
}

export default HelpCenter
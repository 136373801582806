import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";
import {PrivacyComp_EN, PrivacyComp_IT} from '../components';
import { Helmet } from 'react-helmet-async';

const Privacy = () => {
  const {i18n} = useTranslation()
  const [lan,setLan] = useState("")
  useEffect(()=>{
    let lang = ""
    if (i18n.language === "it-IT"){
      setLan("it")
    }else if (i18n.language === "en-EN"){
      setLan("en")
    }else{
      setLan(i18n.language)
    }
  },[i18n.language])
  return (
    <>
      <Helmet>
        <title>Privacy - Speaty</title>
          <meta name="description" content='The Privacy of our Customer is very important. This page is important in order to let you know our privacy policy on how your personal information is handled when you use our website.'/>
          <link rel='canonical' href="https://speaty.com/help-center/privacy-policy"/>
          <meta name="keywords" content='food, delivery restaurant, bar, ordering, app, business, cibo, ristorante, ordinazioni, orders, ordini, ordinazione, takeaway, consegna, domicilio'/>
      </Helmet>
    {lan==="en" && 
      <PrivacyComp_EN />
    }
    {lan==="it" && 
      <PrivacyComp_IT />
    }
   </>
  )
}

export default Privacy
/*global google*/
import React , {useContext, useState, useEffect, useCallback, useRef}from 'react'
import {useLoadScript, GoogleMap, Marker, Autocomplete, LoadScript, geo} from '@react-google-maps/api'
import {LoginDelyContext} from '../../contexts/LoginDelyContext'
import {Loading} from '../'
import { useTranslation } from 'react-i18next';

const DeliveryAddress = ({verified}) => {
  const User = useContext(LoginDelyContext)
  const {t} = useTranslation()
  const order = User.order_info
  const settings = User.hmi_CL_info
  const user=User.client_info
  const libraries = ['places', 'maps']
  let libRef = useRef(libraries)
  const [message,setMessage] = useState("")
  const {isLoaded} = useLoadScript({
    id:"google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: libRef.current,
  })
  
  
  const restaurantAddress = settings.address || "napoli"
  
  /**@type <React.MutableRefObject<HTMLInputElement> */
  let addrMapRef = useRef("")

  
  const [center, setCenter] = useState()
  const delivery_km = settings.delivery_km || 4
  
  const [distance, setDistance] = useState(0)


  const calculateRoute = async (destination) =>{
    setMessage("")
    //destination=address
    if (restaurantAddress==="" || destination===""){
      return
    }
    let directionsService = new google.maps.DirectionsService()
    const results = await directionsService.route({
      origin: restaurantAddress,
      destination: destination,
      travelMode: google.maps.TravelMode.DRIVING
    })
    const distances = results.routes[0].legs[0].distance.value/1000
    setDistance(distances)
    return distances
  }

  const enableDelivery = async (address) =>{
    const distances = await calculateRoute(address)
    if (distances > delivery_km){
      verified(false)
      setMessage(t("The Address is to Far for Delivery"))
      console.log(message)
    }else{
      verified(true)
      setMessage("")
    }

  }
  useEffect(()=>{
    
    setMessage("")
    if(order.type==="Delivery"){
      if (Object.entries(order?.delivery_address).length!==0){
        enableDelivery(order.delivery_address)
        fromAddrtoGeo(order.delivery_address)
      }else if(Object.entries(User?.client_info?.address).length!==0){
        enableDelivery(User.client_info.address)
        fromAddrtoGeo(User.client_info.address)
      }else if(Object.entries(settings?.address).length!==0){
        intialAddrtoGeo(settings?.address)
      }else{
        intialAddrtoGeo("Napoli")
      }
    }else {
      intialAddrtoGeo(settings?.address)
    }

  },[])

  const intialAddrtoGeo = async (address) =>{
    if (address.length >0) {
      setMessage("")
      let geocoder = new google.maps.Geocoder()
      geocoder.geocode({ address: address }, (results, status) => {
        console.log(results, status)
        if (status === 'OK') { 
          setCenter(results[0].geometry.location)
        } else {
          alert(t('Geocode was not successful for the following reason') + ': ' + status);
        }
      
      });
    }else{
      console.log("No address for geolocation")
    }
  };

  const fromAddrtoGeo = async (address) =>{
    if (address.length >0) {
      setMessage("")
      let geocoder = new google.maps.Geocoder()
      geocoder.geocode({ address: address }, (results, status) => {
        console.log(results, status)
        if (status === 'OK') { 
          setCenter(results[0].geometry.location)
          setNewAddress(address)
          enableDelivery(address)
          let order = User.order_info
          let userClone = {...user }
          
          order.delivery_address = address
          userClone["address"] = address
          try {
            if(results[0].address_components.some(type=>type.types[0]==="administrative_area_level_3")){
              userClone["city"] = results[0].address_components.find(type=>type.types[0]==="administrative_area_level_3").long_name
            }else if (results[0].address_components.some(type=>type.types[0]==="locality")){
              userClone["city"] = results[0].address_components.find(type=>type.types[0]==="locality").long_name
            }else if (results[0].address_components.some(type=>type.types[0]==="postal_town")){
              userClone["city"] = results[0].address_components.find(type=>type.types[0]==="postal_town").long_name
            }else{
              userClone["city"] = ""
            }
            order["city"] = userClone["city"]
          } catch (error){
            console.log(error)
            userClone["city"] = ""
            order["city"] = userClone["city"]
          }
          try {
            userClone["country"] = results[0].address_components.find(type=>type.types[0]==="country").long_name 
            order["country"] = userClone["country"]
          } catch (error){
            console.log(error)
            userClone["country"] = ""
            order["country"] = userClone["country"]
          }
          try {
            userClone["postal_code"] = results[0].address_components.find(type=>type.types[0]==="postal_code").long_name 
            order["postal_code"] = userClone["postal_code"]
          } catch (error){
            console.log(error)
            userClone["postal_code"] = ""
            order["postal_code"] = userClone["postal_code"]
          }
          User.stateOrder(order)
          User.stateclient(userClone)
        } else {
          alert(t('Geocode was not successful for the following reason') + ': ' + status);
        }
      
      });
    }else{
      console.log("No address for geolocation")
    }
  };

  const [newAddress, setNewAddress] = useState(()=>{
    return User.order_info.delivery_address || ""
  })
  

  const handleChange = () => {
    setMessage("")
    setNewAddress(addrMapRef.current.value)
  }

  
  console.log(restaurantAddress, newAddress, distance,)
  console.log (isLoaded)


  if (!isLoaded) return  <Loading />
  return ( 
      <div className='flex-cols items-center justify-center mx-2 py-2 pb-5 mb-2 border-b-1 border-gray-400 '>
        {order.type==="Delivery" ?  
        <>
          <h3 className='text-center font-semibold text-xl'>{t("Delivery Address")}</h3>
          <Autocomplete >
            <input
              placeholder={t('Your Address')}
              name="address"
              className=" block rounded-lg h-[30px] px-1 break-words border-1 border-blue-800 mx-auto w-[350px] md:w-[550px] my-1"
              ref={addrMapRef}
              value={newAddress}
              onChange={handleChange}>
              
            </input>
          </Autocomplete>
          <button className='flex mx-auto border my-2 bg-blue-400 text-black items-center justify-center text-center cursor-pointer hover:bg-blue-800 hover:text-white'
            onClick={()=>fromAddrtoGeo(addrMapRef.current.value)}>
            {t("Confirm & Verify on Map")}
          </button>
        
        
          <div className='flex mx-auto items-center justify-center mt-3 mb-2 ' >
            <GoogleMap center={center} zoom={14} mapContainerStyle={{width:'300px',height: '200px'}}
            options={{
              zoomControl: false,
              streetViewControl: false,
              mapTypeControl: false,
              fullscreenControl:false,
            }}>
              <Marker position={center}/>
            </GoogleMap>
          </div>
         
          <h3 className='text-center text-red-600 text-bold text-lg my-1'>{message}</h3>  
          <div className='grid grid-cols-1 md:grid-cols-2 gap-3 py-2 mt-7 bg-white rounded-xl shadow-xl'>
            <h3 className='text-center'>{t("Address Selected")}: <span className="font-bold ">{User.order_info.delivery_address}</span></h3>
            <h3 className="text-center ">{t("City")}: {user.city}</h3>
            <h3 className="text-center "> {t("Country")}: {user.country}</h3>
            <h3 className="text-center "> {t("Postal Code")}: {user.postal_code}</h3>
              
          </div>
          
          </>
          : 
          <>
            <h3 className='text-center font-semibold text-xl'>{t("Map")} <span className='font-bold text-orange-500'>{settings.company_title}</span> </h3>
            <div className='flex mx-auto items-center justify-center mt-3 mb-2 ' >
              <GoogleMap center={center} zoom={14} mapContainerStyle={{width:'300px',height: '200px'}}
              options={{
                zoomControl: false,
                streetViewControl: false,
                mapTypeControl: false,
                fullscreenControl:false,
              }}>
                <Marker position={center}/>
              </GoogleMap>
            </div>
            <p className='text-center'> {t("Company Address")}: <span className='font-semibold'>{restaurantAddress}</span> </p>
          </>
          }
      </div>
  )
}

export default DeliveryAddress
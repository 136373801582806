import React , {useContext, useState} from 'react'
import { CartContext } from '../../contexts/CartContext'
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import {LoginDelyContext} from '../../contexts/LoginDelyContext';



const ProductCard = (props) => {

  const product = props.product
  const category = props.category
  const cart = useContext(CartContext)
  const User = useContext(LoginDelyContext)
  const settings = User.hmi_CL_info
  const [show, setShow] = useState(false)
  const {t} = useTranslation()
  let selectedStyle = ""
  const productQuantity = cart.getProductQuantity(product.name);
  const [image,setImage] = useState()
  const restaurant_id = settings.user_id.replace(/-/g,'')
  
  const handleClick = (e) => {
    e.preventDefault();
    setShow(true)
    props.handleDetails({selected: show, selected_detail: product.descrizione, selected_image : image, selected_dish: product.name, selected_price: Number(product.prezzo)})
    
  }

  if (productQuantity > 0){
    selectedStyle="bg-green-100"
  }else{
    selectedStyle="bg-gray-100"
  }

  const getImage = async (picture_name) => {
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    let body = JSON.stringify({picture_name: picture_name, restaurant_id: restaurant_id});;
     
    const res = await fetch(backend + '/api/category/get-dish-picture', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'content-type': 'application/json',
        responseType: 'blob',
      },
      body,
    }).catch(err=>console.log(err))

    const imageBlob = await res.blob();
    const imageObjectURL = URL.createObjectURL(imageBlob);


    const file = await blobToBase64(imageObjectURL)
    setImage(file)
    URL.revokeObjectURL(imageBlob)
  }

  const blobToBase64 = (url) => {
    return new Promise(async (resolve, _) => {
      // do a request to the blob uri
      const response = await fetch(url);
  
      // response has a method called .blob() to get the blob file
      const blob = await response.blob();
  
      // instantiate a file reader
      const fileReader = new FileReader();
  
      // read the file
      fileReader.readAsDataURL(blob);
  
      fileReader.onloadend = function(){
        resolve(fileReader.result); // Here is the base64 string
      }
    });
  };

  getImage(product.name)

  return (
    
    <div key={product.name} className={`border rounded-lg shadow-lg hover:scale-105 duration-300 cursor-pointer relative ${selectedStyle}`}>
      
      {productQuantity > 0 ?
        <img src={image} alt={product.name}
          loading='lazy'
          className='w-full h-[200px] object-cover rounded-t-lg'
        />
        :

        <img src={image} alt={product.name}
          loading='lazy'
          className='w-full h-[200px] object-cover rounded-t-lg'
          onClick={() => cart.addOneToCart(product.name, category,  product.prezzo, product.ingredienti)}

        />}

      <div className='flex justify-between px-2 py-4'>
        <p className='font-bold text-lg sm:text-sm'>{product.name}</p>

        <p>
          <span className='bg-green-600 text-white p-1 sm:text-sm rounded-full'>€{Number(product.prezzo).toFixed(2)}</span>
        </p>
      </div>
      <button 
        className='absolute top-2 items-center justify-center mx-2 mt-2 border-white bg-green-600 text-white z-10 rounded-xl px-2 p-1'
          onClick = {handleClick}
        >
        {t("Details")}
      </button>
      {productQuantity >0 ?
        <div className='flex-row items-center justify-center mb-3'>
          <div className='flex items-center justify-center mb-1'>
            {t("Orders")}: {productQuantity}
          </div>
          <div className='container flex items-center justify-center text-center text-white'>
            <button className='mx-2 bg-green-500 rounded w-[20px] items-center justify-center font-bold text-xl flex'
            onClick={() => cart.addOneToCart(product.name,category,product.prezzo, product.ingredienti)}
            ><span>+</span></button>
            <button className='mx-2 bg-red-500 rounded w-[20px] items-center justify-center font-bold text-xl flex'
            onClick={() => cart.removeOneFromCart(product.name, category, product.prezzo)}
            ><span>-</span></button>
          </div>
          <div className='container flex items-center justify-center text-center text-white mt-3'>
            <button className='mx-2 bg-red-700 rounded-lg w-auto items-center justify-center font-bold text-lg'
            onClick={() => cart.deleteFromCart(product.name, category, product.prezzo)}
            ><span>{t("Remove Orders")}</span></button>
          </div>
        </div>
        
        :
        
        ""}
        
    </div>
  )
}

export default ProductCard
import React from 'react'
import styles from "../style";
import { useTranslation } from 'react-i18next';
import bg2 from "../data/AppLogo_ReApp.png";

const PrivacyComp_IT = () => {
  const {t} = useTranslation()
  return (
    <div className='flex flex-col mx-3 items-center justify-center space-y-10'>

    <h2 className='text-center mx-auto font-bold text-4xl text-white'>{t("Privacy Policy")}</h2>
    <div className='flex flex-col relative lg:grid lg:grid-cols-2 mx-auto items-start justify-start gap-5'>
      <img className='absolute -bottom-[70px] lg:-bottom-[120px] -right-7 z-10 w-[400px] h-[400px] opacity-20' src={bg2}></img>
      <div className='z-20 flex flex-col lg:col-span-2 mx-auto items-center justify-center space-y-4'>
      <p className='text-white text-justify cursor-auto'>Auxilium S.R.L. Via Libero Temolo, 4, 20126 - Milano - Italia - (di seguito “<span className="font-bold"> Speaty</span>” o “<span className="font-bold">Titolare</span>”) si impegna costantemente per tutelare la privacy on-line dei propri utenti. Questo documento è stato redatto ai sensi degli artt. 13 e 14 del Regolamento UE 2016/679 (di seguito: "<span className="font-bold">Regolamento</span>") e del D.Lgs. 196/2003 e successive modifiche (“<span className="font-bold">Codice Privacy</span>”) al fine di permetterti di conoscere la nostra politica sulla privacy su come le tue informazioni personali vengono gestite quando utilizzi i nostri siti - www.speaty.com (“<span className="font-bold">Sito</span>”) e/o c.d. Mini-Siti ("<span className="font-bold">Mini-Sito</span>" o "<span className="font-bold">Mini-Siti</span>")- e, se del caso, di prestare un consenso al trattamento dei tuoi dati personali espresso e consapevole. Le informazioni ed i dati da te forniti od altrimenti acquisiti nell'ambito dell’utilizzo dei servizi di  Speaty, come ad esempio l’iscrizione all’area riservata del Sito o l’acquisto di prodotti ("<span className="font-bold">Servizi</span>"), saranno oggetto di trattamento nel rispetto delle disposizioni del Regolamento e degli obblighi di riservatezza che ispirano l'attività di  Speaty.</p>
      <p className='text-white text-justify cursor-auto'>Cosa significa “trattare dati personali”? Vuol dire eseguire qualunque tipo di operazione su informazioni che permettono di identificarti, come ad esempio, in questo caso, il tuo nome e cognome o il tuo indirizzo e-mail. Le operazioni possono essere di vario tipo e consistere, ad esempio in: raccolta, registrazione, conservazione, modifica, consultazione, uso, cancellazione o distruzione. Leggendo questa informativa saprai quali tuoi dati personali verranno trattati da  Speaty e perché. Sono dati personali (“<span className="font-bold">Dati</span>”) tutte le informazioni a te riferite, tramite la quali puoi essere identificato o identificabile. Nella categoria dei Dati trattati sono ricompresi, ad esempio:</p>
      <ul className='list-inside list-disc text-white space-y-3'>
        <li className='text-white text-justify cursor-auto'>nome, data di nascita, indirizzo e-mail, indirizzo postale, numero di telefono fisso e/o mobile;</li>
        <li className='text-white text-justify cursor-auto'>informazioni relative a pc, tablet o smartphone (come l’indirizzo IP, che è un’etichetta numerica che identifica univocamente un dispositivo – host – collegato a una rete informativa che utilizza l’Internet Protocol (IP) come protocollo di rete, o il codice IMEI, che è il codice numerico che identifica in modo univoco il tuo smartphone).</li>
        </ul>
        <p className='text-white text-justify cursor-auto'>Il Titolare può compiere le attività di trattamento direttamente o tramite soggetti esterni alla propria organizzazione, definiti Responsabili del trattamento. Tali Responsabili trattano i tuoi Dati in base alle istruzioni del Titolare, ed esclusivamente per le finalità da quest’ultimo stabilite. Responsabili del trattamento possono essere, per esempio, i soggetti che forniscono l’infrastruttura tecnologica del Sito.</p>
        <p className='text-white text-justify cursor-auto font-bold'>La presente Privacy Policy, in particolare:</p>
        <ul className='list-inside list-disc text-white  space-y-3'>
          <li className='text-white text-justify cursor-auto'>ti informa in merito ai tuoi diritti riguardo al trattamento dei tuoi dati personali;</li>
          <li className='text-white text-justify cursor-auto'>ti consente di conoscere in modo trasparente come raccogliamo e trattiamo i tuoi dati personali e come garantiamo la loro protezione;</li>
          <li className='text-white text-justify cursor-auto'>ti mostra le modalità con cui puoi esercitare i tuoi diritti e i modi in cui risponderemo alle tue richieste.</li>
        </ul>
        <p className='text-white text-justify cursor-auto'>Il trattamento dei tuoi Dati è ispirato al rispetto dei principi di correttezza, liceità, trasparenza, limitazione delle finalità e della conservazione, minimizzazione ed esattezza, integrità e riservatezza, nonché al principio di responsabilizzazione di cui all’art. 5 del Regolamento. I tuoi Dati sono trattati in accordo alle disposizioni legislative del Regolamento e del Codice Privacy e degli obblighi di riservatezza ivi previsti.</p>
      </div>
    </div>
  </div>
  )
}

export default PrivacyComp_IT
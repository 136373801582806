/*global google*/
import React, {useState, useEffect, useRef}from 'react'
import { Billing, Business, CardDeal, Clients, CTA, Footer, Navbar, Stats, Testimonials, Hero, ProductList, CheckMap} from "../components/HomePage";
import styles from "../style";
import { Helmet } from 'react-helmet-async';
import { useNavigate, useLocation} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import AOS from "aos"
import 'aos/dist/aos.css'
import {useLoadScript,} from '@react-google-maps/api'
import { Loading } from '../components';


//const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_DEV_PUBLISHABLE_KEY, {});

const Home = () => {
  const {t} = useTranslation()
  //const [stripePromise, setStripePromise] = useState(null)
  //let stripePromise = null
  const [clientSecret, setClientSecret] = useState(null)
  const [color, setColor] = useState(false);
  const libraries = ['places', 'maps']
  let libRef = useRef(libraries)
  const navigate = useNavigate();
  const location = useLocation();
  const changeColor = () =>{
    if (window.scrollY >=60){
      setColor(true)
    }else {
      setColor(false)
    }
  }

  const {isLoaded} = useLoadScript({
    id:"google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: libRef.current,
  })

  window.addEventListener('scroll',changeColor)
  localStorage.removeItem("option_selected");
  console.log(color)

  useEffect(()=>{
    window.localStorage.removeItem('order','restaurant_info','page','stripe_account');
    AOS.init({duration:3000})
  },[])
  
  return (

    <div className='bg-[url("data/background.png")] bg-no-repeat bg-cover bg-center bg-fixed' >
      <Helmet>
        <title>{t("Speaty - Revolution of Order & Delivery Food")}</title>
          <meta name="description" content={t('The Best Ordering System for Restaurants e Bars. Orders and Pay directly with Speaty Platform both delivery and order from the table of your restaurant')}/>
          <meta name="keywords" content='food, delivery restaurant, bar, ordering, app, business, cibo, ristorante, ordinazioni, orders, ordini, ordinazione, takeaway, consegna, domicilio'/>
        <link rel='canonical' href="https://speaty.com"/>
      </Helmet>
      <div className={`${styles.flexCenter} ${color ? "bg-gradient-to-r from-cyan-200 to-green-400" : ""} sticky -top-1 z-40` }>
        <div className={`${styles.boxWidth} px-8`}>
          <Navbar use={"home"}/>
        </div>
      </div>

      <div className={` ${styles.flexStart} ` } >
        <div className={`${styles.boxWidth}`}>
          <Hero />
        </div>
      </div>
      <div className='flex mx-auto xl:max-w-[1280px] justify-center items-center'>
        {!isLoaded ? <Loading /> : <CheckMap />}
      </div>
      <div className={` ${styles.paddingX} ${styles.flexCenter}`}>
        <div className={`${styles.boxWidth}`}>
          {/*<Stats />*/}
          
          
          <Business />
          
          
          <ProductList dimension={"normal"}/>
          <Billing />
          <CardDeal />
          {/*<Testimonials />*/}
          {/*<Clients />*/}
          <CTA />
          <Footer />
        </div>
      </div>

    </div>

  )
}

export default Home
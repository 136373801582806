import React, { useState } from 'react'
import styles from "../../style";
import { socialMedia } from "../../data/consts";
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';

const HelpCenterComp = () => {
  const {t} = useTranslation()

  const intialstate = {
    name:"",
    email:"",
    message:"",
    category:"Speaty Dashboard Support",
  }
  const [email, setEmail] = useState(intialstate)
  const [message, setMessage] = useState("")

  const categories = ["Speaty DashBoard Support", "Issue on Speaty", "New Skills Request for Speaty", "Speaty Prices", "Others"]

  const handleChange = (e) => {
      setMessage("")
      let emailClone = { };
      emailClone = {...email }
      emailClone[e.target.name] = e.target.value;  
       
      setEmail(emailClone);
    }

  const handleChangeCateg = (e) => {
      setMessage("")
      let emailClone = { };
      emailClone = {...email }
      emailClone.category = e.target.value;  
       
      setEmail(emailClone);
    }

  const isEmail = (email) => {
    
    const test = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)
    //const test = email.match()
    console.log(email, test)
    return test
  }

  const sendSupportEmail = async() => {
    setMessage("")
    if(email?.name.length===0 || email?.email.length===0 || email?.category.length===0 || email?.message.length===0 ) {
      setMessage("All fields should be filled")
    }else if (!isEmail(email.email)) {
      setMessage("Wrong email");
    }else {
      let backend = ""
      const body = JSON.stringify(email)
      if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
        backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
      }
      const res = await axios.post(backend + '/api/login/send-email', body, 
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          //'X-CSRFTOKEN': csrfCookie,
          
        },
      }).catch(err =>{console.log(err) 
        return err.response});
      const data = await res.data;
      console.log(data)
      if (res.status === 200) {
        console.log(data);
        alert(t("Email sent Successfully"))
        const response = {
          stat: res.status,
          data: data
        }
        setEmail({...intialstate})
        setMessage("")
        return response;
      } else {
        console.log(data);
        alert(t("Issue to send Email, please resend or contact the support"))
        const response = {
          stat: res.status,
          data: data
        }
        return response;
      }
    }    
  }

  console.log(email)
  return (
    <div className={`flex flex-col text-white`}>
      <Helmet>
        <title>Contact Us - Speaty</title>
          <meta name="description" content='The Help Center of our Platform. Reading and sending usual problem and issue with our platform. If you have an idea of new improvements as well'/>
          <link rel='canonical' href="https://speaty.com/help-center"/>
          <meta name="keywords" content='food, delivery restaurant, bar, ordering, app, business, cibo, ristorante, ordinazioni, orders, ordini, ordinazione, takeaway, consegna, domicilio'/>
      </Helmet>
      <div className={`flex-1 ${styles.flexStart} flex-col xxl:px-0 md:px-16 px-6`}>
        <div className="flex flex-col text-center w-full mt-12 mb-6">
          <h1 className="sm:text-5xl text-3xl font-medium title-font mb-4 text-white">
            {t("Contact Us")}
          </h1>
          <p className="w-full sm:w-[90%] mx-auto leading-relaxed text-xl">
            {t("Send Us an Email For a request of Support or if Speaty can be improved. Speaty Learns every day and He is happy to assist you in your business")}.
          </p>
        </div>
        <div className="md:w-[95%] w-full mx-auto">
          <div className="flex flex-col -m-2">
            <div className="flex flex-col items-start justify-start w-full sm:w-[50%] m-2 pr-4">
              <label htmlFor="name" className="text-lg leading-7 ">
                {t("Select Category")}
              </label>
              <div className={`flex  lg:flex-row w-full flex-col`}>
                <div className={`flex-1 flex-col`}>
                  <select className="rounded p-1 w-full text-black py-2"
                    value={email.category}
                    onChange={handleChangeCateg}
                  >
                    {categories.map((categ, idx) =>

                      <option key={idx} id="category" name="category" value={categ}>{t(categ)}</option>
                    )}

                  </select>
                </div>
              </div>
            </div>
            <div className='flex flex-col sm:flex-row'>
              <div className="p-2 w-full sm:w-[50%]">
                <div className="relative">
                  <label htmlFor="name" className="text-lg leading-7 text-white">
                    {t("Name")}
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder={t("Name")}
                    onChange={handleChange}
                    value={email.name}
                    className="w-full bg-gray-100 rounded border border-gray-300 focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  />
                </div>
              </div>
              <div className="p-2 w-full sm:w-[50%]">
                <div className="relative">
                  <label
                    htmlFor="email"
                    className="leading-7 text-lg  text-white"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email"
                    className="w-full bg-gray-100 rounded border border-gray-300 focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                    onChange={handleChange}
                    value={email.email}
                  />
                </div>
              </div>
            </div>
            <div className="p-2 w-full">
              <div className="relative">
                <label
                  htmlFor="message"
                  className="leading-7 text-lg  text-white"
                >
                  {t("Message")}
                </label>
                <textarea
                  placeholder={t("Message") + "......"}
                  id="message"
                  name="message"
                  className="w-full bg-gray-100 rounded border border-gray-300 focus:border-indigo-500 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                  onChange={handleChange}
                  value={email.message}
                ></textarea>
              </div>
            </div>
            {message.length > 0 ?
              <div className="p-2 text-center font-bold text-red-500 bg-white mx-auto">
                {t(message)}
              </div>
              :
              ""
            }
            <div className="p-2 w-full">
              <button className="flex mx-auto text-white bg-teal-500 border-0 py-2 px-4 focus:outline-none hover:bg-teal-600 rounded-xl text-lg"
                onClick={sendSupportEmail}>
                {t("Send")}
              </button>
            </div>
            <div className="p-2 w-full  mt-6 border-t border-gray-200 text-center ">
              <a className="text-blue-700 underline text-xl">support@speaty.com</a>
              <br />
              <a className="text-blue-700 underline text-xl">info@speaty.com</a>
              <p className="leading-normal my-5">
                Auxilium S.R.L. {t("VAT IT")}12615730962
                <br />
                Via Libero Temolo, 4,
                <br />
                20126, {t("Milan")}, {t("Italy")}
                <br />
                {t("Phone")}: +39 0230462509
              </p>
              <div className="flex flex-row md:mt-0 mt-6 justify-center items-center">
                {socialMedia.map((social, index) => (
                  <img
                    key={social.id}
                    src={social.icon}
                    alt={social.id}
                    className={`w-[21px] h-[21px] object-contain cursor-pointer ${index !== socialMedia.length - 1 ? "mr-6" : "mr-0"
                      }`}
                    onClick={() => window.open(social.link)}
                  />
                ))}
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default HelpCenterComp
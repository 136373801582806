import React, {useState, useContext, useEffect} from 'react'
import { useNavigate, useSearchParams, useLocation} from 'react-router-dom';
import { HMICLContext } from '../../contexts/HMICLContext'
import {LoginDelyContext,LoginDelyProvider} from '../../contexts/LoginDelyContext';
import { useTranslation } from 'react-i18next';
import { Outlet} from 'react-router-dom';
import {loadStripe} from "@stripe/stripe-js"
import {BsQrCode} from "react-icons/bs"
import { Loading } from '../../components';
import queryString from "query-string";

import i18next from 'i18next'

const HMI_CL_Mobile_test = () => {
  const clientParams = useContext(HMICLContext)
  const User = useContext(LoginDelyContext)
  const [check_Restaurant, setCheck_Restaurant] = useState(false)
  const [image,setImage] = useState()
  const loading = User.loading
  const {t} = useTranslation()
  const CLParams = clientParams.HMI_CL_Params
  const [stripePromise, setStripePromise] = useState(null)
  const [clientSecret, setClientSecret] = useState(null)
  const [showAddUser, setShowAddUser] = useState(false)
  const [newUser, setNewUser] = useState({userID: ""})
  const [userCreated, setUserCreated]= useState(false)
  const [searchParams, setSearchParams] = useSearchParams();
  const url = Object.fromEntries([...searchParams])
  console.log(url)
  const restaurant_id = url.restaurant_id
  const userID = CLParams.userID
  const tableID = url.tableId || CLParams.tableID
  let User_on_table = clientParams.users
  let reservationCount = 0;
  const location = useLocation();
  const page = User.hmi_CL_info

  const restaurantName = page.company_title || ""

  const type = page.sub_type.split("_")[0]
  const dely_info = {
    delivery_cost : page.delivery_cost || 1,
    delivery_days: page.delivery_days || 0,
    start_hours: page.start_dely_time_hours || 19,
    start_minutes: page.start_dely_time_minutes|| 30,
    end_hours: page.end_dely_time_hours || 22,
    end_minutes: page.end_dely_time_minutes|| 30,
    minutes_dely: page.minutes_dely || 30,
    user: page.user_id.replace(/-/g,'') || restaurant_id || ""
  }

  const getImage = async ()  => {
    let body = JSON.stringify({title: "main_picture", restaurant_id: restaurant_id});;
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    const res = await fetch(backend + '/api/category/get-dish-picture', {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'content-type': 'application/json',
        responseType: 'blob',
      },
      body,
    }).catch(err=>console.log(err))

    const imageBlob = await res.blob();
    const imageObjectURL = URL.createObjectURL(imageBlob);

    const file = await blobToBase64(imageObjectURL)
    setImage(file)
    URL.revokeObjectURL(imageBlob)
  }

  const blobToBase64 = (url) => {
    return new Promise(async (resolve, _) => {
      // do a request to the blob uri
      const response = await fetch(url);
  
      // response has a method called .blob() to get the blob file
      const blob = await response.blob();
  
      // instantiate a file reader
      const fileReader = new FileReader();
  
      // read the file
      fileReader.readAsDataURL(blob);
  
      fileReader.onloadend = function(){
        resolve(fileReader.result); // Here is the base64 string
      }
    });
  };
  useEffect(()=>{
    getImage()
  },[])

  useEffect(()=>{
    window.localStorage.removeItem('order')
    window.localStorage.removeItem('cart')
    window.localStorage.removeItem('customDish')
    window.localStorage.removeItem('restaurant_info')
    window.localStorage.removeItem('client_info')
    window.localStorage.removeItem('page')
    sessionStorage.removeItem("hmi_CL_page")

    if (restaurant_id){
      checkRestaurant()
     
    }
    
  },[])


  const checkRestaurant = async () =>{
    clientParams.postTable(url.tableId)
    console.log(userID,tableID,restaurant_id,User_on_table)
    const res = await User.getRestaurantPage(restaurant_id).then(res=>{
      let resp = ""
        if (res.stat===200){
         resp= res.data.user_id.toString()
        }
        return resp
    })
    const check = res.replace(/-/g,'')
    if (restaurant_id === check) {
      setCheck_Restaurant(true)
    }
    const resp  = await clientParams.getUserTable(url.tableId,url.restaurant_id).then(async res=>{
      const resp = await res.data
      console.log(resp)
      return resp
    })
    if(resp.some(user=>user.user_id===userID) && tableID.length!==0 && restaurant_id.length!==0){
      console.log(userID,tableID,restaurant_id)
      routeChange()
    }
  }


  useEffect(()=>{
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    fetch(backend + "/api/stripe/stripe-config/",{
      method: 'GET',
    }).then(async(r)=>{
      
      const {publishablekey} = await r.json();


      
      setStripePromise(loadStripe(publishablekey));
    })
  },[""])

  useEffect(()=>{
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    fetch(backend + "/api/stripe/create-payment-intent/", {
      method: "POST",
      body: JSON.stringify({})
    }).then(async(r)=>{
      const {clientSecret} = await r.json();

      
      setClientSecret(clientSecret);
    })
  },[""])


  const navigate = useNavigate()
  const routeChange = () => {
    const path = process.env.REACT_APP_HMI_CL_LINK_ORDERS
    navigate(path)
  }


  const handleShowAddUser = () => {
    showAddUser ? setShowAddUser(false) : setShowAddUser(true)
    
    
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newUser.userID.length <3) {
      setUserCreated(false);
      return alert(t("User must be has more than 3 digit"))

    }else { 
      if (User_on_table.some(user=>user.user_id===newUser.userID)){
        newUser.userID = newUser.userID + "_01"
      }
      const userId = newUser.userID
      const tableId = url.tableId
      const res = clientParams.postUser(userId,tableId,restaurant_id)
      clientParams.getUserTable(url.tableId, url.restaurant_id)
      User_on_table = clientParams.users

      setUserCreated(true)
      /*if (await res.status === 200){
        setUserCreated(true);
      }else{
        setUserCreated(false);
      }*/
    //post on Back end
    }
  }

  

  const handleChange = (e) => {
    const user = { ...newUser };
    setNewUser(user)
    user[e.target.name] = e.target.value;

  };

  const application = process.env.REACT_APP_APPLICATION_NAME


  console.log(User_on_table, clientParams.userDishList)

  if (!stripePromise && !clientSecret){
    return null
  }else if(tableID?.length===0 || restaurant_id?.length===0 ){
    return(
      <div className='relative bg-gray-800 items-center justify-center h-[100vh] '>
        <img className='absolute inset-x-0 mx-auto object-cover h-full '
          src={image} />
        <div className='relative inset-x-0 mx-auto  bg-scroll grid-flow-row z-1'>

          <div className='flex items-center justify-center pt-3 '>
            <button
              className='text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-400 focus:ring-4 focus:ring-gray-500 font-medium rounded-r text-lg'
              onClick={() => i18next.changeLanguage("en")}>English</button>
            <button
              className='text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-500 focus:ring-4 focus:ring-gray-500 font-medium rounded-l text-lg'
              onClick={() => i18next.changeLanguage("it")}>Italiano</button>
          </div>
          <h1 className='mx-auto justify-center max-w-[450px] w-[380px] text-2xl mt-6 font-body text-teal-500 text-center'><span className='font-bold'>{application.charAt(0).toUpperCase() + application.slice(1)}</span> {t("Order_and_Pay_in")} <span className='font-bold text-orange-500'>{restaurantName}</span></h1>
          <div className='mx-auto bg-gray-400 bg-opacity-70 shadow-xl items-center justify-center max-w-[350px] text-xl h-auto rounded-xl my-10 py-1 '>
            <h1 className='mx-1 text-center'> {t("Dear Client - No Table")}</h1>
            <div className='grid-row-2 items-center justify-center mt-5 mx-1 pt-3 border-t-1 border-black'>
              <h1 className='text-center'>{t("Please scan the")} <span className='font-bold text-teal-700'>{t("QR code on the Table again")}</span></h1>
              <BsQrCode size={50} className='px-1 object-cover mx-auto my-2' />
            </div>
          </div>
        </div>
      </div>
    )
  }else if(page?.sub_status!=="active" || type!=="premium") {
    return(
    <div className='relative bg-gray-800 items-center justify-center h-[100vh] '>
    <img className='absolute inset-x-0 mx-auto object-cover h-full '
      src={image} />
    <div className='relative inset-x-0 mx-auto  bg-scroll grid-flow-row z-1'>

      <div className='flex items-center justify-center pt-3 '>
        <button
          className='text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-400 focus:ring-4 focus:ring-gray-500 font-medium rounded-r text-lg'
          onClick={() => i18next.changeLanguage("en")}>English</button>
        <button
          className='text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-500 focus:ring-4 focus:ring-gray-500 font-medium rounded-l text-lg'
          onClick={() => i18next.changeLanguage("it")}>Italiano</button>
      </div>
      <h1 className='mx-auto justify-center max-w-[450px] w-[380px] text-2xl mt-6 font-body text-teal-500 text-center'><span className='font-bold'>{application.charAt(0).toUpperCase() + application.slice(1)}</span> {t("Order_and_Pay_in")} <span className='font-bold text-orange-500'>{restaurantName}</span></h1>
      <div className='mx-auto bg-gray-400 bg-opacity-70 shadow-xl items-center justify-center max-w-[350px] text-xl h-auto rounded-xl my-10 py-1 '>
        <p className='mx-1 text-center'> {t("Dear Client the page is not Active, please contact the Restaurant")}</p>
       
      </div>
    </div>
  </div>
  )
  }

  return (
    <LoginDelyProvider>
      {loading ? <Loading /> : ""}
      <div className='relative bg-gray-800 items-center justify-center h-[100vh] '>
        <img className='absolute inset-x-0 mx-auto object-cover h-full '
          src={image} />
        <div className='relative inset-x-0 mx-auto  bg-scroll grid-flow-row z-1'>

          <div className='flex items-center justify-center pt-3 '>
            <button
              className={`text-gray-900  bg-teal-500 border-1 border-gray-400 focus:outline-none hover:bg-teal-700 hover:text-white focus:ring-4 focus:ring-gray-600  focus:text-white font-semibold rounded-r px-3 ${i18next.language==="en"?"ring-gray-600 text-white bg-teal-700":""}` }
              onClick={() => i18next.changeLanguage("en")}>English</button>
            <button
              className={`text-gray-900  bg-teal-500 border-1 border-gray-400 focus:outline-none hover:bg-teal-700 hover:text-white focus:ring-4 focus:ring-gray-600  focus:text-white font-semibold rounded-l px-3 ${i18next.language==="it"?"ring-gray-600 text-white bg-teal-700":""}` }
              onClick={() => i18next.changeLanguage("it")}>Italiano</button>
          </div>
        <h1 className='mx-auto justify-center max-w-[380px] text-2xl mt-6 font-body text-teal-500 text-center'><span className='font-bold'>{application.charAt(0).toUpperCase() + application.slice(1)}</span> {t('Order_and_Pay_in')} <span className='font-bold text-orange-500'>{restaurantName}</span></h1>
        <div className='mx-auto bg-gray-400 bg-opacity-70 shadow-xl items-center justify-center max-w-[350px]  h-auto rounded-xl my-8'>
          <div className='flex-row justify-center items-center px-3'>
            {userCreated ?
              <h1 className='text-2xl font-body text-white text-center py-4'>{t("You are")} <span className='font-bold text-red-500'>{newUser.userID}</span></h1>
              :
              <h1 className='text-2xl font-body text-white text-center py-4'>{t('User Title')}</h1>
            }
            <div className='justify-center text-white items-center border-1 py-1 text-xl text-center first-line:py-4'>
              {t('List of Users')} <span className='pl-2 pr-2.5 font-bold bg-orange-300 text-red-500 rounded-full'>{url.tableId}</span>
              <div className='grid grid-cols-2 p-1'>
                {User_on_table?.map((user, idx) =>
                  <div className='bg-white text-gray-600 rounded-xl m-1' key={idx}>
                    {user.user_id}
                  </div>
                )}
              </div>

            </div>
          </div>
          <div className='flex justify-center py-4 items-center'>
            {userCreated ?
              <div className=''>
                <p className='mb-2 text-center justify-center text-gray-800 text-xl'> {t("User Created")}</p>
                <button
                  className='text-white font-bold bg-teal-500 text-2xl'
                  onClick={routeChange}>{t("Let's Order")}</button>
              </div>
              :
              <div className=''>

                {showAddUser
                  ?
                  <div className='items-center justify-center grid-rows-2'>
                    <input
                      className="w-f[200px] rounded-lg h-[40px] text-xl mx-1 items-center justify-center mb-5 px-1 "
                      type="text"
                      placeholder={t("Place Holder User Text")}
                      name="userID"
                      value={newUser.userID}
                      onChange={handleChange}
                    />
                    <div className='flex items-center justify-center'>
                      <button
                        className='bg-teal-500 hover:bg-teal-700 rounded-xl text-white text-xl font-bold  mr-4'
                        onClick={handleSubmit}
                      >{t("Create Button")}</button>
                      <button
                        className='relative right-0 bg-red-500  hover:bg-red-700  rounded-xl text-xl text-white font-bold'
                        onClick={handleShowAddUser}>
                        {t("Cancel Button")}</button>
                    </div>
                  </div>
                  :
                  <button
                    className='bg-teal-500 rounded-lg text-white text-2xl font-bold '
                    onClick={handleShowAddUser}>{t("Create New")}
                  </button>
                }

              </div>
            }
          </div>
          </div>
        </div>
        <Outlet />

      </div>
    </LoginDelyProvider>
  )
}

export default HMI_CL_Mobile_test
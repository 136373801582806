import React , {useState, useEffect, useContext} from 'react'
import AddModifyDish from './ModifyMenu/AddModifyDish';
import SelectCategory from './ModifyMenu/SelectCategory'
import AddModifyCategory from './ModifyMenu/AddModifyCategory';
import { useTranslation } from 'react-i18next';
import {MenuContext} from '../../contexts/MenuContext'
import {LoginDelyContext} from '../../contexts/LoginDelyContext';
import { DeleteModalUser } from '../../page/Dashboard';
import {AiOutlineUpload, AiFillDelete} from 'react-icons/ai'

const Modify_Menu = () => {
  const {t} = useTranslation();
  const [category, setCategory] = useState(()=>{
    const saved = localStorage.getItem("category");
    const initialValue = JSON.parse(saved);
    return initialValue || ""
  })
  const menus = useContext(MenuContext)
  let customDish = menus.customDish
  let menu=menus.menus
  let ingredients = menus.ingredients
  const User = useContext(LoginDelyContext)
  const settings = User.page_info
  const restaurant_id = settings.user_id
  const [selected, setSelected] = useState(()=>{
    const saved = localStorage.getItem("option_selected");
    const initialValue = JSON.parse(saved);
    return initialValue || "menu"
  } )
  const [newCustomDish , setNewCustomDish]= useState(false)
  const [openDelete, setOpenDelete] =  useState(false)
  const [newingredients,setNewingredients] = useState([])
  const [dish_id,setDish_id] = useState("")

  const submitNewCD = async () =>{
    const res = await menus.createNewCustomDish(newOption).then(async res=>{
      const resp = await res
      console.log(resp)
      if (res.stat === 200) {
        console.log("AAAAAAAAAa")
        menus.fetchAddIns(restaurant_id)
        alert(t("Created Succesfully") + "!!!")
        setNewCustomDish(false)
      }else{
        alert(res.data)
      }

    })

  }
  const handleRemoveCD = async () =>{
    const dish_tb_deleted = await customDish[0]?.dish_list.find(dish=>dish.name===selected)
    console.log(dish_tb_deleted)
    const res = await menus.DeleteCustomDish(dish_tb_deleted).then(async res=>{
      const resp = await res
      console.log(resp)
      if (res.stat === 200) {
        console.log("AAAAAAAAAa")
        menus.fetchAddIns(restaurant_id)
        alert(t("Created Succesfully") + "!!!")
        setNewCustomDish(false)
        setSelected("menu")
        setOpenDelete(false)
      }else{
        alert(t("Problem to create the New Custom Ingredient"))
      }

    })

  }
  const [newOption, setNewOption] = useState("")

  console.log(newOption)

  useEffect(()=>{
    window.localStorage.setItem('category', JSON.stringify(category));
    window.localStorage.setItem('option_selected', JSON.stringify(selected));
 },[category,selected]);


  const CategorySelected = (category_selected)=>{
    setCategory(category_selected)
  }


  useEffect(()=>{
    if (menus.menus.length === 0) {
      console.log("useffect Menu")
      menus.fetchMenu(restaurant_id);
    }else  if (menus.customDish.length === 0) {
      menus.fetchAddIns(restaurant_id);
    }else if (menus.ingredients.length === 0) {
      menus.fetchIngredients(restaurant_id);
    }
    if(selected!=="menu" && selected!=="ingredients"){
      
      const dish_id = customDish[0]?.dish_list.find(dish=>dish.name===selected)._id
      console.log(dish_id)
      setDish_id(dish_id)
      menus.fetchIngredients(restaurant_id,selected,dish_id)
    }
  },[])

  const handleChange = (e) => {
    let clonenewOption = newOption
    clonenewOption = e.target.value
    setNewOption(clonenewOption)

  }

  const handleClick = async (option,dish_Id) =>{
    if (option!=="add_new"){
      setNewingredients([])
      setCategory("")
      console.log(dish_Id)
      //localStorage.removeItem('category')
      setSelected(option)
      if (option==="ingredients"){
        menus.fetchIngredients(restaurant_id,option, dish_Id)
        setNewingredients([])
      }else if((option!=="menu" || option!=="ingredients") && dish_Id){
        setDish_id(dish_Id)
      }
    }else{
      setNewCustomDish(true)
    }

  }

  useEffect(()=>{
    if(selected!=="menu" && selected!=="ingredients"){
      console.log("AAAAAAAAAAAAAAAAAAAAAAAAa")
      let ingredients = []
      if (customDish && customDish.length!==0){
        console.log(customDish[0]?.dish_list.find(dish=>dish.name===selected))
        if (Object.hasOwn(customDish[0]?.dish_list.find(dish=>dish.name===selected),"ingredients")){
          ingredients = customDish[0]?.dish_list.find(dish=>dish.name===selected).ingredients || []
        }
        
      }
      console.log(ingredients)
      setNewingredients([...ingredients])
    }
  },[])
  console.log(newingredients)
  console.log(menu)
  console.log(customDish)
  console.log(ingredients)
    return (
    <div className='bg-gray-50 shadow-xl rounded-b-2xl rounded-t max-w-[1400px] h-auto w-full pb-10 flex flex-col md:w-[600px] lg:w-[800px] mx-auto'>
      <div  className="w-2/3">
        <div className="relative right-0">
          <ul className="flex items-center justify-center bg-gray-100 pt-1 mb-5">
            <li className="flex-1  shadow-xl text-center cursor-pointer"
              onClick={()=>handleClick("menu")}>
              <button
                className={`w-full ext-center mx-auto block rounded-t py-2 px-4 ${selected==="menu" ? "border-blue-500   bg-blue-500 hover:bg-blue-700 text-white" : "hover:border-gray-200 text-blue-500 hover:bg-gray-200"}`}
              >
                <span className="ml-1 text-center">Menu</span>
              </button>
            </li>
            <li className="flex-1  shadow-xl text-center cursor-pointer"
              onClick={()=>handleClick("ingredients")}>
              <button
                className={`w-full ext-center mx-auto block rounded-t py-2 px-4 ${selected==="ingredients" ? "border-blue-500   bg-blue-500 hover:bg-blue-700 text-white" : "hover:border-gray-200 text-blue-500 hover:bg-gray-200"}`}
              >
                <span className="ml-1 text-center">{t("Ingredients")}</span>
              </button>
            </li>

             {customDish[0]?.dish_list?.map((cust_dish,index)=>
            <li key={index} 
              className="flex-1 text-center  shadow-xl cursor-pointer"
              onClick={()=>handleClick(cust_dish.name,cust_dish._id)}>
              <button
                 className={`w-full text-center block  mx-auto rounded-t py-2 px-4 ${selected===cust_dish.name ? "border-blue-500   bg-blue-500 hover:bg-blue-700 text-white" : "hover:border-gray-200 text-blue-500 hover:bg-gray-200"}`}
              >
                <span className="ml-1">{cust_dish.name}</span>
              </button>
            </li>
            )}
             <li 
              className="flex   text-center items-center px-[1px] justify-center cursor-pointer"
              onClick={()=>handleClick("add_new")}>
              <div
                className='text-center px-3 w-full block border rounded-full mx-auto border-green-500   bg-green-500 text-3xl font-bold hover:bg-green-700 text-white'
              >+
              </div>
            </li>
          </ul>
        </div>
      </div>
      
      <p className="relative justify-center items-center flex text-4xl mt-3 font-bold">{t("Modify") + " " + selected}
       <button className={`absolute right-[20px]  rounded-xl mx-autohover:text-gray-300 text-center text-lg ${selected==="menu" || selected==="ingredients" ? "hidden" :  "bg-red-400  hover:bg-red-300  " }`}
       onClick={()=>setOpenDelete(true)}>{t("Delete")}
        </button>
      </p>
      {(selected==="menu" || selected ==="ingredients") && 
      <div className='bg-gray-200 shadow-xl rounded-2xl my-[20px] mx-[20px] h-auto flex-cols items-center py-2'>
        
        <p className='justify-center items-center flex text-2xl font-bold'>{t("Category")}</p>
        
          <AddModifyCategory option={selected} handleCategorySelected={CategorySelected}/>

        <p className="justify-center items-center flex text-2xl font-bold mt-2">{t("Select the Category")} </p>
          
          <SelectCategory selected= {category} option={selected} handleCategorySelected={CategorySelected}/>
      </div>
      }
      <div className='bg-gray-200 shadow-xl rounded-2xl my-[20px] mx-[20px] h-auto flex-cols py-2'>
        <p className='justify-center items-center flex text-2xl font-bold'>
          {selected==="menu" && t("Modify Dish")+ ": " + category}
          {selected==="ingredients" && t("Modify") + " " +  t("Ingredients") + ": " + category}
          {selected==="menu" && t("Modify") + " " + selected + ": " + category}
        </p>
        <AddModifyDish category={category} option={selected} dish_id={dish_id}/>
      </div>
      <DeleteModalUser open={newCustomDish} onclose={() => { setNewCustomDish(false) }}>
        <div className='text-center w-90 items-center justify-center flex-col'>
          <h2 className='text-center font-bold text-xl'>Create New list of Ingredients</h2>
          <AiOutlineUpload className="mx-auto" size={56} color="green" />
          <div>
          <p>{t("Name")}</p>
          <input
            className="w-full rounded-lg h-[30px] p-1 "
            type="text"
            placeholder={"Name.."}
            name="name"
            onChange={handleChange}
          />
          <div className='mt-2 flex mx-auto justify-center items-center'>
            <button
                className='flex bg-green-400 rounded-xl mx-1 hover:bg-green-600  hover:text-gray-100 w-auto'
                onClick={submitNewCD}>{t("Create")}</button>
              <button
                className='flex bg-gray-200 rounded-xl mx-1 hover:bg-gray-400 hover:text-gray-100 w-auto text-center'
                onClick={() => {
                  setNewOption("")
                  setNewCustomDish(false)}}>
                    {t("Cancel")}
              </button>

          </div>
           
          </div>
        </div>
      </DeleteModalUser>
      <DeleteModalUser open={openDelete} onclose={()=>{setOpenDelete(false)}}>
        <div className='text-center w-56 items-center justify-center flex-col'>
          <AiFillDelete className="mx-auto" size={56} color="red"/>
          <div>
            <h3 className='text-lg font-black text-gray-800'> {t("Confirm Delete") + " " + selected}</h3>
            <p className='text-sm text-gray-600'>{t("Are you sure to delete permanentely") + " " + selected}?
            </p>
          </div>
          <div className='w-full items-center justify-center flex mt-3'>
            <button  
              className='flex bg-red-400 rounded-xl mx-1 hover:bg-red-600  hover:text-gray-100 w-auto'
              onClick={()=>handleRemoveCD()}>{t("Delete")}</button>
            <button 
              className='flex bg-gray-200 rounded-xl mx-1 hover:bg-gray-400 hover:text-gray-100 w-auto text-center'
              onClick={()=> setOpenDelete(false)}>{t("Cancel")}</button>
          </div>
        </div>
      </DeleteModalUser>

    </div>
  );
};

export default Modify_Menu
import React, {StrictMode} from 'react'
import {createRoot} from 'react-dom/client'
import './index.css'
import App from './App'
import {HelmetProvider} from 'react-helmet-async'
import { BrowserRouter} from 'react-router-dom';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);


root.render(
        
        <HelmetProvider>
                <BrowserRouter basename={"/"}>
                        <App />
                </BrowserRouter>
        </HelmetProvider>
);
import React, {useContext, useState, useEffect,} from 'react'
import {HeroTestDely, ListOfFoodTest , Navbar_CL_Mobile} from '../../components/HMI_CL_Mobile';
import { HMICLContext } from '../../contexts/HMICLContext'
import { useNavigate,useLocation, Navigate} from 'react-router-dom';
import { LoginDelyContext , LoginDelyProvider} from '../../contexts/LoginDelyContext'
import queryString from "query-string";
import {Loading} from '../../components';
import {MenuProvider} from '../../contexts/MenuContext';

const HMI_CL_Mobile_orders = () => {
  const User = useContext(LoginDelyContext)
  const user = User.client_info
  const settings = User.hmi_CL_info
  const page = User.page_info
  const loading = User.loading
  const location = useLocation();
  console.log(settings)
  const restaurant_id = settings.user_id.replace(/-/g,'')
  const params = useContext(HMICLContext)
  const CLParams = params.HMI_CL_Params
  const tableID = CLParams.tableID || ""
  const userID = CLParams.userID || ""

  const menu_bg_color = page.menu_bg_color || "white"

  const navigate = useNavigate()

  useEffect(()=>{
    console.log(restaurant_id, CLParams.userID, CLParams.tableID)
    if (!restaurant_id || restaurant_id.length===0 || userID.length===0 || tableID.length===0) {
      navigate("/m/client-test?tableId=" + tableID + "&restaurant_id=" + restaurant_id)
    }

  },[])

  useEffect(() => {
    const values = queryString.parse(location.search);
    const code = values.code ? values.code : null;

    if (code) {
      User.stateLoading(true)
      OnGogglelogin();
    }
  }, []);




  const OnGogglelogin = async () => {
    console.log("AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAa")
    const res = await User.googleLoginTable(location.search).then(async res => {
      
      if (res.data.access_token && Object.keys(user.email).length!==0) {
        
        console.log(user.email)
        User.stateLoading(false)
      }
      return res
    }).catch(err => {
      
      console.log(err)
      alert(err)
      User.stateLoading(false)
    });
   
    navigate("")
    window.location.reload()
  }

  return (
    <LoginDelyProvider>
      <MenuProvider>
        <div style={{backgroundColor: menu_bg_color}}>
        {loading ? <Loading /> : ""}
              <Navbar_CL_Mobile />
              <HeroTestDely />
              
              {/*<HeadCardsTest />*/}
              <ListOfFoodTest />
        </div>
      </MenuProvider>
    </LoginDelyProvider>
  );
}

export default HMI_CL_Mobile_orders
import React, {useState, useContext, useEffect, useRef} from 'react'
import {AiFillTag, AiOutlineClose, AiOutlineMenu, AiOutlineSearch} from 'react-icons/ai'
import {BsFillCartFill, BsFillSaveFill} from 'react-icons/bs'
import {TbTruckDelivery} from 'react-icons/tb'
import {FaWallet} from 'react-icons/fa'
import {MdHelp} from 'react-icons/md'
import {RiListUnordered} from 'react-icons/ri'
import {OrderButtonModalDely, UserButtonModalDely} from './'
import { CartContext } from '../../contexts/CartContext'
import { useTranslation } from 'react-i18next';
import i18next from 'i18next'
import {AiOutlineUser} from 'react-icons/ai'
import {LoginDelyContext} from '../../contexts/LoginDelyContext';
import {IoFastFoodOutline} from 'react-icons/io5';

const Navbar_CL_Mobile_Dely = () => {
  const cart = useContext(CartContext)
  const User = useContext(LoginDelyContext)
  const {t} = useTranslation()
  const [selected, setSelected] = useState("")
  const user = User.client_info
  const orders = User.client_orders
  const order = User.order_info
  const application = process.env.REACT_APP_APPLICATION_NAME

  const [nav,setNav] = useState(false);
  const [show, setShow] = useState(false);
  const [showUser, setShowUser]  = useState(false);
  const handleClose = () => setShow(false);
  const handleCloseUser = () => setShowUser(false);
  const productsCount = cart.items.reduce((sum, product)=> sum + product.quantity, 0);

  
  const handletype = (type) =>{
    const neworder = order
    neworder.type = type
    User.stateOrder(neworder)
    setNav(!nav)
    
  }

  let reservationCount = 0;


  return (
    <>
    <div className='max-w-[1640px] mx-auto px-4 pt-3 pb-5 sticky w-full inset-0 top-[-1px] justify-center items-center z-20 overflow-y-auto animate-bg bg-teal-700 rounded-b-xl'>
        {/*left side */}
      <div className='flex items-center'>
        <div onClick={()=> setNav(!nav)}
        className='cursor-pointer'>
          <AiOutlineMenu size={30} color='white'/>
        </div>
        <h1 className='text-teal-400 text-2xl sm:text-3xl lg:text-4xl px-2 font-bold'>
          {application.charAt(0).toUpperCase() + application.slice(1)}
        </h1>
      </div>
      {/*Change Languages */}
      <div className='items-center justify-center mt-4'>
      <button
          className={`text-gray-900  bg-teal-300 border-1 border-gray-400 focus:outline-none hover:bg-teal-500 hover:text-white focus:ring-4 focus:ring-gray-600  focus:text-white font-semibold rounded-r px-3 ${i18next.language==="en"?"ring-gray-600 ring-4 text-white bg-teal-500":""}` }
          onClick={() => i18next.changeLanguage("en")}>English</button>
        <button
          className={`text-gray-900  bg-teal-300 border-1 border-gray-400 focus:outline-none hover:bg-teal-500 hover:text-white focus:ring-4 focus:ring-gray-600  focus:text-white font-semibold rounded-l px-3 ${i18next.language==="it"?"ring-gray-600 ring-4 text-white bg-teal-500":""}` }
          onClick={() => i18next.changeLanguage("it")}>Italiano</button>
      </div>
      {/* Cart Button  */}
      <div 
        className='fixed w-auto bottom-10 py-3 right-4 bg-teal-400 rounded-full text-xl text-white flex items-center hover:scale-110 z-30 cursor-pointer lg:absolute ' 
        onClick={()=>setShow(true)}>
        <div className='absolute bottom-[-7px] left-[-7px] rounded-full bg-red-600 flex justify-center align-center px-1 w-[40px] text-2xl font-bold '>{productsCount}</div>
        <BsFillCartFill size={38} className='ml-4 '/> 
        <span className='flex justify-center align-center pr-1 text-3xl font-bold mx-2'>{t("Orders")}</span>
      </div>

      {/*User and Table Info*/}
      <div 
        className='absolute w-auto top-6 py-3 right-4 bg-teal-400 rounded-full text-xl text-white flex items-center hover:scale-110 z-30 cursor-pointer lg:right-[210px]'
        onClick={()=>{
          setShowUser(true)
        }}
        >
        <AiOutlineUser size={40} className='ml-4'/>
        <span className='flex justify-center align-center pr-1 text-2xl font-bold mx-2 '>{user.first_name}</span>
      </div>

      {/*Mobile Menu */}
      {/*Overlay */}
      {nav ? <div className='bg-black/80 fixed w-full h-[100vh] z-50 top-0 left-0'></div> : ''}
      {/*Side drawer menu */}
      <div className={nav ? 'fixed top-0 left-0 w-[300px] h-[100vh]  bg-teal-100 z-50 duration-300' : 'fixed top-0 left-[-100%] w-[300px] h-[100vh]  bg-teal-100 z-50 duration-300'}>
        <AiOutlineClose 
          onClick={()=> setNav(!nav)}
          size={30} 
          className='absolute right-4 top-4 cursor-pointer text-teal-600'
          />
        <h2 className='text-teal-600 text-4xl p-4 font-bold'>
          {application.charAt(0).toUpperCase() + application.slice(1)}
        </h2>
        <nav>
          <ul className='flex flex-col p-4 text-teal-600 '>
            <li 
              className='text-xl py-4 flex cursor-pointer'
              onClick={()=>{
                setNav(!nav)
                setShow(true)}}>
              <RiListUnordered size={25}  className='mr-4'/> {t("Orders")} </li>
            <li 
              className={`text-xl py-4 flex cursor-pointer ${Object.entries(orders).length===0 ? "cursor-not-allowed  text-gray-400" : ""}`}
              
              onClick={()=>{
                setNav(!nav)
                setSelected("orders")
                setShowUser(true)}}>
              <FaWallet size={25} className='mr-4'/> {t("Payments")} </li>
            
            
            <li className="text-xl py-4 flex cursor-not-allowed  text-gray-400">
              <AiFillTag size={25} className='mr-4'/> {t("Promotion")} </li>
            <li className="text-xl py-4 flex cursor-not-allowed  text-gray-400">
              <BsFillSaveFill size={25} className='mr-4'/> {t("Best One")} </li>
            {order.type==="Takeaway" ? 
              <li className='text-xl py-4 flex cursor-pointer'
              onClick={()=>handletype("Delivery")}>
                <TbTruckDelivery size={25} className='mr-4'/> {t("Delivery")}
              </li>
            : 
              <li className='text-xl py-4 flex cursor-pointer'
              onClick={()=>handletype("Takeaway")}>
                <IoFastFoodOutline size={25} className='mr-4'/>{t("Takeaway")}
              </li>
            }
            <li className='text-xl py-4 flex cursor-pointer'
            onClick={()=>{window.location.href = '/help-center'}}>
              <MdHelp size={25} className='mr-4'/> Help </li>
              
          </ul>
        </nav>
      </div>
    </div>
    {show ? <OrderButtonModalDely onClose={handleClose} visible={setShow} productsCount={productsCount} visibleUser={setShowUser}/> : ""}
    {showUser ? <UserButtonModalDely onClose={handleCloseUser} visible={setShowUser} orders_selected={selected} type={"delivery"}/> : ""}
    </>
  )
}

export default Navbar_CL_Mobile_Dely
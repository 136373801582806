import React , {useState, useEffect, useContext,useRef} from 'react'
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import {MenuContext} from '../../../contexts/MenuContext';
import { useTranslation } from 'react-i18next';
import {LoginDelyContext} from '../../../contexts/LoginDelyContext';
import { IoPencil } from "react-icons/io5";
import { MdDelete } from "react-icons/md";
const _ = require('lodash');

const AddModifyDish= (props) => {
  const {t} = useTranslation();
  const menus = useContext(MenuContext)
  const menu = menus.menus;
  const navigate = useNavigate();
  const User = useContext(LoginDelyContext)
  const settings = User.page_info
  const category=props.category;
  const option = props.option;
  const dish_id = props.dish_id;
  const customDish = menus.customDish;
  const restaurant_id=settings.user_id
  const ingredients = menus.ingredients
  const [open, setOpen]  = useState(false)
  const [newingredients,setNewingredients] = useState([])
  const [isEqual,setIsEqual] = useState(true)
  const initingredients = useRef([])

  useEffect(()=>{
    if (option!=="menu" && option!=="ingredients" && dish_id.length>0){
      const init = true
      fetch_Initnewingredients(init)
      setIsEqual(true)
    }
  },[option])

  const fetch_Initnewingredients = async (init) =>{
    console.log(dish_id)
    let ingredients = []
    const resp = await menus.fetchIngredients(restaurant_id,option, dish_id).then(async(res)=>{
      console.log(res)
      const status = await res.stat
      if (status ===200){
        if (customDish && customDish.length!==0){
          if (Object.hasOwn(customDish[0]?.dish_list.find(dish=>dish.name===option),"ingredients")){
            ingredients = await customDish[0]?.dish_list.find(dish=>dish.name===option).ingredients
          }
        }
        initingredients.current = ingredients
        if (init){
          setNewingredients([...initingredients.current])
        }
        
      }
    })
    
  }
  

  const handleDelete = async (e,dish) => {
    e.preventDefault();
    if (option==="menu"){
      const res = await menus.deleteDish(dish).then(async res=>{

        const resp = await res
        console.log(resp)
        if (res.stat === 200) {
          menus.fetchMenu(restaurant_id)
        }else{
          alert(t("Problem to delete the Dish, please try again"))

        }
        

      })
    }else{
      const res = await menus.DeleteDish_CD(dish, option).then(async res=>{

        const resp = await res
        console.log(resp)
        if (res.stat === 200) {
          menus.fetchAddIns(restaurant_id)
          menus.fetchIngredients(restaurant_id)
          
        }else{
          alert(t("Problem to delete the Ingredient, please try again"))

        }
      })
    };
  }

  const AddIngredients = async (ingredient)=>{
    const newingredient = {name: ingredient.category, ingredient_list:[ingredient]}
    if (!newingredients.some(categ=>categ.name===ingredient.category)){
      const cloneingredients = [...newingredients]
      cloneingredients.push(newingredient)
      setNewingredients([...cloneingredients])
    }else if(!newingredients.find(categ=>categ.name===ingredient.category).ingredient_list.some(ingr=>ingr.name===ingredient.name)){
      const cloneingredients = [...newingredients]
      cloneingredients.find(categ=>categ.name===ingredient.category).ingredient_list.push(ingredient)
      setNewingredients([...cloneingredients])
    }
  }
  const ingredient_check = (ingredient) =>{
    let feedback = true
    if (!newingredients.some(categ=>categ.name===ingredient.category)){
      feedback = false
    }else if(!newingredients.find(categ=>categ.name===ingredient.category).ingredient_list.some(ingr=>ingr.name===ingredient.name)){
      feedback = false
    }else{feedback = true}
    return feedback
  }
  const DelIngredients = (ingredient) =>{
    const cloneingredient = newingredients.slice()
    const index0 = cloneingredient.findIndex(categ=>categ.name===ingredient.category)
    const index1 = cloneingredient[index0].ingredient_list.findIndex(ingr=>ingr.name===ingredient.name)
    console.log(index1)
    const removed = cloneingredient[index0].ingredient_list.splice(index1,1)
    console.log(cloneingredient)
    console.log(removed)
    if(cloneingredient[index0].ingredient_list.length===0){
      const index2 = cloneingredient.findIndex(categ=>categ.name===ingredient.category)
      cloneingredient.splice(index2,1)
    }
    console.log(cloneingredient)
    setNewingredients([...cloneingredient])
  }

  const ModifyIngredients = async () => {
    const cd_id = customDish[0].dish_list.find(dish=>dish.name===option)._id
    let ingr_list_id = []
    newingredients.map((categ)=>{
      categ["ingredient_list"].map((ingr)=>{
        ingr_list_id.push(ingr["_id"])
      })
    })
    console.log(ingr_list_id)
    const res = await menus.CD_to_Ingredients(restaurant_id, ingr_list_id, cd_id,).then(async res=>{
      const resp = await res
      console.log(resp)
      if (res.stat === 200) {
        menus.fetchAddIns(restaurant_id)
        await menus.fetchIngredients(restaurant_id,option,dish_id).then(res)
        const init = true
        await fetch_Initnewingredients(init)
        alert(t("Update successfully"))
      }else{
        alert(t("Problem to update the Ingredients, please try again"))

      }
    })
  }

  const Check_Ingredients_change =  async () => {
    const init = false
    await fetch_Initnewingredients(init)
    let isequal = true
    newingredients.map((categ)=>{
      if (initingredients.current.some(category=>category.name===categ.name)){
        categ.ingredient_list.map((ingr)=>{
          if(initingredients.current.find(init_categ=>init_categ.name===categ.name).ingredient_list.some(init_ingr =>init_ingr.name===ingr.name)){
          }else{
            isequal = false
          }
        })
      }else{
        isequal = false
      }
    })

    if (isequal){
      initingredients.current.map((categ)=>{
        if (newingredients.some(category=>category.name===categ.name)){
          categ.ingredient_list.map((ingr)=>{
            if(newingredients.find(init_categ=>init_categ.name===categ.name).ingredient_list.some(init_ingr =>init_ingr.name===ingr.name)){
            }else{
              isequal = false
            }
          })
        }else{
          isequal = false
        }
      })
    }
    console.log(newingredients)
    console.log(initingredients.current)
    console.log(isequal)
    setIsEqual(isequal)
  }

  useEffect(()=>{
    if(dish_id.length>0 ){
      Check_Ingredients_change()
    }
    
  },[newingredients])

  console.log(newingredients)

  return (
    <div className='w-full h-full justify-center items-center'>
      <div className=" flex-row bg-white rounded-xl shadow-xl my-3 py-[30px] px-[20px] justify-center items-center mx-4 ">
      {props.category === "" && (option==="menu" || option==="ingredients") ?<p className=''>{t("No Category Selected, Select a Category")}</p>:
        <div>
          <div className='flex flex-col sm:flex-row sm:justify-between justify-center items-center mx-auto'>
            {(option==="menu" || option==="ingredients") ?
              <button
                onClick={() => navigate(`/dash/manager-dash/modify-menu/${option}/${category}/new`)}
                className="flex bg-sky-600 hover:bg-sky-800 hover:text-white first-line:rounded-xl py-[10px] px-[20px] mb-4 "
              >
                {option==="menu" ? t("Create New Dish") : t("Create New Ingredient")}
              </button>
              :
              <h3 className='italic font-semibold text-start'>{t("If you want to create a new ingridients go to the 'Ingredients tab'")}</h3>
            }
          </div>
            {option==="menu" &&
              <table className="table-auto w-full">
                <thead>
                  <tr className='items-center justify-between content-center'>
                    <th className='text-center text-xs sm:text-lg'>
                    {option==="menu" ? t("Dish Name") : t("Ingredient")} </th>
                    <th className='text-center text-xs sm:text-lg'>{t("Price")} €</th>
                    <th className='text-center text-xs sm:text-lg'>{t("Update")}</th>
                    <th className='text-center text-xs sm:text-lg'>{t("Delete")}</th>
                  </tr>
                </thead>
                <tbody>
                  
                  {(menu.length>0 || menu.some(categ=>categ.name===category)) &&

                    menu?.find(categ=>categ.name===category).dish_list?.map((dish)=>( 
                    <tr className='content-center' key={dish.name}>
                      <td className='text-center '> {dish.name} </td>
                      <td className='text-center '> {dish.prezzo} </td>
                      <td className='text-center '>
                        <button
                          onClick={() => navigate(`/dash/manager-dash/modify-menu/${option}/${category}/${dish.name}`)}
                          className="hidden sm:flex mx-auto rounded-xl py-[10px] px-[20px]flex bg-green-400 hover:bg-green-600  hover:text-gray-100"
                        >
                          {t("Update")}
                        </button>

                        <IoPencil className='sm:hidden mx-auto' size={25} color="blue"  onClick={() => navigate(`/dash/manager-dash/modify-menu/${option}/${category}/${dish.name}`)}/>
                      </td>
                      <td className='text-center ' >
                        <button
                          onClick={(e)=>handleDelete(e,dish)}
                          className="hidden sm:flex  mx-auto rounded-xl py-[10px] px-[20px]flex bg-red-400 hover:bg-red-600  hover:text-gray-100"
                        >
                          {t("Delete")}
                        </button>

                        
                        <MdDelete className='sm:hidden mx-auto' size={25} color="red" onClick={(e)=>handleDelete(e,dish)}/>
                      </td>
                    </tr>
                    ))
                  }
                </tbody>
              </table>
            }
            {option==="ingredients" &&
            <table className="table-auto w-full">
              <thead>
                <tr className='items-center justify-between content-center'>
                  <th className='text-center text-xs sm:text-lg'>
                  {option==="menu" ? t("Dish Name") : t("Ingredient")} </th>
                  <th className='text-center text-xs sm:text-lg'>{t("Price")} €</th>
                  <th className='text-center text-xs sm:text-lg'>{t("Update")}</th>
                  <th className='text-center text-xs sm:text-lg'>{t("Delete")}</th>
                </tr>
              </thead>
              <tbody>
                
                {(ingredients?.length>0 && ingredients?.some(categ=>categ.name===category) && ingredients?.find(categ=>categ.name===category).ingredient_list.length>0) &&

                ingredients?.find(categ=>categ.name===category).ingredient_list?.map((custom)=>( 
                  <tr className='content-center' key={custom.name}>
                    <td className='text-center '> {custom.name} </td>
                    <td className='text-center '> {custom.price}€ </td>
                    <td className='text-center '>
                      <button
                        onClick={() => navigate(`/dash/manager-dash/modify-menu/${option}/${category}/${custom.name}`)}
                        className="rounded-xl py-[10px] px-[20px]flex bg-green-400 hover:bg-green-600  hover:text-gray-100"
                      >
                        {t("Update")}
                      </button>
                    </td>
                    <td className='text-center ' >
                      <button
                        onClick={(e)=>handleDelete(e,custom)}
                        className="rounded-xl py-[10px] px-[20px]flex bg-red-400 hover:bg-red-600  hover:text-gray-100"
                      >
                        {t("Delete")}
                      </button>
                    </td>
                  </tr>
                  ))
                }
                
                

              </tbody>
            </table>
          }
          {(option!=="menu" && option!=="ingredients") &&

          <div className='flex flex-col mx-[50px] mr-auto ml-auto p-3 items-center z-30 justify-center '>
            
            <h2 className='text-center font-bold text-black text-2xl'>{t("Add Ingredients to") + ": " + option}</h2>

          
            <div className='flex flex-col items-center justify-center space-y-4 mt-10'>
              <h2 className='text-center font-bold'>{t("Ingredients available")}</h2>
              <div className='bg-gray-200 shadow-xl flex mx-2 w-full p-3 flex-col space-y-3 max-h-[250px] min-h-[100px] sm:max-h-[400px] rounded-xl overflow-y-auto'>
                {ingredients?.map((categ,idx1)=>
                  <div key={idx1} className='flex flex-col space-y-2'>
                    <h2 className=''>{categ.name}</h2>
                    <div className='flex flex-row flex-wrap  space-x-1 justify-start items-start'>
                      {categ.ingredient_list.map((ingredient,idx2)=>
                      <div key={idx2} className={`flex relative h-[40px] max-w-[140px] md:max-w-[200px] rounded-xl  text-black p-2  ${ingredient_check(ingredient) ? "bg-green-300 cursor-default": " bg-sky-300 cursor-pointer hover:bg-sky-50"}`}
                        onClick={()=>{AddIngredients(ingredient)}}>
                        <h3 className='whitespace-nowrap text-center text-sm overflow-hidden'>{ingredient.name}</h3>
                          
                      </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <h2 className='text-center font-bold'>{t("Ingredients selected")}</h2>
              <div className='bg-gray-200 shadow-xl flex mx-2 w-full p-3 flex-col space-y-3 max-h-[250px] min-h-[100px] sm:max-h-[400px] rounded-xl overflow-y-auto'>
                {newingredients?.map((categ,idx1)=>
                  <div key={idx1} className='flex flex-col space-y-2'>
                    <h2 className=''>{categ.name}</h2>
                    <div className='flex flex-row flex-wrap  space-x-1 justify-start items-start'>
                      {categ.ingredient_list.map((ingredient,idx2)=>
                      <div key={idx2} className='flex my-1 relative bg-green-300 h-[40px] max-w-[150px] md:max-w-[200px] rounded-xl  text-black p-2 '>
                        <h3 className='whitespace-nowrap text-center text-sm overflow-hidden'>{ingredient.name}</h3>
                        <div className='absolute -top-2 -right-2 text-sm font-bold px-2 hover:bg-red-400 rounded-xl bg-red-300 cursor-pointer z-40' onClick={()=>{DelIngredients(ingredient)}}>X</div>
                      </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              {!isEqual && 
              <div className='flex flex-row items-center justify-center mx-auto space-x-4'>
                <div className='flex relative bg-green-400 hover:bg-green-500 h-[40px] max-w-[150px] md:max-w-[200px] rounded-xl  text-black p-2 cursor-pointer '
                onClick={()=>{
                  ModifyIngredients()}}
                  >{t("Confirm")}
                </div>
                <div className='flex relative bg-red-400 hover:bg-red-500 h-[40px] max-w-[150px] md:max-w-[200px] rounded-xl  text-black p-2 cursor-pointer ' 
                onClick={()=>{
                  setNewingredients(initingredients.current)
                  }}>{t("Cancel")}
                </div>
              </div>
              }
            </div>
          </div> 
        }
      </div>
    }
  </div>
</div>



  );
};

export default AddModifyDish
import React, {useState, useEffect, Suspense,}from 'react'
import { Footer, Navbar, TermsAndServices_EN, TermsAndServices_IT, TermsLanguageSwitcher } from "../components/HomePage";
import styles from "../style";
import { Helmet } from 'react-helmet-async';
import { useNavigate, useLocation} from 'react-router-dom';
import { useTranslation } from "react-i18next";

const TermsServices = () => {
  const { i18n } = useTranslation();
  const {t} = useTranslation()

  const [lan,setLan] = useState("en")
  const [color, setColor] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const changeColor = () =>{
    if (window.scrollY >=60){
      setColor(true)
    }else {
      setColor(false)
    }
  }

  window.addEventListener('scroll',changeColor)

  const changelang = (lan) =>{
    setLan(lan)
  }

  console.log(color)

  useEffect(()=>{
    setLan(i18n.language)

  },[i18n.language])

  useEffect(()=>{
    setLan("en")

  },[])


  return (
    <div className='bg-[url("data/background.png")] bg-no-repeat bg-cover bg-center bg-fixed' >
      <Helmet>
        <title>Terms & Conditions - Speaty</title>
        <meta name="description" content='The Terms and Conditions of our Platform. All the Rights and the Terms with which you sign when you subscribe to our Platform'/>
        <link rel='canonical' href="https://speaty.com/terms-and-services/"/>
        <meta name="keywords" content='food, delivery restaurant, bar, ordering, app, business, cibo, ristorante, ordinazioni, orders, ordini, ordinazione, takeaway, consegna, domicilio'/>
      </Helmet>
      <div className={`${styles.flexCenter} ${color ? "bg-gradient-to-r from-cyan-200 to-green-400" : ""} sticky -top-1 z-40` }>
        <div className={`${styles.boxWidth} px-8`}>
          <Navbar use={"terms"}/>
        </div>
      </div>
      <div className={` ${styles.flexStart}`}>
        <div className={`${styles.boxWidth}`}>
          <TermsLanguageSwitcher changelang={changelang} value={lan}/>
          {lan==="en" && 
            <TermsAndServices_EN />
          }
          {lan==="it" && 
            <TermsAndServices_IT />
          }
        </div>
      </div>
      <div className={` ${styles.paddingX} ${styles.flexCenter}`}>
        <div className={`${styles.boxWidth}`}>
        <Footer />
        </div>
      </div>
    </div>
  )
}

export default TermsServices
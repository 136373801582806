import React, { useContext, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import classNames from 'classnames'
import {LoginDelyContext} from '../../contexts/LoginDelyContext'
import { Navigate , useNavigate } from 'react-router-dom';
import ReAppLogo_standard from '../../data/AppLogo_Speaty_Standard.png';
import ReAppLogo_premium from '../../data/AppLogo_Speaty_Premium.png';
import { useTranslation } from 'react-i18next';
import {
  HiOutlineUsers,
  HiOutlineDocumentText,
  HiOutlineQuestionMarkCircle,
  HiOutlineCog,
  HiOutlineShoppingCart,
  HiOutlineViewGrid,
  HiOutlineUser,
  HiOutlineLogout,

 

} from 'react-icons/hi'
import { RiPassportLine } from "react-icons/ri";
import axios from 'axios';

import {AiOutlineMenuUnfold, AiOutlineMenuFold} from 'react-icons/ai'

const linkClasses = "flex items-center gap-2 px-3 py-2 hover:bg-teal-400 hover:no-underline active:bg-green-600 rounded-sm text-base active:font-bold"




const Sidebar_Dash = ({visible}) => {
  const {t} = useTranslation()
  const [openSide, setOpenSide] = useState(true)
  const DASHBOARD_SIDEBAR_LINKS = [
    {
  
      key: 'dashboard',
      label: 'Manager Dashboard',
      path: "/dash/manager-dash",
      icon: <HiOutlineViewGrid />,
      sub_type: "standard"
    },
   
    {
      key: 'HMI_CL',
      label: t('Manage Table Live'),
      path: "/dash/manage-tables-live",
      icon: <HiOutlineUsers/>,
      sub_type: "premium"
    },
  
    {
      key: 'Orders',
      label: t('Orders'),
      path: "/dash/manage-orders",
      icon: <HiOutlineShoppingCart />,
      sub_type: "standard"
    },
    {
      key: 'transactions',
      label: t('Transactions'),
      path: "/dash/manage-transaction",
      icon: <HiOutlineDocumentText/>,
      sub_type: "standard"
    },
  
  ]
  
  const DASHBOARD_SIDEBAR_BOTTOM_LINKS = [
    {
      key: 'account',
      label: 'Account',
      path: "/dash/account",
      icon: <HiOutlineUser/>,
      sub_type: "standard"
    },
    {
      key: 'settings',
      label: 'Settings',
      path: "/dash/settings",
      icon: <HiOutlineCog/>,
      sub_type: "standard"
    },
    {
      key: 'support',
      label: 'Help & Support',
      path: "/help-center",
      icon: <HiOutlineQuestionMarkCircle/>,
      sub_type: "standard"
    },
  
  
  ]


  const [logout, setLogout] = useState("false")
  const User = useContext(LoginDelyContext)
  const user = User.restaurant_info
  const status = user.sub_status
  const type = user.sub_type.split("_")[0]
  const navigate = useNavigate()
  const application = process.env.REACT_APP_APPLICATION_NAME
  const handleSubmit = async (e) => {
    e.preventDefault()
    User.logout();
    
    setLogout(!logout)
    navigate("/")
  }

  const handleSession = () => {
    const body = {session_id: user.sub_session_id, stripe_id: user.sub_stripe_id, return_url : "/dash/account"}
    console.log(body)
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    axios.post(backend + "/api/stripe/create-portal-session/",body).then((res)=>{
      console.log(res.data)

      if(res.data.url){
        console.log(res)
        window.location.href = res.data.url 
      }
    }).catch((err) => {
    alert(err.message)
    console.log(err.message)});
  }


  return (
    <aside className=' text-white flex-col relative flex'>
      <div className={`${type==="premium" ? "bg-orange-100" : "bg-teal-100"}  ${
        openSide ? "w-[235px]" : "w-[65px] "
        }`}>
      {!openSide ? 
        <AiOutlineMenuUnfold color='black' size={30} className='absolute cursor-pointer -right-[40px] top-6 hidden md:flex'
        onClick={() => setOpenSide(!openSide)}/>
        :
        <AiOutlineMenuFold color='black' size={30} className='absolute cursor-pointer -right-[40px] top-6  '
        onClick={() => setOpenSide(!openSide)}/>
        }
        <div className={`${openSide ? "flex flex-row mx-auto my-6 justify-center items-center" : "my-6 justify-center items-center"}`}>
          {type==="premium" ? 
          
          <img src={ReAppLogo_premium} alt='Logo' className={` ${openSide ? "h-[30px] w-[30px] mr-2" : "h-[30px] w-[30px] items-center flex justify-center mx-auto"}`}/>
        
          :
          <img src={ReAppLogo_standard} alt='Logo' className={` ${openSide ? "h-[30px] w-[30px] mr-2" : "h-[30px] w-[30px] items-center flex justify-center mx-auto"}`}/>
          
          }

          <h2 className={`${type==="premium" ? "text-orange-500" : "text-green-500"}  text-2xl font-bold origin-left  duration-200 ${!openSide && "hidden"}`}>
          {application.charAt(0).toUpperCase() + application.slice(1)}</h2>
          {type==="premium" ? 
            <h2 className={`${type==="premium" ? "text-orange-500" : "text-green-500"}  text-xl duration-200 ml-1 ${!openSide && "hidden"}`}>{type.charAt(0).toUpperCase() + type.slice(1)}</h2>
          :
          ""}
          
        </div>
      </div>
      <div 
        className={` ${
        openSide ? "w-[235px]" : "w-[65px] "
        } bg-teal-700 p-2 pt-3 h-[100vh] flex flex-col  duration-300`}>
        
        <div className='flex flex-col pb-[100px] pt-2 space-y-1 '>
          {DASHBOARD_SIDEBAR_LINKS.map((item) => (
            <>
            {type==="premium" ? 
              <SidebarLink key={item.key} item={item} open={openSide}
              visible={visible}/>
            
            :
            
            <>
              {type===item.sub_type ? 
              <SidebarLink key={item.key} item={item} open={openSide}
              visible={visible}/>
              
              :
              <SidebarLink_type key={item.key} item={item} open={openSide}
              visible={visible}/>
            }   

            </>}
            
            </>
          ))}
          <div
            onClick={handleSession}
            className={`${classNames('text-green-200 cursor-pointer', linkClasses)}` }>
            <span className='text-2xl'>
              <RiPassportLine />
            </span>
            <span className={`${
              !openSide && "scale-0"}`}>
              {t("Subscription Review")}
            </span>
            
          </div>
        </div>
        <div className='flex flex-col pt-2 space-y-1 border-t-1 pb-[280px]  border-gray-300'>
          {DASHBOARD_SIDEBAR_BOTTOM_LINKS.map((item) => (
            <SidebarLink key={item.key} item={item} open={openSide}
           visible={visible}/>
          ))}

          <div
            onClick={handleSubmit}
            className={`${classNames('text-red-500 cursor-pointer', linkClasses)}` }>
            <span className='text-2xl ml-1'>
              <HiOutlineLogout />
            </span>
            <span className={`${
              !openSide && "scale-0"}`}>
              {t("Logout")}
            </span>
            
          </div>

        </div>
          <p className={`px-5 pt-4 my-6 text-sm text-center space-y-1 border-t-1 border-gray-300 ${
              !openSide && "scale-0"}` }>{t("Created by")} <a className="underline" href={'https://www.auxilium-services.com'}>Auxilium SRL</a></p>

      </div>
    </aside>
    
  )
}

function SidebarLink({item,open,visible}) {
  const {pathname} = useLocation ()

  return(
    <Link 
      to={item.path} 
      className={classNames(pathname === item.path ? ' bg-teal-400 text-white font-bold' : 'text-white  ',linkClasses)}>
      <span className='text-2xl'
      onClick={visible}>{item.icon}
      </span>
      <span className={`${!open && "hidden"} origin-left duration-200`}
      onClick={visible}
      >{item.label}</span>
    </Link>
  )
}

function SidebarLink_type({item,open,visible}) {
  const {t} = useTranslation()

  return(
    <div
      className="relative flex items-center gap-2 px-3 py-2 rounded-sm text-base cursor-not-allowed">
      <h2 className={`${!open && "hidden"} absolute  z-40 p-1 font-bold text-orange-400 top-4 right-1 text-center bg-white rounded-xl shadow-xl `}>{t("Premium")}</h2>
      <span className='text-2xl opacity-40'>{item.icon}
      </span>
      <span className={`${!open && "hidden"} origin-left duration-200 opacity-40`}
      >{item.label}</span>
    </div>
  )
}

export default Sidebar_Dash
import React, {useState, useContext, useEffect} from 'react'
import {BsQrCode} from "react-icons/bs"
import i18next from 'i18next'
import { useTranslation } from 'react-i18next';
import { LoginDelyContext } from '../../contexts/LoginDelyContext'
import { useNavigate, useSearchParams, useParams} from 'react-router-dom';
import { HMICLContext } from '../../contexts/HMICLContext';

const Cancel_dely = () => {

  const User = useContext(LoginDelyContext)
  const {t} = useTranslation()
  const [searchParams] = useSearchParams()
  const url = Object.fromEntries([...searchParams])
  console.log(url)
  const restaurant_id = url.restaurant_id
  const order_id = url.order_id
  const page = User.hmi_CL_info
  const client = User.client_info
  const [check_Restaurant, setCheck_Restaurant] = useState(false)
  const [image,setImage] = useState()
  const navigate = useNavigate()
  const routeChange = () => {
    
    const path = "/" + process.env.REACT_APP_HMI_CL_TAKE_DELY + "/" + process.env.REACT_APP_DELIVERY_LINK
    navigate(path)
  }

  const getImage = async ()  => {
    let body = JSON.stringify({title: "main_picture", restaurant_id: restaurant_id});;
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    const res = await fetch(backend + '/api/category/get-dish-picture', {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'content-type': 'application/json',
        responseType: 'blob',
      },
      body,
    }).catch(err=>console.log(err))

    const imageBlob = await res.blob();
    const imageObjectURL = URL.createObjectURL(imageBlob);

    const file = await blobToBase64(imageObjectURL)
    setImage(file)
    URL.revokeObjectURL(imageBlob)
  }

  const blobToBase64 = (url) => {
    return new Promise(async (resolve, _) => {
      // do a request to the blob uri
      const response = await fetch(url);
  
      // response has a method called .blob() to get the blob file
      const blob = await response.blob();
  
      // instantiate a file reader
      const fileReader = new FileReader();
  
      // read the file
      fileReader.readAsDataURL(blob);
  
      fileReader.onloadend = function(){
        resolve(fileReader.result); // Here is the base64 string
      }
    });
  };
  useEffect(()=>{
    getImage()
  },[])

  useEffect(()=>{
    if (restaurant_id){
    checkRestaurant()
      if (order_id){
        User.del_order(order_id, restaurant_id)
        User.get_client_orders(restaurant_id,client.user_id)
        User.get_restaurant_orders(restaurant_id)
      }
    }
    
  },[])

  const checkRestaurant = async () =>{
    const res = await User.getRestaurantPage(restaurant_id).then(res=>{
      let resp = ""
        if (res.stat===200){
         resp= res.data.user_id.toString()
        }
        return resp
    })
    const check = res.replace(/-/g,'')
    console.log(check)
    if (restaurant_id === check) {
      setCheck_Restaurant(true)
    }
  }

  const application = process.env.REACT_APP_APPLICATION_NAME

  return (
    <div className='relative bg-gray-800 items-center justify-center h-[100vh] '>
    <img className='absolute inset-x-0 mx-auto object-cover h-full '
      src={image} />
      <div className='relative inset-x-0 mx-auto  bg-scroll grid-flow-row z-1'>
      <div className='flex items-center justify-center pt-3'>
        <button
          className='text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-400 focus:ring-4 focus:ring-gray-500 font-medium rounded-r text-lg'
          onClick={() => i18next.changeLanguage("en")}>English</button>
        <button
          className='text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-500 focus:ring-4 focus:ring-gray-500 font-medium rounded-l text-lg'
          onClick={() => i18next.changeLanguage("it")}>Italiano</button>
      </div>
      <h1 className='mx-auto justify-center max-w-[450px] w-[380px] text-2xl mt-6 font-body text-green-500 text-center'><span className='font-bold'>{application.charAt(0).toUpperCase() + application.slice(1)}pp</span> {t("Order_and_Pay_in")} <span className='font-bold text-orange-500'>{page.company_title}</span></h1>
      <div className='mx-auto bg-gray-400 bg-opacity-70 shadow-xl items-center justify-center max-w-[350px] text-xl h-auto rounded-xl my-10 py-1 '>
        <h1 className='mx-1 text-center'> {t("Dear Client - Cancel")}</h1>
        <button
          className='bg-sky-500 rounded-lg text-white text-2xl font-bold items-center justify-center flex mx-auto mt-2'
          onClick={routeChange}>{t("Restaurant Page")}
        </button>
      </div>
      </div>
    </div>
  )
}

export default Cancel_dely
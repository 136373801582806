import React, { useState,useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "../../style";


const TermsLanguageSwitcher = ({changelang, lan}) => {
  const { i18n } = useTranslation();
  const [lang,setLang] = useState(() =>{
    return lan || "en"
  })
  useEffect(()=>{
    changelang(lan)
    setLang(lan)
  },[lan])
  return (
    <div className={`flex lg:flex-row flex-col pt-20 pb-5`}>
      <div className={`flex-1 flex-col xxl:px-0 md:px-16 px-6`}>
      <select className="rounded-xl p-1"
        value={lang}
        onChange={(e) =>
          changelang(e.target.value)
        }
      >
        <option value="en">English</option>
        <option value="it">Italiano</option>
      </select>
      </div>
      </div>

  );
}
export default TermsLanguageSwitcher;

import React , {useContext, useState, useEffect} from 'react'
import MainPicture from '../../data/BadBros/main-page.jpg'
import { useTranslation } from 'react-i18next';
import { LoginDelyContext } from '../../contexts/LoginDelyContext'

const HeroTestDely = () => {
  const {t} = useTranslation()
  const User = useContext(LoginDelyContext)
  const page = User.hmi_CL_info
  const restaurantName = page.company_title || ""
  const restaurantType = page.restaurant_description || ""
  const [image,setImage] = useState()
  const restaurant_id = page.user_id
  const application = process.env.REACT_APP_APPLICATION_NAME

  const getImage = async ()  => {
    let body = JSON.stringify({title: "main_picture", restaurant_id: restaurant_id});
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    const res = await fetch(backend + '/api/category/get-dish-picture', {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'content-type': 'application/json',
        responseType: 'blob',
      },
      body,
    }).catch(err=>console.log(err))
    if (res.status===302) {
      const imageBlob = await res.blob();
      const imageObjectURL = URL.createObjectURL(imageBlob);

      const file = await blobToBase64(imageObjectURL)
      setImage(file)
      URL.revokeObjectURL(imageBlob)

    }else{
      setImage("")
    }
    
  }

  const blobToBase64 = (url) => {
    return new Promise(async (resolve, _) => {
      // do a request to the blob uri
      const response = await fetch(url);
  
      // response has a method called .blob() to get the blob file
      const blob = await response.blob();
  
      // instantiate a file reader
      const fileReader = new FileReader();
  
      // read the file
      fileReader.readAsDataURL(blob);
  
      fileReader.onloadend = function(){
        resolve(fileReader.result); // Here is the base64 string
      }
    });
  };
  useEffect(()=>{
    getImage()
  },[])
  return (
    <div className='max-w-[1640px] min-h-[300px] h-full sm:h-[500px] mx-auto p-4'>
      <div className='relative'>
        {/*Overlay */}
        <div className='absolute w-full h-full rounded-xl text-gray-200 bg-black/40 flex flex-col justify-center'>
          <h1 className='absolute top-0 px-4 text-3xl md:text-4xl lg:text-6xl font-bold'> <span className ='text-orange-500'>{restaurantName} <br /></span><span className='text-teal-400 text-2xl md:text-3xl lg:text-4xl '>{restaurantType}</span></h1> 
          <h1 className='px-4 pt-[120px] text-2xl sm:text-3xl md:text-4xl lg:text-6xl font-bold text-teal-400'>{application.charAt(0).toUpperCase() + application.slice(1)}</h1>
          <h1 className='px-4  text-2xl sm:text-3xl md:text-4xl lg:text-6xl font-bold'><span className ='text-teal-400'>{t("Innovation")}</span>{t("Food Ordering")}</h1>
        </div>
        {!image || image.length===0 ? 
        
        
        <div className='bg-gray-400 w-full h-full -z-10'>
        </div>
        : 
        <img className='object-cover items-center w-full rounded-xl sm:h-[500px] sm:object-center -z-10 justify-center' 
          src={image} />
        
        }
        
      </div>
    </div>
  );
};

export default HeroTestDely
import React, { createContext, useState, useEffect,} from 'react'
import axios from "axios";

export const HMICLContext = createContext({

    HMI_CL_Params: {
      tableID: "",
      userID: "",
      receipt: "",
      
    },
    users:[],
    usersDishes:[],
    userDishList:[],
    all_tables:[],
    getUserTable: () => {},
    postUser: () => {},
    postTable: () => {},
    getReceiptURL: () => {},
    getUserTableDishes: () => {},
    getUserDish: () => {},
    getAllTablesSorted: () => {},
    deleteTable: () => {},
    stateall_tables: () => {},

  
})

export function HMICLProvider({children}){
  
  const [userSelected, setUserSelected] = useState(()=>{
    const saved = localStorage.getItem("user");
    const initialValue = JSON.parse(saved);
    return initialValue || ""
  })
  const [table, setTable] = useState(()=>{
    const saved = localStorage.getItem("table");
    const initialValue = JSON.parse(saved);
    return initialValue || ""
  })
  const [receiptURL , setReceiptURL] = useState(()=>{
    const saved = localStorage.getItem("receipt");
    const initialValue = JSON.parse(saved);
    return initialValue || ""
  })
  const [users, setUsers] = useState([])
  const [usersDishes, setUsersDishes] = useState([])
  const [userDishList, setUserDishList] = useState([])
  const [all_tables , setAll_tables] = useState([])


  const stateusers = (newusers) =>{
    setUsers([...newusers])
  }

  const stateusersDishes = (newusersDishes) =>{
    setUsersDishes([...newusersDishes])
  }

  const stateuserDishList = (usersDishesList) =>{
    setUserDishList([...usersDishesList])
  }

  const stateall_tables = (alltables) =>{
    console.log(alltables)
    setAll_tables(alltables)
  }

  const postUser = async (userID, tableID, restaurant_id) => {
    
    const body = JSON.stringify({
      userId: userID,
      tableId: tableID,
      restaurant_id:restaurant_id,
    })
    console.log(body)
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    const res = await axios.post(backend + "/api/table/post-user/", body, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        //'X-CSRFTOKEN': csrfCookie,
        
      }
    }).catch(err =>{console.log(err) 
      return err.response});

    console.log(res)

    const data = await res.data;
    if (res.status === 200) {

      const response = {
        stat: res.status,
        data: data
      } 
      setUserSelected(userID)
      return res;
    } else {
      const response = {
        stat: res.status,
        data: data
      } 
      setUserSelected("") 
      return res
    }
    
  }

  const postTable = (tableID) => {
   
      setTable(tableID)
  }

 const getReceiptURL = async () => {
  //const res = await axios.get("/api/stripewebhook/getReceiptURL")
  const res = await axios.get("/api/stripe/getReceiptURL/")
      .catch(function(error){
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(error.config);
      });
      console.log(res.data)
      setReceiptURL(res.data)
  }


  const getUserTable = async (tableId, restaurant_id) => {
  const body = JSON.stringify({tableId: tableId,restaurant_id:restaurant_id})
  let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
  const res = await axios.post(backend + '/api/table/getUserTable/', body, {
    withCredentials: true,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      //'X-CSRFTOKEN': csrfCookie,
      
    }
  }).catch(err =>{console.log(err) 
    return err.response});

  console.log(res)

  const data = await res.data;
    if (res.status === 200) {
      console.log(data)
      stateusers(data)
      const resp = {
        data: data,
        status:res.status
      }
      return resp
    }else{
      setUsers([])
      const resp = {
        data: data,
        status:res.status
      }
      return resp
    }
  }

  const getUserTableDishes = async (tableId,restaurant_id) => {
    const body = JSON.stringify({tableId: tableId,restaurant_id:restaurant_id})
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    const res = await  axios.post(backend + '/api/table/getUserTableDishes/', body, {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        //'X-CSRFTOKEN': csrfCookie,
        
      }
    }).catch(err =>{console.log(err) 
      return err.response});
  
    console.log(res)
  
    const data = await res.data;
    if (res.status === 200) {

        stateusersDishes(data)
        const response = {
          data:data,
          status:res.status
        }

        return response
      }else{
        stateusersDishes([])
        const response = {
          data:data,
          status:res.status
        }
        return response
      }
    }

  
  const getUserDish = async (userID, tableID,client_id, restaurant_id) => {
    const body = JSON.stringify({tableId: tableID, userId: userID, client_id:client_id, restaurant_id: restaurant_id})
    console.log(body)
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    const res = await axios.post(backend + '/api/table/getDishFromUser/', body, {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        //'X-CSRFTOKEN': csrfCookie,
        
      }
    }).catch(err =>{console.log(err) 
      return err.response});
  
    console.log(res)
  
    const data = await res.data;
    if (res.status === 200) {

          stateuserDishList(data)
          console.log(data)
          const response = {
            data: data,
            status: res.status
          }
          return response
        }else{
          const response = {
            data: data,
            status: res.status
          }
          stateuserDishList([])
          return response
        }
  }

  const getAllTablesSorted = async(restaurant_id) =>{
    const body = JSON.stringify({restaurant_id:restaurant_id})
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    const res = await  axios.post(backend + '/api/table/getAllTables/', body, {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        //'X-CSRFTOKEN': csrfCookie,
        
      }
    }).catch(err =>{console.log(err) 
      return err.response});
  
    console.log(res)
  
    const data = await res.data;
    if (res.status === 200) {
      console.log(data)
      //stateall_tables(data)
      const response = {
        data:data,
        status:res.status
      }
      return response;
    } else {
      console.log(data)
      //stateall_tables([])
      const response = {
        data:data,
        status:res.status
      }
      return response;
    }
  }

  const deleteTable = async(tableID , restaurant_id) => {
    const body = JSON.stringify({tableId: tableID,restaurant_id:restaurant_id})
    let backend = ""
      if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
        backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
      }
    const res = await axios.post(backend + '/api/table/deleteTable/', body, {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        //'X-CSRFTOKEN': csrfCookie,
        
      }
    }).catch(err =>{console.log(err) 
      return err.response});
  
    console.log(res)
  
    const data = await res.data;
    if (res.status === 202) {
        console.log(data)
        getAllTablesSorted(restaurant_id)
        const response = {
          data:data,
          status: res.status
        }
        return response;

      } else {
        console.log(data)
        const response = {
          data:data,
          status: res.status
        }
        return response;
      }
  }
 

  console.log(userSelected)


  const contextValue = {
    HMI_CL_Params: 
      {
        tableID: table,
        userID: userSelected,
        receipt: receiptURL,
      },
    users:users,
    usersDishes: usersDishes,
    userDishList: userDishList,
    all_tables: all_tables,
    postUser,
    postTable,
    getReceiptURL,
    getUserTable,
    getUserTableDishes,
    getUserDish,
    getAllTablesSorted,
    deleteTable,
    stateall_tables,

    
  }
  
  useEffect(() => {
    window.localStorage.setItem('table', JSON.stringify(table));
    window.localStorage.setItem('user', JSON.stringify(userSelected));
    window.localStorage.setItem('receipt', JSON.stringify(receiptURL));
    
  }, [userSelected,table,receiptURL,]);

  console.log(contextValue.all_tables)

  return(
    <HMICLContext.Provider value={contextValue}>
      {children}
    </HMICLContext.Provider>
  )

}

export default HMICLProvider
import React, {useContext, useState, useEffect,} from 'react'
import {HeroTestDely, ListOfFoodTest , Navbar_CL_Mobile_Dely} from '../../components/HMI_CL_Mobile';
import { LoginDelyContext , LoginDelyProvider} from '../../contexts/LoginDelyContext'
import { useNavigate , useLocation, useParams, Navigate} from 'react-router-dom';
import queryString from "query-string";
import {Loading} from '../../components';
import {MenuProvider} from '../../contexts/MenuContext';

const { BACKEND_API_URL } = process.env;

const HMI_CL_Mobile_orders_dely = () => {
  const User = useContext(LoginDelyContext)
  const user = User.client_info
  const settings = User.hmi_CL_info
  const page = User.page_info
  const loading = User.loading
  const location = useLocation();
  const navigate = useNavigate();
  console.log(settings)
  const restaurant_id = settings.user_id.replace(/-/g,'')

  const menu_bg_color = page.menu_bg_color || "white"

  useEffect(() => {
    const values = queryString.parse(location.search);
    const code = values.code ? values.code : null;

    if (code) {
      User.stateLoading(true)
      OnGogglelogin();
    }
  }, []);




  const OnGogglelogin = async () => {
    const res = await User.googleLogin(location.search).then(async res => {
      
      if (res.data.access_token && Object.keys(user.email).length!==0) {
        
        console.log(user.email)
        User.stateLoading(false)
      }
      return res
    }).catch(err => {
      
      console.log(err)
      alert(err)
      User.stateLoading(false)
    });
   
    navigate("")
    window.location.reload()
  }

   
  console.log(settings.menu_bg_color)
  
  return (!restaurant_id || restaurant_id?.length===0 ? <Navigate to="/client-take-delivery?restaurant_id=" /> :
    <LoginDelyProvider>
      <MenuProvider>
      <div style={{backgroundColor: menu_bg_color}}>
      {loading ? <Loading /> : ""}
        <Navbar_CL_Mobile_Dely />
        <HeroTestDely />
        
        {/*<HeadCardsTest />*/}
        <ListOfFoodTest />
      
      </div>
      </MenuProvider>
    </LoginDelyProvider>
  );
}

export default HMI_CL_Mobile_orders_dely
import React, { useState, useContext } from 'react'
import {useNavigate} from 'react-router-dom'
import {LoginDelyContext} from '../../contexts/LoginDelyContext'
import { useTranslation } from 'react-i18next';



const User_Card = () => {
  const {t} = useTranslation()
  const [logout, setLogout] = useState("false")
  const User = useContext(LoginDelyContext)
  const navigate = useNavigate()
  const user = User?.restaurant_info
  const [click , setClick] = useState(false)

  const handlClick = () => {

    setClick(!click)
    

      const res =  User.isAuth().then(async res=>{

        if (res.stat===200){

          User.getUserRestaurant()

        return res
        }
      }).catch(err=>console.log(err))
    }
  

  


  const handleLogout = async (e) => {
    e.preventDefault()
    User.logout();
    setLogout(!logout)
    navigate("/")
  }

  const handleAccount = async (e) => {
    e.preventDefault()

    navigate("/dash/account")
  }
  const handleSettings = async (e) => {
    e.preventDefault()

    navigate("/dash/settings")
  }





  return (
      <div className="bg-teal-500 hover:bg-teal-700 dark:bg-gray-800 font-semibold hover:text-white w-auto shadow flex justify-center items-center rounded-xl mr-1 cursor-pointer">
        <div onClick={handlClick} className='relative py-3'>
          <div className="flex justify-center items-center  mx-2">
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path></svg>  
              <div className="cursor-pointer mx-2">{user.first_name}</div>
          </div>
          {click ?
            <div className="fixed right-2 w-auto dark:bg-gray-800 bg-white rounded-lg shadow border dark:border-transparent p-5 mt-5 z-50">
              <ul className="space-y-3 dark:text-white">
                <li className="font-medium " 
                    onClick={handleAccount}>
                  <a href="#" className="flex text-teal-600 items-center transform transition-colors duration-200 border-r-4 border-transparent hover:border-indigo-700">
                    <div className="mr-3">
                      <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path></svg>
                    </div>
                    Account
                  </a>
                </li>
                <li className="font-medium"
                  onClick={handleSettings}>
                  <a href="#" className="flex items-center text-teal-600 transform transition-colors duration-200 border-r-4 border-transparent hover:border-indigo-700">
                    <div className="mr-3">
                      <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"></path><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path></svg>
                    </div>
                    {t("Settings")}
                  </a>
                </li>
                <hr className="dark:border-gray-700" />
                <li className="font-medium"
                  onClick={handleLogout}>
                  <a href="#" className="flex items-center  text-red-600 transform transition-colors duration-200 border-r-4 border-transparent hover:border-red-600">
                    <div className="mr-3 text-red-600">
                      <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"></path></svg>
                    </div>
                    {t("Logout")}
                  </a>
                </li>
              </ul>
            </div>
            : ""}
        </div>
      </div>
  )
}

export default User_Card
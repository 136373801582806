import { useState } from "react";

import { close, logo, menu } from "../../data";
import { navLinks } from "../../data/consts";
import {Navigate , useNavigate} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LanguageSwitcher } from "../../page/Dashboard";

const Navbar = ({use}) => {
  const {t} = useTranslation()
  const [active, setActive] = useState("Home");
  const [toggle, setToggle] = useState(false);
  const navigate = useNavigate();
  const application = process.env.REACT_APP_APPLICATION_NAME
  return (
    <nav className=' flex py-4 justify-between items-center navbar '>
      <img src={logo} alt={application} className="w-[50px] h-[50px] cursor-pointer" 
      onClick={()=>{navigate("/")}}/>
      <h1 className="text-3xl font-bold text-green-600 mx-2 cursor-pointer"
      onClick={()=>{navigate("/")}}>{application.charAt(0).toUpperCase() + application.slice(1)}</h1>
      <LanguageSwitcher />
      <ul className="list-none md:flex hidden justify-end items-center flex-1">
        {navLinks.map((nav, index) => (
          <>
          {nav?.use.some(uses =>uses===use) ? 
          <li
            key={nav.id}
            className={`font-poppins font-normal cursor-pointer text-[14px] lg:text-[16px] ${
              active === nav.title ? "text-white" : "text-dimWhite"
            } ${index === navLinks.length - 1 ? "mr-0" : "mr-10"}`}
            onClick={() => setActive(nav.title)}
          >
            {nav.id==="register" ?
              <a className="text-white" href={`${nav.link}`}>{t(nav.title)}</a>
              :
              <a className="text-white" href={`${nav.id}`}>{t(nav.title)}</a>
            }  
          </li>
          :""}
          </>
        ))}
      </ul>

      <div className="md:hidden flex flex-1 justify-end items-center ">

        <img
          src={!toggle ? menu : ""}
          alt="menu"
          className="w-[28px] h-[28px] object-contain"
          onClick={() => setToggle(!toggle)}
        />

        <div
          className={`${
            !toggle ? "hidden" : "flex"
          } p-6 bg-gradient-to-r from-green-700 to-[#08788c] absolute top-0 right-0 mx-4 mt-2 min-w-[140px] rounded-xl z-10`}
        >

          <img
            src={toggle ? close : ""}
            alt="menu"
            className="w-[28px] h-[28px] object-contain z-10 absolute top-6 right-2"
            onClick={() => setToggle(!toggle)}
          />  


          <ul className="list-none flex justify-end items-start flex-1 flex-col">
            {navLinks.map((nav, index) => (
              <>
              {nav?.use.some(uses =>uses===use) ?
              <li
                key={nav.id}
                className={`font-poppins font-medium cursor-pointer text-[16px] ${
                  active === nav.title ? "text-white" : "text-dimWhite"
                } ${index === navLinks.length - 1 ? "mb-0" : "mb-3"}`}
                onClick={() => setActive(nav.title)}
              >{nav.id==="register" ?
                <a className="text-white" href={`${nav.link}`}>{t(nav.title)}</a>
                :
                <a className="text-white" href={`${nav.id}`}>{t(nav.title)}</a>}
              </li>
              :""}
              </>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

import React , {useState, useEffect, useContext} from 'react'
import { MenuContext } from '../../../contexts/MenuContext'
import { useTranslation } from 'react-i18next';
import {LoginDelyContext} from '../../../contexts/LoginDelyContext';

import axios from 'axios';


const AddModifyCategory = (props) => {

const menus = useContext(MenuContext)
//const [menu, setMenu]= useState([])
const menu = menus.menus
const categories = menus.list_category
const customDish = menus.customDish
const ingredients = menus.ingredients
const {t} = useTranslation();
const User = useContext(LoginDelyContext)
const settings = User.page_info
const [message, setMessage]= useState("")
const restaurant_id = settings.user_id
const option = props.option
const [selected, setSelected] =useState("")
const [category, setCategory] = useState("")

useEffect(()=>{
  if (menus.menus.length === 0) {
    menus.fetchMenu(restaurant_id);
  }else  if (menus.customDish.length === 0) {
    menus.fetchAddIns(restaurant_id);
  }else if (menus.ingredients.length === 0) {
    menus.fetchIngredients(restaurant_id);
  }
},[])


 const handleCategoryAdd = async (e) => {
  setMessage("")
  e.preventDefault();
  console.log(category)
  //{"ingredient": "menu", "category": [{"name":categ.name}]}
  if (category.length<3){
    setMessage(t("The name is too short please the category must be 3 digits minimum"))
    return
  }
  else if (categories.find(ingredient=>ingredient.ingredient===option).category.some(categ=>categ.name===category)){
    setMessage(category + " " + t("Already Exist"))
    return
  }else{
    const new_categories = categories
    const new_menu = menu
    const new_customDish = customDish
    const new_ingredients = ingredients
    new_categories.find(ingredient=>ingredient.ingredient===option).category.push({name:category})
    menus.stateCategory(new_categories)
    setCategory("")
    if (option==="menu"){
      new_menu.push({name:category,dish_list:[]})
      menus.stateMenu(new_menu)
    }else if(option==="ingredients"){
      console.log(new_ingredients)
      new_ingredients.push({name:category,ingredient_list:[]})
      menus.stateIngredients(new_ingredients)
    }else{
      new_customDish[0].dish_list.find(dish=>dish.name===option)["ingredients"] = [{name:category,ingredient_list:[]}]
      menus.stateCastomDish(new_customDish)
    }
    
    
  }
 
  
 }

  console.log(menu)
  console.log(categories)

  const handleCategoryDel = async (e) => {
    e.preventDefault();
    const new_categories = categories
    const new_menu = menu
    const new_customDish = customDish
    const new_ingredients = ingredients
    props.handleCategorySelected("")
    if(option==="menu"){
     
      const db_menu = await menus.fetchMenu(restaurant_id).then(async res=>{
        const resp = await res
        if (resp.some(categ=>categ.name===selected)){
        
          const res = await menus.deleteCategory(selected).then(async res=>{
          const resp = await res
          console.log(resp)
          
          
          new_categories.find(ingredient=>ingredient.ingredient===option).category.splice(new_categories.find(ingredient=>ingredient.ingredient===option).category.findIndex(categ=>categ.name===selected), 1)
          menus.stateCategory(new_categories)
          
          setSelected("")
          
          })
        }else{
          
          new_categories.find(ingredient=>ingredient.ingredient===option).category.splice(new_categories.find(ingredient=>ingredient.ingredient===option).category.findIndex(categ=>categ.name===selected), 1)
          menus.stateCategory(new_categories)
          menus.fetchMenu(restaurant_id)
          /*new_menu.splice(menu.findIndex(categ=>categ.name===selected), 1)
          menus.stateMenu(new_menu)
          window.localStorage.setItem('category', JSON.stringify(category));
          setSelected("")*/

          
        }
      })
    }else if(option==="ingredients"){
      const db_custom_ingredients = await menus.fetchIngredients(restaurant_id).then(async res=>{
        const resp = await res
        if (resp.find(ingredient=>ingredient.ingredients===option).category.some(categ=>categ.name===selected)){
          const res = await menus.DeleteCategory_ingredients(option,selected).then(async res=>{
          const resp = await res
          console.log(resp)
      
          new_categories.find(ingredient=>ingredient.ingredient===option).category.splice(new_categories.find(ingredient=>ingredient.ingredient===option).category.findIndex(categ=>categ.name===selected), 1)
          menus.stateCategory(new_categories)

          new_customDish.find(ingredient=>ingredient.ingredients===option).category.splice(new_customDish.find(ingredient=>ingredient.ingredients===option).category.findIndex(categ=>categ.name===selected), 1)
          window.localStorage.removeItem('category', JSON.stringify(category));
          menus.stateCastomDish(new_customDish)



          setSelected("")

          })
        }else{

          new_categories.find(ingredient=>ingredient.ingredient===option).category.splice(new_categories.find(ingredient=>ingredient.ingredient===option).category.findIndex(categ=>categ.name===selected), 1)
          menus.stateCategory(new_categories)
          menus.fetchAddIns(restaurant_id)
          /*new_customDish.find(ingredient=>ingredient.ingredients===option).category.splice(new_customDish.find(ingredient=>ingredient.ingredients===option).category.findIndex(categ=>categ.name===selected), 1)
          menus.stateCastomDish(new_customDish)*/

          setSelected("")
          
        }
      })

    }else{
      
        if (customDish[0].find(cd=>cd.name===option).ingredients.some(categ=>categ.name===selected)){
          const res = await menus.DeleteCategory_CD(option,selected).then(async res=>{
          const resp = await res
          console.log(resp)
      
          new_categories.find(ingredient=>ingredient.ingredient===option).category.splice(new_categories.find(ingredient=>ingredient.ingredient===option).category.findIndex(categ=>categ.name===selected), 1)
          menus.stateCategory(new_categories)

          new_customDish[0].find(cd=>cd.name===option).ingredients.splice(new_customDish[0].find(cd=>cd.name===option).ingredients.findIndex(categ=>categ.name===selected), 1)
          window.localStorage.removeItem('category', JSON.stringify(category));
          menus.stateCastomDish(new_customDish)
          setSelected("")

          })
        }else{

          new_categories.find(ingredient=>ingredient.ingredient===option).category.splice(new_categories.find(ingredient=>ingredient.ingredient===option).category.findIndex(categ=>categ.name===selected), 1)
          menus.stateCategory(new_categories)
          menus.fetchAddIns(restaurant_id)
          /*new_customDish.find(ingredient=>ingredient.ingredients===option).category.splice(new_customDish.find(ingredient=>ingredient.ingredients===option).category.findIndex(categ=>categ.name===selected), 1)
          menus.stateCastomDish(new_customDish)*/
          setSelected("")
        }
    }
  }
 
  console.log(categories)
  if(!categories.find(ingredient=>ingredient.ingredient==="ingredients")){
    const new_categ = {
      ingredient: "ingredients",
      category: [],
    }
    categories.push(new_categ)
  }

  if(!categories.find(ingredient=>ingredient.ingredient===option)){
    const new_categ = {
      ingredient: option,
      category: [],
    }
    categories.push(new_categ)
  }
  return (
    <div className='grid grid-rows-2 justify-center items-center lg:grid-cols-2'>
      <div className='grid grid-flow-row '>
        <p className='justify-center items-center flex mb-2'>{t("Create new Category")}</p>
        <div className='grid grid-flow-row mx-auto md:grid-flow-col justify-center items-center'>
          <input type='text'
              value={category}
              onChange={(e)=>{
                setMessage("")
                setCategory(e.target.value.toLowerCase())}}
              className='flex placeholder:text-gray-700 p-2 outline-none rounded-lg items-center justify-center mx-1' 
              placeholder={t("Create new Category")}/>
          <button 
            onClick={handleCategoryAdd}
            className='flex px-3 h-[35px] items-center bg-red-400 rounded-xl mx-auto hover:bg-red-300  hover:text-gray-300 text-center'> + {t("Add")}</button>
        </div>
        <p className='justify-center items-center flex mb-2 text-center font-semibold text-red-500'>{message}</p>
      </div>
      <div className='grid grid-flow-row'>
        <p className='justify-center items-center flex mb-2'>{t("List of Category Created")}</p>
        <div className='grid grid-flow-row mx-auto md:grid-flow-col'>
          <div className='max-h-[150px] w-[150px] overflow-auto max-w-[150px] bg-white rounded-xl p-2 flex-row'>
            
          {categories?.find(ingredients=>ingredients.ingredient===option).category?.map((category)=>(
           <p
            key={category?.name}  
            className={`p-2 text-sm hover:bg-sky-600 hover:text-white ${category?.name?.toLowerCase() === selected?.toLowerCase() && 'bg-sky-600 text-white'}`}
            onClick={()=>{
              if(category?.name?.toLowerCase() !== selected.toLowerCase()){
                setSelected(category?.name);
                
              }}}
            >
            {category?.name}
          </p>
          ))}
          </div>
          <button 
            onClick={handleCategoryDel}
            className='flex px-3 h-[35px] items-center bg-red-400 rounded-xl mx-1 hover:bg-red-300 text-center hover:text-gray-300 '> - {t("Remove")}</button>

        </div>

      </div>


    </div>




  );
};

export default AddModifyCategory
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import {AiOutlinePlus} from 'react-icons/ai'




const DishDetails = ({onClose, visible,tableID,orders}) => {
  //const [filterClients, setFilterClients] = useState(clients)

  const initialvalue = () =>{
    let intialval = []
    orders.map(table => {
      if (table.tableID ===tableID){
        intialval = table.orders
        
      }

    })
    return intialval
  }
  const [clients, setClients] = useState(initialvalue)
  const [filterClients, setFilterClients]= useState(clients)
  const {t} = useTranslation()
  const filterDishesSts = (filter) => {
    let dishesfiltered = []
    clients.map(user =>{
      //filterdish = [...filter,{user_id: user, dishes: dishes}]

      const filtered = {"user_id": user.user_id, "dishes" : user.dishes.filter(dish=>dish.sts===filter)}
      dishesfiltered = [...dishesfiltered, filtered]})

    setFilterClients(dishesfiltered)
  }
  let table_cost = 0
  clients.map((client)=>{
    client.dishes.map(dish=>{
      table_cost += (dish.quantity * dish.prezzo)
    })
        
  })

  console.log(clients)

  const filterClient = (user)=>{
    let totalcost = 0
    clients.map((client)=>{
      if (client.user_id === user){
        client.dishes.map((dish)=>{
          totalcost += ( dish.quantity * dish.prezzo)
        })
      }
    })
    return totalcost.toFixed(2)
  }

  let selectedStyle = ""
  let selectedButton = ""

  if (clients?.length !==0){
    let dishList = []
    clients?.map( users=>{
      dishList.push(users.dishes)
    })

    console.log(dishList.some(dish =>dish.sts !=="paid"))
    if( dishList.some(dish =>dish.sts !=="paid")){

      selectedStyle="bg-orange-200"
      selectedButton="bg-orange-300"
      
    }else {

      selectedStyle="bg-green-200"
      selectedButton="bg-green-300"
      
    }
    console.log(dishList)
    }else{
    selectedStyle="bg-gray-200"
    selectedButton="bg-gray-300"
  }

  const userStyle = (user) =>{
    let style = ""
    clients?.map( users=>{
      if (users.user_id === user){
        if (users.dishes.length === 0){
          style = "bg-gray-200"
        }else if (users.dishes.every(dish =>dish.sts ==="paid")){
          
          style = "bg-green-200" 
        }else {
          style = "bg-orange-200"
        }
      }
    })
    return style
  }


  if(!visible) return null;
  return (
    <div className='fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center z-50' >

      <div className='bg-white px-1 rounded w-[680px] h-[620px] max-h-[900px] relative overflow-y-auto flex flex-col justify-between'>
        
       
        <header className={`top-0 w-full sticky border-gray-400 border-b-1 py-4 ${selectedStyle} `}>
          <span className='flex items-center justify-center font-bold text-3xl'>{t("Table")} {tableID} </span>
          <button type="button" 
          className={`absolute top-2 right-1 rounded-md p-2 inline-flex items-center justify-center text-black hover:text-bold hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:bg-indigo-500 z-20 ${selectedButton}`}
          onClick={onClose}>
            <span className="sr-only">Close menu</span>
            {/*Heroicon name: outline/x*/}
            <svg className="h-8 w-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
         </button>
         
        </header>
        <div className='sticky top-[70px] flex mx-auto items-center justify-center w-full bg-white py-2 h-auto'>
          <button
            className='text-gray-900 bg-gray-300 border-1 border-gray-400 focus:outline-none hover:bg-gray-500 focus:ring-4 focus:ring-gray-600 focus:text-white font-medium rounded-r text-2xl px-3'
            onClick={() => setFilterClients(clients)}>{t("All")}</button>
          <button
            className='text-gray-900 bg-green-300 border-1 rounded-none border-green-400 focus:outline-none hover:bg-green-500 focus:ring-4 focus:ring-green-600 focus:text-white font-medium  text-2xl px-3'
            onClick={() => filterDishesSts("paid")}>{t("Paid")}</button>
            <button
            className='text-gray-900 bg-orange-300 border-1 border-orange-400 focus:outline-none hover:bg-orange-500 focus:ring-4 focus:ring-orange-600 focus:text-white font-medium rounded-l text-2xl px-3'
            onClick={() => filterDishesSts("new")}>{t("Not Paid")}</button>
         </div>
        <div className='grid grid-cols-1 sm:grid sm:grid-cols-2 items-center justify-center gap-4 my-2 mx-3'>

          {filterClients?.map((client,idx1)=>
            <div key={idx1} className='rounded-lg mx-auto w-full h-full font-semibold text-xl  border-1 justify-between flex flex-col shadow-xl'>
             

              <p className={`border-b-1  text-center py-1 ${userStyle(client.user_id)}`}>{client.user_id}</p>
            
            {client.dishes.map((dish, idx2)=>(
              <>
              {dish.custom ?
              <li key={idx2} className='m-1 font-normal text-lg py-1'>{dish.category} {`(€${Number(dish.prezzo).toFixed(2)})`} x {dish.quantity} = €{Number((dish.prezzo) * dish.quantity).toFixed(2)}</li>
              : 
              <li key={idx2} className='m-1 font-normal text-lg py-1'>{dish.name} {`(€${Number(dish.prezzo).toFixed(2)})`} x {dish.quantity} = €{Number((dish.prezzo) * dish.quantity).toFixed(2)}</li>
              }
              </>
            ))}
              <p className='bg-blue-100 text-center border-t-1 py-1'>Total €{filterClient(client.user_id)}</p>
            </div>
          )}
          <div className='mx-auto w-full h-full font-semibold text-xl  border-1 justify-center flex items-center bg-orange-100 rounded-lg shadow-xl'>
          <AiOutlinePlus  color="black" size={60}/>
          </div>
        </div>
        <div className='bottom-0 border-t-1 bg-blue-100 border-gray-400 sticky  items-center text-center py-3 font-bold text-3xl'> {t("Total of Table")}: €{table_cost.toFixed(2)}
      </div>
      </div>
      
    </div>
  );
}

export default DishDetails
import React , {useContext, useState, useEffect}from 'react'
import { LoginDelyContext } from '../../contexts/LoginDelyContext'
import { HMICLContext } from '../../contexts/HMICLContext'
import { useTranslation } from 'react-i18next';
import {Register_CL, Login_CL} from './LogIn_Dely';
import {IoIosArrowBack} from 'react-icons/io'
import {PayDishesBooked} from './'
import {AiFillDelete, AiFillEye,AiOutlineUpload} from 'react-icons/ai';



const UserButtonModalDely = ({onClose, visible, orders_selected, type}) => {

  const User = useContext(LoginDelyContext)
  const params = useContext(HMICLContext)
  const {t} = useTranslation()
  const[currentForm, setCurrentForm] = useState('login');
  const page=User.hmi_CL_info
  const subscription_type = page.sub_type.split("_")[0]
  const user = User.client_info
  const restaurant_id = page.user_id
  const restaurant = false
  const orders = User.client_orders
  const [total_cost,setTotal_cost] = useState(0)
  const [showOrder, setShowOrder] = useState(false)
  const [userID , setUserID] = useState("")
  const [tableID , setTableID] = useState("")
  const [dishes , setDishes] = useState([])
  const order_without_pay = page.order_without_pay || false

  const get_date = (date) => {
    console.log(date)
    const new_date = date.split("-")
    const new_time = new_date[2].split("_")
    console.log(new_date,new_time)
    const utcDate = new Date(Date.UTC(new_date[0], new_date[1]-1, new_time[0],new_time[1],new_time[2],new_time[3]))
    console.log(utcDate)
    const local_date = utcDate.toLocaleString()
    console.log(local_date)
    return local_date
  }
  const [details, setDetails] = useState({})
  const [selected, setSelected] = useState(()=>{
    const saved = sessionStorage.getItem("client_option_selected");
    const initialValue = JSON.parse(saved);
    return  orders_selected || initialValue || "account"
  } )
  useEffect(()=>{
    window.sessionStorage.setItem('client_option_selected', JSON.stringify(selected));
 },[selected]);

  console.log(user,orders)

  const toggleForm = (formName) => {
    setCurrentForm (formName)
  }

  const initialstate = async () => {
    const CLParams = params.HMI_CL_Params
    const userid = await CLParams.userID
    const tableid = await CLParams.tableID
    const client_id = user.user_id
    console.log(restaurant_id)
    let dishes_orded = []
    let totalCost = 0
    dishes_orded = await params.getUserDish(userid,tableid, client_id,restaurant_id).then(async(res)=>{
      const resp = await res.data
      console.log(res)
      return resp
    })
    dishes_orded?.map((cartItem)=>{
      console.log(dishes_orded)
      totalCost = totalCost + (cartItem.prezzo * cartItem.quantity); 
      
      console.log(totalCost)
    });
    setTotal_cost (totalCost)
    setTableID(tableid)
    setUserID(userid)
    setDishes(dishes_orded)
  }

  useEffect(()=>{
    User.get_client_orders(restaurant_id,user.user_id)
    console.log(user.email)
    if (type==="table_order"  && Object.keys(user.email).length>0){
      initialstate()

    }

  },[])

  const order_status = ["new","payement failed", "modified","paid","payed","confirmed","under preparation", "in delivery", "delivered","deleted", "completed","canceled","under reimbursement","refunded"] 



  const status_style = (sts) =>{
    let style = ""
    /*"new","modified","payed","confirmed","under preparation", "in delivery", "delivered"*/
    if (sts==="new"){
      style = "text-yellow-800 bg-orange-300 bg-opacity-50"
    }else  if (sts==="payed" || sts==="paid"){
      style = "text-green-700 bg-yellow-300 bg-opacity-50"
    }else  if (sts==="confirmed"){
      style = "text-blue-700 bg-gray-300 bg-opacity-50"
    }else  if (sts==="under preparation"){
      style = "text-yellow-800 bg-yellow-300 bg-opacity-50"
    }else  if (sts==="in delivery"){
      style = "text-yellow-800 bg-yellow-300 bg-opacity-50"
    }else  if (sts==="delivered"){
      style = "text-green-800 bg-green-300 bg-opacity-50"
    }else  if (sts==="deleted"){
      style = "text-yellow-800 bg-orange-300 bg-opacity-50"
    }else  if (sts==="modified"){
      style = "text-red-800 bg-red-300 bg-opacity-50 underline"
    }else  if (sts==="completed"){
      style = "text-blue-800 bg-blue-300 bg-opacity-50 "
    }else  if (sts==="under reimbursement"){
      style = "text-red-800 bg-yellow-300 bg-opacity-50 underline"
    }else  if (sts==="refunded"){
      style = "text-blue-800 bg-yellow-300 bg-opacity-50 underline"
    }
    return style
  }


  console.log(details?.type)

  if(!visible) return null
  return (
    <div className='fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center z-50' >

      <div className='bg-gray-100 px-1 rounded max-h-[600px] relative overflow-y-auto  md:max-w-[700px] md:max-h-[100vh] w-full '>
        <header className='top-0 w-full sticky border-gray-400 border-b-1 py-3 bg-blue-200 z-20'>
          {type === "table_order" ?

            <span className='flex items-center justify-center font-bold text-xl'>{t("Hi") + " " + userID}</span>
            :
            <span className='flex items-center justify-center font-bold text-xl'>{t("Hi") + " " + user.first_name}</span>
          }

          <button type="button"
            className="absolute top-1 right-1 rounded-md bg-blue-300 p-2 inline-flex items-center justify-center text-black hover:text-bold hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:bg-indigo-500 z-20"
            onClick={onClose}>
            <span className="sr-only">Close menu</span>
            {/*Heroicon name: outline/x*/}
            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </header>
        {Object.keys(user.first_name).length === 0 ?
          <div className='flex items-center justify-center my-4'>
            {
              currentForm === "login" ? <Login_CL onFormSwitch={toggleForm} type={type} /> : <Register_CL onFormSwitch={toggleForm} type={type} />
            }
          </div>
          :

          <>
            <ul className="top-[53px] flex items-center justify-center bg-gray-200 mb-5 pb-1 sticky z-10 rounded-b-xl">
              <li className="flex-1 shadow-xl text-center cursor-pointer"
                onClick={() => {
                  setDetails({})
                  setSelected("account")
                  setShowOrder(false)

                }}>
                <button
                  className={`w-full ext-center mx-auto block rounded-t py-2 px-4 ${selected === "account" ? "border-blue-500   bg-blue-500 hover:bg-blue-700 text-white" : "hover:border-gray-200 text-black hover:bg-gray-300"}`}
                >
                  <span className="ml-1 text-center">Account</span>
                </button>
              </li>
              <li className="flex-1 shadow-xl text-center cursor-pointer"
                onClick={() => {
                  setDetails({})
                  setSelected("orders")
                  setShowOrder(false)
                }}>
                <button
                  className={`w-full ext-center mx-auto block  rounded-t py-2 px-4 ${selected === "orders" ? "border-blue-500   bg-blue-500 hover:bg-blue-700 text-white" : "hover:border-gray-200 text-black hover:bg-gray-300"}`}
                >
                  <span className="ml-1 text-center">{t("Your Orders")}</span>
                </button>
              </li>
              <li className="flex-1 shadow-xl text-center cursor-pointer"
                onClick={() => {
                  setDetails({})
                  setSelected("dely_address")
                  setShowOrder(false)
                }}>
                <button
                  className={`w-full ext-center mx-auto block  rounded-t py-2 px-4 ${selected === "dely_address" ? "border-blue-500   bg-blue-500 hover:bg-blue-700 text-white" : "hover:border-gray-200 text-black hover:bg-gray-300"}`}
                >
                  <span className="ml-1 text-center">{t("Delivery Address")}</span>
                </button>
              </li>

            </ul>
            {showOrder ?
              <PayDishesBooked dishes_orded={dishes} totalCost={total_cost} />
              :
              <>
                {selected === "account" ?
                  <div div className='flex-cols items-center justify-center my-1 mx-2 py-1'>
                    <h3 className='text-center font-bold text-xl'>Account</h3>
                    <div className='my-2 grid grid-cols-1 md:grid-cols-2 gap-3 py-2 bg-white rounded-xl shadow-xl '>

                      <h3 className="text-center ">{t("First Name")}: {user.first_name}</h3>
                      <h3 className="text-center "> {t("Email")}: {user.email}</h3>
                      <h3 className="text-center "> {t("Last Name")}: {user.last_name}</h3>
                      <h3 className="text-center "> {t("Phone")}: {user.phone}</h3>

                    </div>
                  </div>
                  : ""}
                {selected === "orders" ?
                  <div>
                    <h3 className='text-center font-bold text-xl'>{t("Your Orders")}</h3>
                    {Object.entries(details).length === 0 ?
                      <>

                        <div className='mx-1 overflow-auto rounded-lg shadow my-4'>
                          <table className="table-auto md:whitespace-nowrap w-full overflow-x-scroll ">
                            <thead>
                              <tr className='items-center justify-between content-center'>
                                <th className='ml-4 text-start tracking-wide w-24'>{t("Actions")}</th>
                                <th className='text-start tracking-wide w-24'>{t("Type Order")}</th>
                                <th className='text-start tracking-wide w-26'>{t("Date")} </th>
                                <th className='text-start tracking-wide w-26'>{t("Status")}</th>
                                <th className='text-start tracking-wide w-28'>{t("Total")}</th>

                              </tr>
                            </thead>
                            <tbody className=''>

                              {orders.length === 0 ?
                                ""
                                :

                                orders?.map((order, idx) => (
                                  <tr className='content-center' key={idx}>
                                    <td className='items-start justify-center mx-auto'>
                                      <AiFillEye
                                        onClick={() => setDetails(order)}
                                        color='blue' size={30}
                                        className='cursor-pointer'
                                      />
                                    </td>
                                    <td className='text-start whitespace-nowrap'> {t(order.type.replace("_", " "))} </td>
                                    <td className='text-start text-xs '> {get_date(order.ts)} </td>
                                    <td className='text-center '><span className={`p-0.5 text-xs font-bold  uppercase tracking-wider rounded-lg ${status_style(order.sts)}`}>{t(order.sts)} </span></td>
                                    <td className='text-start '> €{order.total_cost} </td>


                                  </tr>
                                ))
                              }
                            </tbody>
                          </table>

                        </div>
                      </>
                      :
                      <>
                        <div className='relative'>
                          <IoIosArrowBack size={25} className='absolute top-[-34px] left-2 cursor-pointer'
                            onClick={() => { setDetails({}) }} />
                         <div className='absolute top-[-35px] right-2  text-start '><span className={`p-1.5 text-xs font-bold  uppercase tracking-wider rounded-lg ${status_style(details?.sts)}`}>{t(details?.sts)} </span></div>
                          <p className='text-md text-center font-semibold mt-3 my-1 border-gray-400'>{t("Order") + " N. " + details?._id}</p>
                          <p className='text-md text-center font-semibold my-1 border-gray-400'>{t("Email") + ": " + details?.email}</p>
                          <p className='text-md text-center font-semibold my-1 border-gray-400'>{t("Restaurant") + ": " + details?.restaurant_name}</p>
                          <p className='text-md text-center font-semibold border-b-1 pb-2 border-gray-400'>{t("Date") + ": " + get_date(details?.ts)}</p>
                          <h3 className='text-center mt-2 font-bold text-xl'>{t("List of Food")}</h3>
                          <div className='md:grid md:grid-cols-2 gap-3 border-b-1 pb-1 my-2 border-gray-400'>
                            {details?.items.map((dish, idx) =>
                              <div key={idx} className='flex-col my-3 mx-1 gap-3 text-sm bg-white rounded-xl shadow-xl p-2'>
                                {dish.custom ?
                                  <>
                                    <div className='flex-col items-center text-lg font-bold'>{dish.category + " = € " + Number(dish.prezzo).toFixed(2)}</div>
                                    <div>
                                      {dish.ingredienti.map((ingrediente, idx) =>

                                        <p className='flex-col text-start font-thin text-[10px] '>{ingrediente.name} €{Number(ingrediente.price).toFixed(2)} x {ingrediente.quantity} = €{(Number(ingrediente.price)* ingrediente.quantity).toFixed(2)}</p>

                                      )}
                                    </div>
                                  </>
                                  :
                                  <>
                                    <div className='flex-row items-center text-lg font-bold'>{dish.name + " = € " + Number(dish.prezzo).toFixed(2)}</div>
                                    <p className='text-start font-thin text-[10px] my-1'>{dish.ingredienti}</p>

                                  </>
                                }
                                <h3 className='mx-auto text-center justify-center items-center md:justify-center text-red-500 text-2xl font-bold'>{"€ " + Number(dish.prezzo).toFixed(2) + " x " + dish.quantity + " = € " + (dish.quantity * Number(dish.prezzo)).toFixed(2)}</h3>

                              </div>

                            )}
                            {/*details?.type==="Delivery" ? 
                              <div className='flex-row items-center text-lg mx-1 font-bold'>{t("Delivery Cost") + " = € " + Number(details?.delivery_cost).toFixed(2)}</div>
                              :
                              ""
                              */}
                          </div>
                          {details?.type!=="table_order" ?
                          <div className='mx-1 items-center justify-center flex-col md:flex-row gap-3 md:gap-7 py-1 flex my-3  bg-white rounded-xl shadow-xl'>
                            <p className='text-center'>{t("Delivery Date")}: <span className='font-bold'>{details?.delivery_date.day} {details?.delivery_date.monthstr} {details?.delivery_date.year}</span></p>
                            <p className='text-center'>{t("Time Selected")}: <span className='font-bold'>
                              {details?.delivery_time.begin.hours}:{details?.delivery_time.begin.minutes} - {details?.delivery_time.end.hours}:{details?.delivery_time.end.minutes}</span></p>
                          </div>
                          : "" }
                        </div>
                        {details?.type==="Delivery" ?
                          <div className='grid grid-cols-1 mx-1 md:grid-cols-2 gap-3 py-2 mt-7 bg-white rounded-xl shadow-xl'>
                            <h3 className='text-center'>{t("Address Selected")}: <span className="font-bold ">{details?.delivery_address}</span></h3>
                            <h3 className="text-center ">{t("City")}: {details?.city}</h3>
                            <h3 className="text-center "> {t("Country")}: {details?.country}</h3>
                            <h3 className="text-center "> {t("Postal Code")}: {details?.postal_code}</h3>

                          </div>
                          :
                          ""
                        }
                        {details?.type==="table_order" ?
                          <div className='grid grid-cols-1 mx-1 md:grid-cols-2 gap-3 py-2 mt-7 bg-white rounded-xl shadow-xl'>
                            <p className='text-center font-bold'>{t("Table Details")}</p>
                            <p className='text-center font-semibold'>{t("Table") +": " + details?.tableId}</p>
                            <p className='text-center font-semibold'>{t("User") +": " + details?.userId}</p>

                          </div>
                          :
                          ""
                        }
                        <div className='flex w-[300px] items-center justify-center mx-auto mb-3 py-1 text-2xl px-1 text-center bg-white rounded-xl font-bold shadow-xl mt-3'>{t("Total")}: €{details?.total_cost}</div>
                      </>
                    }
                  </div>
                  : ""}
                {selected === "dely_address" ?
                  <div div className='flex-cols items-center justify-center my-1 mx-2 py-1'>
                    <h3 className='text-center font-bold text-xl pb-2'>{t("Address")}</h3>
                    <h4 className='text-sm text-center underline'>{t("To change Address go to Orders Window for Delivery Food")}</h4>
                    <div className='my-2 grid grid-cols-1 md:grid-cols-2 gap-3 py-2 bg-white rounded-xl shadow-xl '>

                      <h3 className='text-center'>{t("Address Selected")}: <span className="font-bold ">{user.address}</span></h3>
                      <h3 className="text-center ">{t("City")}: {user.city}</h3>
                      <h3 className="text-center "> {t("Country")}: {user.country}</h3>
                      <h3 className="text-center "> {t("Postal Code")}: {user.postal_code}</h3>

                    </div>
                  </div>
                  : ""}
              </>
            }
            <div className='flex bottom-0 border-t-1 bg-blue-200 border-gray-400 sticky items-center justify-center text-center  py-3 font-bold'>
              <button className="flex justify-center items-center mx-2 bg-red-400 hover:bg-red-600 hover:text-white"
                onClick={() => {
                  User.logout(restaurant)
                  onClose()
                }}>
                Log Out
              </button>
              {order_without_pay && type==="table_order"?
                <button className='mx-2 px-2 bg-blue-500 rounded-lg w-auto items-center justify-center  text-white'
                  onClick={() => !setShowOrder(!showOrder)}
                ><span>{t("Booked Dish")}</span></button>
                :
                ""
              }
            </div>
          </>
        }
      </div>
    </div>
  )
}

export default UserButtonModalDely
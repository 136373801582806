import React, {useState ,  useContext, useEffect, useRef} from 'react'
import { Routes, Route , Outlet, Navigate ,useNavigate} from 'react-router-dom';
import { HMI_MN_Dash, HMI_CS, HMI_KT,} from '.'
import { Header_dash, Sidebar_Dash, User_Page, Help_Support, Settings, Manage_Table, Orders, Transaction, ProductListDash} from './Dashboard';
import { Modify_Menu , Table_Waiters} from '../components/DashBoard';
import {  Modify_update_QR } from '../components/DashBoard/TableWaiters';
import {  UpdateCreateDish } from '../components/DashBoard/ModifyMenu';
import {MenuProvider} from '../contexts/MenuContext'
import {LoginDelyContext} from '../contexts/LoginDelyContext'
import {Loading} from '../components';
import {useLoadScript, } from '@react-google-maps/api'
import {AiOutlineClose, AiOutlineMenuUnfold} from 'react-icons/ai'
import { useTranslation } from 'react-i18next';
import { FaCcStripe } from "react-icons/fa";
import axios from 'axios';
import { BiErrorAlt } from "react-icons/bi";
import { HMICLContext } from '../contexts/HMICLContext'
import { Helmet } from 'react-helmet-async';

const Main_Page = () => {

  const User =  useContext(LoginDelyContext)
  const {t} = useTranslation()
  const HMICLParams = useContext(HMICLContext)
  const navigate = useNavigate()
  const user = User?.restaurant_info
  const page = User?.page_info
  const type = user.sub_type.split("_")[0]
  const stripe_account = User?.stripe_account
  console.log(stripe_account)
  const loading = user.loading
  const libraries = ['places', 'maps']
  let libRef = useRef(libraries)
  const {isLoaded} = useLoadScript({
    id:"google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: libRef.current,
  })
  const ref_order = useRef(null)
  const ref_table = useRef(null)
  const ref_sub = useRef(null)
  const [nav,setNav] = useState(false);
  const onClose = () =>{
    setNav(false)
  }
  let message = ""
  const [tables, setTables] = useState([])
  
  const check_sub = async () => {
    if(user.sub_status==="incomplete" || user.sub_status==="past_due"){
      message = "Invoice is not Paid, Please Review the Payment Subscription. Speaty need to be recharded with a new Subsription to work again"
    }else if(user.sub_status==="unpaid"){
      message = "After Several Attempts the Invoice of the Subscription cannot be payed. Please Review the Payment Subscription. Speaty need to be recharded with a new Subsription to work again"
    }else if(user.sub_status==="incomplete_expired"){
      message = "The Invoice Cannot be generate because the Payement as Expired, Please Review the Payment Subscription. Speaty need to be recharded with a new Subsription to work again"
    }else if(user.sub_status==="canceled"){
      message = "The Subscription has been cancelled. Speaty need to be recharded with a new Subsription to work again"
      console.log(message)
    }
  }

  useEffect(()=>{
    ref_sub.current = setInterval(() => check_sub(), 2*60*60*1000)
    return () => {
      if(ref_sub.current){
        clearInterval(ref_sub.current);
      }
    };
  },[])

  useEffect (() =>{
      if (!User.isVerified){
        const res =  User.isAuth().then(async res=>{

          if (res.stat===200){

            const resp= await User.getUserRestaurant().then(async res=>{

              if (res.stat===200){
                const client = user
                Object.entries(client).map(([key, item]) => {
                  client[key] =res.data[key]
                })
                console.log(client)
                User.staterestaurantDel(client)
          
                const settings = page
                console.log(settings)
                Object.entries(settings).map(([key, item]) => {
                  settings[key] = res.data[key] || ""
                  if (key==="user_id"){
                    console.log(settings[key])
                    settings[key]=settings[key].replace(/-/g,"")
                    console.log(settings[key])
                  }
                check_sub()
                })
                console.log(settings)
                User.statepage(settings)
          
              }
            
            }).catch(err=>console.log(err))
          }
        })
      }
    }
  ,[])
  check_sub()
  useEffect(()=>{
    console.log(user)
    console.log(page)
    User.get_restaurant_orders(page.user_id)
    User.get_stripe_account(user.stripe_id, user.sub_stripe_id)
    HMICLParams.getAllTablesSorted(page.user_id).then(async(res)=>{

      const resp = await  res.data
      console.log(resp)
      HMICLParams.stateall_tables(resp)
      setTables(resp)

    })
  },[])

  useEffect(()=>{
    ref_order.current = setInterval(() => {User.get_restaurant_orders(page.user_id)}, 1*60*1000)
    if (type==="premium") {
    ref_table.current = setInterval(() => {HMICLParams.getAllTablesSorted(page.user_id).then(async(res)=>{

      const resp = await  res.data
      setTables(resp)
      HMICLParams.stateall_tables(resp)

    })}, 1*20*1000)
    }
    return () => {
      if(ref_order.current){
        clearInterval(ref_order.current);
      }
      if(ref_table.current){
        clearInterval(ref_table.current);
      }
    };
  },[])



  const handleStripeLink = () =>{
    const body = {stripe_id:stripe_account.connect.id, type:"account_onboarding"}
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    console.log(body)
    axios.post(backend + "/api/stripe/link-account/",body).then((res)=>{
      console.log(res.data)
      if(res.data){
        console.log(res.data)
        window.open(res.data,"_blank")     
      }
    }).catch((err) => {
      alert(err.message)
      console.log(err.message)});
 
    }
  
  const stateTable = (table) =>{
    setTables(table)
  }

  console.log(tables,HMICLParams.all_tables)
  
  if(!isLoaded || Object.values(stripe_account.connect).length===0) return  <Loading />
  return (!User.isVerified ? <Navigate to="/login-register" /> : 
  <MenuProvider>
      <Helmet>
        <title>Speaty - Dashboard</title>
          <meta name="description" content={t('The Best Ordering System for Restaurants e Bars. Orders and Pay directly with Speaty Platform both delivery and order from the table of your restaurant')}/>
          <meta name="keywords" content='food, delivery restaurant, bar, ordering, app, business, cibo, ristorante, ordinazioni, orders, ordini, ordinazione, takeaway, consegna, domicilio'/>
        <link rel='canonical' href="https://speaty.com/dash"/>
      </Helmet>
     <div className="bg-neutral-100 h-[100vh] w-screen overflow-hidden flex flex-row">
      <div className="hidden md:flex ">

        <Sidebar_Dash />

      </div>
      {nav ? 
      <div className="bg-black/80 fixed w-full z-50 top-0 left-0 md:hidden">

        <Sidebar_Dash visible={onClose}/>
        <AiOutlineClose 
          onClick={()=> setNav(!nav)}
          size={20} 
          className='fixed left-[210px] top-2 cursor-pointer text-green-600 md:hidden'
          />
      </div>
     
			: ""}
       <AiOutlineMenuUnfold color='black' size={30} className='fixed cursor-pointer top-6 left-[10px] md:hidden'
       onClick={() => setNav(!nav)}/>
			<div className="flex flex-col flex-1">
				<Header_dash />
        
				<div className="flex-1 p-4 min-h-0 overflow-auto">
          {message.length!==0  ? 
            <div className='bg-teal-50 mb-4 flex items-center justify-center rounded-xl shadow-xl text-red-500 font-semibold mx-3 py-1 -mt-3 cursor-pointer sticky -top-3 z-20 px-2'
            onClick={()=>{navigate("/dash/subscription")}}>
              < BiErrorAlt size={50} color="red" className='mr-3'/>
              {t(message)}</div>
            :
            ""
          }
          {Object.values(stripe_account?.connect.capabilities).some(payment => payment==="inactive")  ? 
          <div className={`bg-teal-50 mb-2 flex items-center justify-center rounded-xl shadow-xl text-teal-500 font-semibold mx-3 py-1 cursor-pointer z-20  sticky px-2  ${message.length!==0 ?"top-[50px] -mt-3 " : "top-0"}  `}
          onClick={handleStripeLink}>
            < FaCcStripe size={50} color="green" className='mr-3'/>
            {t("Please click to complete onboarding to enable payments")}.</div>
          :
          ""
          }
          <Routes path="/dash">

              {/* HMI Manager */}
              <Route path="/account" element={<User_Page />} />
              {/* Mofidy Menu Page for Manager*/}
              
              {/* Mofidy Menu Page for Manager*/}
              <Route path="help" element={<Help_Support />} />
              {/* Mofidy Menu Page for Manager*/}
              <Route path="settings" element={<Settings />} />
              { /* HMI Cash */}
              <Route path="cash" element={<HMI_CS />} />

              {/* HMI Kitchen */}
              <Route path="kitchen" element={<HMI_KT />} />
              
              {/*Manage Table*/}
              <Route path="manage-tables-live" element={<Manage_Table tables={tables} stateTable={stateTable}/>} />

              <Route path="manage-orders" element={<Orders />} />

              <Route path="manage-transaction" element={<Transaction />} />

              <Route path="subscription" element={<ProductListDash dimension={"normal"}/>} />

              <Route path="/manager-dash" >
                <Route index element={<HMI_MN_Dash />} />
                {/* Manage Waiters and Table */}
                <Route path="table-waiters" element={<Table_Waiters />} />
                {/* Create and Update QR Table*/}
                <Route path="table-waiters/table/:id" element={<Modify_update_QR />} />
                
                <Route path="modify-menu" element={<Modify_Menu />} />
                {/* Create and Update Dish*/}
                <Route path="modify-menu/:option/:category/:id" element={<UpdateCreateDish />} />

              </Route>
            
          </Routes>
          <Outlet />
          </div>
        </div>
      </div>
    </MenuProvider>
  );

};

export default Main_Page
import React from 'react'
import { useTranslation } from 'react-i18next';





const DishDetails = ({onClose, visible,detail,name,image,price}) => {

  const linkImage = `${process.env.REACT_APP_FRONTEND_DEV_LINK}${process.env.REACT_APP_HMI_CL_DISHES_IMAGE_LINK}`

  if(!visible) return null;


  return (
    <div className='fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center z-50' >

      <div className='bg-white px-1 rounded w-[700px] h-[600px] relative overflow-y-auto sm:w-[500px] items-center justify-center mx-auto space-y-3'>
        
       
        <header className='top-0 w-full sticky border-gray-400 border-b-1 py-2 bg-green-200'>
          <span className='flex items-center justify-center font-bold text-2xl'>{name} </span>
          <button type="button" 
          className="absolute top-1 right-1 rounded-md bg-green-300 p-2 inline-flex items-center justify-center text-black hover:text-bold hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:bg-indigo-500 z-20"
          onClick={onClose}>
            <span className="sr-only">Close menu</span>
            {/*Heroicon name: outline/x*/}
            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
         </button>
        </header>
        <img src={image} alt={name}
          loading='lazy'
          className='w-full h-[250px] object-cover rounded mx-auto object-center'
        />
        <div className='border-t-1 object-center justify-center items-center mb-2 mx-1 flex-col'>
          <h1 className='text-lg text-center justify-center pt-4'>{detail}</h1>
          <p className='text-2xl font-bold text-center justify-center pt-4'>€{Number(price).toFixed(2)}</p>
        </div>
        
      </div>
      
    </div>
  );
}

export default DishDetails
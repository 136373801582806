import React from 'react'
import applogo from '../data/AppLogo_ReApp.png'


const Loading = () => {
  return (

    <div className='fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center z-50' >
      <div className="flex animate-pulse flex-row items-center h-full justify-center ">
      <img className='w-[80px] '  src={applogo} alt="logo" />
      </div>
    </div>
  )
}

export default Loading
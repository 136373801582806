import React from 'react'
import { ListOfTables, Navbar_WT} from '../../components/HMI_WT';
import { HMIWTProvider } from '../../contexts/HMIWTContext';



const HMI_WT = () => {


  return (
    <div>
      <HMIWTProvider>
        <Navbar_WT />
        <ListOfTables manager={false}/>
      </HMIWTProvider>
    </div>
  );
}

export default HMI_WT
import React, {Suspense,}from 'react'
import { BrowserRouter, HashRouter, Routes, Route , Navigate, useNavigate} from 'react-router-dom';
import {Main_Page, HMI_CS, HMI_KT, No_Page, Home, TermsServices, HelpCenter, HMI_Change_pass} from './page'
import {HMI_WT} from './page/HMI_WT'
import {Layout_Login } from './page/LogIn'
import {HMI_CL_Mobile_test, HMI_CL_Mobile_orders, HMI_CL_Mobile_orders_dely, HMI_CL_Delivery, } from './page/HMI_CL';
import {Cancel, Success,Success_dely, Cancel_dely } from './page/Payment';
import {CartProvider, } from './contexts/CartContext'
import {HMICLProvider} from './contexts/HMICLContext'
import {MenuProvider} from './contexts/MenuContext'
import './i18n'
import './App.css';
import { GoogleOAuthProvider } from '@react-oauth/google';
import  LoginDelyProvider  from './contexts/LoginDelyContext';
import {CookieConsent} from './components';
import {useCookies} from 'react-cookie'
import { LoadScript } from '@react-google-maps/api';

const App = () => {
  const [cookies, setCookies] = useCookies(["__CookieConsentSpeaty"])
  const lib = ['places', 'maps']
  const google_client_Id = process.env.REACT_APP_GOOGLE_CLIENT_ID
  const gmap_key = process.env.REACT_APP_GOOGLE_MAPS_API_KEY
  console.log(cookies)
  return (
   
    <Suspense fallback={null}>
        <LoginDelyProvider>
          <GoogleOAuthProvider clientId={google_client_Id}>
            <MenuProvider >
              <CartProvider>

                <HMICLProvider>
                  <Routes>
                  { /*Home */}
                    <Route path="/" element={<Home />} />


                    { /*Login */}
                    <Route path="/login-register" element={<Layout_Login />} />

                    { /*Terms and Services */}
                    <Route path="/terms-and-services" element={<TermsServices />} />

                    { /*Help & Contact */}
                    <Route path="/help-center/*" element={<HelpCenter />} />

                    { /*Dashboard */}
                    <Route path="/dash/*" element={<Main_Page />} />
                    <Route path="*" element={<Navigate to="/" />} />

                    {/* HMI Waiter (Cameriere) */}
                    <Route path="HMI_WT" element={<HMI_WT />} />

                    {/* HMI Change Password*/}
                    <Route path="/change-password" element={<HMI_Change_pass />} />

                    {/* HMI Client Mobile*/}
                    <Route path={"/m/client-test"} >

                      <Route index element={<HMI_CL_Mobile_test />} />
                      <Route path="orders" element={<HMI_CL_Mobile_orders />} />
                      <Route path="success" element={<Success />} />
                      <Route path="cancel" element={<Cancel />} />
                    </Route>

                    <Route path={"client-take-delivery"} >

                      <Route index element={<HMI_CL_Delivery />} />
                      <Route path="orders-dely" element={<HMI_CL_Mobile_orders_dely />} />
                      <Route path="success-dely" element={<Success_dely />} />
                      <Route path="cancel-dely" element={<Cancel_dely />} />
                    </Route>
                  </Routes>
                </HMICLProvider>
              </CartProvider>
            </MenuProvider>
          </GoogleOAuthProvider>
        </LoginDelyProvider>
        {!cookies["__CookieConsentSpeaty"] &&
        <div className={`sticky bottom-0 mx-auto w-full left-0 z-20`}>
          <CookieConsent />
        </div>
        }
    </Suspense>
  );
};

export default App
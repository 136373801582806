import React from 'react'

const DeleteModalUser = ({open, onclose, children}) => {
  return (
    <div
      onClick={onclose}
      className={`fixed inset-0 z-30 caret-zinc-300 flex justify-center items-center transition-colors ${open ? "visible bg-black/20 " : "invisible"}`}>
      
      <div 
        onClick={(e) => e.stopPropagation()}
        className={`  rounded-xl shadow bg-gray-100 p-6 transition-all ${open ? "scale-100 opacity-100" : "scale-125 opacity-0"}`}>
        <button 
          className='absolute top-2 right-2 p-1 rounded-lg text-gray-400 hover:bg-gray-200 bg-gray-100 hover:text-gray-700'
          onClick={onclose}>
          
          X
        </button>
        {children}
      </div>
      
    </div>
  )
}

export default DeleteModalUser
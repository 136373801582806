import React , {useContext, useState, useEffect} from 'react'
import { LoginDelyContext } from '../../contexts/LoginDelyContext'
import {DeleteModalUser} from './'
import {AiOutlineUpload, AiOutlineCopy} from 'react-icons/ai'
import {HiOutlineExternalLink} from 'react-icons/hi'
import {DropdownComp} from './'
import { useTranslation } from 'react-i18next';
import { MainPictureUpload } from './'
import {SketchPicker} from "react-color";
import { Loading } from '../../components'


const Settings = () => {
  const User = useContext(LoginDelyContext)
  const stripe_account = User?.stripe_account
  const settings = User.page_info
  const restaurant= true
  const {t} = useTranslation()
  const user = User.restaurant_info
  const type = user.sub_type.split("_")[0]
  console.log(settings)
  const restaurant_id = settings.user_id
  const [image, setImage] = useState()
  const intialStateSettings = {
    
    delivery_days: settings.delivery_days || 0,
    start_dely_time_hours: settings.start_dely_time_hours || 0,
    start_dely_time_minutes: settings.start_dely_time_minutes || 0,
    end_dely_time_hours: settings.end_dely_time_hours || 0,
    end_dely_time_minutes: settings.end_dely_time_minutes || 0,
    minutes_dely: settings.minutes_dely || 0,
    company_title: settings.company_title || "",
    delivery_cost:settings.delivery_cost || 1,
    delivery_km: settings.delivery_km || 4,
    client_link: settings.client_link|| "",
    restaurant_description: settings.restaurant_description || "",
    user_id: settings.user_id || "",
    order_without_pay: settings.order_without_pay || false,
    menu_bg_color:settings.menu_bg_color || "white"
  }

  const [newsetting, setNewsetting] = useState(intialStateSettings)
  const [modify, setModify] = useState(false)
  const [colorPicker, setColorPicker] = useState(newsetting.menu_bg_color);
  const [openSubmit, setOpenSubmit] = useState(false)
  const [message, setMessage]= useState("")
  const [status, setStatus] = useState("")
  const [style_status,setStyle_status] = useState("")

  const location = settings.client_link
  const days = [0,1,2,3,4,5,6,7,8,9,10]
  const TimeHours = [10,11,12,13,14,15,16,17,18,19,20,21,22,23,]
  const TimeMinutes = ["00",30]
  const [isChecked, setIsChecked] = useState(intialStateSettings.order_without_pay);
  const minutes = [15,30,60]
  
  localStorage.removeItem("option_selected");

  const getImage = async () => {
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    let body = JSON.stringify({restaurant_id: restaurant_id});;
     
    const res = await fetch(backend + '/api/login/get-QR-delivery', {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'content-type': 'application/json',
        responseType: 'blob',
      },
      body,
    }).catch(err=>console.log(err))
    if(res.status===302){
      const imageBlob = await res.blob();
      const imageObjectURL = URL.createObjectURL(imageBlob);
  
  
      const file = await blobToBase64(imageObjectURL)
      setImage(file)
      URL.revokeObjectURL(imageBlob)
    }
   
  }

  const blobToBase64 = (url) => {
    return new Promise(async (resolve, _) => {
      // do a request to the blob uri
      const response = await fetch(url);
  
      // response has a method called .blob() to get the blob file
      const blob = await response.blob();
  
      // instantiate a file reader
      const fileReader = new FileReader();
  
      // read the file
      fileReader.readAsDataURL(blob);
  
      fileReader.onloadend = function(){
        resolve(fileReader.result); // Here is the base64 string
      }
    });
  };

  useEffect(()=>{
    getImage()
  },[])
    

  const OptionSelected = (selected)=>{

    //{what: which_option, choose: option}
    const cloneSettings = newsetting
    cloneSettings[selected.what] = selected.choose
    console.log(cloneSettings)
    setNewsetting({...cloneSettings})
  }


  const handleIsChecked = () =>{
    setIsChecked(!isChecked);
    let settingClone = { };
    settingClone = {...newsetting }
    settingClone.order_without_pay = !isChecked;
    setNewsetting(settingClone);
  }

  const [trigger, setTrigger] = useState(false)

  console.log(newsetting)
  const handleChange = (e) => {
    let settingClone = { };
    settingClone = {...newsetting }
    settingClone[e.target.name] = e.target.value;
      
    setNewsetting(settingClone);

  };

  const handleChangeColor = (color) => {
    setColorPicker(color)
    let settingClone = {...newsetting }
    settingClone.menu_bg_color = color;
    console.log(settingClone)
    setNewsetting(settingClone);

  };

 

  const handleModify = () => {

    if (modify===true){
      handleDiscard()
    }else{
      setModify(!modify)
    }

  }

  const handleDiscard = () => {
    setMessage("")
    setModify(!modify)
    setNewsetting(intialStateSettings)

  }

  const handleSubmit= (e) => {
    setMessage("")
    e.preventDefault()
    setModify(false)
    Object.entries(newsetting).map(([key, item])=>{
      if (newsetting[key].length===0 ){
        newsetting[key]=settings[key]
      }
    })
    console.log(settings)
    console.log(newsetting)

    const updatesettings = settings

    Object.entries(settings).map(([key, item]) => {
      updatesettings[key] = newsetting[key]
    })

    User.statepage(updatesettings)

    User.update_restaurant_settings(updatesettings,restaurant).then(async res=>{
      const response = await res
      console.log(response)
      if (response.stat ===201){
        setOpenSubmit(false)
        setTrigger(true)
        alert(t("Update successfully"))
      }else{
        setOpenSubmit(false)
        setTrigger(false)
        alert(t("Error to Update, please try again or contact the support"))
      }
    })
    
  }


  let stylemodify = ""
  let color = ""
  let classNameInput=""


  if (modify){
    stylemodify = "" 
    color="bg-gray-100"
    classNameInput = "border-0 px-3 py-3 placeholder-gray-500 text-gray-600 bg-white rounded-lg text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
  }else{ 
    stylemodify = "readonly"
    color="bg-gray-200"
    classNameInput = "border-0 px-3 py-3 placeholder-gray-300 text-gray-600 bg-gray-100 rounded-lg text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"}
  
    const pyament_status = () =>{
      let sts = ""
      console.log(stripe_account)
      if (settings.sub_status==="active"){
        if (Object.values(stripe_account.connect.capabilities).some(payment => payment==="inactive") ){
          sts="Disabled"
          setStatus(sts)
          setStyle_status("text-red-500  bg-opacity-50")
        }else if (Object.values(stripe_account.connect.capabilities).some(payment => payment==="pending")){
          sts="Pending"
          setStatus(sts)
          setStyle_status("text-yellow-600  bg-opacity-50")
        }else if (settings.status!=="active"){
          sts="Active"
          setStatus(sts)
          setStyle_status("text-green-600  bg-opacity-50")
        }
      }else{
        sts="Disabled"
        setStatus(sts)
        setStyle_status("text-red-500  bg-opacity-50")
      }
    }
  
    useEffect(()=>{
      pyament_status()
      console.log(status, style_status)
    },[stripe_account])

    const pickerStyle = {
        picker: {
          width: "430px",
          heigth: "400px",
        }
      
    };

  return (
    <div className='flex flex-col px-4 mx-auto my-3'>

      <div className={`flex flex-col w-full mb-6 shadow-lg rounded-lg ${color}`}>
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-gray-700 text-xl font-bold">
              SETTINGS
            </h6>
            <button
              className="bg-pink-500 text-white hover:bg-pink-700 active:bg-green-700 text-xl font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              onClick={handleModify}>
              {t("Modify")}
            </button>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          {modify ?
            <div>
              <h6 className="text-gray-400 text-sm mt-3 mb-6 font-bold uppercase">
                {t("Client Page")}
              </h6>
                <div className="flex flex-wrap">
                  
                  <div className="w-full lg:w-6/12 px-4">
                  {status!=="Disabled" ?  
                  <>
                    <div className="relative w-full mb-3">

                      <div className="block uppercase text-gray-600 text-xs font-bold mb-2"  >
                        <label className='flex flex-row'>
                          {t("Link to Client Page")}
                            <HiOutlineExternalLink onClick={()=>window.open(location,'_blank',)} className='mt-[-3px] ml-[5px] cursor-pointer' color="blue" size={20} />
                            <AiOutlineCopy  onClick={() =>  {
                              navigator.clipboard.writeText(location)
                              alert(t("Link Copied"))}} 
                              className='mt-[-3px] ml-[5px] cursor-pointer' color="green" size={20} />
                           
                        </label>
                      </div>
                        
                      <input
                      href={settings.client_link}
                      type="text"
                      className="border-0 px-3 py-3 placeholder-gray-300 text-gray-600 bg-gray-100 rounded-lg text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder={settings.client_link === "" ? t("Link to Client Page") : settings.client_link}
                      name="client_link"
                      value={newsetting.client_link}
                      onChange={handleChange}
                      readOnly="readonly" />
                    </div>
                  </>
                  :
                    <div className="relative w-full mb-3">

                      <div className="block uppercase text-gray-600 text-xs font-bold mb-2"  >
                        <label className='flex flex-row'>
                          {t("Link to Client Page") + " - "}
                          <span className={`ml-1 ${style_status}`}>{" " + t(status)}</span>

                        </label>
                      </div>
                    </div>
                    }
                  </div>
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-gray-600 text-xs font-bold mb-2"  >
                        {t("Speaty Logo to be Shared in your Restaurant")}
                      </label>
                      {image && 
                      <div className='h-max-[200px] w-[200px] my-2 flex mx-auto'>
                        <img src={image} className="items-center object-cover " />
                      </div>
                      }
                    </div>
                  </div>
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-gray-600 text-xs font-bold mb-2"  >
                        {t("Name of the Restaurant in the Client Page")}
                      </label>
                      <input
                      type="text"
                      className={classNameInput}
                      placeholder={settings.company_title === "" ? t("Restaurant Name") : settings.company_title}
                      name="company_title"
                      value={newsetting.company_title}
                      onChange={handleChange}
                      readOnly={stylemodify} />
                    </div>
                  </div>
                 
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-gray-600 text-xs font-bold mb-2"  >
                        {t("Company Restaurant / Bar Description")}
                      </label>
                      <input
                      type="text"
                      className={classNameInput}
                      placeholder={settings.restaurant_description === "" ? t("Company Restaurant / Bar Description") : settings.restaurant_description}
                      name="restaurant_description"
                      value={newsetting.restaurant_description}
                      onChange={handleChange}
                      readOnly={stylemodify} />
                    </div>
                  </div>
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-gray-600 text-xs font-bold mb-2"  >
                        {t("Background Color Menu")}
                      </label>
                      <div className='items-center gap-3 sm:gap-10 justify-center mx-auto flex sm:flex-row flex-col'>
                        <SketchPicker
                        style={pickerStyle}
                        color={newsetting.menu_bg_color}
                        onChange={(updatedColor) => handleChangeColor(updatedColor.hex)}/>
                        <div className='text-center items-center text-sm flex flex-col justify-center'>{t("Color Selected")}
                          <div className="w-[120px] h-20 rounded-lg" style={{backgroundColor: newsetting.menu_bg_color}}></div> 
                          </div>                 
                      </div>
                    </div>
                  </div>
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <label className="mx-auto block uppercase text-gray-600 text-xs font-bold mb-2"  >
                        {t("Select Main Picture of the Client Page")}
                      </label>
                        <MainPictureUpload trigger={trigger} imageName={"main_picture"} modify={modify}/>
                    </div>
                  </div>
                </div>
              <h6 className="text-gray-400 text-sm mt-3 mb-6 font-bold uppercase">
                {t("Client Delivery")}
              </h6>
              <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label className="block uppercase text-gray-600 text-xs font-bold mb-2"  >
                      {t("Delivery Cost")}
                    </label>
                    <input
                      type="number"
                      className={classNameInput}
                      placeholder={settings.delivery_cost === "" ? t("Cost") : settings.delivery_cost}
                      name="delivery_cost"
                      value={newsetting.delivery_cost}
                      onChange={handleChange}
                      readOnly={stylemodify} />
                    </div>
                  </div>
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-gray-600 text-xs font-bold mb-2"  >
                        {t("Max Delivery Kilometer Distance")}
                      </label>
                      <input
                        type="number"
                        className={classNameInput}
                        placeholder={settings.delivery_km === "" ? "Km" : settings.delivery_km}
                        name="delivery_km"
                        value={newsetting.delivery_km}
                        onChange={handleChange}
                        readOnly={stylemodify} />
                    </div>
                  </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label className="block uppercase text-gray-600 text-xs font-bold mb-2"  >
                      {t("Number of days available for Delivery and Takeway")}
                    </label>
                    <DropdownComp
                    handleOptionSelected={OptionSelected}
                    menu={days}
                    which_option={"delivery_days"}
                    variable={"delivery_days"}
                    already_selected={newsetting.delivery_days}
                    />
                  </div>


                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label className="block uppercase text-gray-600 text-xs font-bold mb-2"  >
                      {t("Start Time for Delivery or Takeway")}
                    </label>
                    <div className=' justify-center items-center mx-auto grid grid-cols-4'>
                      <h3 className=' mx-auto my-auto top-2'>{t("Hours")}:</h3>
                      <DropdownComp
                        handleOptionSelected={OptionSelected}
                        menu={TimeHours}
                        variable={"start_dely_time_hours"}
                        which_option={"hours"}
                        already_selected={newsetting.start_dely_time_hours}
                      />
                      <h3 className='mx-auto'>{t("Minutes")}:</h3>
                      <DropdownComp
                        handleOptionSelected={OptionSelected}
                        menu={TimeMinutes}
                        which_option={"minutes"}
                        variable={"start_dely_time_minutes"}
                        already_selected={newsetting.start_dely_time_minutes}
                      />
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label className="block uppercase text-gray-600 text-xs font-bold mb-2"  >
                      {t("End Time for Delivery or Takeway")}
                    </label>
                    <div className=' justify-center items-center mx-auto grid grid-cols-4'>
                      <h3 className=' mx-auto my-auto top-2'>{t("Hours")}:</h3>
                      <DropdownComp
                        handleOptionSelected={OptionSelected}
                        menu={TimeHours}
                        which_option={"hours"}
                        variable={"end_dely_time_hours"}
                        already_selected={newsetting.end_dely_time_hours}
                      />
                      <h3 className='mx-auto'>{t("Minutes")}:</h3>
                      <DropdownComp
                        handleOptionSelected={OptionSelected}
                        menu={TimeMinutes}
                        which_option={"minutes"}
                        variable={"end_dely_time_hours"}
                        already_selected={newsetting.end_dely_time_minutes}
                      />
                    </div>
                  </div>
                </div>
                
                <div className="w-full lg:w-12/12 px-4">
                  <div className="relative w-full mb-3">
                    <label className="block uppercase text-gray-600 text-xs font-bold mb-2"  >
                      {t("Minutes of Window Delivery or Takeway Time")}
                    </label>
                    <DropdownComp
                      handleOptionSelected={OptionSelected}
                      menu={minutes}
                      which_option={"minutes_dely"}
                      variable={"minutes_dely"}
                      already_selected={newsetting.minutes_dely}
                    />
                  </div>
                </div>
                {type==="premium" ? 
                <>
                <h6 className="text-gray-400 text-sm mt-3 mb-6 font-bold uppercase">
                {t("Client Table Order")}
              </h6>
                <div className="w-full lg:w-12/12 px-4">
                  <div className="relative w-full mb-3">
                    <label className="block uppercase text-gray-600 text-xs font-bold mb-2"  >
                      {t("Check if the Client can Order before Paying")}
                    </label>
                    <div className="mr-2 p-0 pt-1 justify-start flex">
                      <input
                        className='w-12 sm:w-7'
                        type="checkbox"
                        id="order_without_pay"
                        name="order_without_pay"
                        value={settings.order_without_pay}
                        checked={isChecked}
                        onChange={handleIsChecked}
                        disabled={!modify}
                      />
                      <p className="ml-2 text-black text-xs sm:text-sm ">{t("If checked The Client Can Order the dishes without paying. He will pay just after")}.</p>
                    </div>
                  </div>
                </div>
                </>
                :""}
                <div className="w-full items-center justify-center flex mt-3">
                  <button
                    className='flex bg-green-400 rounded-xl mx-1 hover:bg-green-600  hover:text-gray-100 w-[110px] text-center'
                    onClick={() => {
                      if(newsetting.delivery_days===0 || newsetting.end_dely_time_hours===0 || newsetting.end_dely_time_minutes===0 || newsetting.start_dely_time_minutes===0 || newsetting.start_dely_time_hours===0 || newsetting.minutes===0) {
                        setMessage("All fields should be selected")
                      }else{
                        setOpenSubmit(true)
                      }
                    }}

                  >
                    {t("Confirm Update")}</button>
                  <button
                    className='flex text-center bg-red-400 rounded-xl mx-1 hover:bg-red-300  hover:text-gray-300 w-[110px]'
                    onClick={handleDiscard}
                  >{t("Discard Update")}</button>
                </div>
                <p className='mx-auto text-center text-red-500 text-sbold mt-2'>{message}</p>
              </div>
            </div>
            :
            <div>
              <h6 className="text-gray-400 text-sm mt-3 mb-6 font-bold uppercase">
                {t("Client Page")}
              </h6>
                <div className="flex flex-wrap">
                <div className="w-full lg:w-6/12 px-4">
                {status!=="Disabled" ?  
                  <>
                    <div className="relative w-full mb-3">

                      <div className="block uppercase text-gray-600 text-xs font-bold mb-2"  >
                        <label className='flex flex-row'>
                          {t("Link to Client Page")}
                            <HiOutlineExternalLink onClick={()=>window.open(location,'_blank',)} className='mt-[-3px] ml-[5px] cursor-pointer' color="blue" size={20} />
                            <AiOutlineCopy  onClick={() =>  {
                              navigator.clipboard.writeText(location)
                              alert(t("Link Copied"))}} 
                              className='mt-[-3px] ml-[5px] cursor-pointer' color="green" size={20} />
                           
                        </label>
                      </div>
                        
                      <input
                      href={settings.client_link}
                      type="text"
                      className="border-0 px-3 py-3 placeholder-gray-300 text-gray-600 bg-gray-100 rounded-lg text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder={settings.client_link === "" ? t("Link to Client Page") : settings.client_link}
                      name="client_link"
                      value={newsetting.client_link}
                      onChange={handleChange}
                      readOnly="readonly" />
                    </div>
                  </>
                  :
                    <div className="relative w-full mb-3">

                      <div className="block uppercase text-gray-600 text-xs font-bold mb-2"  >
                        <label className='flex flex-row'>
                          {t("Link to Client Page") + " - "}
                          <span className={`ml-1 ${style_status}`}>{" " + t(status)}</span>

                        </label>
                      </div>
                    </div>
                    }
                  </div>
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-gray-600 text-xs font-bold mb-2"  >
                        {t("Speaty Logo to be Shared in your Restaurant")}
                      </label>
                      {image && 
                      <div className='h-max-[200px] w-[200px] my-2 flex mx-auto'>
                        <img src={image} className="items-center object-cover " />
                      </div>
                      }
                    </div>
                  </div>
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-gray-600 text-xs font-bold mb-2"  >
                        {t("Name of the Restaurant in the Client Page")}
                      </label>
                      <input
                      type="text"
                      className={classNameInput}
                      placeholder={settings.company_title === "" ? t("Restaurant Name") : settings.company_title}
                      name="company_title"
                      value={newsetting.company_title}
                      onChange={handleChange}
                      readOnly={stylemodify} />
                    </div>
                  </div>
                  
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-gray-600 text-xs font-bold mb-2"  >
                        {t("Company Restaurant / Bar Description")}
                      </label>
                      <input
                      type="text"
                      className={classNameInput}
                      placeholder={settings.restaurant_description === "" ? t("Company Restaurant / Bar Description") : settings.restaurant_description}
                      name="restaurant_description"
                      value={newsetting.restaurant_description}
                      onChange={handleChange}
                      readOnly={stylemodify} />
                    </div>
                  </div>
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-gray-600 text-xs font-bold mb-2"  >
                        {t("Background Color Menu")}
                      </label>
                      <div className='items-center gap-3 sm:gap-10 justify-center mx-auto flex sm:flex-row flex-col'>

                        <div className='text-center items-center text-sm flex flex-col justify-center'>{t("Color Selected")}
                          <div className="w-[120px] h-20 rounded-lg" style={{backgroundColor: newsetting.menu_bg_color}}></div> 
                          </div>                 
                      </div>
                      
                    </div>
                  </div>
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <label className="mx-auto block uppercase text-gray-600 text-xs font-bold mb-2"  >
                        {t("Select Main Picture of the Client Page")}
                      </label>
                        <MainPictureUpload trigger={trigger} imageName={"main_picture"} modify={modify}/>
                    </div>
                  </div>
                </div>
              <h6 className="text-gray-400 text-sm mt-3 mb-6 font-bold uppercase">
                {t("Client Delivery")}
              </h6>
              <div className="flex flex-wrap">
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label className="block uppercase text-gray-600 text-xs font-bold mb-2"  >
                      {t("Delivery Cost")}
                    </label>
                    <input
                      type="number"
                      className={classNameInput}
                      placeholder={settings.delivery_cost === "" ? t("Cost") : settings.delivery_cost}
                      name="delivery_cost"
                      value={newsetting.delivery_cost}
                      onChange={handleChange}
                      readOnly={stylemodify} />
                    </div>
                  </div>
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-gray-600 text-xs font-bold mb-2"  >
                        {t("Max Delivery Kilometer Distance")}
                      </label>
                      <input
                        type="number"
                        className={classNameInput}
                        placeholder={settings.delivery_km === "" ? "km" : settings.delivery_km}
                        name="delivery_km"
                        value={newsetting.delivery_km}
                        onChange={handleChange}
                        readOnly={stylemodify} />
                    </div>
                  </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label className="block uppercase text-gray-600 text-xs font-bold mb-2"  >
                      {t("Number of days available for Delivery and Takeway")}
                    </label>
                    <input
                      type="number"
                      className={classNameInput}
                      placeholder={settings.delivery_days === "" ? t("Days") : settings.delivery_days}
                      name="delivery_days"
                      value={newsetting.delivery_days}
                      onChange={handleChange}
                      readOnly={stylemodify} />
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label className="block uppercase text-gray-600 text-xs font-bold mb-2"  >
                      {t("Start Time for Delivery or Takeway")}
                    </label>
                    <div className=' justify-center items-center mx-auto grid grid-cols-4'>
                      <h3 className='text-center mx-auto'>{t("Hours")}:</h3>
                      <input
                        type="text"
                        className={classNameInput}
                        placeholder={settings.start_dely_time_hours === "" ? t("Start Hours") : settings.start_dely_time_hours}
                        name="start_dely_time_hours"
                        value={newsetting.start_dely_time_hours}
                        onChange={handleChange}
                        readOnly={stylemodify} />
                      <h3 className='text-center mx-auto'>{t("Minutes")}:</h3>
                      <input
                        type="text"
                        className={classNameInput}
                        placeholder={settings.start_dely_time_minutes === "" ? t("Start Minutes") : settings.start_dely_time_minutes}
                        name="start_dely_time_minutes"
                        value={newsetting.start_dely_time_minutes}
                        onChange={handleChange}
                        readOnly={stylemodify} />
                    </div>
                    
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label className="block uppercase text-gray-600 text-xs font-bold mb-2"  >
                      {t("End Time for Delivery or Takeway")}
                    </label>
                    <div className=' justify-center items-center mx-auto grid grid-cols-4'>
                      <h3 className='text-center mx-auto'>{t("Hours")}:</h3>
                      <input
                        type="text"
                        className={classNameInput}
                        placeholder={settings.end_dely_time_hours === "" ? t("End Hours") : settings.end_dely_time_hours}
                        name="end_dely_time_hours"
                        value={newsetting.end_dely_time_hours}
                        onChange={handleChange}
                        readOnly={stylemodify} />
                      <h3 className='text-center mx-auto'>{t("Minutes")}:</h3>
                      <input
                        type="text"
                        className={classNameInput}
                        placeholder={settings.end_dely_time_minutes === "" ? t("End Minutes") : settings.end_dely_time_minutes}
                        name="end_dely_time_minutes"
                        value={newsetting.end_dely_time_minutes}
                        onChange={handleChange}
                        readOnly={stylemodify} />
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-12/12 px-4">
                  <div className="relative w-full mb-3">
                    <label className="block uppercase text-gray-600 text-xs font-bold mb-2"  >
                      {t("Minutes of Window Delivery or Takeway Time")}
                    </label>
                    <input
                      type="number"
                      className={classNameInput}
                      placeholder={settings.minutes_dely === "" ? t("Minutes") : settings.minutes_dely}
                      name="address"
                      value={newsetting.minutes_dely}
                      onChange={handleChange}
                      readOnly={stylemodify} />
                  </div>
                </div>
                {type==="premium" ? 
                <>
                <h6 className="text-gray-400 text-sm mt-3 mb-6 font-bold uppercase">
                {t("Client Table Order")}</h6>
                <div className="w-full lg:w-12/12 px-4">
                  <div className="relative w-full mb-3">
                    <label className="block uppercase text-gray-600 text-xs font-bold mb-2"  >
                      {t("Check if the Client can Order before Paying")}
                    </label>
                    <div className="mr-2 p-0 pt-1 sm:text-sm justify-start flex">
                      <input
                        className='w-12 sm:w-7'
                        type="checkbox"
                        id="order_without_pay"
                        name="order_without_pay"
                        value={settings.order_without_pay}
                        checked={isChecked}
                        onChange={handleIsChecked}
                        disabled={!modify}
                      />
                      <p className="ml-2 text-black text-xs sm:text-sm ">{t("If checked The Client Can Order the dishes without paying. He will pay just after")}.</p>
                    </div>
                  </div>
                </div>
                </>
                :""}
              </div>
            </div>
            }
            </div>

            <DeleteModalUser open={openSubmit} onclose={() => { setOpenSubmit(false) }}>
              <div className='text-center w-56 items-center justify-center flex-col'>
                <AiOutlineUpload className="mx-auto" size={56} color="green" />
                <div>
                  <h3 className='text-lg font-black text-gray-800'> {t("Confirm Update Client Settings")}</h3>
                  <p className='text-sm text-gray-600'>{t("Are you sure you want to update Client Interface")}?
                  </p>
                </div>
                <div className='w-full items-center justify-center flex mt-3'>
                  <button
                    className='flex bg-green-400 rounded-xl mx-1 hover:bg-green-600  hover:text-gray-100 w-auto'
                    onClick={handleSubmit}>{t("Update")}</button>
                  <button
                    className='flex bg-gray-200 rounded-xl mx-1 hover:bg-gray-400 hover:text-gray-100 w-auto text-center'
                    onClick={() => setOpenSubmit(false)}>{t("Cancel")}</button>
                </div>
              </div>
            </DeleteModalUser>
          </div>
    </div>

  )
}

export default Settings
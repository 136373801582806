import React , {useState , useContext, useCallback} from 'react'
import {LoginDelyContext} from '../../contexts/LoginDelyContext'
import {useNavigate, useLocation, redirect } from 'react-router-dom';
import {AiOutlineEye, AiOutlineEyeInvisible} from 'react-icons/ai'
import { useTranslation } from 'react-i18next';

const { REACT_APP_GOOGLE_CLIENT_ID, REACT_APP_GOGGLE_REDIRECT_URL_ENDPOINT_COMPANY,REACT_APP_GOGGLE_REDIRECT_URL_ENDPOINT_COMPANY_PROD } =
  process.env;



const LogIn = (props) => {
  const {t} = useTranslation()
  const User = useContext(LoginDelyContext)
  const[email, setEmail] = useState('');
  const[password,setPassword] = useState('');
  const navigate = useNavigate()
  const location = useLocation()
  const [showpass, setShowpass] = useState(false)
  const [type,setType] = useState("password")
  const [errorLogin, setErrorLogin] = useState(false)
  const fromLocation = location?.state?.from?.pathname || '/dash/'
  
  let LogIn = {
    email: email,
    password: password
  }
  const restaurant = true
  
  const handleSubmit = async () => {
    User.stateLoading(true)
    const data = await User.login(LogIn,restaurant).then((response)=>{
      if (response.status ===200) {
        User.stateLoading(false)
        User.getUserRestaurant()
        setEmail()
        setPassword()
        User.stateIsVerified(true)

      }else{
        User.stateLoading(false)
        console.log(response)
        setErrorLogin(true)
        console.log(response.message)
        User.stateIsVerified(false)
      }
    }).catch(error => {
      User.stateLoading(false)
      alert(error)
      console.log(`Error: ${error}`)
    })    
  
  }


const handleSubmitGoogle = useCallback(() => {
  let redirect_uri = REACT_APP_GOGGLE_REDIRECT_URL_ENDPOINT_COMPANY
  if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
    redirect_uri = REACT_APP_GOGGLE_REDIRECT_URL_ENDPOINT_COMPANY_PROD
  }
  const googleAuthUrl = "https://accounts.google.com/o/oauth2/v2/auth";
  
  const scope = [
    "https://www.googleapis.com/auth/userinfo.email",
    "https://www.googleapis.com/auth/userinfo.profile",
  ].join(" ");
  
  const params = new URLSearchParams({
    response_type: "code",
    client_id: REACT_APP_GOOGLE_CLIENT_ID,
    redirect_uri: redirect_uri,
    prompt: "select_account",
    access_type: "offline",
    scope,
  });

  const url = `${googleAuthUrl}?${params}`;

  window.location.href = url;
}, []);




const handleshow = () =>{
  setShowpass(!showpass)
  showpass ?
  setType("password")
  : setType("")
}


  console.log(LogIn)
  console.log(User.restaurant_info)



  document.addEventListener(
    "keydown",(e) => {
      if (e.key==="Enter"){
        handleSubmit()
      }
    }
  )


  return (
      <div className="bg-teal-300 shadow-2xl rounded-2xl m-4 sm:m-0 ">
        
        <div className='p-4' >
          <div className="flex items-center justify-center">
            <h2 className="text-3xl font-bold tracking-wide mb-6"> {t("Log In")} </h2>
          </div>
          <label className="text-lg font-semibold" htmlFor="email">Email</label>
          <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" className="rounded-xl px-4 w-full py-1 bg-gray-200  border border-gray-400 mb-4 text-green-900 placeholder-gray-700 focus:outline-none" placeholder="Email" id="email" name="email"/>
          <div className='relative'>
          <label className="text-lg font-semibold" htmlFor="password">Password</label>
          
          <input value={password} onChange={(e) => setPassword(e.target.value)} type={type} className=" rounded-xl px-4 w-full py-1 bg-gray-200  border border-gray-400 mb-4 text-gray-700 placeholder-gray-700  focus:outline-none" placeholder="Password" id="password" name="password" />
          {showpass ?
            <AiOutlineEye 
              size={25}
              className='absolute right-3 bottom-[18px] z-30  cursor-pointer'
              onClick={handleshow} />
            :
              <AiOutlineEyeInvisible
                size={25}
                className='absolute right-3 z-30 bottom-[18px] cursor-pointer'
                onClick={handleshow} />
          }
          </div>
          {errorLogin ?
          <span className='text-red-500 '>{t("Email or Password not correct, please try again")}</span> : ""}
          <div className="flex items-center mb-3">
            <div className='flex flex-col space-y-2'>
              <button 
                className="mx-2 p-0 text-xs sm:text-sm underline" 
                onClick={()=>props.onFormSwitch('register')}
                >{t("Don't have an account? Register here")} 
              </button>
              <button 
                className="mx-2 p-0 text-xs sm:text-sm underline" 
                onClick={()=>props.onFormSwitch('password')}
                >{t("Did you forget your password")}? 
              </button>
            </div>

            <button type="submit" className="bg-teal-600 text-gray-200 p-1.5 rounded-lg object-right sm:text-lg hover:bg-teal-800 text-sm"
            tabIndex="0"
            onClick={()=>handleSubmit()}
            >{t("Log In")}</button>
            </div>
            {/*<div className='flex-col flex items-center justify-between border-t-1 mt-4 border-black mx-5 pt-4'>
            <button
           
           className="bg-white text-gray-800 font-bold px-4 border rounded shadow  focus:outline-none mb-4"
           onClick={handleSubmitGoogle}
         >
           <div className="flex items-center justify-center">
             <svg
               xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 48 48"
               width="48px"
               height="48px"
             >
               <path
                 fill="#FFC107"
                 d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
               />
               <path
                 fill="#FF3D00"
                 d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
               />
               <path
                 fill="#4CAF50"
                 d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
               />
               <path
                 fill="#1976D2"
                 d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
               />
             </svg>
             {t("Sign in with Google")}
           </div>
          
         </button>
        
        
      </div>
      */}
    </div>

  </div>
  );
};

export default LogIn
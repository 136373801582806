import React , {useContext, useState, useEffect, useCallback}from 'react'
import { CartContext } from '../../contexts/CartContext'
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { MenuContext } from '../../contexts/MenuContext'
import { LoginDelyContext } from '../../contexts/LoginDelyContext'
import {v4 as uuidv4} from 'uuid';
import { useId } from 'react';

const CreateFood = ({onClose,visible,custom_Dish}) => {
  const cart = useContext(CartContext)
  const menus = useContext(MenuContext)
  const User = useContext(LoginDelyContext);
  const settings = User.hmi_CL_info
  const {t} = useTranslation();
  const id = uuidv4()
  const custom_dishes = menus.customDish
  console.log(settings.user_id)
  console.log(custom_Dish)
  console.log(custom_dishes)

  useEffect(()=>{
    if (cart.fodd_add_in.length===0){
      cart.state_addtotalcost(0)
    }
  },[cart.fodd_add_in])

  console.log(cart.fodd_add_in)
  const handleSubmit = () => {
    if (cart.fodd_add_in.length>0){
      const dish = {
        name : id + "new",
        quantity : 1,
        category : t("custom") + " " + custom_Dish.name,
        prezzo : cart.getTotalFoodAddCost(custom_Dish).toFixed(2),
        custom: true,
        ingredienti: cart.fodd_add_in
        
      }
    

      cart.addOneToCart(dish.name,dish.category,dish.prezzo, dish.ingredienti,dish.custom)
      cart.deleteAllFoodAdd()
      onClose()
    }else{
      alert(t("No ingredients selected"))
    }
  }
  console.log(custom_Dish)

  console.log(cart.fodd_add_in)
  console.log(cart.items)

  if(!visible) return null;

  return (
    <div className='fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center z-50' >

    <div className='bg-white px-1 rounded w-full md:w-[700px] max-h-[650px] relative overflow-y-auto sm:w-[500px]'>
      
     
      <header className='top-0 w-full sticky border-gray-400 border-b-1 py-2 bg-teal-200 z-20'>
        <h1 className='flex items-center justify-center font-bold text-2xl'>{custom_Dish.name}</h1>
        <button type="button" 
        className="absolute top-1 right-1 rounded-md bg-teal-300 p-2 inline-flex items-center justify-center text-black hover:text-bold hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:bg-indigo-500 z-20"
        onClick={onClose}>
          <span className="sr-only">Close menu</span>
          {/*Heroicon name: outline/x*/}
          <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
       </button>
      </header>
      <div className='pb-4 mx-2'>
       {custom_Dish?.ingredients?.map((categ,index1) => 
        <div key={index1} className='mb-5 '>
          <p className='font-bold text-gray-700 text-center lg:text-3xl sm:text-2xl my-3 '>{categ.name}</p>
          <div className='grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-3 '>
            {categ.ingredient_list.map((food,index2) => 
            <div key={index2}>
            {cart.getAddInsQuantity(food.name)>0 ? 
            
            <div  className='relative bg-teal-300 text-black text-center rounded-xl cursor-pointer p-2 text-sm justify-between items-center flex-row hover:scale-105 duration-300 max-h-[300px]'>{food.name} €{Number(food.price).toFixed(2)}
              <div className='flex items-center justify-center text-center text-white mt-1'>
                <div className='absolute top-[-7px] left-[-7px] rounded-full bg-red-600 flex justify-center align-center px-1 w-[30px] text-xl font-bold '>{cart.getAddInsQuantity(food.name)}</div>
                <button className='mx-2 bg-teal-500 rounded w-[8px] items-center justify-center font-bold  flex'
                onClick={() => cart.addFoodAddOneToCart(food.name,categ.name,Number(food.price),custom_Dish.ingredients)}
                ><span>+</span></button>
                <button className='mx-2 bg-red-500 rounded w-[8px] items-center justify-center font-bold flex'
                onClick={() => cart.removeFoodAddOneFromCart(food.name, categ.name, Number(food.price),custom_Dish.ingredients)}
                ><span>-</span></button>
              </div>
            </div>

            : 
            <>
            {cart.fodd_add_in.find(add_ins=>add_ins.category===categ.name) ? 
             <div key={index2} className='bg-blue-300 text-center rounded-xl cursor-not-allowed p-2 text-sm justify-center items-center flex'>{food.name} €{Number(food.price).toFixed(2)}</div>
            :
            <div key={index2} className='bg-blue-300 hover:bg-blue-600 hover:text-white text-center rounded-xl cursor-pointer p-2 text-sm justify-center items-center flex hover:scale-105 duration-300'
            onClick={() => cart.addFoodAddOneToCart(food.name, categ.name,  food.price,custom_Dish.ingredients)}>{food.name} €{Number(food.price).toFixed(2)}</div>

            }
             
            </>
            }
            </div>
            )
          }
          </div> 
        </div>
       )}
      </div>
      
      <div className='flex-row items-center justify-center mx-auto my-2 border-t-1 border-gray-600'>
        <h3 className='font-bold text-gray-700 text-center lg:text-3xl text-2xl my-3'> {custom_Dish.name} </h3>
        {cart.fodd_add_in.map((food,idx)=>
        <p className='text-start my-1'>{food.name} €{Number(food.price).toFixed(2)} x {food.quantity} = €{(food.price*food.quantity).toFixed(2)}</p>
        )}
        <h1 className='mb-3 text-xl font-bold'>{t("Total")}: €{cart.getTotalFoodAddCost(custom_Dish).toFixed(2)}</h1>
        <div className='flex items-center justify-center mx-auto text-white my-2'>
          <button className='mx-2 bg-teal-500 hover:bg-teal-800 rounded-xl text-xl items-center justify-center font-bold  flex'
          onClick={()=>handleSubmit()}>
            {t("Confirm")}
          </button>
          <button className='mx-2 bg-red-500 hover:bg-red-800 rounded-xl text-xl items-center justify-center font-bold flex'
          onClick={()=>cart.deleteAllFoodAdd()}>
            {t("Discard")}
          </button>
        </div>
      </div>


      <div className='bottom-0 border-t-1 bg-blue-100 border-gray-400 sticky flex items-center justify-center text-center  py-3 font-bold'>     
        <h1 className='text-xl sm:text-2xl mr-1 sm:mr-2 justify-center'>{t("Total")}: €{cart.getTotalFoodAddCost(custom_Dish).toFixed(2)}</h1>
        <button className='mx-1 px-2 sm:px-3 sm:mx-2 bg-teal-500 hover:bg-teal-800 hover:text-white rounded-xl text-xl items-center justify-center font-bold  flex'
          onClick={handleSubmit}>
            {t("Confirm")}
          </button>
          <button className='mx-1 px-2 sm:px-3 sm:mx-2 bg-red-500 hover:bg-red-800 hover:text-white rounded-xl text-xl items-center justify-center font-bold flex'
          onClick={()=>cart.deleteAllFoodAdd()}>
            {t("Discard")}
          </button>
      </div>

    </div>
    </div>
  )
}

export default CreateFood
import React, {useState, useContext, useEffect} from 'react'
import { useNavigate, useSearchParams, useParams, useLocation} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Outlet} from 'react-router-dom';
import {loadStripe} from "@stripe/stripe-js"
import {TbTruckDelivery} from "react-icons/tb"
import {MdOutlineNoFood} from "react-icons/md"
import {BsFillArrowRightCircleFill} from "react-icons/bs"
import {LoginDelyContext,LoginDelyProvider} from '../../contexts/LoginDelyContext';
import { UserButtonModalDely } from '../../components/HMI_CL_Mobile';
import {AiOutlineUser} from 'react-icons/ai';
import { Loading } from '../../components';
import queryString from "query-string";

import i18next from 'i18next'


const HMI_CL_Delivery = () => {
  const User = useContext(LoginDelyContext)
  const {t} = useTranslation()
  const [image,setImage] = useState()
  const [stripePromise, setStripePromise] = useState(null)
  const [clientSecret, setClientSecret] = useState(null)
  const [searchParams] = useSearchParams()
  const url = Object.fromEntries([...searchParams])
  console.log(url)
  const restaurant_id = url.restaurant_id
  const page = User.hmi_CL_info
  const restaurantName = page.company_title || ""
  const [check_Restaurant, setCheck_Restaurant] = useState(false)
  let user = User.client_info
  const orders = User.client_orders
  const [showUser, setShowUser]  = useState(false);
  const handleCloseUser = () => setShowUser(false);
  const [selected, setSelected] = useState("");
  let reservationCount = 0;
  const loading = User.loading
  const location = useLocation();


  const dely_info = {
    delivery_cost : page.delivery_cost || 1,
    delivery_days: page.delivery_days || 0,
    start_hours: page.start_dely_time_hours || 19,
    start_minutes: page.start_dely_time_minutes|| 30,
    end_hours: page.end_dely_time_hours || 22,
    end_minutes: page.end_dely_time_minutes|| 30,
    minutes_dely: page.minutes_dely || 30,
    user: page.user_id.replace(/-/g,'') || restaurant_id || ""
  }
  
  useEffect(()=>{
    window.localStorage.removeItem('order')
    window.localStorage.removeItem('cart')
    window.localStorage.removeItem('customDish')
    window.localStorage.removeItem('restaurant_info')
    window.localStorage.removeItem('client_info')
    window.localStorage.removeItem('page')
    sessionStorage.removeItem("hmi_CL_page")

    if (restaurant_id){
      checkRestaurant()
     
    }
    
  },[])

  console.log(dely_info)

  useEffect(() => {
    const values = queryString.parse(location.search);
    const code = values.code ? values.code : null;

    if (code) {
      User.stateLoading(true)
      OnGogglelogin();
    }
  }, []);


  const OnGogglelogin = async () => {
    const res = await User.googleLogin(location.search).then(async res => {
      
      if (res.data.access_token && Object.keys(user.email).length!==0) {
        
        console.log(user.email)
        User.stateLoading(false)
      }
      return res
    }).catch(err => {
      
      console.log(err)
      alert(err)
      User.stateLoading(false)
    });
   
    navigate("")
    window.location.reload()
  }


  const getImage = async ()  => {
    let body = JSON.stringify({title: "main_picture", restaurant_id: restaurant_id});;
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    const res = await fetch(backend + '/api/category/get-dish-picture', {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'content-type': 'application/json',
        responseType: 'blob',
      },
      body,
    }).catch(err=>console.log(err))

    const imageBlob = await res.blob();
    const imageObjectURL = URL.createObjectURL(imageBlob);

    const file = await blobToBase64(imageObjectURL)
    setImage(file)
    URL.revokeObjectURL(imageBlob)
  }

  const blobToBase64 = (url) => {
    return new Promise(async (resolve, _) => {
      // do a request to the blob uri
      const response = await fetch(url);
  
      // response has a method called .blob() to get the blob file
      const blob = await response.blob();
  
      // instantiate a file reader
      const fileReader = new FileReader();
  
      // read the file
      fileReader.readAsDataURL(blob);
  
      fileReader.onloadend = function(){
        resolve(fileReader.result); // Here is the base64 string
      }
    });
  };
  useEffect(()=>{
    getImage()
  },[])
  

  const checkRestaurant = async () =>{
    const res = await User.getRestaurantPage(restaurant_id).then(res=>{
      let resp = ""
        if (res.stat===200){
         resp= res.data.user_id.toString()
        }
        return resp
    })
    const check = res.replace(/-/g,'')
    console.log(check)
    if (restaurant_id === check) {
      setCheck_Restaurant(true)
    }
  }

  useEffect(()=>{
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    fetch(backend + "/api/stripe/stripe-config/",{
      method: 'GET',
    }).then(async(r)=>{
      
      const {publishablekey} = await r.json();


      
      setStripePromise(loadStripe(publishablekey));
    })
  },[""])

  useEffect(()=>{
    let backend = ""
    if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
      backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
    }
    fetch(backend + "/api/stripe/create-payment-intent/", {
      method: "POST",
      body: JSON.stringify({})
    }).then(async(r)=>{
      const {clientSecret} = await r.json();

      
      setClientSecret(clientSecret);
    })
  },[""])

  
  
  const now = new Date();
  const today = {
    month: now.getMonth() + 1,
    monthstr: now.toLocaleString('default', { month: 'short' }),
    year: now.getFullYear(),
    day: now.getDate(),
  }

  const initialstate= {
    booked_date: {},
    choose:""
  }
  const [info, setInfo] = useState(initialstate)


  const getNextDays = (currentDate, daysToAdd) => {
    //const nextDate = new Date(currentDate)
    
    let daysSet = [today]
    for (let i=1; i<= daysToAdd; i++){
      let nextDate = new Date(currentDate.getTime() + (i * 86400000))
      let nextday= {
        month: nextDate.getMonth() + 1,
        monthstr: nextDate.toLocaleString('default', { month: 'short' }),
        year: nextDate.getFullYear(),
        day: nextDate.getDate(),
      }

      daysSet.push(nextday)
    }

    return daysSet
  }

  const days = getNextDays(now,dely_info.delivery_days)

  const navigate = useNavigate()

  const handleSubmit = async (e) => {
    e.preventDefault();
    const path = process.env.REACT_APP_DELIVERY_LINK
    navigate(path)
    
  }
  const handleClickDely = (e) => {
    e.preventDefault();
    setInfo(initialstate)
    setInfo({...info, choose: "Delivery"})
  }

  const handleClickTake = (e) => {
    e.preventDefault();
    setInfo(initialstate)
    setInfo({...info, choose: "Takeaway"})
  }

  const handleDate = (day) =>{
    setInfo({...info, booked_date: day})
    let order = User.order_info
    order.date = day
    order.type = info.choose
    User.stateOrder(order)
  }

  console.log(User.order_info)

  const application = process.env.REACT_APP_APPLICATION_NAME


  //if (!stripePromise && !clientSecret) return null
  //
  
if(page.sub_status!=="active") {
  return(
  <div className='relative bg-gray-800 items-center justify-center h-[100vh] '>
  <img className='absolute inset-x-0 mx-auto object-cover h-full '
    src={image} />
  <div className='relative inset-x-0 mx-auto  bg-scroll grid-flow-row z-1'>

    <div className='flex items-center justify-center pt-3 '>
      <button
        className={`text-gray-900  bg-teal-500 border-1 border-gray-400 focus:outline-none hover:bg-teal-700 hover:text-white focus:ring-4 focus:ring-gray-600  focus:text-white font-semibold rounded-r px-3 ${i18next.language==="en"?"ring-gray-600 text-white bg-teal-700":""}` }
        onClick={() => i18next.changeLanguage("en")}>English</button>
      <button
        className={`text-gray-900  bg-teal-500 border-1 border-gray-400 focus:outline-none hover:bg-teal-700 hover:text-white focus:ring-4 focus:ring-gray-600  focus:text-white font-semibold rounded-l px-3 ${i18next.language==="it"?"ring-gray-600 text-white bg-teal-700":""}` }
        onClick={() => i18next.changeLanguage("it")}>Italiano</button>
    </div>
    <h1 className='mx-auto justify-center max-w-[450px] w-[380px] text-2xl mt-6 font-body text-teal-500 text-center'><span className='font-bold'>{application.charAt(0).toUpperCase() + application.slice(1)}</span> {t("Order_and_Pay_in")} <span className='font-bold text-orange-500'>{restaurantName}</span></h1>
    <div className='mx-auto bg-gray-400 bg-opacity-70 shadow-xl items-center justify-center max-w-[350px] text-xl h-auto rounded-xl my-10 py-1 '>
      <p className='mx-1 text-center'> {t("Dear Client the page is not Active, please contact the Restaurant")}</p>
     
    </div>
  </div>
</div>

)

}



  return (
    <LoginDelyProvider>
      {loading ? <Loading /> : ""}
      <div className='relative bg-gray-800 items-center justify-center h-[100vh] '>
        <img className='absolute inset-x-0 mx-auto object-cover h-full '
          src={image} />
          {/*User and Table Info*/}
        <div 
          className='absolute hidden sm:flex w-auto top-3 py-2 right-4 bg-teal-400 rounded-full text-xl text-white  items-center hover:scale-110 z-30 cursor-pointer lg:right-[210px]'
          onClick={()=>{
            setShowUser(true)
          }}
          >
          <AiOutlineUser size={40} className='ml-4'/>
          <span className='flex justify-center align-center pr-1 text-2xl font-bold mx-2 '>{user.first_name}</span>
        </div>

        <div className='relative inset-x-0 mx-auto  bg-scroll grid-flow-row z-1'>

          <div className='flex items-center justify-center pt-3 '>
            <button
              className='text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-400 focus:ring-4 focus:ring-gray-500 font-medium rounded-r text-lg'
              onClick={() => i18next.changeLanguage("en")}>English</button>
            <button
              className='text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-500 focus:ring-4 focus:ring-gray-500 font-medium rounded-l text-lg'
              onClick={() => i18next.changeLanguage("it")}>Italiano</button>
          </div>

          <h1 className='mx-auto justify-center max-w-[380px] text-2xl mt-4 font-body text-teal-500 text-center'><span className='font-bold'>{application.charAt(0).toUpperCase() + application.slice(1)}</span> {t('Order_and_Pay_in')} <span className='font-bold text-orange-500'>{restaurantName}</span></h1>
          <div className='mx-auto bg-gray-400 bg-opacity-70 shadow-xl items-center justify-center max-w-[350px]  h-auto rounded-xl pb-5 my-5'>
            {!check_Restaurant ?
              <div className='text-2xl font-bold text-center'>
                {t("No Page Found, please verify the URL")}
              </div>
              :
              <div className='flex-col justify-center items-center px-3'>
                <h3 className='text-center font-bold text-white text-2xl py-3'>{t("What do you want to do")}?</h3>
                <div
                  className='text-center border-1 bg-teal-500 text-2xl text-white border-white font-bold rounded-xl justify-center items-center py-2 cursor-pointer hover:bg-teal-800'
                  onClick={handleClickTake}>
                  <MdOutlineNoFood size={45} className='mx-auto my-1' />
                  <span>{t("Takeaway")}</span>
                  <p className='text-center text-sm font-normal text-white'>{t("Choose the time for the pick up in the Restaurant")}</p>
                </div>
                <div
                  className='text-center border-1 bg-teal-500 text-2xl text-white border-white font-bold rounded-xl justify-center items-center mt-4 py-2 cursor-pointer hover:bg-teal-800'
                  onClick={handleClickDely}>
                  <TbTruckDelivery size={50} className='mx-auto' />
                  <span>{t("Delivery")}</span>
                  <p className='text-center text-sm font-normal text-white'>{t("Receive food at home easily")}</p>
                </div>
              </div>
            }
            {info.choose.length !== 0 ?
              <>
                <h3 className='text-center font-bold text-white text-xl mt-2'>{t("When")}?</h3>
                <div className='mx-3 flex flex-row gap-2 overflow-y-auto text-sm font-semibold h-auto'>

                  {days?.map((day, idx) =>
                    <div key={idx}
                      onClick={() => handleDate(day)}
                      className=' whitespace-nowrap p-2 items-center rounded-xl text-center grow border-[1px]  my-3 bg-teal-500 border-white text-white hover:bg-teal-800 hover:text-white cursor-pointer'>
                      <h3>{day.day}</h3>
                      <h3>{t(day.monthstr)} {day.year}</h3>
                    </div>
                  )}
                </div>
                {Object.keys(info.booked_date).length > 0 ?
                  <>
                    <div className='flex-col items-center justify-center bg-teal-500  max-w-[180px] mx-auto py-2 rounded-xl border-1 border-white mt-2 cursor-pointer hover:bg-teal-800'
                      onClick={handleSubmit}>
                      <BsFillArrowRightCircleFill size={50} color='white' className='mx-auto' />
                      <h3 className='text-center text-white '>{t("Start your") + " " + info.choose}</h3>
                    </div>
                    <h3 className='text-center font-bold text-white text-xl mt-1'>{t("you selected")} {info.booked_date.day} {t(info.booked_date.monthstr)} {info.booked_date.year} </h3>
                  </>
                  :
                  ""
                }
              </>
              :
              ""
            }
            
          </div>
          <div 
              className='relative sm:hidden flex flex-row p-2 bg-teal-400 rounded-full text-xl text-white  items-center hover:scale-110 z-30 justify-center cursor-pointer max-w-[200px] mx-auto'
              onClick={()=>{
                setShowUser(true)
              }}
              >
              <AiOutlineUser size={40} className='ml-4'/>
              <span className='flex justify-center align-center pr-1 text-2xl font-bold mx-2 '>{user.first_name}</span>
            </div>
          {showUser ? <UserButtonModalDely onClose={handleCloseUser} visible={setShowUser} orders_selected={selected}/> : ""}
          <Outlet />

        </div>
      </div>

    </LoginDelyProvider>
  )
}

export default HMI_CL_Delivery
import React, {useState ,  useContext, useEffect} from 'react'
import { Routes, Route , Outlet, Navigate , useNavigate, useLocation} from 'react-router-dom';
import {LogIn, Register, Header_Login, ChangePassword} from '.'
import {LoginDelyContext} from '../../contexts/LoginDelyContext'
import queryString from "query-string";
import {Loading} from '../../components';
import { Helmet} from 'react-helmet-async';
import { ProductList } from '../../components/HomePage';
import { useTranslation } from 'react-i18next';
import { FaCheck } from "react-icons/fa";
import axios from 'axios';
import styles from "../../style";
import {Footer} from '../../components/HomePage';

const { BACKEND_API_URL } = process.env;



const Layout_Login = () => {
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [sessionId, setSessionId] = useState('');

  const[currentForm, setCurrentForm] = useState('register');
  const {t} = useTranslation()
  const toggleForm = (formName) => {
    setCurrentForm (formName)
  }
  const application = process.env.REACT_APP_APPLICATION_NAME
  const User =  useContext(LoginDelyContext)
  const user = User.restaurant_info
  const subscrption = User.subscription
  const loading = User.loading
  const location = useLocation();
  const navigate = useNavigate();
  
  /*useEffect (() =>{
   
      const res =  User.isAuth().then(async res=>{

        if (res.stat===200){

          User.getUserRestaurant()
        }
        return res
      }).catch(err=>console.log(err))
    

  },[])*/

  console.log(user)

  useEffect(() => {
    const values = queryString.parse(location.search);
    const code = values.code ? values.code : null;
    if (code) {
      User.stateLoading(true)
      onGogglelogin();
    }else{
      const res =  User.isAuth().then(async res=>{

        if (res.stat===200){

          User.getUserRestaurant()
        }
        return res
      }).catch(err=>console.log(err))
    }
  }, []);

  const onGogglelogin = async () => {
    console.log(user)
    const res = await User.googleCompLogin(location.search).then(res => {console.log(user.email)
      if (res.data.access_token && Object.keys(user.email).length!==0) {
        console.log(user.email)
        User.stateLoading(false)
        User.stateIsVerified(true)
        navigate("/dash")
      }
      return res
    }).catch(err => {
      console.log(err)
      alert(err)
      User.stateLoading(false)
      navigate("")
    });
  }
  
  const [color, setColor] = useState(false);
  const changeColor = () =>{
    if (window.scrollY >=30){
      setColor(true)
    }else {
      setColor(false)
    }
  }

  window.addEventListener('scroll',changeColor)
  
  console.log(color)
  


  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get('success')) {
      setSuccess(true);
      setSessionId(query.get('session_id'));
      setCurrentForm("login")
    }

    if (query.get('canceled')) {
      setSuccess(false);
      setMessage(
        "Order canceled - Please try when you're ready"
      );
      const email = query.get('email')
      console.log(email)
      User.del_account(email)

    }
  }, [sessionId]);


    const handleSession = () => {
      const body = {session_id: sessionId, return_url : "/login-register"}
      console.log(body)
      let backend = ""
      if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
        backend = process.env.REACT_APP_BACKEND_SERVER_DOMAIN
      }
      axios.post(backend + "/api/stripe/create-portal-session/",body).then((res)=>{
        console.log(res.data)

        if(res.data.url){
          console.log(res)
          window.location.href = res.data.url 
        }
      }).catch((err) => {
      alert(err.message)
      console.log(err.message)});
    }

    return (User.isVerified ? <Navigate to="/dash" /> :
      <div className={` bg-[url("data/background.png")]  bg-no-repeat bg-cover bg-center bg-fixed $`}>
        <Helmet>
          <title>Login & Register - Speaty</title>
          <meta name="description" content='Login and Register in the new World of Food & Drink Ordering with Speaty. Try our Amazing Platform.' />
          <meta name="keywords" content='food, delivery restaurant, bar, ordering, app, business, cibo, ristorante, ordinazioni, orders, ordini, ordinazione, takeaway, consegna, domicilio'/>
          <link rel='canonical' href="https://speaty.com/login-register" />
        </Helmet>
        {loading ? <Loading /> : ""}
        {!success ?
          <>
            <Header_Login onFormSwitch={toggleForm} color={color} />
            {message ? 
              <h2 className='bg-white p-3 text-center items-center justify-between mx-auto font-bold text-xl text-red-400'>{message}</h2>
            
            : ""}
            
            <div className='mx-auto flex max-w-[1640px] items-center flex-col-reverse md:flex-row justify-center lg:pt-10'>
              
              <ProductList dimension={"small"} />

              {currentForm === "login" && <LogIn onFormSwitch={toggleForm} /> }
              {currentForm === "register" && <Register onFormSwitch={toggleForm} />}
              {currentForm === "password" && <ChangePassword onFormSwitch={toggleForm} />}
            
            </div>
          </>
        :
        
          <>
            <Header_Login onFormSwitch={toggleForm} color={color} />
            <h2 className='py-2 text-center items-center justify-between mx-auto font-bold text-xl'>{message}</h2>
            <div className='mx-auto flex max-w-[1640px] gap-6 items-center flex-col md:flex-row justify-center lg:pt-20 mt-[100px]'>
              <div className='bg-green-500  bg-opacity-50 w-[350px] gap-4 h-full flex flex-col items-center justify-between p-3 border-1 rounded-3xl '>
                <div className='flex flex-row justify-center items-center'>
                <FaCheck size={40} className='fill-green-700 w-[50px]'/>
                
                <h2 className=' w-[300px] text-xl font-bold text-white'>{t("Thanks to Subscribe to the Speaty Platform. Now You can Log In to start your new adventure")}</h2>
                </div>
                <button 
                  onClick={handleSession}
                  className='bg-teal-500 text-white rounded-xl p-1hover:bg-teal-700 hover:font-bold'>{t("Check your Subscription")}</button>
              </div>
              {currentForm === "login" && <LogIn onFormSwitch={toggleForm} /> }
              {currentForm === "register" && <Register onFormSwitch={toggleForm} />}
              {currentForm === "password" && <ChangePassword onFormSwitch={toggleForm} />}

            </div>
          </>
        }
        <div className={` ${styles.paddingX} ${styles.flexCenter}`}>
          <div className={`${styles.boxWidth}`}>
            <Footer />
          </div>
        </div>
        <Outlet />
      </div>
    );
 
};

export default Layout_Login
import React , {useContext, useState, useEffect, useRef, useCallback}from 'react'
import { LoginDelyContext } from '../../../contexts/LoginDelyContext'
import { useTranslation } from 'react-i18next';
import {useNavigate, useLocation, redirect } from 'react-router-dom';
import {AiOutlineEye, AiOutlineEyeInvisible} from 'react-icons/ai'
import { HMICLContext } from '../../../contexts/HMICLContext'


const { REACT_APP_GOOGLE_CLIENT_ID, REACT_APP_GOGGLE_REDIRECT_URL_ENDPOINT_1, REACT_APP_GOGGLE_REDIRECT_URL_ENDPOINT_2,REACT_APP_GOGGLE_REDIRECT_URL_ENDPOINT_1_PROD,REACT_APP_GOGGLE_REDIRECT_URL_ENDPOINT_2_PROD, REACT_APP_GOGGLE_REDIRECT_URL_ENDPOINT_2_TABLE_PROD, REACT_APP_GOGGLE_REDIRECT_URL_ENDPOINT_1_TABLE_PROD, REACT_APP_GOGGLE_REDIRECT_URL_ENDPOINT_2_TABLE, REACT_APP_GOGGLE_REDIRECT_URL_ENDPOINT_1_TABLE} =
  process.env;

const Login_CL= (props) => {
  const {t} = useTranslation()
  const User = useContext(LoginDelyContext)
  const params = useContext(HMICLContext)
  const CLParams = params.HMI_CL_Params
  const userID = CLParams.userID
  const tableID = CLParams.tableID
  const[email, setEmail] = useState('');
  const[password,setPassword] = useState('');
  const navigate = useNavigate()
  const location = useLocation()
  const [showpass, setShowpass] = useState(false)
  const [type,setType] = useState("password")
  const [errorLogin, setErrorLogin] = useState(false)
  const page = User.hmi_CL_info
  const restaurant_id = page.user_id
  const restaurant=false

  let LogIn = {
    email: email,
    password: password
  }


  const handleSubmit = () => {
    
    User.stateLoading(true)
      const data = User.login(LogIn,restaurant).then((response)=>{
        if (response.login ===true) {
          setEmail()
          setPassword()
          User.stateLoading(false)
          //navigate(fromLocation, { replace: true })
        }else{
          User.stateLoading(false)
          setErrorLogin(true)
          console.log(response.message)
        }
      }).catch(error => console.log(`Error: ${error}`))    
    }
  
  console.log(props.type)
  const handleSubmitGoogle = useCallback(() => {
    let redirect_uri = ""
    console.log(props.type)
    if (props.type==="table_order"){
      redirect_uri = REACT_APP_GOGGLE_REDIRECT_URL_ENDPOINT_1_TABLE + REACT_APP_GOGGLE_REDIRECT_URL_ENDPOINT_2_TABLE 
      if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
        redirect_uri = REACT_APP_GOGGLE_REDIRECT_URL_ENDPOINT_1_TABLE_PROD + REACT_APP_GOGGLE_REDIRECT_URL_ENDPOINT_2_TABLE_PROD 
      }
    }else{
      redirect_uri = REACT_APP_GOGGLE_REDIRECT_URL_ENDPOINT_1 + REACT_APP_GOGGLE_REDIRECT_URL_ENDPOINT_2 
      if (process.env.REACT_APP_DEV_OR_PROD !== "dev") {
        redirect_uri = REACT_APP_GOGGLE_REDIRECT_URL_ENDPOINT_1_PROD + REACT_APP_GOGGLE_REDIRECT_URL_ENDPOINT_2_PROD
      }
    }
    const googleAuthUrl = "https://accounts.google.com/o/oauth2/v2/auth";
    
    const scope = [
      "https://www.googleapis.com/auth/userinfo.email",
      "https://www.googleapis.com/auth/userinfo.profile",
    ].join(" ");

    const params = new URLSearchParams({
      response_type: "code",
      client_id: REACT_APP_GOOGLE_CLIENT_ID,
      redirect_uri: redirect_uri ,
      prompt: "select_account",
      access_type: "offline",
      scope,
    });

    const url = `${googleAuthUrl}?${params}`;

    window.location.href = url;
  }, []);




  const handleshow = () =>{
    setShowpass(!showpass)
    showpass ?
    setType("password")
    : setType("")
  }


  return (
    <div className="bg-white shadow-xl rounded-lg p-4">
    <div >
      <div className="flex items-center justify-center">
        <h2 className="text-2xl font-bold tracking-wide mb-6"> {t("Log In")} </h2>
      </div>
      <label className="text-lg font-semibold" htmlFor="email">Email</label>
      <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" className="rounded-xl px-4 w-full py-1 bg-gray-200  border border-gray-400 mb-4 text-green-900 placeholder-gray-700 focus:outline-none" placeholder="Email Address" id="email" name="email"/>
      <div className='relative'>
      <label className="text-lg font-semibold" htmlFor="password">Password</label>
      
      <input value={password} onChange={(e) => setPassword(e.target.value)} type={type} className=" rounded-xl px-4 w-full py-1 bg-gray-200  border border-gray-400 mb-4 text-gray-700 placeholder-gray-700  focus:outline-none" placeholder="Password" id="password" name="password" />
      {showpass ?
        <AiOutlineEye 
          size={25}
          className='absolute right-3 bottom-[18px] z-30  cursor-pointer'
          onClick={handleshow} />
        :
          <AiOutlineEyeInvisible
            size={25}
            className='absolute right-3 z-30 bottom-[18px] cursor-pointer'
            onClick={handleshow} />
      }
      </div>
      {errorLogin ?
      <span className='text-red-500 '>{t("Email or Password not correct, please try again")}</span> : ""}
      <div className="flex items-center justify-between mb-3">
        <button 
          className="ml-0 pl-0 text-sm md:text-lg underline " 
          onClick={()=>props.onFormSwitch('register')}
          >{t("Don't have an account? Register here")} 
        </button>
        
        <button type="submit" className="bg-green-900 text-gray-200 px-2 py-1 rounded-lg object-right text-lg hover:bg-gray-800 md:text-xl"
        onClick={()=>handleSubmit()}>{t("Log In")}</button>
        </div>
        <div className='flex-col flex items-center justify-between border-t-1 mt-4 border-black mx-1 pt-4 md:mx-5'>
          <button
           
           className="bg-white text-gray-800 font-bold py-2 px-4 border rounded shadow  focus:outline-none mb-4"
           onClick={handleSubmitGoogle}
         >
           <div className="flex items-center justify-center">
             <svg
               xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 48 48"
               width="48px"
               height="48px"
             >
               <path
                 fill="#FFC107"
                 d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
               />
               <path
                 fill="#FF3D00"
                 d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
               />
               <path
                 fill="#4CAF50"
                 d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
               />
               <path
                 fill="#1976D2"
                 d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
               />
             </svg>
             Sign in with Google
           </div>
         </button>
        
        
      </div>
    </div>

  </div>
  )
}

export default Login_CL
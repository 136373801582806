import { apple, bill, google } from "../../data";
import styles, { layout } from "../../style";
import { useTranslation } from 'react-i18next';

const Billing = () => {
  const {t} = useTranslation()

  
  const application = process.env.REACT_APP_APPLICATION_NAME
  return (
  <section className={layout.sectionReverse}>
    <div className={layout.sectionImgReverse}>
      <img src={bill} alt="Speaty Payments" className="w-[85%] h-[85%] relative z-[5]" />

      {/* gradient start 
      <div className="absolute z-[3] -left-1/2 top-0 w-[50%] h-[50%] rounded-full bg-gradient-to-r from-gray-100 to-gray-500 opacity-50"/>
      <div className="absolute z-[0] w-[50%] h-[50%] -left-1/2 bottom-0 rounded-full bg-gradient-to-r from-pink-400 to-pink-600 opacity-50" />
      {/* gradient end */}
    </div>

    <div className={layout.sectionInfo}>
      <h2 className={styles.heading2}>
        {t("Easily control your")} <br className="sm:block hidden" /> {t("bills & invoices")}.
      </h2>
      <p className={`${styles.paragraph} max-w-[470px] mt-5`}>
        {t("You and your Customers will check easily the bill and invoices. The")} {application.charAt(0).toUpperCase() + application.slice(1)} {t("Platform gives you all the information of the payments and the security of the money transfer")}.
      </p>

      {/*<div className="flex flex-row flex-wrap sm:mt-10 mt-6">
        <img src={apple} alt="google_play" className="w-[128.86px] h-[42.05px] object-contain mr-5 cursor-pointer" />
        <img src={google} alt="google_play" className="w-[144.17px] h-[43.08px] object-contain cursor-pointer" />
  </div>*/}
    </div>
  </section>
  )
  };

export default Billing;

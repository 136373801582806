import { people01, people02, people03, facebook, instagram, linkedin, twitter, airbnb, binance, coinbase, dropbox, send, shield, star  } from "../../data";

export const navLinks = [
  {
    id: "/",
    title: "Home",
    use:["home","terms","help"]
  },
  {
    id: "#check_map",
    title: "Check on Map",
    use:["home"],
  },
  /*{
    id: "#features",
    title: "Features",
    use:["home"]
  },*/
  {
    id: "#product",
    title: "Product",
    use:["home"]
  },
  {
    id: "register",
    title: "Get Started - Login",
    link:"/login-register",
    use:["home","terms","help"]
  },
];

export const features = [
  {
    id: "feature-1",
    icon: star,
    title: "Best Web App",
    content:
      "The best Reservation Application for Restaurants and Bars. Clients are happy to order and pay with our Application",
  },
  {
    id: "feature-2",
    icon: shield,
    title: "100% Secured",
    content:
      "We take proactive steps make sure your information and transactions are secure.",
  },
  {
    id: "feature-3",
    icon: send,
    title: "User Friendly",
    content:
      "Easy configuration for the Restaurant. No installation needed. On request, Hardware and Software will be provided and installed to best fit your needs",
  },
];

export const feedback = [
  {
    id: "feedback-1",
    content:
      "Money is only a tool. It will take you wherever you wish, but it will not replace you as the driver.",
    name: "Herman Jensen",
    title: "Founder & Leader",
    img: people01,
  },
  {
    id: "feedback-2",
    content:
      "Money makes your life easier. If you're lucky to have it, you're lucky.",
    name: "Steve Mark",
    title: "Founder & Leader",
    img: people02,
  },
  {
    id: "feedback-3",
    content:
      "It is usually people in the money business, finance, and international trade that are really rich.",
    name: "Kenn Gallagher",
    title: "Founder & Leader",
    img: people03,
  },
];

export const stats = [
  {
    id: "stats-1",
    title: "User Active",
    value: "3800+",
  },
  {
    id: "stats-2",
    title: "Trusted by Company",
    value: "230+",
  },
  {
    id: "stats-3",
    title: "Transaction",
    value: "$230M+",
  },
];

export const footerLinks = [
  {
    title: "Useful Links",
    links: [

      {
        name: "How it Works",
        link: "/#product",
      },

      {
        name: "Terms & Conditions",
        link: "/terms-and-services/",
      },
      {
        name: "Help Center",
        link: "/help-center/",
      },
      {
        name: "Contact Us",
        link: "/help-center",
      },
    ],
  },
  {
    title: "Legal",
    links: [
      {
        name: "Terms & Conditions",
        link: "/terms-and-services/",
      },
      {
        name: "Cookie Policy",
        link: "/help-center/cookie-policy",
      },
      {
        name: "Privacy Policy",
        link: "/help-center/privacy-policy",
      },
    ],
  },
  {
    title: "Partner",
    links: [
      {
        name: "Our Partner",
        link: "/",
      },
      {
        name: "Become a Partner",
        link: "/login-register",
      },
    ],
  },
];

export const socialMedia = [
  {
    id: "social-media-1",
    icon: instagram,
    link: "https://www.instagram.com/auxilium.srl/",
  },
  {
    id: "social-media-2",
    icon: facebook,
    link: "https://www.facebook.com/auxilium.srl/",
  },
  
  {
    id: "social-media-4",
    icon: linkedin,
    link: "https://www.linkedin.com/company/auxilium-srl/about/",
  },
];

/*{
  id: "social-media-3",
  icon: twitter,
  link: "https://www.twitter.com/",
},*/

export const clients = [
  {
    id: "client-1",
    logo: airbnb,
  },
  {
    id: "client-2",
    logo: binance,
  },
  {
    id: "client-3",
    logo: coinbase,
  },
  {
    id: "client-4",
    logo: dropbox,
  },
];


export const subscription = [
  {
    name: "Speaty Standard",
    description: "Speaty for Restaurant and Bar. Delivery",
    cost: 25,
    duration_month: 1,
    features: [
      "Create Your User Platform Easily",
      "Client Interface Clean",
      "Manage the Client's Payments",
      "Manage your Delivery, You have your 'Rider'"
    ],
    best:false,
    stripe_description_dev: "standard_month_test",
    stripe_description_prod: "standard_month"
  },
  {
    name: "Speaty Premium",
    description: "Speaty for Restaurant and Bar. Delivery + Order in the Restaurant",
    cost: 40,
    duration_month: 1,
    features: [
      "Create Your User Platform Easily",
      "Client Interface Clean",
      "Manage the Client's Payments",
      "Manage your Delivery, You have your 'Rider'",
      "The Client is able to Order and Pay directly from the Smartphone"
    ],
    best:true,
    stripe_description_dev: "premium_month_test",
    stripe_description_prod: "premium_month"
  },
  {
    name: "Speaty Standard",
    description: "Speaty for Restaurant and Bar. Delivery",
    cost: 200,
    duration_month: 12,
    features: [
      "Create Your User Platform Easily",
      "Client Interface Clean",
      "Manage the Client's Payments",
      "Manage your Delivery, You have your 'Rider'"
    ],
    best:false,
    stripe_description_dev: "standard_year_test",
    stripe_description_prod: "standard_year"
  },
  {
    name: "Speaty Premium",
    description: "Speaty for Restaurant and Bar. Delivery + Order in the Restaurant",
    cost: 350,
    duration_month: 12,
    features: [
      "Create Your User Platform Easily",
      "Client Interface Clean",
      "Manage the Client's Payments",
      "Manage your Delivery, You have your 'Rider'",
      "Order and Pay With The Client Smartphone in your Restaurant"
    ],
    best:true,
    stripe_description_dev: "premium_year_test",
    stripe_description_prod: "premium_year"

  },
];
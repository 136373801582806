import React from 'react'
import { ListOfTables_Manager,  ListOfTables} from '../../components/HMI_WT';
import {HMICLProvider} from '../../contexts/HMICLContext'



const Manage_Table = ({tables,stateTable}) => {
  localStorage.removeItem("option_selected");
  return (
    <div className='flex w-full'>
      <HMICLProvider>
        <ListOfTables manager={true} tables_orders={tables} stateTable={stateTable}/>
      </HMICLProvider>
    </div>
  );
}

export default Manage_Table
import React , {useState} from 'react'
import ReAppLogo from '../../data/AppLogo_ReApp.png'
import { useTranslation } from 'react-i18next';
import {LanguageSwitcher} from '../Dashboard'
import { useNavigate } from 'react-router-dom';

const Header_Login = (props) => {
    const {t} = useTranslation()
    const color = props.color
    const application = process.env.REACT_APP_APPLICATION_NAME
    //bg-teal-500 sm:bg-transparent
    const navigate = useNavigate()
    return (
        <div className={`w-full items-center  sticky top-0 left-0 z-40 ${color ? "bg-gradient-to-r from-cyan-200 to-green-400" : ""}`}>
            <nav className="flex  mx-auto items-center justify-between p-4 max-w-[1640px]">
                <div className="flex items-center w-[140px] sm:w-auto cursor-pointer gap-1 sm:gap-3">
                    <img src={ReAppLogo} alt='Logo' className='flex h-[50px] w-[50px] flex-col sm:flex-row'
                    onClick={()=>{navigate("/")}}/>
                    <h2 className="flex flex-col font-bold sm:text-4xl text-2xl text-green-600 "
                     onClick={()=>{navigate("/")}}>
                        {application.charAt(0).toUpperCase() + application.slice(1)} 
                        <span className='hidden sm:flex font-semibold  bg-gradient-to-r from-green-600 to-green-900 bg-clip-text text-transparent gap-4 underline italic sm:text-lg text-sm'
                        onClick={()=>{navigate("/")}}>{t("Powered by Auxilium SRL")}</span></h2>
                    <LanguageSwitcher />                  
                </div>
                
                <div className="flex flex-col-reverse sm:flex-row items-end sm:items-center gap-3">
                    <button onClick={()=>props.onFormSwitch('login')}className="bg-gray-200 text-gray-600 py-2 sm:py-4 px-2  rounded-xl border-[1px] border-gray-300 hover:bg-teal-300 hover:text-white text-sm md:text-xl ">{t("Log In")}</button>
                    <button onClick={()=>props.onFormSwitch('register')} className="bg-teal-800 text-gray-200  py-2 sm:py-4 px-2 rounded-xl  hover:bg-teal-500 hover:text-white text-sm md:text-xl">{t("Register & Subscribe")}</button>
                </div>
            </nav>
        </div>
  );
};

export default Header_Login
import React, {useState, useContext, useEffect, useRef} from 'react'
import {AiFillTag, AiOutlineClose, AiOutlineMenu, AiOutlineSearch} from 'react-icons/ai'
import {BsFillCartFill, BsFillSaveFill} from 'react-icons/bs'
import {TbTruckDelivery} from 'react-icons/tb'
import {FaWallet} from 'react-icons/fa'
import {MdHelp} from 'react-icons/md'
import {RiListUnordered} from 'react-icons/ri'
import waiter from '../../data/waiter.png'
import table from '../../data/table.png'
import OrderButtonModal from './OrderButtonModal'
import UserButtonModal from './UserButtonModal'
import UserButtonModalDely from './UserButtonModalDely'
import { CartContext } from '../../contexts/CartContext'
import { HMICLContext } from '../../contexts/HMICLContext'
import { useTranslation } from 'react-i18next';
import i18next from 'i18next'
import { LoginDelyContext } from '../../contexts/LoginDelyContext'
import {AiOutlineUser} from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'

const Navbar_CL_Mobile = () => {
  const cart = useContext(CartContext)
  const params = useContext(HMICLContext)
  const {t} = useTranslation()
  const CLParams = params.HMI_CL_Params
  const tableID = CLParams.tableID
  const userID = CLParams.userID
  let receiptURL = CLParams.receipt
  const User = useContext(LoginDelyContext);
  const user = User.client_info
  const page = User.hmi_CL_info
  const restaurant_id = page.user_id
  const [selected, setSelected] = useState("")
  const orders = User.client_orders
  const navigate = useNavigate()
  const order_without_pay = page.order_without_pay || false
  const routeChange = () => {
   
    window.location.href = receiptURL;
    
  }


  const [nav,setNav] = useState(false);
  const [show, setShow] = useState(false);
  //const [showUser, setShowUser]  = useState(false);
  const [showUserLogin, setShowUserLogin]  = useState(false);
  
  const handleClose = () => setShow(false);
  //const handleCloseUser = () => setShowUser(false);
  const handleCloseUserLogin = () => setShowUserLogin(false);
  const callWtRef = useRef(null)
  const socketRef = useRef()
  const productsCount = cart.items.reduce((sum, product)=> sum + product.quantity, 0);
  const application = process.env.REACT_APP_APPLICATION_NAME
  
  let reservationCount = params.userDishList.reduce((sum, product)=> sum + product.quantity, 0);

  //const [call, setCall] = useState([])
  let call = {};
  const [callreceived, setCallreceived] = useState([]);
  useEffect(()=>{
    if (Object.keys(user.email).length>0){
      console.log(userID,tableID,user.user_id,restaurant_id)
      params.getUserDish(userID,tableID,user.user_id,restaurant_id)
    }
  },[])

  const onCallWt = async () =>{

      call =  {
        countCall: 1,
        tableId : tableID,
        clientId : userID,
        read: false,
      }
    console.log(call)
    socketRef.current.send(JSON.stringify(call))
  }
 
  /*useEffect(()=>{
    const callWt = callWtRef.current
    callWt.addEventListener('click', onCallWt, false)

    socketRef.current = new WebSocket('ws://127.0.0.1:3001/ws/notification')
    socketRef.current.onopen = e => {
      console.log('open', e)
    }
    socketRef.current.onmessage = async (e) => {

    }
    socketRef.current.onclose = () => {
      console.log("Connection Closed!");
      
    };

    socketRef.current.onerror = e => {
      console.log('error',e)
    }

    
    return () => socketRef.current.close();

  },[])*/


  return (
    <>
    <div className='max-w-[1640px] mx-auto px-4 pt-3 pb-7 sticky w-full inset-0 top-[-1px] justify-center items-center z-20 overflow-y-auto animate-bg bg-teal-700 rounded-b-xl'>
        {/*left side */}
      <div className='flex items-center'>
        <div onClick={()=> setNav(!nav)}
        className='cursor-pointer'>
          <AiOutlineMenu size={30}/>
        </div>
        <h1 className='text-teal-400 font-bold text-2xl sm:text-3xl lg:text-4xl px-2 '>
          {application.charAt(0).toUpperCase() + application.slice(1)}
        </h1>
      </div>
      {/*Change Languages */}
      <div className='items-center justify-center mt-4'>
        <button
          className={`text-gray-900  bg-teal-400 border-1 border-gray-400 focus:outline-none hover:bg-teal-600 hover:text-white focus:ring-4 focus:ring-gray-600  focus:text-white font-semibold rounded-r px-3 ${i18next.language==="en"?"ring-gray-600 text-white bg-teal-600":""}` }
          onClick={() => i18next.changeLanguage("en")}>English</button>
        <button
          className={`text-gray-900  bg-teal-400 border-1 border-gray-400 focus:outline-none hover:bg-teal-600 hover:text-white focus:ring-4 focus:ring-gray-600  focus:text-white font-semibold rounded-l px-3 ${i18next.language==="it"?"ring-gray-600 text-white bg-teal-600":""}` }
          onClick={() => i18next.changeLanguage("it")}>Italiano</button>
      </div>
      {/* Cart Button */}
      <button 
        className='fixed w-auto top-[8px] right-4 bg-green-600 rounded-full text-xl text-white flex items-center py-2 hover:scale-110 z-40'
        onClick={()=>setShow(true)}>
        <div className='absolute bottom-[-7px] left-[-7px] rounded-full bg-red-600 flex justify-center align-center px-1 w-[40px] text-2xl font-bold '>{productsCount}</div>
        <BsFillCartFill size={38} className='px-1 object-cover '/> 
        <span className='font-bold'>{t("Orders")}</span>
      </button>

      {/*User and Table Info*/}
      <div 
        className='fixed w-auto top-[70px] right-4 bg-blue-500 rounded-full text-lg text-white flex items-center py-2.5 px-2'
        onClick={()=>{

          setShowUserLogin(true)
          
        }}
        >
        {order_without_pay ?
          <div className='absolute bottom-[-7px] left-[-15px] rounded-full bg-red-600 flex justify-center align-center px-1 w-[40px] text-2xl font-bold'>{reservationCount}</div>
        :""}
        <img src={table} className='object-cover w-8  ml-2'/>
        <span className='flex justify-center align-center pl-1 text-lg font-bold'>{tableID}</span>
        <span className='flex justify-center align-center pr-1 text-lg font-bold mx-2 text-orange-400'>{userID}</span>
      </div>
      {/* Waiters Button 
      <button id='Waiter' ref = {callWtRef}
        className='fixed w-auto z-40 bottom-10 text-lg left-4 bg-yellow-500 rounded-full text-white flex items-center py-2'>
        <img src={waiter} className='object-cover w-8 mr-2'/> <span className='font-bold'>{t("Call Waiter")}</span>
        
      </button>*/}

      {/*Mobile Menu */}
      {/*Overlay */}
      {nav ? <div className='bg-black/80 fixed w-full h-[100vh] z-50 top-0 left-0'></div> : ''}
      {/*Side drawer menu */}
      <div className={nav ? 'fixed top-0 left-0 w-[300px] h-[100vh]  bg-green-100 z-50 duration-300' : 'fixed top-0 left-[-100%] w-[300px] h-[100vh]  bg-green-100 z-50 duration-300'}>
        <AiOutlineClose 
          onClick={()=> setNav(!nav)}
          size={30} 
          className='absolute right-4 top-4 cursor-pointer text-teal-600'
          />
        <h2 className='text-teal-600 text-4xl p-4 '>
        {application.charAt(0).toUpperCase() + application.slice(1)}
        </h2>
        <nav>
        <ul className='flex flex-col p-4 text-teal-600 '>
            <li 
              className='text-xl py-4 flex cursor-pointer'
              onClick={()=>{
                setNav(!nav)
                setShow(true)}}>
              <RiListUnordered size={25}  className='mr-4'/> {t("Orders")} </li>
            <li 
              className={`text-xl py-4 flex cursor-pointer ${Object.entries(orders).length===0 ? "cursor-not-allowed  text-gray-400" : ""}`}
              
              onClick={()=>{
                setNav(!nav)
                setSelected("orders")
                setShowUserLogin(true)}}>
              <FaWallet size={25} className='mr-4'/> {t("Payments")} </li>
            
            
            <li className="text-xl py-4 flex cursor-not-allowed  text-gray-400">
              <AiFillTag size={25} className='mr-4'/> {t("Promotion")} </li>
            <li className="text-xl py-4 flex cursor-not-allowed  text-gray-400">
              <BsFillSaveFill size={25} className='mr-4'/> {t("Best One")} </li>
            <li className='text-xl py-4 flex cursor-pointer'
             onClick={()=>{
              setNav(!nav)
              navigate("/" + process.env.REACT_APP_HMI_CL_TAKE_DELY + "?restaurant_id=" + restaurant_id)}}>
              <TbTruckDelivery size={25} className='mr-4'/> Delivery</li>
            <li className='text-xl py-4 flex cursor-pointer'>
              <MdHelp size={25} className='mr-4'/> Help </li>
          </ul>
        </nav>
      </div>
    </div>
    {show ? <OrderButtonModal onClose={handleClose} visible={setShow} productsCount={productsCount} visibleUser={setShowUserLogin}/> : ""}
    {showUserLogin ? <UserButtonModalDely onClose={handleCloseUserLogin} visible={setShowUserLogin} orders_selected={selected} type={"table_order"}/> : ""}
    
    
    </>
  )
}

export default Navbar_CL_Mobile